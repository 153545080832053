import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Button,
  Box,
  Divider,
} from "@mui/material";
import {
  CorGravidade,
  CorGrav,
  addZeroes,
  formatarData,
  formatarDataBaseDados,
  formatarDataBr,
} from "../../../../utils/utils";

export const DialogsAndChamadosCard = ({
  item,
  handleClickOpen,
  quantidadeTotal,
}) => {
  return (
    <Card
    className={CorGrav(item.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade)}
      sx={{
        mb: 2,
     
        borderRadius: 2,
        padding: 2,
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "nowrap",
        cursor: "pointer",
      }}
      onClick={handleClickOpen}
    >
      <CardContent>
        <Grid
          container
          spacing={1}
          sx={{
            alignItems: "space-between",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <b>O.S:</b>
            </Grid>
            <Grid item>{addZeroes(item.id, 8)}</Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <b>Abertura:</b>
            </Grid>
            <Grid item>{formatarDataBr(item.dataAbertura)}</Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <b>Solicitante:</b>
            </Grid>
            <Grid item>{item.solicitante.nome}</Grid>
          </Grid>


          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <b>O.S:</b>
            </Grid>
            <b>{item.patrimonio.numPatrimonio}</b>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <b>Gravidade:</b>
            </Grid>
            <Grid item>
              {item.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade}
            </Grid>
          </Grid>

          {item.mecanico && (
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>Mecânico:</Grid>
              <Grid item>
                <b>{item.mecanico?.usuario?.nome}</b>
              </Grid>
            </Grid>
          )}

          {item.chamadoPecas.length > 0 && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  whiteSpace: "nowrap",
                  autoheight: "auto",
                  width: "100%",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("click");
                }}
              >
                <Box
                  style={{
                    marginRight: "10px",
                    background: "white",
                    borderRadius: "50%",
                    padding: "10px 15px",
                    color: "black",
                  }}
                >
                  {quantidadeTotal}
                </Box>
                SOLICITAÇÃO DE PEÇAS
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            width: "100%",
            marginInline: -1,
          }}
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2">Descrição</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {item.patrimonio.descricao}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2">Setor</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{item.setor.descricao}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2">Unid.Produção</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {item.setor?.unidadeProducao?.fantasia}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle2">Responsável do Setor</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {item.setor?.responsavel?.nome}
              </Typography>
            </Grid>
          </Grid>

          {item.agendamento?.length > 0 && (
            <>
              <Grid>
                <Typography variant="subtitle2">Data Agendamento</Typography>
                <br />
                <Typography variant="caption">
                  {formatarData(item.agendamento[0]?.dataAgendada)}
                </Typography>
              </Grid>

              <Grid>
                <Typography variant="subtitle2">Data Encerramento</Typography>
                <br />
                <Typography variant="caption">
                  {formatarData(item.agendamento[0]?.dataAgendada2)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
