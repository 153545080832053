import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { MaquinarioGrid } from "./Component/MaquinarioGrid";

class Maquinarios extends Component {
  handleNewItem = (e) => {
    this.props.history.push("/MaquinarioItem/0");
  };
  handlePrint = (e) => {
    this.props.history.push("/maquinariosPrint");
  };
  handleStatitic = (e) => {
    this.props.history.push("/maquinariosStatistic");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Patrimônio",
      nomeNivelCrumb: "Lista de Registro",
      nomeNivelCrumb2: "Página Inicial",
      nomeNivelCrumb3: "Ajuda",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid text-right">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6 text-left">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>

                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <p>
                              <i className="fa fa-map-marker mr-1" />
                              {this.state.nomeNivelCrumb}
                            </p>
                          </li>

                          <li className="breadcrumb-item active">
                            <a className="text-primary" href="/home/">
                              <i className="fa fa-home mr-1" />
                              {this.state.nomeNivelCrumb2}
                            </a>
                          </li>

                          <li className="breadcrumb-item active">
                            <a
                              className="text-danger text-bold"
                              href="https://doc-patrimonio.appdemocrata.com/docs/Cadastros%20Auxiliares/cadastropatrimonio/Patrimonio"
                              target="_blank"
                            >
                              <i className="fa fa-info mr-1" />
                              {this.state.nomeNivelCrumb3}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body text-right w-100 d-flex justify-content-end">
                    <div className="row ">
                      <div className="col-12">
                        <div className="row">
                          <div className="d-flex flex-row">
                            <button
                              className="btn btn-sm bg-primary"
                              style={{ padding: 7, marginRight: 4 }}
                              onClick={this.handleStatitic}
                            >
                              <i class="fas fa-chart-line" /> Estatística
                            </button>
                          </div>
                          <div className="d-flex flex-row">
                            <button
                              className="btn btn-sm bg-primary"
                              style={{ padding: 7, marginRight: 4 }}
                              onClick={this.handlePrint}
                            >
                              <i className="fas fa-print" /> Relatório
                            </button>
                          </div>

                          <div className="d-flex flex-row">
                            <button
                              className="btn btn-sm bg-success"
                              style={{ padding: 7, marginRight: 4 }}
                              onClick={this.handleNewItem}
                            >
                              <i className="fas fa-file" /> Novo Registro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MaquinarioGrid />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Maquinarios;
