import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { AberturaEditar } from "./Component/app_aberturaEdit";
import { Box } from "@mui/material";

class AtualizarVersoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Abertura de Chamado",
      nomeNivelCrumb: "Formulário do Registro",
      nomeNivel2Crumb: "Lista Registros",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row ">
                  <div className="col-sm-6">
                    <h1 className="m-0">{this.state.nomePagina} </h1>
                  </div>

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/abertura">{this.state.nomeNivel2Crumb}</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {this.state.nomeNivelCrumb}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <Box
              sx={{ background: "#ffff", borderRadius: 2, width: "100%" }}
              p={2}
              m={2}
            >
              <AberturaEditar />
            </Box>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default AtualizarVersoes;
