import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

export const ChartGravidade = ({ gravidade, tam }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [gravidades] = React.useState({
    series: gravidade.map((gravid) => gravid.total),
    options: {
      plotOptions: {
        pie: {
          customScale: 1.0,
          width: "100%",
          offsetY: isMobile ? 10 : 0,
          offsetX: tam == 12 ? -20 : -20,
        },
      },
      chart: {
        type: "pie",
      },
      labels: gravidade.map((gravid) => gravid.nome),
      legend : {
        position: "right",
        offsetY: 35,
        offsetX: isMobile ? -30 : isTablet ? 160 : tam == 12 ? 300 : tam == 6 ? 50 : 125,
        fontSize: isMobile ? '8px' : '10px',
        width: 100,
        itemMargin: {
          horizontal: 0,
          vertical: 3,
        },
        formatter: (seriesName, opts) => {
          return `
          <span style="text-align: left; flex-grow: 1;">${seriesName}</span>
          <span>: ${opts.w.globals.series[opts.seriesIndex]}</span>
      </div>`;
        }
      },
      colors: ["#D1131C", "#044ECD", "#FFD74E"],
      title: {
        offsetX: isMobile ? 0 : isTablet ? -15 : 0,
        text: "GRAVIDADE DE OCORRÊNCIAS",
        align: "center",
        margin: 6,
        style: {
          height: 'auto',
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",        
        }
      },
      responsive: [
        {
          breakpoint: undefined,
          options: {
            chart: {
              width: 200,
              type: "pie",
            },
            /* legend: {
              position: "bottom",
              offsetY: 70,
              height: 370,
            }, */
          },
          title: {
            style: {
              fontSize: '12px'
            }
          },
        },
      ],
    },
  });

  return (
    <Grid>
    <Box sx={{ p: 0 }} className="shadow-lg bg-white rounded" style={{ padding: 16 }}>
      <div id="chart">
      <style>
        {`
          .apexcharts-legend-series {
            display: flex !important;
            flex-direction: column important;
            align-items: flex-start !important;
          }
        `}
      </style>
        <ReactApexChart
          options={gravidades.options}
          series={gravidades.series}
          type="pie"
          width={"100%"}
          height={"100%"}
        />
      </div>
    </Box>
    </Grid>
  );
};
