import { createTheme, responsiveFontSizes, Stack, TextField, useMediaQuery } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState } from "react";
import api from "../services/api";
import { TableSearch } from "./TableSearch";
import SearchIcon from "@mui/icons-material/Search";

export const Search = () => {
  const [search, setSearch] = useState("");
  const [resultSearch, setResultSearch] = useState([]);
  

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSearch = async (e) => {
    const resp = e.target.value;
    setSearch(resp);
    if (resp.length >= 3) {
      try {
        await api.get(`busca-generica?busca=${resp}`).then((response) => {
          setResultSearch(response.data.records);
        });

        
      } catch (err) {
        console.error("Erro ao consultar a API:", err);
      }
    } else {
      setResultSearch([]);
    }
  };

  return (
    <div className="container">
      <Stack spacing={3}>
        <TextField
          onChange={handleSearch}
          value={search}
          name="search"
          id="search"
          variant="standard"
          placeholder="Patrimônio/Chamado/Mecânico/Solicitante"
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: isMobile ? "39vh" : "55vh",
            backgroundColor: "#FFFFFF",
            borderRadius: 3,
            border: "0.5px solid #C6C4C0",
            padding: 1,
            alignItems: "left",
            justifyContent: "left",
            "& input::placeholder": {
              textAlign: "left",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }}
        />
        </Stack>
        {search && resultSearch.length > 0 && (
          <TableSearch result={resultSearch} key={`${search}-search`} />
        )}
    </div>
  )
};
