import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GridComponent } from '../../../components/controles/GridComponente';
import { addZeroes } from '../../../utils/utils';
import api from "../../../services/api";



export const GrupoMaquinaDataGrid = () => {
  let history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);

  const handleClick = (event, cellValues) => {
    history.push({
      pathname: '/grupomaquinaItem/' + cellValues.row.id
    });
  };

  const handleChange = value => {
    setSelectedRows(value);
  }

  const deleteRow = event => {
    console.log(event.target.id);
  };

  const viewRow = event => {
    console.log(event.target.id);
  };


  const columns = [
    {
      field: " Ações ",
      renderCell: (cellValues) => {
        return (

          <button type="button" className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            <i className="fas fa-pen"></i>
          </button>

        );
      }
    },

    {
      field: 'id', headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      width: 90,
      flex: 50,
      renderCell: params => {
        return <div style={{ color: '#000' }} > { addZeroes(params.row.id, process.env.REACT_APP_ZEROES_GRID)} </div>;
    }
    },


    {
      field: 'descricao', headerName: 'DESCRIÇÃO DO GRUPO',
      headerClassName: 'super-app-theme--header',

      align: 'left',
      flex: 50,
    },
    



  ];

  useEffect(() => {
   
      api.get("grupomaquinario")
        .then((response) => {
          setData(response.data.records);

        });
 
  }, []);

  if (!data) return null;

  return (

    < div >
      {/* GRID COMPONENTE RECEBE OS DADOS E AS COLUNAS QUE SERAO RENDERIZADA */}
      <GridComponent data={data} columns={columns} />

    </div >


  );
}
