import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import api from "../../../../services/api";
import { ChamadoChart } from "./Charts/ChamadoBarChart";
import { formatarData } from "../../../../utils/utils";
import { SetorChart } from "./Charts/SetorBarChart";
import { GrupoChart } from "./Charts/GrupoBarChart";
import { TableChart } from "./Charts/TableChart";
import { UnidadeChart } from "./Charts/UnidadeChart";
import { GravidadeChart } from "./Charts/GravidadeChart";
import { LegendFilter } from "./Charts/LegendFilter";

export const Statistic = ({
  dataInicial,
  dataFinal,
  filterUnidade,
  filterSetor,
  filterGrupo,
  unidade,
  setor,
  grupo,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    const getDados = async () => {
      let stringBase = `maquinario-estatistica/busca?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
      let stringSetor = "";
      let stringUnidade = "";
      let stringGrupo = "";

      setData([]);
      setLoading(true);

      if (
        filterUnidade.length != 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length == 0
      ) {
        stringUnidade = `&unidadeProducao=` + filterUnidade;

        stringBase =
          `maquinario-estatistica/busca?` +
          `dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}` +
          stringUnidade;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length == 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;

        stringBase =
          `maquinario-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length != 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;
        stringGrupo = `grupo=` + filterGrupo;

        stringBase =
          `maquinario-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          "&" +
          stringGrupo +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length != 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringGrupo = `grupo=` + filterGrupo;

        stringBase =
          `maquinario-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringGrupo +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      }

      try {
        await api.get(stringBase).then((response) => {
          setLoading(false);
          setData(response.data.records);
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    Promise.all([getDados()]);
  }, [dataInicial, dataFinal, filterUnidade, filterSetor, filterGrupo]);

  const filtros = { filterUnidade, filterSetor, filterGrupo };

  const calcularTamanhosGrid = (filterUnidade, filterSetor, filterGrupo) => {
    let tamanhoGravidade = 6;
    let tamanhoUnidade = 6;
    let tamanhoSetor = 6;
    let tamanhoGrupo = 6;
    let tamanhoPatrimonio = 6;
    let tamanhoRegistro = 12;

    if (
      filterUnidade.length !== 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length === 0
    ) {
      tamanhoGravidade = 12;
      tamanhoGrupo = 6;
      tamanhoSetor = 6;
      tamanhoRegistro = 12;
    } else if (filterSetor.length !== 0 && filterGrupo.length === 0) {
      tamanhoGravidade = 12;
      tamanhoPatrimonio = 6;
      tamanhoGrupo = 6;
      tamanhoRegistro = 12;
    } else if (filterSetor.length !== 0 && filterGrupo.length !== 0) {
      tamanhoGravidade = 12;
      tamanhoPatrimonio = 12;
      tamanhoRegistro = 12;
    } else if (
      filterUnidade.length !== 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length !== 0
    ) {
      tamanhoGravidade = 12;
      tamanhoSetor = 12;
      tamanhoRegistro = 12;
    } else if (
      filterUnidade.length === 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length !== 0
    ) {
      tamanhoSetor = 12;
      tamanhoRegistro = 12;
    }

    return {
      tamanhoUnidade,
      tamanhoGravidade,
      tamanhoSetor,
      tamanhoGrupo,
      tamanhoPatrimonio,
    };
  };
  const tamanhosGrid = calcularTamanhosGrid(
    filterUnidade,
    filterSetor,
    filterGrupo
  );

  return (
    <Box style={{ marginTop: "2cm" }} m={2}>
      {loading ? (
        <Box
          sx={{ display: "flex" }}
          justifyContent="center"
          alignContent="center"
        >
          <CircularProgress size={70} />
        </Box>
      ) : (
        <>
          {data &&
            data.map((task) => {
              return (
                <Grid container columnSpacing={2}>
                  <Grid
                    item
                    width="100%"
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    align="center"
                    gridArea="titulo"
                  >
                    <ThemeProvider theme={theme}>
                      <Typography variant="h3">
                        Análise de Ocorrências Realizadas
                      </Typography>
                      <Typography variant="h4">
                        <b>
                          {filterUnidade.length > 0
                            ? ""
                            : "Estatísticas Geral por "}{" "}
                          Período de {formatarData(dataInicial)} até{" "}
                          {formatarData(dataFinal)}
                        </b>
                      </Typography>
                      <LegendFilter
                        unidade={unidade}
                        setor={setor}
                        grupo={grupo}
                        filterUnidade={filterUnidade}
                        filterSetor={filterSetor}
                        filterGrupo={filterGrupo}
                      />
                    </ThemeProvider>
                  </Grid>
                  {task.unidadesProducao &&
                    task.unidadesProducao.length > 0 &&
                    filterUnidade == 0 && (
                      <Grid
                        md={tamanhosGrid.tamanhoUnidade}
                        sm={tamanhosGrid.tamanhoUnidade}
                        align="center"
                        gridArea="unidade"
                      >
                        <UnidadeChart unidade={task.unidadesProducao} />
                      </Grid>
                    )}
                  {task.gravidades && task.gravidades.length > 0 && (
                    <Grid
                      item
                      align="center"
                      gridArea="gravidade"
                      md={tamanhosGrid.tamanhoGravidade}
                      sm={tamanhosGrid.tamanhoGravidade}
                    >
                      <GravidadeChart gravidade={task.gravidades} />
                    </Grid>
                  )}
                  {task.patrimonios &&
                    task.patrimonios.length > 0 &&
                    filterSetor != 0 && (
                      <Grid
                        item
                        md={tamanhosGrid.tamanhoPatrimonio}
                        sm={tamanhosGrid.tamanhoPatrimonio}
                        mt={5}
                        gridArea="patrimonio"
                      >
                        <ChamadoChart patrimonio={task.patrimonios} />
                      </Grid>
                    )}
                  {task.setores &&
                    task.setores.length > 0 &&
                    filterSetor == 0 && (
                      <Grid
                        item
                        mt={5}
                        md={tamanhosGrid.tamanhoSetor}
                        sm={tamanhosGrid.tamanhoSetor}
                        gridArea="setor"
                      >
                        <SetorChart setor={task.setores} />
                      </Grid>
                    )}
                  {task.grupos &&
                    task.grupos.length > 0 &&
                    filterGrupo == 0 && (
                      <Grid
                        item
                        mt={5}
                        md={tamanhosGrid.tamanhoGrupo}
                        sm={tamanhosGrid.tamanhoGrupo}
                        gridArea="grupo"
                      >
                        <GrupoChart grupo={task.grupos} />
                      </Grid>
                    )}
                  {task.registros && task.registros.length > 0 && (
                    <Grid item md={12} sm={12} mt={5}>
                      <TableChart data={task.registros} filtros={filtros} />
                    </Grid>
                  )}
                </Grid>
              );
            })}

          {data[0] && !data[0].unidadesProducao && (
            <>
              <div className="col-12">
                <div className="invoice p-3 mb-3">
                  <div className="col-md-12">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          Não Há Informações{" "}
                        </span>
                        <span className="info-box-number text-center text-muted mb-0">
                          Defina os Critérios de Pesquisa acima!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Box>
  );
};
