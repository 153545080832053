import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";

import { MaquinarioPrint } from "./Component/MaquinarioPrint";

class MaquinariosRel extends Component {
  handleNewItem = (e) => {
    this.props.history.push("/MaquinarioItem/0");
  };
  handlePrint = (e) => {
    this.props.history.push("/maquinariosPrint");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Ambiente de Impressão Patrimônio ",
      nomeNivelCrumb: "Impressão",
      nomeNivelCrumb2: "Lista Registros",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-9">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>
                      <div className="col-sm-3">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                            <p>
                              <i className="fa fa-map-marker mr-1" />
                              {this.state.nomeNivelCrumb}
                            </p>
                          </li>
                          <li className="breadcrumb-item active">
                            <a href="/maquinarios">
                              {this.state.nomeNivelCrumb2}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body" style={{ textAlign: "right" }}>
                      <button
                          className="btn btn-sm bg-success"
                          style={{ borderRadius: "5px", padding: 10 }}
                          onClick={this.handleNewItem}
                        >
                          <i className="fas fa-file" /> Novo Registro
                        </button>
                      </div>
                      <MaquinarioPrint />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default MaquinariosRel;
