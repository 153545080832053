import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";

export const  ExpandableGroup = ({ item }) => {
    const [itemsToShow, setItemsToShow] = useState(2);
    const [expanded, setExpanded] = useState(false);
    const initialItemsToShow = 2;


    const handleExpand = () => {
      setItemsToShow(expanded ? 2 : item.grupo.length);
      setExpanded(!expanded);
    };

    return (
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            Grupo Máquinas:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {item.grupo.slice(0, itemsToShow).map((maquinas, index) => (
              <Box
                key={index}
                sx={{
                  padding: "4px 12px",
                  borderRadius: "4px",
                  backgroundColor: "#f0f5fa",
                  color: "primary.main",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 230,
                  whiteSpace: "nowrap",
                }}
              >
                {maquinas}
              </Box>
            ))}
          </Box>
        </Box>
        {item.grupo.length > initialItemsToShow && (
          <Button
            size="small"
            onClick={handleExpand}
            sx={{ alignSelf: "flex-start", marginTop: "8px" }}
          >
            {expanded ? "Mostrar Menos" : "Mostrar Mais..."}
          </Button>
        )}
      </Box>
    );
  }