import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import apiUpdate from "../../../../services/apiUpdate";
import { addZeroes } from "../../../../utils/utils";
import { CircularProgress, Grid } from "@mui/material";

export const AberturaGrid = () => {
  let history = useHistory();

  const fetchRecords = async () => {
    const response = await Promise.all([
      apiUpdate.get(`atualizacao-app-abertura`),
      apiUpdate.get(`atualizacao-app-abertura-apk`),
    ])
    return response.map(response => response.data.records);
  };

  const { data, isLoading, error } = useQuery("records", fetchRecords);

  const handleClick = (event, codigo) => {
    history.push({
      pathname: "/VersaoItem/" + codigo,
    });
  };

  if (isLoading) return <Grid container md={12} align="center"><CircularProgress /></Grid>;
  if (error) return "Ocorreu um erro ao buscar os dados";


  return (
    <div>
      <div className="row">
        <div className="col-12">
          <table>
            <caption></caption>
            <thead>
              <tr>
                <th style={{ width: "5%", textAlign: "center" }} scope="col">
                  Ações
                </th>
                <th style={{ width: "7%", textAlign: "center" }} scope="col">
                  ID
                </th>
                <th style={{ width: "12%", textAlign: "center" }} scope="col">
                  Versão
                </th>
                <th style={{ width: "12%", textAlign: "center" }} scope="col">
                  Informação
                </th>
              </tr>
            </thead>
            {data &&
              data[0]
              .sort((a, b) => {
                  return a.id - b.id
              })
              .map((item) => (
                <tbody>
                  <tr>
                    <td>
                      <button
                        type="button"
                        className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                        onClick={(event) => {
                          handleClick(event, item.id);
                        }}
                      >
                        <i className="fas fa-pen"></i>
                      </button>
                    </td>
                    <td>{addZeroes(item.id, 6)}</td>
                    <td>{item.versao}</td>
                    <td>{item.app}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};
