import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { MecanicoPrint } from "./Component/MecanicoPrint";

class MecanicoRel extends Component {
  handleNewItem = (e) => {
    this.props.history.push("/MecanicoItem/0");
  };
  handlePrint = (e) => {
    this.props.history.push("/mecanicoPrint");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Ambiente de Impressão Mecânico ",
      nomeNivelCrumb: "Lista Registros",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-9">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>

                      <div className="col-sm-3">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item active">
                            <a href="/mecanicos">{this.state.nomeNivelCrumb}</a>
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="card">
                     
                      <MecanicoPrint />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default MecanicoRel;
