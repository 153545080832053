import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export const ChartFabrica = ({ setor, filtroTipo }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    const total = setor./* .slice(0, 12);
    const total = topSetores. */reduce(
      (accumulator, set) =>
        accumulator +
        set.total,
      0
    );

  const [chartSetor] = React.useState({
    series: [
      {
        data: setor.map(dadosSetor => dadosSetor.total),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380 ,
      },
      plotOptions: {
        bar: {
          barHeight: '95%',
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      colors: [
        "#00E4E4",
      ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ["#304758"],
          fontWeight: 'normal',
          fontSize: isMobile ? '10px' : '14px',
        },
        formatter: function (val, opt) {
          if(filtroTipo != 0){
            return opt.w.globals.labels[opt.dataPointIndex] + ' :.. ' + val + '%';
          }else{
            return opt.w.globals.labels[opt.dataPointIndex] + ' :.. Total: ' + val;
          }
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: setor.map(dadosSetor => dadosSetor.nome),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: `OCORRÊNCIAS POR SETORES` ,
        align: 'center',
        floating: true,
        margin: 6,
        style: {
          height: 'auto',
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",        
        }
      },
      subtitle: {
        text: `Total: ${total.toFixed(0)}`,
        align: 'right',
        margin: 10,
        offsetX: 3,
        style: {
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",    
          fontWeight: 'bold',
          height: 'auto'
        }
    },
     
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
    },
  });

  return (
    <div className="shadow-lg bg-white rounded" style={{padding: 16,}}>
      <div id="chart">
        <ReactApexChart options={chartSetor.options} series={chartSetor.series} type="bar" height={600} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
