import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";

class MaquinarioHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Patrimônio",
      nomeNivelCrumb: "Como Usar",
      nomeNivel2Crumb: "Lista Registros",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">{this.state.nomePagina} </h1>
                  </div>

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="/maquinarios">{this.state.nomeNivel2Crumb}</a>
                      </li>

                      <li className="breadcrumb-item active">
                        {this.state.nomeNivelCrumb}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="d-flex flex-row ">
                        <div className="col-sm-12 mr-3">
                          <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                              <a href="/maquinarioHelp/cadastro">
                                Tela de Cadastros
                              </a>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="/maquinarioHelp/relatorio">
                                Relatório e Filtros
                              </a>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    {/* <MaquinarioHelpVideo /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default MaquinarioHelp;
