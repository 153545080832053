import { Typography } from "@mui/material";
import React from "react";

export const ListFilter = ({
  unidade,
  setor,
  mecanico,
  grupo,
  filterUnidade,
  filterSetor,
  filterMecanico,
  filterGrupo,
}) => {
  const listUnidade = unidade?.map((item) => {
    if (filterUnidade == item.cgcCpf) {
      return (
        <Typography variant="h6" key={item.cgcCpf}>
          <b>Unidade Produção: </b> {item.cidade}
        </Typography>
      );
    }
  });

  const listSetor = setor?.map((item) => {
    if (filterSetor == item.id) {
      return (
        <Typography variant="h6" key={item.id} className="ml-1">
          <b>Setor: </b> {item.descricao}
        </Typography>
      );
    }
  });

  const listMecanico = mecanico?.map((item) => {
    if (filterMecanico == item.usuario.nome) {
      return (
        <Typography variant="h6" key={item.id} className="ml-1">
          <b>Mecânico: </b> {item.usuario.nome}
        </Typography>
      );
    }
  });

  const listGrupo = grupo?.map((item) => {
    if (filterGrupo == item.id) {
      return (
        <Typography variant="h6" key={item.id} className="ml-1">
          <b>Grupo: </b> {item.descricao}
        </Typography>
      );
    }
  });

  return (
    <div className="row d-flex justify-content-center mt-1">
      <div>{listUnidade}</div>
      <div>{listSetor}</div>
      <div>{listGrupo}</div>
      <div>{listMecanico}</div>
    </div>
  );
};
