import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { addZeroes, formatarData } from "../../../utils/utils";
import api from "../../../services/api";
import {
  Box,
  Button,
  Checkbox,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";

import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import "./style.css";

import { QRCodeCanvas } from "qrcode.react";

import { PDFExport } from "@progress/kendo-react-pdf";
import { Link } from "react-router-dom/cjs/react-router-dom";
import VisualizaChamados from "../../Kanban/Chamados";

export const MaquinarioSetorPrintQRCode = () => {
  const [data, setData] = useState([]);
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );

  const [selectedOptionSetor, setSelectedOptionSetor] = useState(null);
  const [listUnidadeProducao, setListUnidadeProducao] = useState([]);
  const [listSetor, setListSetor] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [tipoRel, setTipoRel] = useState("QR");
  const [selectedUnidadeProducao, setSelectedUnidadeProducao] = useState([]);
  const [filteredSetores, setFilteredSetores] = useState([]);
  const [dataChamado, setDataChamado] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedChamadoId, setSelectedChamadoId] = useState(null);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [fantasia, setFantasia] = useState("");
  const [nomeSetor, setNomeSetor] = useState("");

  const tipoLista = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const handleOpenModal = (chamadoId) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedChamadoId(chamadoId);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedChamadoId(null);
  };

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Main content */}
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  useEffect(() => {
    const getUnProducao = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidadeProducao(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);

        if (selectedUnidadeProducao) {
          const filteredSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == selectedUnidadeProducao
          );
          setFilteredSetores(filteredSetores);
        }
      });
    };

    const getDados = () => {
      let stringBase = `maquinario_setores`;

      api.get(stringBase).then((response) => {
        setData(response.data.records);

        setSelectedOptionSetor(response.data.records[0].id);
      });
    };

    const getDadosChamados = () => {
      let stringBase = "";
      let stringSetor = "";
      let stringUnidade = "";

      setDataChamado([]);

      if (selectedSetor.length != 0) {
        stringSetor = `setor=` + selectedSetor;
        stringBase = `maquinario/relatorio-completo?` + stringSetor;
      } else if (selectedUnidadeProducao.length !== 0) {
        stringUnidade = `unidade=` + selectedUnidadeProducao;
        stringBase = `maquinario/relatorio-completo?` + stringUnidade;
      }

      api.get(stringBase).then((response) => {
        setDataChamado(response.data.records[0].relatorioCompleto);
      });
    };

    getSetores();
    getUnProducao();
    checked ? getDadosChamados() : getDados();
  }, [selectedUnidadeProducao, selectedSetor, checked]);

  const handleChangeManut = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeUnidadeProducao = async (event) => {
    let valor = event.target.value;
    setSelectedUnidadeProducao(valor);
    setSelectedSetor("");
    if (valor != 0) {
      let label = listUnidadeProducao.find((option) => option.cgcCpf === valor);
      setFantasia(label.fantasia);
    }
  };

  const handleChangeSetor = async (event) => {
    let valorSetor = event.target.value;
    setSelectedSetor(valorSetor);
    if (valorSetor != 0) {
      let label = filteredSetores.find((option) => option.id === valorSetor);
      setNomeSetor(label.descricao);
    }
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const listaHist = () => {
    let contador = dataChamado.filter((reg) => reg.chamados !== null).length;
    const StatusChamado = (parTitulo) => {
      switch (parTitulo) {
        case 0:
          return (
            <span style={{fontSize: 10}}>
              AGUARDANDO
            </span>
          );
        case 1:
          return (
            <span style={{fontSize: 10}}>
              ANDAMENTO
            </span>
          );
        case 2:
          return (
            <span  style={{fontSize: 10}}>
              FINALIZADO
            </span>
          );
        case 3:
          return (
            <span style={{fontSize: 10}}>
              HOMOLOGADO
            </span>
          );
        case 4:
          return (
            <span style={{fontSize: 10}}>
              RETORNADO
            </span>
          );
        case 5:
          return (
            <span style={{fontSize: 10}}>
              RECUSADO
            </span>
          );
          case 6:
            return (
              <span style={{fontSize: 10}}>
                ENCERRADO PELO SOLICITANTE
              </span>
            );
  
        default: {
          return "";
        }
      }
    };

    
    return (
      <>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                        <small className="float-right">
                          <img
                            style={{ width: "190px" }}
                            src={LogoPatrimonio}
                          />
                        </small>
                      </h4>
                    </div>
                  </div>
                  <br />


                  {/* Seleção "Lista Detalhada" com histórico */}
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        Lista de Máquinas / Patrimônio ao Setor Alocado
                        <small className="float-right">Total: <FormLabel>{contador}</FormLabel></small>
                        <br/>
                        <div style={{fontSize: 14}}>
                          {listUnidadeProducao != ""
                          ? `Unidade Produção: ${fantasia} / `
                          : " "}
                          {selectedSetor != ""
                          ? `Setor: ${nomeSetor}`
                          : ""}
                        </div>
                      </h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead>
                          {tipoRel == "Detalhados" ? (
                            <tr>
                              <th style={{ width: "17%" }}>N° Patrimônio</th>
                              <th style={{ width: "20%" }}>Descrição Patrimônio</th>
                              <th style={{ width: "33%", textAlign: "center"}}>Setores / Unid. Produção</th>
                              <th style={{ width: "25%", textAlign: "center" }}>Responsável</th>
                              <th style={{ width: "20%", textAlign: "center" }}>C.Custo</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Status</th>
                              <th style={{ width: "18%"}}>{""}</th>
                            </tr>
                          ) : (
                            <tr>
                              <th style={{ width: "15%" }}>N.Pat</th>
                              <th style={{ width: "15%" }}>Total</th>
                              <th style={{ width: "30%", textAlign: "left" }}>Descrição</th>
                              <th style={{ width: "25%", textAlign: "left" }}>Setor</th>
                              <th style={{ width: "25%", textAlign: "left" }}>Unid.Produção</th>
                              <th style={{ width: "20%", textAlign: "center" }}>Status</th> 
                              <th style={{ width: "15%"}}>{""}</th>
                            </tr>
                          )}
                        </thead>
   
                        {dataChamado
                          .filter((pat) => pat.chamados !== null)
                          .map((reg) => (
                            <React.Fragment key={reg.maquinario.id}>
                              <tbody>
                                {tipoRel == "Detalhados" ? (
                                  <tr style={{backgroundColor: `${reg.chamados && reg.chamados.length > 0
                                          ? "#E0E2FA"
                                          : "#FFFFFF"
                                      }`,
                                    }}
                                  >
                                    <td>{reg.maquinario.numPatrimonio}</td>
                                    <td>{reg.maquinario.descricao}</td>
                                    <td>{reg.maquinario.maquinarioSetores[0].setores.descricao}
                                      {" | "}{reg.maquinario.maquinarioSetores[0].setores.unidadeProducao?.fantasia}</td>
                                    <td>{reg.maquinario.maquinarioSetores[0].setores.responsavel?.nome}</td>
                                    <td>{reg.maquinario.maquinarioSetores[0].setores.ccusto?.id}</td>
                                    <td>{reg.maquinario.maquinarioSetores[0].status == 1 ? " Ativo" : " Inativo "}</td>
                                    <td style={{ backgroundColor: "#E0E2FA"}}>{""}</td>
                                  </tr>
                                ) : (
                                  <tr style={{backgroundColor: `${reg.chamados && reg.chamados.length > 0
                                          ? "#E0E2FA"
                                          : "#FFFFFF"
                                      }`,
                                    }}
                                  >
                                    <td>{addZeroes(reg.maquinario.id, 6)}</td>
                                    <td>{reg.total != null ? reg.total : "0"}</td>
                                    <td style={{ textAlign: "left" }}>{reg.maquinario.descricao}</td>
                                    <td style={{ textAlign: "left" }}>{reg.maquinario.maquinarioSetores[0]
                                          .setores.unidadeProducao.fantasia}</td>
                                    <td style={{ textAlign: "left" }}>{reg.maquinario.maquinarioSetores[0].setores.descricao}</td>
                                    <td style={{ textAlign: "left" }}>{reg.maquinario.maquinarioSetores[0].status == 1 ? "Ativo" : "Inativo"}</td>
                                    <td style={{ backgroundColor: "#E0E2FA"}}>{""}</td>
                                  </tr>
                                )}
                              </tbody>

                              {reg.chamados && reg.chamados.length > 0 && (
                                <>
                                  <thead>
                                    <tr style={{ backgroundColor: "#E0E2FA" }}>
                                      <th style={{width: "5%",backgroundColor: "#FFFFFF"}}>{""}</th>
                                      <th style={{width: "5%",textAlign: "left"}}>Chamado</th>
                                      <th style={{width: "19%",textAlign: "left"}}>Problema</th>
                                      <th style={{width: "8%",textAlign: "left"}}>Abertura</th>
                                      <th style={{width: "8%",textAlign: "left"}}>Mecânico</th>
                                      <th style={{width: "8%",textAlign: "left"}}>Status</th>
                                      <th style={{width: "14%",textAlign: "left"}}>Encerrado</th>
                                    </tr>
                                  </thead>

                                  {reg.chamados.map((chamado) => (
                                    <tbody>
                                      <tr
                                        style={{ backgroundColor: "#F2F2F2" }}>
                                        <td style={{width: "15%", backgroundColor: "#FFFFFF"}}>{""}</td>
                                        <td style={{width: "15%", textAlign: "left"}}>
                                          <Link onClick={() => handleOpenModal(chamado.id)}>
                                            {addZeroes(chamado.id, 6)}
                                          </Link>
                                        </td>
                                        <td style={{ textAlign: "left" }}>{chamado.motivoAbertura}</td>
                                        <td style={{ textAlign: "left" }}>{formatarData(chamado.dataAbertura)}</td>
                                        <td style={{ textAlign: "left" }}>{chamado.mecanico?.usuario.nome}</td>
                                        <td style={{ textAlign: "left" }}>{StatusChamado(chamado.status)}</td>
                                          {chamado.dataFechamento == null ? (
                                          <td></td>
                                          ) : (
                                          <td style={{ textAlign: "left" }}>{formatarData(chamado.dataFechamento)}</td>
                                        )}
                                       
                                      </tr>
                                    </tbody>
                                  ))}
                                  <div className="text-white">{" . "}</div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={modalIsOpen}
              onClose={handleCloseModal}
            >
              <DialogTitle></DialogTitle>

              <DialogContent>
                <DialogContentText></DialogContentText>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    width: "fit-content",
                  }}
                >
                  <VisualizaChamados selecionar={selectedChamadoId} />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Fechar</Button>
              </DialogActions>
            </Dialog>
          </div>
        </section>
      </>
    );
  };

  const listaDetalhes = () => {
    const total = data.filter(
      (reg) => reg.setores?.id === selectedSetor
    ).length;
    return (
      <>
        <section className="content">
          <div >
            <div className="row">
              <div className="col-12">
                <div  >
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                        <small className="float-right">
                          <img
                            style={{ width: "190px" }}
                            src={LogoPatrimonio}
                          />
                        </small>
                      </h4>
                    </div>
                  </div>
                  <br />


                  {/* Selecionado "Lista Detalhada" */}
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        Lista de Máquinas / Patrimônio ao Setor Alocado
                        <small className="float-right">Total: <FormLabel>{total}</FormLabel></small>
                        <br/>
                        <div style={{fontSize: 14}}>
                          {listUnidadeProducao != ""
                          ? `Unidade Produção: ${fantasia} / `
                          : " "}
                          {selectedSetor != ""
                          ? `Setor: ${nomeSetor}`
                          : ""}
                        </div>
                      </h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "11%" }}>N° Patrimônio</th>
                            <th style={{ width: "15%" }}>Descrição Patrimônio</th>
                            <th style={{ width: "42%" }}>Setores / Unid. Produção</th>
                            <th style={{ width: "15%" }}>Responsável</th>
                            <th style={{ width: "10%" }}>C.Custo</th>
                            <th style={{ width: "8%" }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data
                            .sort((a, b) => a.id - b.id)
                            .filter(
                              (regmap) => regmap.setores?.id === selectedSetor
                            )
                            .map((reg) => (
                              <tr key={reg.maquinario}>
                                <td>{reg.maquinario.numPatrimonio}</td>
                                <td>{reg.maquinario.descricao}</td>
                                <td>{reg.setores.descricao} |{" "}{reg.setores.unidadeProducao?.fantasia}</td>
                                <td>{reg.setores.responsavel.nome}</td>
                                <td>{reg.setores.ccusto.id}</td>
                                <td>{reg.status == 1 ? " Ativo" : " Inativo "}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const htmlFormat = () => {
    const total = data.filter(
      (reg) => reg.setores?.id === selectedSetor
    ).length;
    return (
      <>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="p-1 mb-3">
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                        <small className="float-right">
                          <img
                            style={{ width: "190px" }}
                            src={LogoPatrimonio}
                          />
                        </small>
                      </h4>
                      <h5></h5>
                    </div>
                  </div>
                  <br />


                  {/* Seleção de "Lista" */}
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        Lista de Máquinas / Patrimônio ao Setor Alocado
                        <small className="float-right">
                          Total:{" "}
                          <FormLabel>{total}</FormLabel> </small>
                          <br/>
                        <div style={{fontSize: 14}}>
                          {listUnidadeProducao != ""
                          ? `Unidade Produção: ${fantasia} / `
                          : " "}
                          {selectedSetor != ""
                          ? `Setor: ${nomeSetor}`
                          : ""}
                        </div>
                      </h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "11%" }}>N° Patrimônio</th>
                            <th style={{ width: "11%" }}>N° Patrimônio Antigo</th>
                            <th style={{ width: "32%" }}>Descrição Patrimônio</th>
                            <th style={{ width: "35%" }}>Setores / Unid. Produção</th>
                            <th style={{ width: "10%" }}>Status</th>
                          </tr>
                        </thead>

                        {data
                          .sort((a, b) => a.id - b.id)
                          .filter(
                            (regmap) => regmap.setores?.id === selectedSetor
                          )
                          .map((reg) => (
                            <tbody>
                              <tr key={reg.maquinario}>
                                <td>{reg.maquinario.numPatrimonio}</td>
                                <td>{reg.maquinario.numPatrimonioAdd}</td>
                                <td>{reg.maquinario.descricao}</td>
                                <td>{reg.setores.descricao} |{" "}{reg.setores.unidadeProducao?.fantasia}</td>
                                <td>{reg.status == 1 ? " Ativo" : " Inativo "}</td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const htmlFormatQr = () => {
    let soma = "";
    { soma = data.filter(
      (reg) => reg.setores?.id === selectedSetor
      ).length}
    return (
      <>
        <meta charSet="UTF-8"></meta>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div >
                  <div className="col-md-12">
                  
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        Lista de QRCode de Máquinas / Patrimônio ao Setor Alocado
                        <small className="float-right">
                          Total:{" "}
                          <FormLabel>{soma}</FormLabel> </small>
                          <br/>
                        <div style={{fontSize: 14}}>
                          {listUnidadeProducao != ""
                          ? `Unidade Produção: ${fantasia} / `
                          : " "}
                          {selectedSetor != ""
                          ? `Setor: ${nomeSetor}`
                          : ""}
                        </div>
                      </h4>
                    </div>
                  </div>

                    {data
                      .sort((a, b) => a.id - b.id)
                      .filter((regmap) => regmap.setores?.id === selectedSetor)
                      .map((reg) => (
                        <div className="card" key={reg.id}>
                          <div className="card-body">
                            <div
                              className="tab-content"
                              id="custom-content-below-tabContent"
                            >
                              <div className="row">
                                <div className="col-sm-2">
                                  <QRCodeCanvas
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                    }}
                                    data-qr={reg.maquinario?.numPatrimonio}
                                    value={reg.maquinario?.numPatrimonio}
                                    key={reg.maquinario?.numPatrimonio}
                                    renderAs="svg"
                                  />
                                </div>
                                <div />

                                <div className="col-10">
                                  <div className="row">
                                    <div className="col-4">
                                      <span>
                                        <small>CÓDIGO: </small>
                                        <br />

                                        <b> {addZeroes(reg.id, 8)}</b>
                                      </span>
                                    </div>

                                    <div className="col-6">
                                      <span>
                                        <small>DESCRIÇÃO DO PATRIMÔNIO: </small>
                                        <br />
                                        <b>{reg.maquinario?.descricao}</b>
                                      </span>
                                    </div>

                                    <div className="row">
                                      <div className="col-12">
                                        <div>
                                          <small>PATRIMÔNIO: </small>
                                          <br />
                                          <b>{reg.maquinario?.numPatrimonio}</b>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <small>SETOR: </small>
                                      <br />
                                      <b>{reg.setores?.descricao}</b>
                                    </div>

                                    <div className="col-6">
                                      <small>UNIDADE DE PRODUÇÃO: </small>
                                      <br />
                                      <b>
                                        {reg.setores?.unidadeProducao?.fantasia}
                                      </b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const htmlFormatQrPrint = () => {
    let somaQr = "";
    { somaQr = data.filter(
      (reg) => reg.setores?.id === selectedSetor
      ).length}
    return (
      <>
        <meta charSet="UTF-8"></meta>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="col-md-12">
              {/* <br/> */}
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        QRCode de Máquinas / Patrimônio ao Setor Alocado
                        <small className="float-right">
                          Total:{" "}
                          <FormLabel>{somaQr}</FormLabel> </small>
                          <br/>
                        <div style={{fontSize: 14}}>
                          {listUnidadeProducao != ""
                          ? `Unidade Produção: ${fantasia} / `
                          : " "}
                          {selectedSetor != ""
                          ? `Setor: ${nomeSetor}`
                          : ""}
                        </div>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>


                <div className="p-3 mb-3">
                  {/* <p className='page-break'> */}
                  <div className="row">
                    {data
                      .filter((reg) => reg.setores?.id === selectedSetor)
                      .map((regmap) => (
                        <div
                          key={regmap.id}
                          style={{
                            margin: "15px",
                            border: "1.5px solid #3c3c3c",
                            //borderRadius: "20px",
                            width: "450px",
                          }}
                        >
                          <div>
                            <div
                              div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  marginLeft: "42px",
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                <span>{regmap.maquinario.numPatrimonio}</span>
                                <br />
                                <span>{regmap.maquinario.descricao}</span>
                              </div>

                              <QRCodeCanvas
                                style={{
                                  width: "350px",
                                  height: "350px",
                                }}
                                data-qr={regmap.maquinario?.numPatrimonio}
                                value={regmap.maquinario?.numPatrimonio}
                                key={regmap.maquinario?.numPatrimonio}
                                renderAs="svg"
                              />

                              <small>
                                <img
                                  style={{
                                    width: "190px",
                                    marginBottom: "20px",
                                    marginTop: "10px",
                                  }}
                                  src={Logo}
                                  alt="Logo"
                                />
                              </small>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  const htmlNull = () => {
    return (
      <>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="invoice p-3 mb-3">
                  <div className="col-md-12">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          Não Há Informações{" "}
                        </span>
                        <span className="info-box-number text-center text-muted mb-0">
                          Defina os Critérios de Pesquisa acima!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <div className="card-body">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal">
              <div>
                <FormControl sx={{ m: 1, minWidth: 250, marginRight: 2 }}>
                  <InputLabel id="demo-controlled-open-select-label">
                    Unidade de Produção
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    label="Unidade de Produção"
                    value={selectedUnidadeProducao}
                    onChange={handleChangeUnidadeProducao}
                    spacing={3}
                  >
                    <MenuItem value="0">Selecione</MenuItem>
                    {listUnidadeProducao
                      .sort((a, b) => {
                        if (a.fantasia > b.fantasia) return 1;
                        if (a.fantasia < b.fantasia) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.cgcCpf}>{item.fantasia}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 250 }}>
                  <InputLabel id="demo-controlled-open-select-label">
                    Setor
                  </InputLabel>

                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    //size="small"
                    //sx={{ minWidth: 280 }}
                    label="Setor"
                    value={selectedSetor}
                    onChange={handleChangeSetor}
                    spacing={3}
                  >
                    <MenuItem value="0">Selecione</MenuItem>
                    {filteredSetores
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.id}>{item.descricao}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-12 mt-3">
                <FormControl sx={{ m: 0, minWidth: 300 }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Tipo de Relatório
                  </FormLabel>
                  <div className="row">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={tipoRel}
                      onChange={tipoLista}
                    >
                      <FormControlLabel
                        value="QR"
                        control={<Radio />}
                        label="QRCode"
                      />
                      <FormControlLabel
                        value="QRPrint"
                        control={<Radio />}
                        label="QRCode Print"
                      />

                      <FormControlLabel
                        value="Lista"
                        control={<Radio />}
                        label="Lista"
                      />

                      <FormControlLabel
                        value="Detalhados"
                        control={<Radio />}
                        label="Lista Detalhada"
                      />
                    </RadioGroup>
                  </div>
                </FormControl>

                <FormControl
                  sx={{
                    //minWidth: 100,
                    m: 1,
                    marginTop: 4,
                    marginRight: 2,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          checked={checked}
                          onChange={handleChangeManut}
                        />
                      }
                      label=" Hist.Manutenção"
                    />
                  </FormGroup>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 100, marginTop: 4 }}>
                  <Button
                    type="button"
                    onClick={handleExport}
                    //sx={{ m: 1, minWidth: 50, maxHeight: 55 }}
                    variant="outlined"
                  >
                    Gerar PDF
                  </Button>
                </FormControl>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        {(() => {
          if (selectedSetor.length == 0) {
            return htmlNull();
          } else {
            return (
              <div>
                {(() => {
                  if (tipoRel == "QR") {
                    return (
                      <PDFExport
                        scale={0.4}
                        paperSize={"A4"}
                        margin="0.8cm"
                        fileName="maquinarioQRCodeLista.pdf"
                        forcePageBreak=".page-break"
                        pageTemplate={PDFExportPageTemplate}
                        ref={pdf}
                      >
                        {htmlFormatQr()}
                      </PDFExport>
                    );
                  } else if (tipoRel == "Lista") {
                    return (
                      <PDFExport
                        scale={0.4}
                        paperSize={"A4"}
                        margin="0.8cm"
                        fileName="maquinarioListaSetor.pdf"
                        landscape="true"
                        forcePageBreak=".page-break"
                        pageTemplate={PDFExportPageTemplate}
                        ref={pdf}
                      >
                        {checked ? listaHist() : htmlFormat()}
                      </PDFExport>
                    );
                  } else if (tipoRel == "Detalhados") {
                    return (
                      <PDFExport
                        scale={0.4}
                        paperSize={"A4"}
                        margin="0.8cm"
                        fileName="maquinarioListaSetor.pdf"
                        landscape="true"
                        forcePageBreak=".page-break"
                        pageTemplate={PDFExportPageTemplate}
                        ref={pdf}
                      >
                        {checked ? listaHist() : listaDetalhes()}
                      </PDFExport>
                    );
                  } else {
                    return (
                      <PDFExport
                        scale={0.4}
                        paperSize={"A4"}
                        fileName="maquinarioQRCodPat.pdf"
                        forcePageBreak=".page-break"
                        ref={pdf}
                      >
                        {htmlFormatQrPrint()}
                      </PDFExport>
                    );
                  }
                })()}
              </div>
            );
          }
        })()}
      </div>
    </div>
  );
};
