import { createContext, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { matprimaContext } from "./MatprimaContext";

const DeleteMatPrimaContext = createContext();

export const DeleteMatPrimaContextProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const { setIsDialog } = matprimaContext();
  const [removedIds, setRemovedIds] = useState([]);

  const mutation = useMutation(
    async (id) => {
      const response = await api.delete(`matprima-pecas/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        setIsDialog(false);
        setRemovedIds([])
        queryClient.invalidateQueries(`listTable`);
      },
      onError: () => {
        setIsDialog(false);
        queryClient.invalidateQueries(`listTable`);
      },
    }
  );

  const handleDelete = async () => {
    for (const id of removedIds) {
      await mutation.mutateAsync(id);
    }
  };

  return (
    <DeleteMatPrimaContext.Provider
      value={{
        handleDelete,
        removedIds,
        setRemovedIds,
        isLoadingDelete: mutation.isLoading,
      }}
    >
      {children}
    </DeleteMatPrimaContext.Provider>
  );
};

export const deleteMatPrimaContext = () => {
  return useContext(DeleteMatPrimaContext);
};
