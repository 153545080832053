import React from "react";
import ReactApexChart from "react-apexcharts";

export const UnidadeChart = ({ unidade }) => {
  const [unidadeProd] = React.useState({
    series: unidade.map((unidProd) => unidProd.total),
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: unidade.map((unidProd) => unidProd.nome),
      legend: {
        offsetY: 70,
        height: 370,
        fontSize: '14px'
      },
      colors: ["#A5A5A5", "#4472C4", "#ED7D31"],
      title: {
        text: "OCORRÊNCIAS POR UNIDADE PRODUÇÃO",
        margin: 6,
        style: {
          height: 'auto',
          fontSize: '17px',          
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              type: "pie",
            },
            legend: {
              position: "bottom",
              offsetY: 70,
              height: 370,
            },
          },
        },
      ],
    },
  });

  return (
    <div className="shadow-lg bg-white rounded" style={{ padding: 10 }}>
      <div id="chart">
        <ReactApexChart
          options={unidadeProd.options}
          series={unidadeProd.series}
          type="pie"
          width={430}
        />
      </div>
    </div>
  );
};
