import React from "react";

export const TableChart = ({ data, filtros }) => {
  const total = data.reduce((accumulator, dat) => accumulator + dat.total, 0);

  return (
    <>
      <h4>
        <small class="float-right">
          Registro(s): <b style={{ fontSize: 18 }}></b>
          {total}
        </small>
      </h4>
      <table
        className="table-responsive"
        style={{ overflowY: "auto", height: data.length >= 100 ? 400 : "", width: "100%" }}
      >
        <thead>
          <tr>
            <th style={{ width: "8%" }}>Patrimonio</th>
            <th style={{ width: "30%" }}>Descrição</th>
            <th style={{ width: "23%" }}>Unidade Produção</th>
            <th style={{ width: "17%" }}>Setor</th>
            <th style={{ width: "17%" }}>Grupo</th>
            <th style={{ width: "5%" }}>Total</th>
          </tr>
        </thead>
        {data &&
          data.map((it) => {
            return (
              <tbody>
                <tr key={it.id}>
                  <td>{it.numPatrimonio}</td>
                  <td>{it.descricao}</td>
                  <td>
                    {it.maquinarioSetores[0]?.setores.unidadeProducao.cidade}
                  </td>
                  <td>{it.maquinarioSetores[0]?.setores.descricao}</td>
                  <td>{it.grupo.descricao}</td>
                  <td>{it.total}</td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </>
  );
};
