import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";


export const ChartUnidade = ({ unidade }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [unidadeProd] = React.useState({
    series: unidade.map((unidProd) => unidProd.total),
    options: {
      plotOptions: {
        pie: {
          customScale: 1.0,
          width: "100%",
        },
      },
      chart: {
        type: "pie",
      },
      labels: unidade.map((unidProd) => unidProd.nome),
      legend : {
        position: "right",
        offsetY: 30,
        offsetX: isMobile ? -30 : isTablet ? 50 : 0,
        height: "100%",
        fontSize: isTablet ? '8px' : isMobile ? '6px' : '10px',
        width: isTablet ? 150 : 260,
        itemMargin: {
          horizontal: 0,
          vertical: 3,
        },
        formatter: (seriesName, opts) => {
          
          return `
          <span style="text-align: left; flex-grow: 1;">${seriesName}</span>
          <span>: ${opts.w.globals.series[opts.seriesIndex]}</span>
      </div>`;
        }
      },
      colors: ["#A5A5A5", "#4472C4", "#ED7D31", "#6BB38E"],
      title: {        
        text: "OCORRÊNCIAS POR UN. PRODUÇÃO",
        align: "center",
        margin: 6,
        style: {
          height: 'auto',
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",        
        }
      },
      responsive: [
        {
          breakpoint: undefined,
          options: {
            chart: {
              width: 200,
              type: "pie",
            },
     
          },
          title: {
            style: {
              fontSize: '12px'
            }
          },
        },
      ],
    },
  });

  

  return (
    <Grid>
    <Box className="shadow-lg bg-white rounded" style={{ padding: 16 }}>
     <div id="chart">
     <style>
     {`
          .apexcharts-legend-series {
            display: flex !important;
            align-items: flex-start !important;
          }
        `}
      </style>
        <ReactApexChart
          options={unidadeProd.options}
          series={unidadeProd.series}
          type="pie"
          width={"100%"}
          height={"100%"}
        />
      </div>
    </Box>
    </Grid>
  );
};
