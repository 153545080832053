import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import api from "../../../services/api";
import { Designados } from "./ColunasDesignadas";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
const KanbanGrid = () => {
  const [dateValue1, setDateValue1] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(dayjs().format("YYYY-MM-DD"));
  const [tipoChamadoDesc, setTipoChamadoDesc] = useState("Todos");
  const [mecanico, setMecanico] = useState("Todos");
  const [listMecanico, setListMecanico] = useState([]);

  useEffect(() => {
    const fetchMechanics = async () => {
      const response = await api.get("mecanico");
      setListMecanico(response.data.records);
    };
    fetchMechanics();
  }, []);

  const sortedMechanics = listMecanico
    .filter((mec) => mec.status === 1)
    .sort((a, b) => a.usuario.nome.localeCompare(b.usuario.nome));

  return (
    <>
    <div className="card-row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal">
              <div className="row">
                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="pt-br"
                    >
                      <Stack spacing={3}>
                        <DatePicker
                          sx={{ m: 0, minWidth: 200 }}
                          label="Data Inicial"
                          value={dateValue1}
                          onChange={(newValue) =>
                            setDateValue1(dayjs(newValue).format("YYYY-MM-DD"))
                          }
                          renderInput={(params) => <TextField {...params} />}
                          dayOfWeekFormatter={(day) => {
                            const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                            return daysOfWeek[day];
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="pt-br"
                    >
                      <Stack spacing={3}>
                        <DatePicker
                          sx={{ m: 0, minWidth: 200 }}
                          label="Data Final"
                          value={dateValue2}
                          onChange={(newValue) =>
                            setDateValue2(dayjs(newValue).format("YYYY-MM-DD"))
                          }
                          renderInput={(params) => <TextField {...params} />}
                          dayOfWeekFormatter={(day) => {
                            const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                            return daysOfWeek[day];
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="col-sm-3 col-md-3">
                  <div className="form-group">
                    <Stack spacing={3}>
                      <FormControl>
                        <InputLabel>Mecânico</InputLabel>
                        <Select
                          label="Mecânico"
                          value={mecanico}
                          sx={{ m: 0, minWidth: 160 }}
                          onChange={(event) => setMecanico(event.target.value)}
                        >
                          <MenuItem value="Todos">Todos</MenuItem>
                          {sortedMechanics.map((item) => (
                            <MenuItem value={item.id}>
                              {item.usuario.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </div>
                </div>

                <div className="col-sm-3 col-md-3">
                  <div className="form-group">
                    <Stack spacing={3}>
                      <FormControl>
                        <InputLabel>Gravidade</InputLabel>
                        <Select
                          label="Gravidade"
                          value={tipoChamadoDesc}
                          sx={{ m: 0, maxWidth: 120 }}
                          onChange={(event) =>
                            setTipoChamadoDesc(event.target.value)
                          }
                        >
                          <MenuItem value="Todos">Todos</MenuItem>
                          <MenuItem value="BAIXA">BAIXA</MenuItem>
                          <MenuItem value="MEDIA">MEDIA</MenuItem>
                          <MenuItem value="ALTA">ALTA</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </div>
                </div>
                {/* <div className="col-sm-2 col-md-2">
                  <Stack spacing={3}>
                    <FormControl>
                      <Button
                        sx={{ m: 0, width: 52, height: 52 }}
                        variant="outlined"
                        endIcon={<SearchIcon sx={{ p: 1, width: 50, height: 50 }}  />}
                      />
                    </FormControl>
                  </Stack>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row">
        {[0, 1, 2, 4, 3].map((param) => (
          <Designados
            key={param}
            parametro={param}
            dataInicial={dateValue1}
            dataFinal={dateValue2}
            mecan={mecanico}
            tipoCham={tipoChamadoDesc}
          />
        ))}
      </div>
    </div>
    </>
  );
};

export default KanbanGrid;
