import { Alert, InputLabel } from "@mui/material";
import React from "react";
import { addZeroes } from "../../../utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../services/api";
import PesquisaImagem from "./PesquisaImagem";

const PesquisaMaquinario = ({ maquinario }) => {
  return (
    <div className="col-12 mb-4 mt-4">
      <hr />
      {maquinario.map((maq) => (
        <>
          {(() => {
            if (maq.maquinario.status == 0) {
              return (
                <Alert severity="error">Esse patrimônio está inativo </Alert>
              );
            } else if (maq.maquinario.status == 2) {
              return (
                <Alert severity="error">
                  Esse patrimônio está em Manutenção{" "}
                </Alert>
              );
            } else if (maq.maquinario.status == 1) {
              return (
                <div
                  className="d-flex justify-content-start"
                  key={maq.maquinario.id}
                >
                  <div className="row">
                    <div className="col-sm-2">
                      <PesquisaImagem maquinario={maq.maquinario.id} />
                    </div>
                    <div className="col-sm-2">
                      <InputLabel className="col-sm- col-form-label">
                        ID
                      </InputLabel>
                      <input
                        type="text"
                        className="form-control"
                        id="id"
                        name="id"
                        value={addZeroes(maq.maquinario.id, 8)}
                        placeholder="ID..."
                        readOnly="True"
                      />
                    </div>

                    <div className="col-sm-3">
                      <InputLabel className="col-sm-6 col-form-label">
                        Descrição
                      </InputLabel>
                      <input
                        type="text"
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        value={maq.maquinario.descricao}
                        placeholder="Descrição..."
                        readOnly="True"
                      />
                    </div>

                    <div className="col-sm-3">
                      <InputLabel className="col-sm-6 col-form-label">
                        Setor
                      </InputLabel>
                      <input
                        type="text"
                        className="form-control"
                        id="setor"
                        name="setor"
                        value={maq.setores.descricao}
                        placeholder="Setor..."
                        readOnly="True"
                      />
                    </div>

                    <div className="col-sm-2">
                      <InputLabel className="col-sm-12 col-form-label">
                        Responsavel
                      </InputLabel>
                      <input
                        type="text"
                        className="form-control"
                        id="responsavel"
                        name="responsavel"
                        value={maq.setores.responsavel.nome}
                        placeholder="Responsavel"
                        readOnly="True"
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <Alert severity="error">Esse patrimônio não existe </Alert>
              );
            }
          })()}
        </>
      ))}
    </div>
  );
};

export default PesquisaMaquinario;
