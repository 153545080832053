import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { useFilterContext } from "./listModal/andamentos/FilterContext";
import { Link } from "react-router-dom";

const CardDashboardRecusado = () => {
  const [data, setData] = useState([]);
  const { dateValue1, dateValue2 } = useFilterContext();

  useEffect(() => {
    api.get(`chamado/chamadoTotalRecusado?maiorOuIgual=${dateValue1}&menorOuIgual=${dateValue2}`).then((response) => {
      setData(response.data.records[0]);
    });
  }, [dateValue1, dateValue2]);


  return (
    <>
      <Link to="/chamadosFilter/rec" className="">
        <div className="card">
          <div className="card-content">
            <div className="card-body">
              <div className="media d-flex">
                <div className="align-self-center">
                  <i className="icon-trash warning font-medium-2 float-left" />
                </div>
                 
                <div className="media-body text-right">
                  <h3>{data.quantidade || 0}</h3>
                  <span>RECUSADO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>

    </>
  );
};

export default CardDashboardRecusado;
