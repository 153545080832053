import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import api from "../services/api";

const GetPecasContext = createContext();

export const GetPecasContextProvider = ({ children }) => {

    const { data: listUnidades, isLoading: isLoadingUnidades } = useQuery(
        ["listUnid"],
        async () => {
          const response = await api.get(`/unidadeproducao/`);
          return response.data.records;
        },
        {
          refetchOnWindowFocus: false,
        }
      );
    
      const { data: grupoMaquina, isLoading: isLoadingMaquina } = useQuery(
        ["grupoMaquina"],
        async () => {
          const response = await api.get("/grupomaquinario");
          return response.data.records;
        },
        {
          refetchOnWindowFocus: false,
        }
      );
    
      const { data: grupoMateria, isLoading: isLoadingGrupo } = useQuery(
        ["grupoMateria"],
        async () => {
          const response = await api.get(`grupo-matprima-pecas`);
          return response.data;
        },
        {
          refetchOnWindowFocus: false,
        }
      );
    

  return (
    <GetPecasContext.Provider
      value={{
        listUnidades,
        isLoadingUnidades,
        grupoMaquina,
        isLoadingMaquina,
        grupoMateria,
        isLoadingGrupo
      }}
    >
      {children}
    </GetPecasContext.Provider>
  );
};
export const getPecasContext = () => {
  return useContext(GetPecasContext);
};
