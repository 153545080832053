import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../../../services/api";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import {DatePicker, LocalizationProvider, ptBR, TimePicker} from "@mui/x-date-pickers";

import { InputLabel, Paper, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";

import VisualizaChamadosAdmin from "../VisualizarChamadoAdm";

import { Select, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import CustomizedAndDialogs from "../andamentos/DialogsAndChamados";

const ChamadosFinalizadosGrid = ({ calls }) => {
  let history = useHistory();

  let tituloFiltro = "";

  const { st } = useParams();
  const [data, setData] = useState([]);
  const [nome, setNome] = useState(calls);
  const [descricao, setDescricao] = useState("");

  const [registro, setRegistro] = useState([]);

  const [setor, setSetor] = useState([]);
  const [dataAbertura, setDataAbertura] = useState([]);
  const [status, setStatus] = useState([]);
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);

  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()));

  const [tipoChamadoDesc, setTipoChamadoDesc] = useState(["Todos"]);
  const [selectedTipoChamadoDesc, setSelectedTipoChamadoDesc] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [mecanico, setMecanico] = useState(["Todos"]);
  const [listMecanico, setListMecanico] = useState([]);
  const [patrimonio, setPatrimonio] = useState("");
  const [solicitante, setSolicitante] = useState(["Todos"]);
  const [selectedSolicitante, setSelectedSolicitante] = useState(["Todos"]);
  const [listSolicitante, setListSolicitante] = useState([]);
  const [unidadeProducao, setUnidadeProducao] = useState([]);

  const dadosFiltros = async () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));

    console.log(dateValue1);
    console.log(dateValue2);
  };

  const aoSairControleData = () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));

    console.log(dateValue1);
    console.log(dateValue2);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      api.get("/chamado").then((response) => {
        setData(response.data.records);
        setNome(response.data.records[0].solicitante.nome);
        setDescricao(response.data.records[0].descricao);
        setDescricao(response.data.records[0].patrimonio.descricao);
        setSetor(response.data.records[0].setor.descricao);
        setDataAbertura(response.data.records[0].dataAbertura);
        setStatus(response.data.records[0].status);
        setPatrimonio(response.data.records[0].patrimonio.numPatrimonio);
      });
      
      listaDados();
    }, 10000);

    return () => clearInterval(interval);
  }, []);


  const handleChangeTipoChamadoDesc = (event) => {
    let tipoChamadoDesc = event.target.value;
    setMecanico("Todos");
    setTipoChamadoDesc(tipoChamadoDesc);
  };


  const handleChangeMecanico = (event) => {
    let mecanicoSelecionado = event.target.value;
    setMecanico(mecanicoSelecionado);
  };

  useEffect(() => {
    const getMecanico = async () => {
      await api.get(`mecanico`).then((response) => {
        setListMecanico(response.data.records);
      });
    };
    getMecanico();
    dadosFiltros();
    /* getTipoChamadoDesc(); */
  }, []);

  /* const getTipoChamadoDesc = async () => {
    await api.get(`tipoChamadoDescricao`).then((response) => {
      setSelectedTipoChamadoDesc(response.data.records);
    });
}; */

  useEffect(() => {
    api.get(`/chamado`).then((response) => {
      setData(response.data.records);
    });
  }, []);

  function listTipoChamadoDesc(chamado) {
    if (tipoChamadoDesc != "Todos") {
      return (
        chamado.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade ==
        tipoChamadoDesc
      );
    } else {
      return (
        chamado.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade != ""
      );
    }
  }

  const listaDados = () => {
    let parametro = st != null ? st : "ab";
    
    if (parametro == "fin") {
      tituloFiltro = " Filtrar por Chamados Finalizados";

      if (dateValue1.length > 0 && dateValue2.length > 0) {
        return data.filter(

          (chamado) =>
          (chamado.status == 3 || chamado.status == 2) &&
            chamado.dataAbertura >= `${dateValue1} 00:00:00` &&
            chamado.dataAbertura <= `${dateValue2} 23:59:00` && 
            (mecanico == "Todos" || chamado.mecanico?.id == mecanico) &&
            listTipoChamadoDesc(chamado)
        );
      } else {
        return data.filter(
          (chamado) =>
            chamado.status == 2 || chamado.status == 3 && listTipoChamadoDesc(chamado));
      }
    } else if (parametro == "ab") {
      tituloFiltro = " Filtrar por Chamados Abertos";

      if (dateValue1.length > 0 && dateValue2.length > 0) {
        return data.filter(
          (chamado) =>
            chamado.status == 0 && chamado.mecanico == null &&
            chamado.dataAbertura >= `${dateValue1} 00:00:00` &&
            chamado.dataAbertura <= `${dateValue2} 23:59:00` && listTipoChamadoDesc(chamado));
      } else {
        return data.filter(
          (chamado) =>
            chamado.status == 0 && chamado.mecanico == null && listTipoChamadoDesc(chamado));
      }
    } else if (parametro == "re") {
      tituloFiltro = " Filtrar por Chamados Retornados";

      if (dateValue1.length > 0 && dateValue2.length > 0) {
        return data.filter(
          (chamado) =>
            chamado.status == 4 &&
            chamado.mecanico != null &&
            chamado.movimento.length > 0 &&
            chamado.dataAbertura >= `${dateValue1} 00:00:00` &&
            chamado.dataAbertura <= `${dateValue2} 23:59:00` &&
            listTipoChamadoDesc(chamado)
        );
      } else {
        return data.filter(
          (chamado) =>
            chamado.status == 4 &&
            chamado.mecanico != null &&
            chamado.movimento.length > 0 &&
            listTipoChamadoDesc(chamado)
        );
      }
    } else if (parametro == "ds") {
      tituloFiltro = " Filtrar por Chamados Designados";

      if (dateValue1.length > 0 && dateValue2.length > 0) {
        return data.filter(
          (chamado) =>
            chamado.status != 2 &&
            chamado.status != 3 &&
            chamado.status != 0 &&
            chamado.dataDesignacao >= `${dateValue1} 00:00:00` &&
            chamado.dataDesignacao <= `${dateValue2} 23:59:00` &&
            (mecanico == "Todos" || chamado.mecanico?.id == mecanico) &&
            listTipoChamadoDesc(chamado)
        );
      } else {
        return data.filter(
          (chamado) =>
            chamado.status != 2 &&
            chamado.status != 3 &&
            chamado.status != 0 &&
            chamado.mecanico != null &&
            listTipoChamadoDesc(chamado)
        );
      }
    } else if (parametro == "aguar") {
      tituloFiltro = " Filtrar por Chamados em Aguardo";

      if (dateValue1.length > 0 && dateValue2.length > 0) {
        return data.filter(
          (chamado) =>
            chamado.status != 2 &&
            chamado.status != 3 &&
            chamado.status != 1 &&
            chamado.dataAceite == null &&
            chamado.mecanico != null &&
            chamado.dataAbertura >= `${dateValue1} 00:00:00` &&
            chamado.dataAbertura <= `${dateValue2} 23:59:00` &&
            listTipoChamadoDesc(chamado)
        );
      } else {
        return data.filter(
          (chamado) =>
            chamado.status != 2 &&
            chamado.status != 3 &&
            chamado.status != 1 &&
            chamado.mecanico != null + listTipoChamadoDesc(chamado)
        );
      }
    } else if (parametro == "ag") {
      tituloFiltro = " Filtrar por Chamados Agendados";
      return data.filter(
        (chamado) =>
          chamado.status != 2 &&
          chamado.agendamento.length > 0 &&
          (mecanico != "Todos"
            ? chamado.mecanico.id == mecanico
            : chamado.mecanico != null) &&
          listTipoChamadoDesc(chamado)
      );
    }
  };

  //TRAZ O TOTAL DOS ELEMENTOS QUE
  var quantidadeElementos = listaDados()?.length;

  const CoresLinhas = (nivelGravidade) => {
    if (nivelGravidade === "ALTA") {
      return "callout callout-danger";
    } else if (nivelGravidade === "MEDIA") {
      return "callout callout-warning";
    } else if (nivelGravidade === "BAIXA") {
      return "callout callout-info";
    } else {
      return "callout";
    }
  };

  return (
    <div className="card-row">
      <div className="card">
        <div className="card-header ">
          <div className="row">
            <div class="col-sm-12">
              <h3 className="card-title">
                {(() => {
                  if (st == "ab") {
                    return (
                      <i
                        className="fas fa-calendar-day"
                        style={{ color: "#17a2b8" }}
                      ></i>
                    );
                  } else if (st == "re") {
                    return (
                      <i
                        className="fas fa-rotate-left"
                        style={{ color: "#dc3545" }}
                      ></i>
                    );
                  } else if (st == "ds") {
                    return (
                      <i
                        className="fas fa-gears"
                        style={{ color: "#28a745" }}
                      ></i>
                    );
                  } else if (st == "ag") {
                    return (
                      <i
                        className="fas fa-calendar-check"
                        style={{ color: "#ffc107" }}
                      ></i>
                    );
                  } else if (st == "aguar") {
                    return (
                      <i
                        className="fas fa-clock"
                        style={{ color: "#6c757d" }}
                      ></i>
                    );
                  } else if (st == "fin") {
                    return (
                      <i
                        className="fas fa-check"
                        style={{ color: "#28a745" }}
                      ></i>
                    );
                  } else {
                    if (typeof st == "undefined") {
                      return (
                        <i
                          className="fas fa-calendar-day"
                          style={{ color: "#17a2b8" }}
                        ></i>
                      );
                    }
                  }
                })()}
                {tituloFiltro} / Quantidade:{" "}
                <strong>{quantidadeElementos}</strong>
              </h3>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form className="form-horizontal">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="form-group ">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Inicial"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-2 col-md-2">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Final"
                        value={valueFim}
                        onChange={(newValue) => {
                          setValueFim(newValue);
                          dadosFiltros();
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-2 col-md-3">
                <div className="form-group">
                  <Stack spacing={3}>
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Gravidade
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        sx={{ m: 0, minWidth: 190 }}
                        label="Tipo de Chamado"
                        value={tipoChamadoDesc}
                        onChange={handleChangeTipoChamadoDesc}
                        spacing={3}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        <MenuItem value="BAIXA">BAIXA</MenuItem>
                        <MenuItem value="MEDIA">MEDIA</MenuItem>
                        <MenuItem value="ALTA">ALTA</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>
              </div>

              {(() => {
                if (st != "ab" && st != "aguar" && st != null) {
                  return (
                    <div className="col-sm-2 col-md-3">
                      <div className="form-group">
                        <Stack spacing={3}>
                          <FormControl>
                            <InputLabel id="demo-controlled-open-select-label">
                              Mecânico
                            </InputLabel>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              id={"demo-controlled-open-select-label"}
                              sx={{ m: 0, minWidth: 190 }}
                              label="Mecânico"
                              value={mecanico}
                              onChange={handleChangeMecanico}
                              spacing={3}
                            >
                              <MenuItem value="Todos">Todos</MenuItem>
                              {listMecanico
                                .filter((mec) => mec.status == 1)
                                .sort((a, b) => {
                                  if (a.usuario.nome > b.usuario.nome) return 1;
                                  if (a.usuario.nome < b.usuario.nome) return -1;
                                  return 0;
                                })
                                .map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.usuario.nome}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Stack>
                      </div>
                    </div>
                  );
                }
              })()}

              <div className="col-sm-2 col-md-2">
                <FormControl>
                  <Stack spacing={3}>
                    <Button
                      size="large"
                      style={{ height: "55px" }}
                      onClick={dadosFiltros}
                      variant="contained"
                      endIcon={<SearchIcon />}
                    ></Button>
                  </Stack>
                </FormControl>
              </div>
            </div>
          </form>
        </div>
      </div>

      {listaDados()
        .sort((a, b) => b.id - a.id)
        .map(
          (
            item //
          ) => (
            <CustomizedAndDialogs
              item={item}
              coreslinhas={CoresLinhas(item.tipoChamadoDesc)}
            >
              <VisualizaChamadosAdmin selecionar={item.id} />
            </CustomizedAndDialogs>
          )
        )}
    </div>
  );
};

export default ChamadosFinalizadosGrid;
