import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { NavControle } from "./NavControle";
import ChamadosFinalizadosGrid from "./listModal/finalizados/ChamadosFinalizadosGrid";

class VisualizaTodosFinalizados extends Component {


    constructor(props) {
        super(props);
        this.state = {
            nomePagina: "Chamados Finalizados",
            nomeNivelCrumb: "Principal",
        };
    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <Menu />

                <div>
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row mb-2">
                                            <div className="col-sm-6">
                                                <h1 className="m-0">{this.state.nomePagina} </h1>
                                            </div>

                                            <div className="col-sm-6">
                                                <ol className="breadcrumb float-sm-right">
                                                    <li className="breadcrumb-item">
                                                        <a href="/home">{this.state.nomeNivelCrumb}</a>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <NavControle />
                                            <div
                                                className="card"
                                                style={{ paddingLeft: 15, paddingRight: 30 }}
                                            >
                                                <ChamadosFinalizadosGrid />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default VisualizaTodosFinalizados;
