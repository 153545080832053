export const TOKEN_KEY = "@patrimonioToken";
export const USER_CONTROLL = "@control";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getControle = () => localStorage.getItem(USER_CONTROLL);

export const login =(token, usuario) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_CONTROLL, usuario);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_CONTROLL);
  
};


        