import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Stack } from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import dayjs from "dayjs";
import { formatarData } from "../../../utils/utils";
import ReactPaginate from "react-paginate";

export const TransferenciaEditar = () => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()));
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);
  const [tipoRel, setTipoRel] = useState("AGU");

  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagina, setPagina] = useState(0);

  const tipoListaStatus = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const dadosFiltros = async () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));
  };

  useEffect(() => {
    const formattedInitialDate = dayjs(value).format("YYYY-MM-DD");
    const formattedFinalDate = dayjs(valueFim).format("YYYY-MM-DD");

    api
      .get(
        `/transferencia/status/${tipoRel}?maiorOuIgual=${formattedInitialDate}&menorOuIgual=${formattedFinalDate}&page=${currentPage}&size=20`
      )
      .then((response) => {
        setData(response.data.records);

        setpageCount(Math.ceil(response.data.meta.recordCount / 20));
      });

    dadosFiltros();
  }, [value, tipoRel, currentPage, valueFim]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  if (!data) return null;

  return (
    <div>
      <div className="card-header border-0 p-2">
        <div>
          <form className="form-horizontal">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="form-group ">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Inicial"
                        value={dateValue1}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}                    
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="col-sm-2 col-md-2">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Final"
                        value={dateValue2}
                        onChange={(newValue) => {
                          setValueFim(newValue);
                          dadosFiltros();
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="form-group col-sm-8 col-md-8">
                <div className="input-group input-group-lg">
                  <div className="border pl-2" style={{ borderRadius: "5px" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={tipoRel}
                        onChange={tipoListaStatus}
                      >
                        <FormControlLabel
                          value="APR"
                          color="success"
                          control={<Radio />}
                          label="Aprovado"
                          sx={{ color: "green" }}
                        />
                        <FormControlLabel
                          value="REC"
                          control={<Radio />}
                          label="Recusado"
                          sx={{ color: "red" }}
                        />
                        <FormControlLabel
                          value="AGU"
                          control={<Radio />}
                          label="Aguardo"
                          sx={{ color: "orange" }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-12">
        <div className="content w-100 p-2">
          <div className="row">
            <div className="card">
              <div className="row m-0" style={{ backgroundColor: "#f8f8f8" }}>
                <div className="col-md-6 d-flex justify-content-center card-header border-transparent ">
                  <h5 className="card-title">Origem</h5>
                </div>
                <div className="col-md-6 d-flex justify-content-center card-header border-transparent ">
                  <h5 className="card-title">Destino</h5>
                </div>
              </div>
              <table className="flex-item mb-2">
                <thead>
                  <tr>
                    <th style={{ width: "8%", textAlign: "left"}}>Data</th>
                    <th style={{ width: "6%", textAlign: "left"}}>Pat.</th>
                    <th style={{ width: "15%", textAlign: "left"}}>Descrição</th>
                    <th style={{ width: "15%", textAlign: "left"}}>Setor</th>
                    <th style={{ textAlign: "left"}}>Unidade</th>
                    <th style={{ backgroundColor: "#fbf2d6" }}>Setor</th>
                    <th style={{ backgroundColor: "#ffeeba" }}>Unidade</th>
                    <th style={{ width: "8%"}}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data
                  .sort((a, b) => {
                    if (a.dataSolicitacaoTrans < b.dataSolicitacaoTrans) return 1;
                    if (a.dataSolicitacaoTrans > b.dataSolicitacaoTrans) return -1;
                    return 0;
                  })
                  .map((item) => {
                    return (
                      <tr key={item.id}>
                        <td  style={{width: "10%", textAlign: "left" }}>
                          <a href={`TransferenciaItem/${item.id}`}>
                            {formatarData(item.dataSolicitacaoTrans)}
                          </a>
                        </td>
                        <td style={{width: "10%", textAlign: "left" }}>{item.maquinario.id}</td>
                        <td style={{ textAlign: "left" }}>
                          {item.maquinario.descricao}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.origem.descricao}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.origem.unidadeProducao.fantasia}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#fbf2d6",
                            textAlign: "left",
                          }}
                        >
                          {item.destino.descricao}
                        </td>
                        <td
                          style={{
                            backgroundColor: "#ffeeba",
                            textAlign: "left",
                          }}
                        >
                          {item.destino.unidadeProducao.fantasia}
                        </td>
                        <td style={{ fontSize: 16, padding: 3 }}>
                          {(() => {
                            if (item.status == "AGU") {
                              return (
                                <span
                                  data-label="Status"
                                  className="badge rounded-pill bg-warning"
                                >
                                  Aguardo
                                </span>
                              );
                            } else if (item.status == "APR") {
                              return (
                                <span
                                  data-label="Status"
                                  className="badge rounded-pill bg-dark"
                                >
                                  Aprovado
                                </span>
                              );
                            } else if (item.status == "REC") {
                              return (
                                <span
                                  data-label="Status"
                                  className="badge rounded-pill bg-light text-dark"
                                >
                                  Recusado
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <ReactPaginate
                initialPage={0}
                previousLabel={"Anterior"}
                nextLabel={"Próximo"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={currentPage}
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
