import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import {
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { addZeroes } from "../../../utils/utils";
import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { PDFExport } from "@progress/kendo-react-pdf";
import { REACT_APP_TOTAL_GRID } from "../../../utils/constants";

export const MecanicoPrint = () => {
  let history = useHistory();
  var setGrupo = null;
  var setStatus = null;

  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );
  const [statusReg, setStatusReg] = useState("Todos");
  const [selectedEspecialidade, setSelectedEspecialidade] = useState(["Todos"]);
  const [listEspecialidade, setListEspecialidade] = useState(["Todos"]);
  const [selectedSetor, setSelectedSetor] = useState(["Todos"]);
  const [listSetor, setListSetor] = useState([]);
  const [data, setData] = useState([]);
  const [mecanico, setMecanico] = useState(["Todos"]);
  const [especial, setEspecial] = useState("");
  const [nomeSetor, setNomeSetor] = useState("");

  let limit = parseInt(REACT_APP_TOTAL_GRID);

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  useEffect(() => {
    const getEspec = async () => {
      await api.get(`especialidades`).then((response) => {
        setListEspecialidade(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
      });
    };

    const getMecanico = async () => {
      await api.get(`mecanico`).then((response) => {
        setMecanico(response.data.records);
        setData(response.data.records);
      });
    };

    getEspec();
    getSetores();
    getMecanico();
  }, []);

  const handleChangeEspecialidade = async (event) => {
    const especialidadeSelecionada = event.target.value;
    setSelectedEspecialidade(especialidadeSelecionada);

    if (especialidadeSelecionada != 0) {
      let labelEspecialidade = listEspecialidade.find((option) => option.id == especialidadeSelecionada);
      setEspecial(labelEspecialidade.nome);
      setSelectedGrupo("");
    }
  };

  const handleChangeSetor = async (event) => {
    const setorSelecionado = event.target.value;
    setSelectedSetor(setorSelecionado);
    if(setorSelecionado != 0) {
      let labelSetorSelecionado = listSetor.find((option) => option.id == setorSelecionado);
      setNomeSetor(labelSetorSelecionado.descricao);
    }
  };

  const handleChangeStatus = async (event) => {
    let valor = event.target.value;
    setStatusReg(valor);
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const htmlFormat = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="invoice p-3 mb-3">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                    <small className="float-right">
                      <img style={{ width: "190px" }} src={LogoPatrimonio} />
                    </small>
                  </h4>
                  <h5></h5>
                </div>
              </div>
              <br />


              <div className="row">
                <div className="col-12">
                  <h4>
                    Mecânicos
                    <small className="float-right">
                      Total de Itens:{" "}
                      <FormLabel>
                        <b>
                          {selectedEspecialidade != "Todos" && selectedSetor != "Todos" && statusReg != "Todos" 
                            ? data.filter(
                              (mec) =>
                                mec.especialidades[0].especialidades.id == selectedEspecialidade && mec.setores[0].setor.id == selectedSetor && mec.status == statusReg ).length                          
                            : selectedEspecialidade != "Todos" &&  selectedSetor != "Todos"
                            ? data.filter(
                              (mec) =>
                                mec.especialidades[0].especialidades.id == selectedEspecialidade && mec.setores[0].setor.id == selectedSetor).length
                            : selectedEspecialidade != "Todos" && statusReg != "Todos"
                            ? data.filter(
                              (mec) =>
                                mec.especialidades[0].especialidades.id == selectedEspecialidade && mec.status == statusReg).length
                            : statusReg != "Todos" && selectedSetor != "Todos"
                            ? data.filter(
                              (mec) =>
                                mec.setores[0].setor.id == selectedSetor && mec.status == statusReg).length 
                            : selectedEspecialidade != "Todos"
                            ? data.filter(
                              (mec) =>
                                mec.especialidades[0].especialidades.id == selectedEspecialidade).length
                            : selectedSetor != "Todos"
                            ? data.filter(
                              (mec) =>
                                mec.setores[0].setor.id == selectedSetor).length
                            : statusReg != "Todos"
                            ? data.filter((mec) => mec.status == statusReg).length
                            : data.length}
                        </b>
                      </FormLabel>
                    </small>
                    <br/>
                      <div style={{ fontSize: 14 }}>
                        {selectedEspecialidade != "Todos" && selectedSetor != "Todos" && statusReg != "Todos"
                        ? `Especialidades: ${especial} /  Setor: ${nomeSetor}  /  Status: ${statusReg == 1 ? "Ativo" : statusReg == 0 ? "Inativo" : ""}`
                        : selectedEspecialidade != "Todos" && selectedSetor != "Todos"
                        ? `Especialidades: ${especial} / Setor: ${nomeSetor}`
                        : selectedEspecialidade != "Todos" && statusReg != "Todos"
                        ? `Especialidades: ${especial} /  Status: ${statusReg == 1 ? "Ativo" : statusReg == 0 ? "Inativo" : ""}`
                        : selectedSetor != "Todos" && statusReg != "Todos"
                        ? `Setor: ${nomeSetor}  /  Status: ${statusReg == 1 ? "Ativo" : statusReg == 0 ? "Inativo" : ""}`
                        : selectedEspecialidade != "Todos"
                        ? `Especialidades: ${especial}`
                        : selectedSetor != "Todos"    
                        ? `Setor: ${nomeSetor}`     
                        : statusReg != "Todos"
                        ? `Status: ${statusReg == 1 ? "Ativo" : statusReg == 0 ? "Inativo" : ""}`
                        :  ""

                        }
                      </div>
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12 table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ width: "10%", textAlign: "center" }}
                          scope="col"
                        >
                          ID
                        </th>
                        <th
                          style={{ width: "20%", textAlign: "center" }}
                          scope="col"
                        >
                          Mecânico{" "}
                        </th>
                        <th
                          style={{ width: "30%", textAlign: "center" }}
                          scope="col"
                        >
                          Especialidade{" "}
                        </th>
                        <th
                          style={{ width: "30%", textAlign: "center" }}
                          scope="col"
                        >
                          Setores
                        </th>
                        <th
                          style={{ width: "10%", textAlign: "center" }}
                          scope="col"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter((mec) => {
                          if (
                            statusReg != "Todos" &&
                            selectedEspecialidade != "Todos" &&
                            selectedSetor != "Todos"
                          ) {
                            return (
                              mec.status == statusReg &&
                              mec.especialidades[0].especialidades.id ==
                                selectedEspecialidade &&
                              mec.setores[0].setor.id == selectedSetor
                            );
                          } else if (
                            statusReg != "Todos" &&
                            selectedEspecialidade != "Todos"
                          ) {
                            return (
                              mec.status == statusReg &&
                              mec.especialidades[0].especialidades.id ==
                                selectedEspecialidade
                            );
                          } else if (
                            selectedEspecialidade != "Todos" &&
                            selectedSetor != "Todos"
                          ) {
                            return (
                              mec.especialidades[0].especialidades.id ==
                                selectedEspecialidade &&
                              mec.setores[0].setor.id == selectedSetor
                            );
                          } else if (
                            statusReg != "Todos" &&
                            selectedSetor != "Todos"
                          ) {
                            return (
                              mec.status == statusReg &&
                              mec.setores[0].setor.id == selectedSetor
                            );
                          } else if (selectedSetor != "Todos") {
                            return mec.setores[0].setor.id == selectedSetor;
                          } else if (selectedEspecialidade != "Todos") {
                            return (
                              mec.especialidades[0].especialidades.id ==
                              selectedEspecialidade
                            );
                          } else if (statusReg != "Todos") {
                            return mec.status == statusReg;
                          } else {
                            return data;
                          }
                        })
                        .sort((a, b) => {
                          if (a.usuario.nome > b.usuario.nome) return 1;
                          if (a.usuario.nome < b.usuario.nome) return -1;
                          return 0;
                        })
                        .sort((a, b) => b.status - a.status)
                        .map((mec) => (
                          <tr key={mec.id}>
                            <td style={{ textAlign: "center" }}>
                              {addZeroes(mec.id, 6)}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {mec.usuario.nome}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {mec.especialidades.map((esp) => (
                                <span className="badge bg-primary">
                                  {esp.especialidades.nome}
                                </span>
                              ))}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {mec.setores.map((esp) => (
                                <span className="badge bg-success">
                                  {esp.setor.descricao}
                                </span>
                              ))}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <span
                                className={
                                  mec.status == 0 ? "badge bg-warning" : ""
                                }
                              >
                                {mec.status == 1 ? " Ativo" : " Inativo "}{" "}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const htmlNull = () => {
    return (
      <>
        <div className="col-12">
          <div className="invoice p-3 mb-3">
            <div className="col-md-12">
              <div className="info-box bg-light">
                <div className="info-box-content">
                  <span className="info-box-text text-center text-muted">
                    Não Há Informações{" "}
                  </span>
                  <span className="info-box-number text-center text-muted mb-0">
                    Defina os Critérios de Pesquisa acima!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!data) return null;
  return (
    <div className="card-body">
      <div className="col-md-12">
        <div className="invoice p-1 mb-2">
          <div className="card-body">
            <form className="form-horizontal">
              <div className="row">
                {/* NÃO APAGAR, AINDA SERÁ UTILIZADO
                    
                    <FormControl sx={{ m: 1, width: 200 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                      >
                        <Stack spacing={3}>
                          <DatePicker
                            label="Data Cadastro"
                            value={valueCad}
                            onChange={(newValue) => {
                              setValueCad(newValue);
                              dadosFiltros();
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl> */}
                <div className="d-flex flex-row">
                  <div className="col-sm-4 col-md-4">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Especialidade
                      </InputLabel>
                      <Select
                        sx={{ m: 1, minWidth: 250 }}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        label="Especialidade"
                        value={selectedEspecialidade}
                        onChange={handleChangeEspecialidade}
                        spacing={3}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        {listEspecialidade
                          .sort((a, b) => {
                            if (a.nome > b.nome) return 1;
                            if (a.nome < b.nome) return -1;
                            return 0;
                          })
                          .map((item) => (
                            <MenuItem value={item.id}>{item.nome}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="d-flex flex-row">
                  <div className="col-sm-4 col-md-4">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Setor
                      </InputLabel>
                      <Select
                        sx={{ m: 1, minWidth: 250 }}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        label="Setor"
                        value={selectedSetor}
                        onChange={handleChangeSetor}
                        spacing={3}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        {listSetor
                          .sort((a, b) => {
                            if (a.descricao > b.descricao) return 1;
                            if (a.descricao < b.descricao) return -1;
                            return 0;
                          })
                          .map((item) => (
                            <MenuItem value={item.id}>
                              {item.descricao}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="d-flex flex-row">
                  <div className="col-sm-1 col-md-2">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Status
                      </InputLabel>
                      <Select
                        sx={{ m: 1, minWidth: 200 }}
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        label="Status"
                        value={statusReg}
                        onChange={handleChangeStatus}
                        spacing={3}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        <MenuItem value="1">Ativo</MenuItem>
                        <MenuItem value="0">Inativo</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="d-flex flex-row">
                  <div className="col-sm-2 col-md-2">
                    <FormControl>
                      <Button
                        className="btn btn"
                        sx={{ m: 1, minWidth: 50, maxHeight: 55 }}
                        size="medium"
                        onClick={handleExport}
                        variant="outlined"
                      >
                        Gerar PDF
                      </Button>
                    </FormControl>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="row">
        {(() => {
          if (data.length == 0) {
            return htmlNull();
          } else {
            return (
              <PDFExport
                scale={0.5}
                landscape="true"
                paperSize={"A4"}
                margin="0.8cm"
                fileName="maquinario_.pdf"
                forcePageBreak=".page-break"
                pageTemplate={PDFExportPageTemplate}
                ref={pdf}
              >
                {htmlFormat()}
              </PDFExport>
            );
          }
        })()}
      </div>
    </div>
  );
};
