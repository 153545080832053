import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Link,
} from "@mui/material";
import api from "../../services/api";
import { QRCodeCanvas } from "qrcode.react";
import { red } from "@mui/material/colors";
import { useSyncExternalStore } from "react";
import SemImg from "../../assets/sem-foto.svg";
import { addZeroes, formatarDataBr } from "../../utils/utils";

const VisualizaChamados = ({ selecionar, id }) => {
  const paperStyle = { padding: "5px 5px 5x 5px" };

  const [registro, setRegistro] = useState(selecionar);

  const [indexPrincipal, setIndexPrincipal] = useState(0);
  const [solicitante, setSolicitante] = useState("");
  const [setor, setSetor] = useState("");
  const [patrimonio, setPatrimonio] = useState("");
  const [observacaoTecnica, setObservacaoTecnica] = useState([]);
  const [fabricante, setFabricante] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [problrelatado, setProbrelatado] = useState("");
  const [laudotecnico, setLaudotecnico] = useState("");
  const [observacaoFechamento, setObservacaoFechamento] = useState("");
  const [descricao, setDescricao] = useState();
  const [status, setStatus] = useState();
  const [nomeMec, setNomeMec] = useState("");
  const [especialidades, setEspecialidades] = useState("");
  const [blobImage, setBlobImage] = useState("");
  const [dataAbertura, setDataAbertura] = useState("");
  const [dataFechamento, setDataFechamento] = useState("");
  const [dataImagem, setDataImagem] = useState([]);
  const [imageMec, setImageMec] = useState("");
  const [imageMecBlob, setImageMecBlob] = useState("");

  const [images, setImages] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [movimento, setMovimento] = useState([]);
  const [movimentoMecanico, setMovimentoMecanico] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [openHistory, setOpenHistory] = useState(false);

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  useEffect(() => {
    const getChamado = async () => {
      await api.get(`chamado/` + registro).then((response) => {
        setSolicitante(response.data.records[0].solicitante.nome);
        setDescricao(response.data.records[0].patrimonio.descricao);
        setSetor(response.data.records[0].setor.descricao);
        setPatrimonio(response.data.records[0].patrimonio.numPatrimonio);
        setObservacaoTecnica(response.data.records[0].observacaoTecnica);
        setQrcode(response.data.records[0].patrimonio.numPatrimonio);
        setFabricante(response.data.records[0].patrimonio.fabricante.nome);
        setProbrelatado(response.data.records[0].motivoAbertura);
        setDataAbertura(response.data.records[0].dataAbertura);
        setDataFechamento(response.data.records[0].dataFechamento);
        setLaudotecnico(response.data.records[0].laudoTecnico);
        setObservacaoFechamento(response.data.records[0].observacaoFechamento);
        setNomeMec(response.data.records[0].mecanico?.usuario.nome);
        setMovimentoMecanico(response.data.records[0].movimentoMecanico);
        setEspecialidades(
          response.data.records[0].mecanico?.especialidades[0]?.especialidades
            ?.nome
        );
        setBlobImage(response.data.records[0].blobImage);
        setStatus(response.data.records[0].status);
        setMovimento(response.data.records[0].movimento);

        console.log("status", response.data.records[0].status);
        console.log("Resposta da API:", response.data.records);

        api
          .get(
            `maquinarioalbum/maquinario/` +
              response.data.records[0].patrimonio.id
          )
          .then((response) => {
            setImages(response.data.records);
            setImagemPrincipal(response.data.records[indexPrincipal]);
          });

        api
          .get(`mecanico/` + response.data.records[0].mecanico.id)
          .then((response) => {
            setImageMec(response.data.records[0].imagem);
            setImageMecBlob(response.data.records[0].blobImage);
          });
      });
    };

    const getFiles = async () => {
      await api.get(`/chamadoarquivos/` + registro).then((response) => {
        setDataImagem(response.data.records);
      });
    };

    getChamado();
    getFiles();
  }, [registro, indexPrincipal, status]);

  const StatusChamado = (parTitulo) => {
    switch (parTitulo) {
      case 0:
        return (
          <b className="text-lg" style={{ color: "orange" }}>
            AGUARDANDO
          </b>
        );
      case 1:
        return (
          <b className="text-lg" style={{ color: "blue" }}>
            ANDAMENTO
          </b>
        );
      case 2:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            FINALIZADO
          </b>
        );
      case 3:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            HOMOLOGADO
          </b>
        );
      case 4:
        return (
          <b className="text-lg" style={{ color: "red" }}>
            RETORNADO
          </b>
        );
      case 5:
        return (
          <b className="text-lg" style={{ color: "red" }}>
            RECUSADO
          </b>
        );
      case 6:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            ENCERRADO PELO SOLICITANTE
          </b>
        );

      default: {
        return "";
      }
    }
  };

  function verificarAvatar(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image profile-user-img img-fluid img-circle"
              Style={`height: 80px; width: 90px;`}
              src={`data:image/jpeg;base64, ${imPrincipal}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 120px;}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 150px; min-Width: 180px`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 200px;`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  <script>
    $(document).ready(function(){" "}
    {$(".product-image-thumb").on("click", function () {
      var $image_element = $(this).find("img");
      $(".product-image").prop("src", $image_element.attr("src"));
      $(".product-image-thumb.active").removeClass("active");
      $(this).addClass("active");
    })}
    )
  </script>;

  const alteraImagemPrincipal = (item) => {
    setImagemPrincipal(item);
  };

  const handleChangeObservacao = (event, variant) => {
    let tipoMecanicoCombo = event.target.value;
    let mecanico = tipoMecanicoCombo;

    try {
      api.put(`chamado/` + registro, {
        observacaoTecnica,
      });

      setMensagemDialog("Registro Atualizado com Sucesso");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um Problema ao atualizar o registro");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  return (
    <div className="opacity-100">
      <Grid style={{ width: "100%" }}>
        <Paper elevation={0} style={paperStyle}>
          <div className="row">
            <div className="col-md-6 ">
              <div className="breadcrumb-item active">
                <strong className="text-lg">
                  {status == 0 ? "MOVIMENTO" : "DETALHES DO CHAMADO"}
                </strong>
              </div>

              <div>
                <td className="text-muted text-sm">
                  <b>SOLICITANTE: </b>
                  <span style={{ color: "black" }}>
                    <b>{solicitante}</b>
                  </span>
                </td>
              </div>

              <div>
                <td className="text-muted text-sm">
                  <b>SETOR: </b>
                  <span style={{ color: "black" }}>
                    <b>{setor}</b>
                  </span>
                </td>
              </div>
            </div>

            <div className="col-md-6 text-right">
              <span>
                <h5>
                  <b>{addZeroes(registro, 8)}</b>
                  <br />
                  {StatusChamado(status)}
                  <br />
                  {movimento.length >= 1 && (
                    <Button onClick={handleClickOpenHistory}>
                      <span
                        className="far fa-file-alt  "
                        style={{ color: "#000", fontSize: 20, color: "brown" }}
                      >
                        {" "}
                        <span
                          className="mr-0"
                          style={{ fontSize: 15, fontFamily: "sans-serif" }}
                        >
                          <b>Histórico de Retorno</b>
                        </span>
                      </span>
                    </Button>
                  )}
                </h5>
              </span>
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-md-4">
              <div className="text-center">
                {verificarImagem(imagemPrincipal)}
              </div>
              <div>
                <div>
                  <div className="card-header">
                    {verificarAvatar(imageMecBlob)}
                    <div className="text-center mt-1">
                      <strong>{nomeMec}</strong>
                      <br />
                      <strong>{especialidades}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div
                    className="tab-content"
                    id="custom-content-below-tabContent"
                  >
                    <div>
                      <div className="row">
                        <div className="col-sm-2 pr-4">
                          <QRCodeCanvas
                            style={{ width: "100px", height: "100px" }}
                            data-qr={qrcode}
                            value={qrcode}
                          />
                        </div>
                        <div className="col-sm-10 pl-5">
                          <div>
                            <td>
                              <b className="text-xs">DESCRIÇÃO : </b>
                              <strong
                                style={{
                                  color: "black",
                                  borderBottom: "3px solid rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                {descricao}
                              </strong>
                            </td>
                          </div>

                          <div>
                            <td>
                              <b className="text-xs">CÓDIGO : </b>
                              <strong
                                style={{
                                  color: "black",
                                  borderBottom: "3px solid rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                {patrimonio}
                              </strong>
                            </td>
                          </div>

                          <div>
                            <td>
                              <b className="text-xs">FABRICANTE : </b>
                              <strong
                                style={{
                                  color: "black",
                                  borderBottom: "3px solid rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                {fabricante}
                              </strong>
                            </td>
                          </div>
                          {(() => {
                            if (status == 2 || status == 3) {
                              return (
                                <>
                                  <div>
                                    <td>
                                      <b className="text-xs">
                                        DATA ABERTURA :{" "}
                                      </b>
                                      <strong
                                        style={{
                                          color: "black",
                                          borderBottom:
                                            "3px solid rgba(0, 0, 0, 0.05)",
                                        }}
                                      >
                                        {formatarDataBr(dataAbertura)}{" "}
                                      </strong>
                                    </td>
                                  </div>
                                  <div>
                                    <td>
                                      <b className="text-xs">
                                        {status == 2
                                          ? "DATA FECHAMENTO :"
                                          : status == 3
                                          ? "DATA HOMOLOGADO : "
                                          : " "}
                                      </b>
                                      <strong
                                        style={{
                                          color: "black",
                                          borderBottom:
                                            "3px solid rgba(0, 0, 0, 0.05)",
                                        }}
                                      >
                                        {dataFechamento
                                          ? formatarDataBr(dataFechamento)
                                          : ""}
                                      </strong>
                                    </td>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <td>
                                  <b className="text-xs">DATA ABERTURA : </b>
                                  <strong
                                    style={{
                                      color: "black",
                                      borderBottom:
                                        "3px solid rgba(0, 0, 0, 0.05)",
                                    }}
                                  >
                                    {formatarDataBr(dataAbertura)}{" "}
                                  </strong>
                                </td>
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <br />

                      {problrelatado ? (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="callout callout-danger">
                              <td>
                                <b className="text-xs">PROBLEMA RELATADO: </b>
                                <br />
                                <span>{problrelatado}</span>
                              </td>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {laudotecnico ? (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="callout callout-warning">
                              <td>
                                <b className="text-xs">LAUDO TÉCNICO: </b>
                                <br />
                                <span>{laudotecnico}</span>
                              </td>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {movimentoMecanico.length > 0 ? (
                        <>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="callout callout-info">
                                <td>
                                  <b>RESUMO FECHAMENTO MECÂNICO:</b>
                                  <br />
                                  {movimentoMecanico
                                    .sort((a, b) => b.id - a.id)
                                    .map((item) => {
                                      return (
                                        <div className="p-1">
                                          <span>
                                            {formatarDataBr(
                                              item.dataFechamentoMecanico
                                            )}
                                            {" - "}
                                            {item.observacaoFechamentoMecanico}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </td>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {(() => {
                        if (status == 4) {
                          return (
                            <div className="col-12 mb-3">
                              <Alert severity="error">
                                Chamado recusado pelo solicitante — verificar{" "}
                                <a
                                  className="text-underline text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClickOpenHistory}
                                >
                                  histórico
                                </a>{" "}
                                de retorno!
                              </Alert>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })()}

                      {dataFechamento ? (
                        <>
                          {(() => {
                            if (status == 2 || status == 3) {
                              return (
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="callout callout-success">
                                      <td>
                                        <b>
                                          APROVAÇÃO E HOMOLOGAÇÃO PELO
                                          SOLICITANTE:
                                        </b>
                                        <br />
                                        <span>
                                          {formatarDataBr(dataFechamento)}
                                          {" - "}
                                          {observacaoFechamento}
                                        </span>
                                      </td>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })()}
                        </>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="callout callout-info">
                            <label className="col-sm-12 col-label">
                              OBSERVAÇÕES
                            </label>

                            <textarea
                              className="form-control"
                              style={{ fontSize: 12 }}
                              id="observacao_tecnica"
                              placeholder="Observacao Técnica..."
                              onChange={(e) =>
                                setObservacaoTecnica(e.target.value)
                              }
                              defaultValue={observacaoTecnica}
                              disabled={
                                status === 2 || status === 3 || status === 5
                              }
                            />
                            <br />

                            <div className="row">
                              <div className="col-sm-12">
                                <div className="btn-group btn-group-sm">
                                  <Button
                                    onClick={handleChangeObservacao}
                                    className="btn btn-info"
                                    disabled={
                                      status === 2 ||
                                      status === 3 ||
                                      status === 5
                                    }
                                  >
                                    Atualizar
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 product-image-thumbs">
                        {dataImagem.map((mitem) => (
                          <div className="product-image-thumb" key={mitem.id}>
                            <img
                              src={`data:image/jpeg;base64, ${mitem.arquivo}`}
                              alt={`Product Thumbnail ${mitem.id}`}
                            />
                          </div>
                        ))}
                      </div>
                      {/* Mostrar a primeira imagem acima */}
                      <div className="col-12 mt-2">
                        {dataImagem.length > 0 && (
                          <img
                            style={{ width: "100%", height: "auto" }}
                            src={`data:image/jpeg;base64, ${dataImagem[0].arquivo}`}
                            className="product-image"
                            alt="Product Image"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={openHistory}
            onClose={handleCloseHistory}
          >
            <DialogTitle>Histórico</DialogTitle>
            <DialogContent>
              <DialogContentText>Motivo do retorno.</DialogContentText>

              <div className="row">
                <div className="col-12">
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{ width: "20%", textAlign: "left" }}
                          scope="col"
                        >
                          Data Hora{" "}
                        </th>
                        <th
                          style={{ width: "80%", textAlign: "left" }}
                          scope="col"
                        >
                          Descrição do Motivo{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {movimento
                        .sort((a, b) => b.id - a.id)
                        .map((item) => {
                          return (
                            <tr key={item.id}>
                              <td
                                style={{ textAlign: "left" }}
                                data-label="Data Hora"
                              >
                                {" "}
                                {formatarDataBr(item.datahora, "DataHora")}
                              </td>
                              <td
                                style={{ textAlign: "left" }}
                                data-label="Descrição do movimento"
                              >
                                {" "}
                                {item.descricao}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseHistory}>Fechar</Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Grid>
    </div>
  );
};

export default VisualizaChamados;
