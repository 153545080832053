import { Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { BoxWithSpacedGrid } from "./ModalCardMobile";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../services/api";
import { useState } from "react";

export const ModalCardReprovacaoMobile = ({
  open,
  handleClose,
  chamadosSelecionados,
}) => {
  const [motivo, setMotivo] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState([]);

  const [corDialog, setCorDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);

  const queryClient = useQueryClient();

  const dadosJson = {
    idChamados: chamadosSelecionados.map((row) => row.id),
  };

  const { data: reprovarPecas, isLoading: isLoadingReprovar } = useQuery(
    ["reprovarPecas", motivo],
    async () => {
      const response = await api.post(`chamado-pecas/detalhes`, {
        ids: dadosJson.idChamados,
      });
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutation, isLoading } = useMutation(
    async () => {
      const dadosConfirmar = {
        statusAprov: "R",
        motivo: motivo,
        idChamados: dadosJson.idChamados,
        pecas: reprovarPecas?.pecas,
      };

      const response = await api.put(
        `chamado-pecas/aprovar-reprovar/`,
        dadosConfirmar
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chamadoPecas"]);
        setMensagemDialog(`Reprovação realizada com sucesso!`);
        setOpenMsg(!openMsg);
        setTipoDialog("success");
        setCorDialog("success");
        setMotivo("");
        handleClose();
      },
      onError: (error) => {
        setMensagemDialog(
          `Houve um erro na operação de reprovação: ${error.message}`
        );
        setTipoDialog("error");
        setCorDialog("success");
      },
    }
  );

  const handleMotivoChange = (event) => {
    setMotivo(event.target.value);
  };

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  return (
    <Dialog open={open}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 4,
              right: 8,
              cursor: "pointer",
              fontSize: 15,
              color: "gray",
            }}
            onClick={handleClose}
          >
            X
          </Box>
          <Typography sx={{ fontSize: 18, color: "red" }}>
            <Info sx={{ color: "red", marginRight: 1 }} />
            Reprovar Solicitação
          </Typography>

          <Box sx={{ fontWeight: "Bold" }}>
            <Typography sx={{ textAlign: "center", fontWeight: "inherit" }}>
              Informe, no campo abaixo, o motivo da reprovação da solicitação de
              uso de peças na manutenção do patrimônio.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography variant="h5">Patrimônio</Typography>

          {chamadosSelecionados.map((chamado) => (
            <>
              <Card sx={{ my: 2 }}>
                <CardContent sx={{ display: "grid", gap: 1 }}>
                  <BoxWithSpacedGrid label="Chamado" value={chamado?.id} />
                  <BoxWithSpacedGrid
                    label="Patrimônio"
                    value={`PAT-${chamado.patrimonio.id}`}
                  />
                  <BoxWithSpacedGrid
                    label="Descrição"
                    value={chamado?.patrimonio.descricao}
                  />
                  <BoxWithSpacedGrid
                    label="Setor"
                    value={chamado?.setor.descricao}
                  />
                  <BoxWithSpacedGrid
                    label="Unidade"
                    value={chamado?.setor.unidadeProducao.fantasia}
                  />
                </CardContent>
              </Card>
            </>
          ))}
        </Box>

        <hr style={{ border: "1px solid #000", width: "100%" }} />

        <Box>
          <Typography>Motivo</Typography>
        </Box>
        <TextField
          label="Motivo da Reprovação"
          variant="outlined"
          multiline
          fullWidth
          rows={4}
          value={motivo}
          onChange={handleMotivoChange}
          sx={{
            marginTop: 0.5,
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          }}
        />

        <Box sx={{ display: "flex", marginTop: 2, justifyContent: "flex-end" }}>
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            sx={{ color: "gray" }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => mutation()}
            size="small"
            variant="contained"
            sx={{ minWidth: 100, marginLeft: 1 }}
            color="error"
            disabled={!motivo || isLoading}
          >
            Reprovar
          </Button>
        </Box>
      </DialogContent>

      {openMsg && (
        <Dialog open={openMsg} onClose={isLoadingReprovar && handleFecharAlert}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            onClose={() => {
              setOpenMsg(false);
            }}
            sx={{
              "& .MuiAlert-icon": {
                color: "blue",
              },
            }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      )}
    </Dialog>
  );
};
