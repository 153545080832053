import { Box, Typography } from "@mui/material";
import { FiltrosEstoque } from "./components/Filtros_Estoques";
import { SearchEstoque } from "./components/Search_Estoque";
import { TotalEstoque } from "./components/Total_Estoque";
import { GridTable } from "./components/GridTable";
import { pecasContext } from "../../contexts/PecasContext";

export const EstoqueDePecas = () => {
  const { ListaTable, isListTable } = pecasContext();

  return (
    <Box sx={{ margin: 2, display: "grid", gap: "20px" }}>
      <FiltrosEstoque />
      <SearchEstoque />
      {ListaTable !== null && !isListTable && (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <TotalEstoque />
          </Box>
        )}

      <Box>
        <GridTable />
      </Box>
    </Box>
  );
};
