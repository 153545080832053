import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ChartPatrimonio = ({ patrimonio, filtroTipo }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const history = useHistory();

  if (!patrimonio) {
    return null;
  }

  const total = patrimonio.reduce(
    (accumulator, pat) => accumulator + pat.total,
    0
  );

  const [chartPat] = React.useState({
    series: [
      {
        data: patrimonio.map((dadosPat) => dadosPat.total),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
        events: {
          dataPointSelection: function (event, chartContext, config) {
            const patrimonio =
              chartContext.w.config.xaxis.categories[config.dataPointIndex];

            const numPatrimonio = patrimonio.split("-")[1].trim();

            history.push(`maquinarioHistorico/${numPatrimonio}`);
          },
          dataPointMouseEnter: function (event) {
            event.target.style.cursor = "pointer";
          },
        },
      },
      plotOptions: {
        bar: {
          barHeight: "95%",
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#00E4E4"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#304758"],
          fontWeight: "normal",
          fontSize: isMobile ? '12px' : '14px',
        },
        formatter: function (val, opt) {
          if (filtroTipo != 0) {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :...  " + val + "%"
            );
          } else {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :... Total: " + val
            );
          }
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: patrimonio.map((dadosPat) => dadosPat.nome),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: `OCORRÊNCIAS POR PATRIMÔNIOS`,
        align: "center",
        floating: true,
        margin: 6,
        style: {
          height: 'auto',
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",        
        }
      },
      subtitle: {
        text: `Total: ${total}`,
        align: "right",
        margin: 10,
        offsetX: 3,
        style: {
          fontSize: isMobile ? '14px' : '18px',
          fontFamily: "'Roboto', sans-serif",    
          fontWeight: 'bold',
          height: 'auto'
        }
      },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  });

  return (
    <Box sx={{ p: 2 }} className="shadow-lg bg-white rounded" style={{ padding: 16 }}>
        <ReactApexChart
          options={chartPat.options}
          series={chartPat.series}
          type="bar"
          height={600}
        />  
      <div id="html-dist"></div>
    </Box>
  );
};
