import React, { useEffect, useState } from 'react'
import { Grid, Paper, Button, Typography } from '@mui/material'
import api from '../../services/api';


const VisualizarMec = ({ dados }) => {
    const paperStyle = { padding: '10px 10px 10px 10px' }


    const [registro, setRegistro] = useState(dados);


    const [nome, setNome] = useState('');
    const [usuario, setUsuario] = useState('');
    const [setor, setSetor] = useState([]);
    const [observacao, setObservacao] = useState();
    const [status, setStatus] = useState('1');
    const [imagem, setImagem] = useState('');
    const [blobImage, setBlobImage] = useState('')
    const [statusRecord, setStatusRecord] = useState({
        type: '',
        mensagem: ''
    })
    const [listUsuario, setListUsuario] = useState([])
    const [listSetor, setListSetor] = useState([])

    const [listEspecialidades, setListEspecialidades] = useState([])

    const [selectedUsuario, setSelectedUsuario] = useState(0);
    const [selectedSetor, setSelectedSetor] = useState([]);
    const [selectedSetorArray, setSelectedSetorArray] = useState();
    const [selectedEspecialidadesArray, setSelectedEspecialidadesArray] = useState();


    const [values, setValues] = useState([]);

    const [aguardando, setAguardando] = useState(true);

    const [mecSetores, setMecSetores] = useState([]);
    const [mecEspecialidades, setMecEspecialidades] = useState([]);

    const [uDescricao, setUDescricao] = useState([]);
    const [uEmail, setUEmail] = useState([]);


    useEffect(() => {


        const getMecanico = async () => {

            await api.get(`mecanico/` + registro)
                .then((response) => {

                    const arrSMec = [];
                    const arrSMecEspecialidades = [];


                    api.get(`usuarioDetalhe/` + response.data.records[0].usuario.id)
                        .then((response) => {
                            setUDescricao(response.data.records[0].descricao);
                            setUEmail(response.data.records[0].email);
                        })

                    setMecSetores(response.data.records[0].setores);
                    setMecEspecialidades(response.data.records[0].especialidades);

                    setNome(response.data.records[0].usuario.nome);
                    setSetor(response.data.records[0].setores);
                    setSelectedSetor(response.data.records[0].setores);
                    setObservacao(response.data.records[0].observacao);
                    setUsuario(response.data.records[0].usuario.id);
                    setSelectedUsuario(response.data.records[0].usuario.id);

                    setImagem(response.data.records[0].imagem)
                    setBlobImage(response.data.records[0].blobImage)


                });

        }


        getMecanico();



    }, [registro]);


    return (
        

        
        <Grid >
            <Paper elevation={0} style={paperStyle}>

                <div className="col-12 d-flex align-items-stretch flex-column">
                    <div className="card bg-light d-flex flex-fill">
                        <div className="card-header text-muted border-bottom-0">

                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                <div className="col-7">
                                    <h2 className="lead"><b>{uDescricao}</b></h2>
                                    <p className="text-muted text-sm">
                                        <ul className="ml-4 mb-0 fa-ul text-muted">
                                            <li className="small"><span className="fa-li"><i className="fas fa-lg fa-envelope" /></span> {uEmail}</li>
                                            <li className="small"><span className="fa-li"><i className="fas fa-lg fa-user" /></span> {nome} </li>
                                        </ul>
                                    </p>
                                    <p className="text-muted text-sm">
                                        <b>Setores Atendimento : </b>
                                        <ul className="nav nav-pills flex-column">
                                            {

                                                mecSetores.map(item => (
                                                    <li className="nav-item">

                                                        <a key={item.setor.id} className="nav-link">
                                                        <i class="far fa-circle text-success"></i>
                                                             {item.setor.descricao.toUpperCase()}
                                                        </a>

                                                    </li>



                                                )
                                                )}
                                        </ul>


                                    </p>

                                    <p className="text-muted text-sm">
                                        <b>Especialidades : </b>
                                        <ul className="nav nav-pills flex-column">

                                            {
                                                mecEspecialidades.map(itemEsp => (
                                                    <li className="nav-item">
                                                        <a key={itemEsp.especialidades.id} className="nav-link" href="#"><i class="far fa-circle text-primary"></i> {itemEsp.especialidades.nome.toUpperCase()}</a>
                                                    </li>
                                                )
                                                )}
                                        </ul>

                                    </p>

                                    <p className="text-muted text-sm">
                                        <b>Atendimento de Chamados Atuais : </b>
                                        <ul className="nav nav-pills flex-column">
                                            

                                            
                                                    <li className="nav-item">

                                                        <a className="nav-link">
                                                            Nenhum Chamados em Atendimento
                                                             
                                                        </a>

                                                    </li>



                                                
                                                
                                        </ul>


                                    </p>

                                </div>
                                <div className="col-5 text-center">
                                    <img src={`data:image/jpeg;base64, ${blobImage}`} alt="Imagem do Mecanico" className="img-circle img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="text-right">
                                <a href="#" className="btn btn-sm bg-teal">
                                    On Line <i className="fas fa-wifi" />
                                </a>

                            </div>
                        </div>
                    </div>
                </div>



            </Paper>
        </Grid>
        
    )
}

export default VisualizarMec;