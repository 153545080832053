import {
  Stack,
  Autocomplete,
  CircularProgress,
  TextField,
  Box,
  Radio,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { AdicionarScript } from "../../../components/AdicionarScript";

import { AlertSuccess, AlertDanger } from "./styles";
import { textAlign } from "@mui/system";
import {
  FormatAlignCenter,
  FormatAlignJustify,
  SettingsSystemDaydreamOutlined,
  SystemSecurityUpdateRounded,
} from "@mui/icons-material";
import { gridSelectionStateSelector } from "@mui/x-data-grid";
import createPalette from "@mui/material/styles/createPalette";
import { cepMask } from "../../../components/controles/Mask";
import { FormLabel } from "react-bootstrap";

export const FabricanteEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [nome, setNome] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [site, setSite] = useState("");
  const [codigopostal, setCodigopostal] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [pais, setPais] = useState("");

  const [observacao, setObservacao] = useState("");
  const [status, setStatus] = useState("1");

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const dataInit = {
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    ibge: "",
    gia: "",
    ddd: "",
    siafi: "",
  };

  const handler = (event) => {
    getCep();
  };

  const getCep = async () => {
    fetch(`http://viacep.com.br/ws/${codigopostal.replace(".", "")}/json/`, {
      mode: "cors",
    }).then((response) => {
      response.json().then((data) => showData(data));
    });
  };

  const showData = (result) => {
    setLogradouro(result.logradouro.toUpperCase());
    setBairro(result.bairro.toUpperCase());
    setUf(result.uf.toUpperCase());
    setCidade(result.localidade.toUpperCase());
  };

  const mascararCep = (e) => {
    setCodigopostal(cepMask(e.target.value));
  };

  /*Editar Registros*/
  const editFabricantes = async (e) => {
    /**
     * CRIADO UMA LISTA DE JSON SEPARADO
     * DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
     */
    var dadosJson = {
      id: id,
      nome: nome,
      contato: contato,
      email: email,
      site: site,
      codigopostal: codigopostal,
      logradouro: logradouro,
      bairro: bairro,
      cidade: cidade,
      uf: uf,
      pais: pais,
      observacao: observacao,
      status: status,
    };

    console.log("Dados Adicionado : " + JSON.stringify(dadosJson));

    e.preventDefault();
    try {
      await api.post(`fabricante/`, dadosJson);

      if (id !== "0") {
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/fabricantes",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getFabricantes = async () => {
      await api.get(`fabricante/` + id).then((response) => {
        setNome(response.data.records[0].nome.toUpperCase());
        setContato(response.data.records[0].contato.toUpperCase());
        setEmail(response.data.records[0].email.toLowerCase());
        setSite(response.data.records[0].site.toLowerCase());
        setCodigopostal(response.data.records[0].codigopostal.toUpperCase());
        setLogradouro(response.data.records[0].logradouro.toUpperCase());
        setBairro(response.data.records[0].bairro.toUpperCase());
        setCidade(response.data.records[0].cidade.toUpperCase());
        setUf(response.data.records[0].uf.toUpperCase());
        setPais(response.data.records[0].pais.toUpperCase());

        setObservacao(response.data.records[0].observacao.toUpperCase());
        setStatus(
          response.data.records[0].status == null
            ? 0
            : response.data.records[0].status
        );
      });
    };

    getFabricantes();
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editFabricantes}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group was-validated">
                        <label htmlFor="Inputnome">Nome</label>
                        <input
                          type="nome"
                          className="form-control"
                          id="InputNomeFabricante"
                          placeholder="Nome..."
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group was-validated">
                        <label htmlFor="InputContatoFabricante">Contato</label>
                        <input
                          type="contato"
                          className="form-control"
                          id="InputContatoFabricante"
                          placeholder="Contato..."
                          value={contato}
                          onChange={(e) => setContato(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-grou was-validated">
                        <label htmlFor="InputEmail">E-mail</label>
                        <input
                          type="email"
                          className="form-control"
                          id="InputEmailFabricante"
                          placeholder="E-Mail..."
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group was-validated">
                        <label htmlFor="Inputsite">Site</label>
                        <input
                          type="site"
                          className="form-control"
                          id="InputSiteFabricante"
                          placeholder="Site..."
                          value={site}
                          onChange={(e) => setSite(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-2">
                      <div className="form-group was-validated">
                        <label htmlFor="InputCep">Cep</label>
                        <input
                          type="cep"
                          className="form-control"
                          id="InputCepFabricante"
                          placeholder="Cep..."
                          onKeyDown={(e) => mascararCep(e)}
                          onBlur={(e) => handler(e)}
                          value={codigopostal}
                          onChange={(e) => setCodigopostal(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="form-group was-validated">
                        <label htmlFor="InputLogradouro">Logradouro</label>
                        <input
                          type="text"
                          className="form-control"
                          id="InputLogradouroFabricante"
                          placeholder="Logradouro..."
                          value={logradouro}
                          onChange={(e) => setLogradouro(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group was-validated">
                        <label htmlFor="InputBairro">Bairro</label>
                        <input
                          type="bairro"
                          className="form-control"
                          id="InputBairroFabricante"
                          placeholder="Bairro..."
                          value={bairro}
                          onChange={(e) => setBairro(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group was-validated">
                        <label htmlFor="InputCidade">Cidade</label>
                        <input
                          type="cidade"
                          className="form-control"
                          id="InputCidadeFabricante"
                          placeholder="Cidade..."
                          value={cidade}
                          onChange={(e) => setCidade(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-1">
                      <div className="form-group was-validated">
                        <label htmlFor="InputUf">UF</label>
                        <input
                          type="uf"
                          className="form-control"
                          id="InputUfFabricante"
                          placeholder="UF..."
                          value={uf}
                          onChange={(e) => setUf(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-5">
                      <div className="form-group was-validated">
                        <label htmlFor="InputPais">País</label>
                        <input
                          type="pais"
                          className="form-control"
                          id="InputPaisFabricante"
                          placeholder="País..."
                          value={pais}
                          onChange={(e) => setPais(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label htmlFor="Inpcard-footerutnome">Observação</label>
                      <textarea
                        className="form-control"
                        id="nome"
                        placeholder="Observacao..."
                        onChange={(e) => setObservacao(e.target.value)}
                        defaultValue={observacao}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <label className="col-sm-2 col-form-label">Status </label>
                      <div className="col-sm-10">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Ativo
                        </FormLabel>
                        <Radio
                          checked={status == "1"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="1"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "1" }}
                        />

                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Inativo
                        </FormLabel>
                        <Radio
                          checked={status == "0"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="0"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "0" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="card-body">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
