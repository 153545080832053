import React, { useState } from "react";
import { addZeroes, formatarData } from "../utils/utils";
import { Link } from "react-router-dom";
import VisualizaChamados from "../pages/Kanban/Chamados";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const TableSearch = ({ result }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedChamadoId, setSelectedChamadoId] = useState(null);
  const [isExpanded, setExpanded] = useState(true);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  if (!result) {
    return "Não há pesquisa relacionada";
  }

  const calcHeight = () => {
    const total = result.reduce(
      (accumulator, group) =>
        accumulator +
        group.patrimonio.length +
        group.chamado.length +
        group.mecanico.length +
        group.solicitante.length,
      0
    );

    return total > 10 ? "50vh" : "20vh";
  };

  const handleOpenModal = (chamadoId) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedChamadoId(chamadoId);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedChamadoId(null);
  };

  const StatusChamado = (parTitulo) => {
    switch (parTitulo) {
      case 0:
        return <span>AGUARDANDO</span>;
      case 1:
        return <span>ANDAMENTO</span>;
      case 2:
        return <span>FINALIZADO</span>;
      case 3:
        return <span>HOMOLOGADO</span>;
      case 4:
        return <span>RETORNADO</span>;
      case 5:
        return <span>RECUSADO</span>;
      case 6: 
        return <span>ENCERRADO PELO SOLICITANTE</span>;

      default: {
        return "";
      }
    }
  };

  return (
    <>
      <div
        className="table-responsive"
        style={{
          position: "absolute",
          top: "100%",
          left: 0,
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: 4,
          padding: "10px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 999,
          width: "100%",
          height: calcHeight(),
          overflowY: "auto",
        }}
      >
        {result.map((group) => {
          return (
            <>
              {group.patrimonio.length > 0 &&
                group.patrimonio.map((pat) => (
                  <Link to={`/MaquinarioItem/${pat.id}`}>
                    <div
                      style={{
                        backgroundColor: "#C7F0EA",
                        padding: 8,
                        borderRadius: 6,
                        width: "100%",
                        marginBottom: "6px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "24%" }} scope="col">
                            Patrimônio
                          </th>
                          <th style={{ width: "17%" }} scope="col">
                            Grupo
                          </th>
                          <th style={{ width: "17%" }} scope="col">
                            Localização
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={pat.id}>
                          <td>
                            {pat.numPatrimonio}
                            {" - "}
                            {pat.descricao}
                          </td>
                          <td>{pat.grupo.descricao}</td>
                          <td>
                            {
                              pat.maquinarioSetores[0]?.setores?.unidadeProducao
                                ?.fantasia
                            }
                          </td>
                        </tr>
                      </tbody>
                    </div>
                  </Link>
                ))}

              {group.chamado.length > 0 &&
                group.chamado.map((chamado) => (
                  <Link onClick={() => handleOpenModal(chamado.id)}>
                    <div
                      style={{
                        backgroundColor: "#DFD1F0",
                        padding: 8,
                        borderRadius: 6,
                        width: "100%",
                        marginBottom: "6px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{ width: "10%", textAlign: "left" }}
                            scope="col"
                          >
                            O.S
                          </th>
                          <th
                            style={{ width: "10%", textAlign: "left" }}
                            scope="col"
                          >
                            Abertura
                          </th>
                          <th style={{ width: "14%" }} scope="col">
                            Solicitante
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Setor
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Mecânico
                          </th>
                          <th style={{ width: "15%" }} scope="col">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={chamado.id}>
                          <td style={{ textAlign: "left" }}>
                            {addZeroes(chamado.id, 6)}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {formatarData(chamado.dataAbertura)}
                          </td>
                          <td>{chamado.solicitante.nome}</td>
                          <td>
                            {chamado.mecanico?.setores[0]?.setor?.descricao}
                          </td>
                          <td>
                            {
                              chamado.mecanico?.setores[0]?.mecanico?.usuario
                                .nome
                            }
                          </td>
                          <td>{StatusChamado(chamado.status)}</td>
                        </tr>
                      </tbody>
                    </div>
                  </Link>
                ))}
                

              {group.mecanico.length > 0 &&
                group.mecanico.map((mec) => (
                  <Link to={`/MecanicoItem/${mec.id}`}>
                    <div
                      style={{
                        backgroundColor: "#F8C8C9",
                        padding: 8,
                        borderRadius: 6,
                        width: "100%",
                        marginBottom: "6px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }} scope="col">
                            Mecânico
                          </th>
                          <th style={{ width: "20%" }} scope="col">
                            Especialidade
                          </th>
                          <th style={{ width: "20%" }} scope="col">
                            Setores
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={mec.id}>
                          <td>{mec.usuario.nome}</td>
                          <td>{mec.especialidades[0].especialidades.nome}</td>
                          <td>{mec.setores[0].setor.descricao}</td>
                        </tr>
                      </tbody>
                    </div>
                  </Link>
                ))}

              {group.solicitante.length > 0 &&
                group.solicitante.map((solic) => (
                  <div
                    className={`your-container-class ${
                      isExpanded ? "fixed-table" : ""
                    }`}
                    style={{
                      backgroundColor: "#CFEEC5",
                      padding: 8,
                      borderRadius: 6,
                      width: "100%",
                      marginBottom: "6px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Solicitante</th>
                        <th scope="col">Unidade Fabricação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        data-widget="expandable-table"
                        aria-expanded={isExpanded}
                        key={solic.id}
                      >
                        <td style={{ width: "45%" }}>
                          <i class="expandable-table-caret fas fa-caret-right fa-fw"></i>
                          {solic.descricao}
                        </td>
                        <td style={{ width: "50%" }}>
                          {solic.unidade.fantasia}
                        </td>
                      </tr>
                      <tr class="expandable-body">
                        <td style={{ width: "30%" }}>
                          <thead>
                            <tr>
                              <th style={{ width: "100%" }} scope="col">
                                Setores
                              </th>
                            </tr>
                          </thead>
                          {solic.setores.map((setor) => (
                            <tbody>
                              <tr key={setor.id}>
                                <Link to={`/setorItem/${setor.id}`}>
                                  <td className="p-1">
                                    {setor.id}
                                    {" - "}
                                    {setor.descricao}
                                  </td>
                                </Link>
                              </tr>
                            </tbody>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </div>
                ))}
            </>
          );
        })}
      </div>
      {modalIsOpen && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={modalIsOpen}
          onClose={handleCloseModal}
        >
          <DialogTitle></DialogTitle>

          <DialogContent>
            <DialogContentText></DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: "auto",
                width: "fit-content",
              }}
            >
              <VisualizaChamados selecionar={selectedChamadoId} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseModal(false)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
