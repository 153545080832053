import React, { useState } from "react";
import { ResultadosChamadosGrid } from "./ResultadosChamadosGrid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { usePatrimonioContext } from "../../contexts/PatrimonioContext";
import { useParams } from "react-router-dom";

const ResultadoChamados = () => {
  const [state] = useState({
    nomePagina: "Detalhes do Chamado",
    nomeNivel2Crumb: "Lista Registros",
    nomeNivel3Crumb: "Histórico de Atendimento",
    nomeNivel4Crumb: "Ajuda",
  });

  const { numIdPat } = usePatrimonioContext();
  const { id } = useParams();

  

  return (
    <div className="wrapper">
      <Header />
      <Menu />

      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row mb-1">
                    <div className="col-sm-4">
                      <h1 className="m-0" style={{ fontSize: 22 }}>{state.nomePagina}{" "}</h1>
                    </div>

                    <div className="col-sm-8">
                      <ol className="breadcrumb float-sm-right">

                        <li className="breadcrumb-item">
                          <a className="text-primary" href="/home/">
                            <i className="fa fa-home mr-1" />
                            <a href="/analiseTempo/">{state.nomeNivel2Crumb}</a>
                          </a>
                        </li>

                        <li className="breadcrumb-item">

                          <i className="fa fa-wrench mr-1" />
                          <a href={`/maquinarioHistorico/${numIdPat}`}>{state.nomeNivel3Crumb}</a>

                        </li>



                        <li className="breadcrumb-item active">
                          <a className="text-danger text-bold"
                            href="https:/doc-patrimonio.appdemocrata.com/docs/Cadastros%20Auxiliares/mecanicos/especialidades"
                            target="_blank"
                            style={{ pointerEvents: 'none'}}
                          >
                            <i className="fa fa-info mr-1" />
                            {state.nomeNivel4Crumb}
                          </a>
                        </li>
                      </ol>
                    </div>
                  </div>


                  <div className="content">
                    <div className=" ">
                      <div className="row">
                        <div className="col-lg-12">
                          <ResultadosChamadosGrid />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResultadoChamados;
