import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { addZeroes } from "../../../utils/utils";
import api from "../../../services/api";

export const MecanicoDataGrid = () => {
  let history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);

  const handleClick = (event, codigo) => {
    history.push({
      pathname: "/MecanicoItem/" + codigo,
    });
  };

  const handleChange = (value) => {
    setSelectedRows(value);
  };

  const deleteRow = (event) => {
    console.log(event.target.id);
  };

  const viewRow = (event) => {
    console.log(event.target.id);
  };

  useEffect(() => {
    api.get("mecanico").then((response) => {
      setData(response.data.records);
    });
  }, []);

  if (!data) return null;

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <table>
            <caption></caption>
            <thead>
              <tr>
                <th style={{ width: "5%", textAlign: "center" }} scope="col">
                  Ações
                </th>
                <th style={{ width: "7%", textAlign: "center" }} scope="col">
                  Número
                </th>
                <th style={{ width: "12%", textAlign: "left" }} scope="col">
                  Mecânico{" "}
                </th>
                <th style={{ width: "15%", textAlign: "left" }} scope="col">
                  Obs{" "}
                </th>
                <th style={{ width: "23%", textAlign: "left" }} scope="col">
                  Especialidade{" "}
                </th>
                <th style={{ width: "20%", textAlign: "left" }} scope="col">
                  Setores
                </th>
                <th style={{ width: "7%" }} scope="col">
                  Status
                </th>
              </tr>
            </thead>

            <tbody>
              {data
              .sort((a, b) => {
                if (a.usuario.nome > b.usuario.nome) return 1;
                if (a.usuario.nome < b.usuario.nome) return -1;
                return 0;
              })

                .sort((a, b) => b.status - a.status)
                .map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <button
                          type="button"
                          className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                          onClick={(event) => {
                            handleClick(event, item.id);
                          }}
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                      </td>

                      <td style={{ textAlign: "center" }} data-label="ID">
                        <div>{addZeroes(item.id, 8)}</div>
                      </td>

                      <td style={{ textAlign: "left" }} data-label="Mecanico ">
                        {" "}
                        {item.usuario.nome}
                      </td>
                      <td style={{ textAlign: "left" }} data-label="Obs. ">
                        {" "}
                        {item.observacao}
                      </td>
                      <td
                        style={{ textAlign: "left" }}
                        data-label="Especialidade "
                      >
                        {item.especialidades.map((esp) => (
                          <span className="badge bg-primary">
                            {esp.especialidades.nome}
                          </span>
                        ))}
                      </td>
                      <td style={{ textAlign: "left" }} data-label="Setores ">
                        {item.setores.map((esp) => (
                          <span className="badge bg-success">
                            {esp.setor.descricao}
                          </span>
                        ))}
                      </td>

                      <td data-label="Status">
                        <span
                          className={item.status == 0 ? "badge bg-warning" : ""}
                        >
                          {item.status == 1 ? " Ativo" : " Inativo "}{" "}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
