import React from "react";
import ReactApexChart from "react-apexcharts";

export const GrupoChart = ({ grupo }) => {

  const total = grupo.reduce(
    (accumulator, grupo) =>
      accumulator +
      grupo.total,
    0
  );

  const [chartGrupo] = React.useState({
    series: [
      {
        data: grupo.map((dadosGrupo) => dadosGrupo.total),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#00E4E4"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#304758"],
          fontWeight: 'normal',
          fontSize: '14px',
        },
        formatter: function (val, opt) {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :...  " + val
            );
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: grupo.map((dadosGrupo) => dadosGrupo.nome),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text:`GRUPOS DE PATRIMÔNIOS`,
        align: "center",
        floating: true,
        margin: 6,
        style: {
          height: 'auto',
          fontSize: '18px',          
        }
      },
      subtitle: {
        text: `Total: ${total}`,
        align: 'right',
        margin: 10,
        offsetX: 3,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          height: 'auto'
        }
    },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  });

  return (
    <div className="shadow-lg bg-white rounded" style={{padding: 10,}}>
      <div id="chart">
        <ReactApexChart
          options={chartGrupo.options}
          series={chartGrupo.series}
          type="bar"
          height={600}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
