import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AlertSuccess, AlertDanger } from "../../Maquinario/Component/styles";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";
import { getControle } from "../../../services/auth";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TransferenciaGrid = () => {
  let history = useHistory();
  const { id } = useParams();
  const [descricao, setDescricao] = useState([]);
  const [maquinario, setMaquinario] = useState([]);
  const [setorAlocadoDescricao, setSetorAlocadoDescricao] = useState("");
  const [responsavelMaquinario, setResponsavelMaquinario] = useState([]);
  const [idSetor, setIdSetor] = useState("");
  const [setor, setSetor] = useState("");
  const [unidadeSelecionada, setUnidadeSelecionada] = useState("");
  const [setorId, setSetorId] = useState("");
  const [unidadeId, setUnidadeId] = useState("");
  const [listUnidadeProducao, setListUnidadeProducao] = useState([]);
  const [listSetor, setListSetor] = useState([]);
  const [selectedUnidadeProducao, setSelectedUnidadeProducao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState("");
  const [filteredSetores, setFilteredSetores] = useState([]);
  const [descrMaq, setDescrMaq] = useState([]);
  const [resp, setResp] = useState("");
  const [numPatr, setNumPatr] = useState([]);
  const [data, setData] = useState([]);
  const [respId, setRespId] = useState("");
  const [observacao, setObservacao] = useState("");
  const [observacaoModal, setObservacaoModal] = useState("");
  const [tipoRel, setTipoRel] = useState("AGU");
  const [open, setOpen] = React.useState(false);
  const [unidadeProducaoDestino, setUnidadeProducaoDestino] = useState([]);
  const [setorDestino, setSetorDestino] = useState([]);
  const [responsavelDestino, setResponsavelDestino] = useState([]);
  const [statusDaTransferencia, setStatusDaTransferencia] = useState("");
  const [dataAlteracaoStatus, setDataAlteracaoStatus] = useState("");
  const [setorDestinoId, setSetorDestinoId] = useState([]);
  const [observacaoDaTransferencia, setObservacaoDaTransferencia] = useState(
    []
  );
  const [unidadeProducaoOrigem, setUnidadeProducaoOrigem] = useState([]);

  let msgError = [];
  const tipoListaStatus = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [buttonDisabledModal, setButtonDisabledModal] = useState(
    tipoRel == "APR" || id == 0 ? false : true
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const transfereSetor = async () => {
    setOpen(false);
    let usuarioLocal = getControle();

    try {
      if (id != 0) {
        var dadosJson = {
          destino: setorDestinoId, // id do setor
          maquinario: numPatr,
          origem: idSetor,
          observacao: observacao + observacaoModal,
          solicitanteAdmin: parseInt(usuarioLocal),
          status: tipoRel,
        };
        
        await api.put(`/transferencia/` + id, dadosJson);

        await api.put(`/transferencia/alterar-maquinario-setor/${numPatr}/${setorDestinoId}`);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          destino: selectedSetor, // id do setor
          maquinario: parseInt(maquinario),
          origem: idSetor,
          observacao: observacao,
          solicitanteAdmin: parseInt(usuarioLocal),
          status: "APR",
        };

        await api.post(`/transferencia/`, dadosJson);

        await api.put(`/transferencia/alterar-maquinario-setor/${maquinario}/${selectedSetor}`);
        setStatusRecord({
          type: "success",
          mensagem: "Registro salvo com sucesso",
        });
      }
      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/transferencia",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro ao salvar este item",
      });
    }
  };

  useEffect(() => {
    const getUnProducao = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidadeProducao(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);

        if (selectedUnidadeProducao) {
          const filteredSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == selectedUnidadeProducao
          );
          setFilteredSetores(filteredSetores);
        }
      });
    };

    getSetores();
    getUnProducao();
    getTransfere();
  }, [descricao, selectedUnidadeProducao, maquinario]);

  useEffect(() => {
    const getTransf = async () => {
      await api.get(`/transferencia/${id}`).then((response) => {
        setData(response.data.records);
        setNumPatr(response.data.records[0].maquinario?.id);
        setUnidadeProducaoDestino(
          response.data.records[0].destino.unidadeProducao.fantasia
        );
        setUnidadeProducaoOrigem(
          response.data.records[0].origem.unidadeProducao.fantasia
        );
        setSetorDestino(response.data.records[0].destino.descricao);
        setSetorDestinoId(response.data.records[0].destino.id);
        setResponsavelDestino(
          response.data.records[0].destino.responsavel.nome
        );
        setIdSetor(response.data.records[0].origem.id);
        setObservacaoDaTransferencia(response.data.records[0].observacao);
        setDescricao(response.data.records[0].maquinario.descricao);
        setSetorAlocadoDescricao(response.data.records[0].origem.descricao);
        setStatusDaTransferencia(response.data.records[0].status);
        setResponsavelMaquinario(
          response.data.records[0].origem.responsavel.nome
        );
        setDataAlteracaoStatus(response.data.records[0].dataAlteracaoStatus);
      });

      if (tipoRel == "REC") {
        setObservacaoModal("RECUSADO ! MOTIVO: ");
      } else {
        setObservacaoModal("");
      }
    };
    getTransf();
  }, [descricao, maquinario, tipoRel]);

  const getTransfere = async () => {
    if (maquinario.length >= 3) {
      await api.get(`maquinario/${maquinario}`).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setSetorAlocadoDescricao(
          response.data.records[0].maquinarioSetores[0].setores.descricao
        );
        setIdSetor(response.data.records[0].maquinarioSetores[0].setores.id);
        setResponsavelMaquinario(
          response.data.records[0].maquinarioSetores[0].setores.responsavel.nome
        );
        setDescrMaq(response.data.records);
        setUnidadeProducaoOrigem(
          response.data.records[0].maquinarioSetores[0].setores.unidadeProducao
            .fantasia
        );
      });
    }
  };

  const handleChangeUnidadeProducao = async (event) => {
    let valor = event.target.value;
    setSelectedUnidadeProducao(valor);
    setSelectedSetor("");
    setSetor("");
    setResp("");

    if (valor != 0) {
      let labelUnidade = listUnidadeProducao.find(
        (option) => option.cgcCpf == valor
      );
      setUnidadeSelecionada(labelUnidade.fantasia);
    }

    setCurrentPage(0);
    setPagina(currentPage);
  };

  const hundleChangeConfirmaModal = async (event) => {
    let confirma = event.target.value;
    setObservacaoModal(confirma);
    setButtonDisabledModal(confirma.length >= 30 ? false : true);

    setCurrentPage(0);
    setPagina(currentPage);
  };

  const handleChangeSetor = async (event) => {
    let valor = event.target.value;
    setSelectedSetor(valor);

    if (valor != 0) {
      let labelSetor = listSetor.find((option) => option.id == valor);
      let labelResp = listSetor.find((option) => option.id == valor);
      setSetor(labelSetor.descricao);
      setSetorId(labelSetor.id);
      setResp(labelResp.responsavel.nome);
      setRespId(labelResp.responsavel.id);

      setCurrentPage(0);
      setPagina(currentPage);
    }
  };

  const handleOpen = () => setOpen(true);

  const handleCancelarModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div>
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                {/* Formulario de tranfêrencia */}
                <form className="form-horizontal">
                  <div className="col-md-12">
                    <div className="row">
                      <Button className="btn btn-sm mb-4" disabled>
                        {statusDaTransferencia === "AGU" && (
                          <span
                            className="bg-info text-white p-2"
                            style={{ borderRadius: "5px" }}
                          >
                            <i className="fa fa-clock mr-1"></i>
                            AGUARDANDO TRANFERÊNCIA
                          </span>
                        )}

                        {statusDaTransferencia === "APR" && (
                          <span
                            className="btn-success text-white p-2"
                            style={{ borderRadius: "5px" }}
                          >
                            <i className="fa fa-check mr-1"></i>
                            TRANSFERÊCIA APROVADA
                          </span>
                        )}

                        {statusDaTransferencia === "REC" && (
                          <span
                            className="btn-danger text-white p-2"
                            style={{ borderRadius: "5px" }}
                          >
                            <i className="fa fa-ban mr-1"></i>
                            TRANFERÊNCIA RECUSADA
                          </span>
                        )}
                      </Button>
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="row">
                        <FormControl>
                          <Stack spacing={3}>
                            <TextField
                              id="my-input"
                              size="small"
                              aria-describedby="my-helper-text"
                              variant="outlined"
                              type="text"
                              label="Nº Patrimônio"
                              fullWidth={true}
                              value={id == 0 ? maquinario : numPatr}
                              onBlur={getTransfere}
                              onChange={(e) => setMaquinario(e.target.value)}
                            />
                          </Stack>
                        </FormControl>

                        <FormControl className="ml-3">
                          <Stack spacing={3}>
                            <TextField
                              type="text"
                              size="small"
                              label="Descrição"
                              className="form-control"
                              id="descricao"
                              onBlur={handleSubmit}
                              value={descricao}
                              spacing={3}
                              style={{ width: "400px" }}
                            ></TextField>
                          </Stack>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-12 mb-5">
                      <div
                        className="card-header"
                        style={{
                          borderLeft: "2px solid blue",
                          backgroundColor: "#E9ECEF",
                        }}
                      >
                        <div className="col-12 text-center">
                          <div className="row">
                            <div style={{ fontSize: 18 }}>
                              {statusDaTransferencia == "APR"
                                ? "Patrimônio transferido de : "
                                : "Patrimônio Alocado:"}
                            </div>
                          </div>
                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Unidade de Produção : ............{" "}
                            <b>{unidadeProducaoOrigem}</b>
                          </div>
                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Setor: ......................................{" "}
                            <b>{setorAlocadoDescricao}</b>
                          </div>
                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Responsável: ...........................{" "}
                            <b>{responsavelMaquinario}</b>
                          </div>
                          <div
                            className="ml-2"
                            style={{ textAlign: "left", color: "blue" }}
                          >
                            Histórico de Manutenção:
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <b className="ml-3 mb-4" style={{ fontSize: 15 }}>
                      {statusDaTransferencia == "APR"
                        ? ""
                        : "Transferir para :"}
                    </b>
                  </div>

                  {statusDaTransferencia != "APR" ? (
                    <div>
                      {(() => {
                        if (id == 0) {
                          return (
                            <div className="col-md-12 mb-3">
                              <div className="col-md-12">
                                <div className="row">
                                  <FormControl sx={{ minWidth: 350 }}>
                                    <InputLabel id="demo-controlled-open-select-label">
                                      Unidade Produção
                                    </InputLabel>
                                    <Select
                                      label="Unidade Produção"
                                      size="small"
                                      value={selectedUnidadeProducao}
                                      onChange={handleChangeUnidadeProducao}
                                      spacing={3}
                                    >
                                      <MenuItem value="0">Selecione</MenuItem>
                                      {listUnidadeProducao
                                        .sort((a, b) => {
                                          if (a.fantasia > b.fantasia) return 1;
                                          if (a.fantasia < b.fantasia)
                                            return -1;
                                          return 0;
                                        })
                                        .map((item) => (
                                          <MenuItem font value={item.cgcCpf}>
                                            {item.fantasia}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl
                                    className="ml-3"
                                    sx={{ minWidth: 250 }}
                                  >
                                    <InputLabel id="demo-controlled-open-select-label">
                                      Setor
                                    </InputLabel>
                                    <Select
                                      labelId="demo-controlled-open-select-label"
                                      size="small"
                                      id="demo-controlled-open-select-label"
                                      label="Setor"
                                      value={selectedSetor}
                                      onChange={handleChangeSetor}
                                      spacing={3}
                                    >
                                      <MenuItem value="0">Selecione</MenuItem>
                                      {filteredSetores
                                        .sort((a, b) => {
                                          if (a.descricao > b.descricao)
                                            return 1;
                                          if (a.descricao < b.descricao)
                                            return -1;
                                          return 0;
                                        })
                                        .map((item) => (
                                          <MenuItem value={item.id}>
                                            {item.descricao}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-12">
                              <div className="col-md-12">
                                <div className="row">
                                  <FormControl sx={{ minWidth: 350 }}>
                                    <TextField
                                      size="small"
                                      label="Unidade Produção"
                                      value={unidadeProducaoDestino}
                                      spacing={3}
                                    ></TextField>
                                  </FormControl>

                                  <FormControl
                                    className="ml-3"
                                    sx={{ minWidth: 250 }}
                                  >
                                    <TextField
                                      labelId="demo-controlled-open-select-label"
                                      size="small"
                                      id="demo-controlled-open-select-label"
                                      label="Setor"
                                      value={setorDestino}
                                      spacing={3}
                                    ></TextField>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ) : (
                    ""
                  )}
                  <br />

                  <div className="form-group">
                    <div className="col-md-12">
                      <div
                        className="card-header"
                        style={{
                          borderLeft: "2px solid blue",
                          backgroundColor: "#F1F8F0",
                        }}
                      >
                        <div className="col-12 text-center">
                          <div className="row">
                            <div style={{ fontSize: 18 }}>
                              {statusDaTransferencia == "APR" ? (
                                "Para : "
                              ) : setorAlocadoDescricao == setor &&
                                maquinario != "" ? (
                                <span style={{ color: "red" }}>
                                  "Setor de Destino mesmo de origem"
                                </span>
                              ) : (
                                "Destino:"
                              )}
                            </div>
                          </div>

                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Unidade de Produção : ............{" "}
                            <b>
                              {id != 0
                                ? unidadeProducaoDestino
                                : unidadeSelecionada}
                            </b>
                          </div>

                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Setor: ......................................{" "}
                            <b>{id != 0 ? setorDestino : setor}</b>
                          </div>
                          <div className="ml-2" style={{ textAlign: "left" }}>
                            Responsável: ...........................{" "}
                            <b>{id != 0 ? responsavelDestino : resp}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 ml-2">
                      <div className="form-group">
                        <label
                          htmlFor="inputExperience"
                          className="col-sm-6 col-form-label"
                        >
                          Observação da Movimentação Patrimonial
                        </label>

                        {(() => {
                          if (id != 0) {
                            return (
                              <textarea
                                className="form-control"
                                id="observacao"
                                placeholder="Observação..."
                                value={
                                  observacaoDaTransferencia == null &&
                                  observacaoModal == ""
                                    ? ""
                                    : observacaoDaTransferencia +
                                      "\n" +
                                      observacaoModal
                                }
                                onChange={(e) => setObservacao(e.target.value)}
                              />
                            );
                          } else {
                            return (
                              <textarea
                                className="form-control"
                                id="observacao"
                                placeholder="Observação..."
                                value={observacao}
                                onChange={(e) => setObservacao(e.target.value)}
                              />
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </div>

                  <form className="form-horizontal">
                    <div className="col-md-10">
                      <div className="row">
                        {(() => {
                          if (id == 0) {
                            {
                              (" ");
                            }
                          } else {
                            return (
                              <div className="col-sm-12 mb-2">
                                <FormControl spacing={3}>
                                  <FormLabel></FormLabel>
                                  <RadioGroup
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 24,
                                      },
                                    }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={
                                      statusDaTransferencia == "APR"
                                        ? "APR"
                                        : statusDaTransferencia == "REC"
                                        ? "REC"
                                        : "AGU"
                                    }
                                  >
                                    {statusDaTransferencia == "AGU" ? (
                                      //aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii
                                      <div>
                                        <Button
                                          type="button"
                                          className="btn btn-info text-white"
                                          size="medium"
                                          value="AGU"
                                          label="Aguardando transferência"
                                          disabled="true"
                                        >
                                          <i className="fa fa-clock mr-1"></i>
                                          AGUARDANDO TRANFERÊNCIA
                                        </Button>
                                      </div>
                                    ) : statusDaTransferencia == "APR" ? (
                                      <div>
                                        <Button
                                          type="button"
                                          className="btn btn-success text-white"
                                          size="medium"
                                          value="APR"
                                          label="Tranferência Aprovada"
                                          disabled="true"
                                        >
                                          <i className="fa fa-check mr-1"></i>
                                          TRANSFERÊCIA APROVADA
                                        </Button>
                                      </div>
                                    ) : statusDaTransferencia == "REC" ? (
                                      <div>
                                        <Button
                                          type="button"
                                          className="btn btn-danger text-white"
                                          size="medium"
                                          value="REC"
                                          label="Recusado"
                                          disabled="true"
                                        >
                                          <i className="fa fa-ban mr-1"></i>
                                          TRANFERÊNCIA RECUSADA
                                        </Button>
                                      </div>
                                    ) : (
                                      <div>
                                        <FormControlLabel
                                          value="AGU"
                                          control={<Radio />}
                                          label="Aguardando"
                                          sx={{ color: "#5bc0de" }}
                                        />
                                        <FormControlLabel
                                          value="REC"
                                          control={<Radio />}
                                          label="Recusado"
                                          sx={{
                                            color: "#d9534f",
                                          }}
                                        />
                                        <FormControlLabel
                                          value="APR"
                                          control={<Radio />}
                                          label="Aprovado"
                                          sx={{ color: "#5cb85c" }}
                                        />
                                      </div>
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            );
                          }
                        })()}

                        {(() => {
                          if (
                            id != 0 &&
                            statusDaTransferencia != "APR" &&
                            statusDaTransferencia != "REC"
                          ) {
                            return (
                              <Button
                                size="medium"
                                onClick={handleOpen}
                                disabled={
                                  setorAlocadoDescricao != setorDestino
                                    ? false
                                    : true
                                }
                              >
                                <p className="btn btn-md btn-outline-success p-2">
                                  <i className="fa fa-pencil fa-sm mr-1"></i>
                                  APROVAR TRANSFERÊNCIA
                                </p>
                              </Button>
                            );
                          } else if (statusDaTransferencia == "APR") {
                            return (
                              <Button
                                type="button"
                                className="btn btn-success text-white mt-3"
                                disabled
                                size="small"
                                sx={{ marginLeft: "8px" }}
                              >
                                Transferido em{" "}
                                {new Date(
                                  dataAlteracaoStatus
                                ).toLocaleDateString()}
                              </Button>
                            );
                          } else if (statusDaTransferencia == "REC") {
                            return;
                            <> </>;
                          } else {
                            return (
                              <Button
                                onClick={handleOpen}
                                disabled={
                                  selectedSetor == "" ||
                                  setorAlocadoDescricao == setor
                                    ? true
                                    : false
                                }
                              >
                                <p
                                  className="btn btn-md btn-outline-primary"
                                  style={{
                                    marginRight: "4px",
                                    marginTop: "0px",
                                  }}
                                >
                                  {" "}
                                  Salvar
                                </p>
                              </Button>
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </form>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {(() => {
            if (id == 0) {
              return (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <h2 className="lead">
                    <h3 className="medium text-muted">
                      {" "}
                      Confirme as informações!
                    </h3>
                    <h5
                      className="small text-muted mt-4"
                      style={{ color: "black" }}
                    >
                      Nº Patrimônio: <b>{maquinario}</b>
                      <br />
                      Descrição: <b>{descricao}</b>
                      <br />
                      Setor Origem : <b>{setorAlocadoDescricao}</b>
                      <br />
                      Responsável : <b>{responsavelMaquinario}</b>
                      <br />
                      <br />
                      <b>TRANSFERIR PARA :</b>
                      <br />
                      <br />
                      Setor Destino : <b>{setor}</b>
                      <br />
                      Responsável : <b>{resp}</b>
                      <br />
                      Observações : <b>{observacao}</b>
                      <br />
                      <dl>
                        {(() => {
                          if (msgError.length > 0) {
                            return <h5 className="small text-danger mt-3"></h5>;
                          }
                        })()}

                        {msgError.map((er) => (
                          <span key={er}>{er}</span>
                        ))}
                      </dl>
                    </h5>
                  </h2>
                </Typography>
              );
            } else {
              //editar transferência
              return (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <h2 className="lead">
                      <h3 className="medium text-muted">
                        {" "}
                        Confirme as informações!
                      </h3>
                      <h5
                        className="small text-muted mt-4"
                        style={{ color: "black" }}
                      >
                        Nº Patrimônio: <b>{numPatr}</b>
                        <br />
                        Descrição: <b>{descricao}</b>
                        <br />
                        Setor Origem : <b>{setorAlocadoDescricao}</b>
                        <br />
                        Responsável : <b>{responsavelMaquinario}</b>
                        <br />
                        <br />
                        <b>TRANSFERIR PARA :</b>
                        <br />
                        <br />
                        Setor Destino : <b>{setorDestino}</b>
                        <br />
                        Responsável : <b>{responsavelDestino}</b>
                        <br />
                        Observações : <b>{observacaoDaTransferencia}</b>
                        <br />
                        <dl>
                          {(() => {
                            if (msgError.length > 0) {
                              return (
                                <h5 className="small text-danger mt-3"></h5>
                              );
                            }
                          })()}

                          {msgError.map((er) => (
                            <span key={er}>{er}</span>
                          ))}
                        </dl>
                      </h5>
                    </h2>
                  </Typography>

                  <FormControl spacing={3}>
                    <FormLabel></FormLabel>
                    <RadioGroup
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 24,
                        },
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={tipoRel}
                      onChange={tipoListaStatus}
                    >
                      <FormControlLabel
                        value="APR"
                        control={<Radio />}
                        label="Aprovar"
                        sx={{
                          color: "#5cb85c",
                        }}
                      />

                      <FormControlLabel
                        value="REC"
                        control={<Radio />}
                        label="Recusar"
                        sx={{
                          color: "#d9534f",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>

                  {(() => {
                    if (tipoRel == "REC") {
                      return (
                        <div>
                          <textarea
                            className="form-control"
                            id="observacao"
                            placeholder="Informe o motivo da recusa ..."
                            value={observacaoModal}
                            onChange={hundleChangeConfirmaModal}
                          />
                          "Campo Obrigatório, acima de 10 caracteres!"
                        </div>
                      );
                    } else {
                      return " ";
                    }
                  })()}
                </>
              );
            }
          })()}

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <button
              disabled={
                tipoRel == "APR" || id == 0 ? false : buttonDisabledModal
              }
              onClick={transfereSetor}
              className="btn btn-outline-success mt-2 mr-2"
              style={{ alignItems: "center" }}
            >
              Confirmar
            </button>

            {(() => {
              if (id == 0) {
                return (
                  <button
                    onClick={handleCancelarModal}
                    className="btn btn-outline-primary mt-2"
                  >
                    Editar
                  </button>
                );
              } else {
                return (
                  <>
                    <button
                      onClick={handleCancelarModal}
                      className="btn btn-outline-primary mt-2"
                    >
                      Editar
                    </button>
                  </>
                );
              }
            })()}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TransferenciaGrid;
