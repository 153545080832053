import {
  Box,
  Button,
  Card,
  createTheme,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  responsiveFontSizes,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useState } from "react";
import GridComponentePecas from "./gridComponentePecas";
import dayjs from "dayjs";
import { ModalConfirmacao } from "./modalConfirmacao";
import { ModalReprovacao } from "./modalReprovacao";
import { GridComponentePecasMobile } from "./GridComponentePecasMobile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ModalCardMobile } from "./ModalCardMobile";
import { ModalCardReprovacaoMobile } from "./ModalCardReprovacaoMobile";

export const FiltrosPecas = () => {
  const [openModalA, setOpenModalA] = useState(false);
  const [openModalR, setOpenModalR] = useState(false);
  const [aprova, setAprova] = useState("N");

  const { id } = useParams();

  const [value, setValue] = useState(
    dayjs().subtract(30, "day").format("YYYY-MM-DD")
  );

  const [valueFim, setValueFim] = useState(dayjs().format("YYYY-MM-DD"));
  const handleChange = (event) => {
    setAprova(event.target.value);
  };
  const [selectedRows, setSelectedRows] = React.useState([]);
  const dataFiltroInicial = async (dtInicial) => {
    setValue(dtInicial);
  };
  const [showErrorA, setShowErrorA] = useState(false);
  const [showErrorR, setShowErrorR] = useState(false);

  const dataFiltroFinal = async (dtFinal) => {
    setValueFim(dtFinal);
  };
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenModalAprova = () => {
    if (selectedRows.length == 0) {
      setShowErrorA(true);
    } else {
      setOpenModalA(true);
      setShowErrorA(false);
    }
  };

  const handleCloseModalA = () => {
    setOpenModalA(false);
  };

  const handleOpenModalReprova = () => {
    if (selectedRows.length == 0) {
      setShowErrorR(true);
    } else {
      setOpenModalR(true);
      setShowErrorR(false);
    }
  };

  const handleCloseModalR = () => {
    setOpenModalR(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".tooltip-button")) {
        setShowErrorA(false);
        setShowErrorR(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Card elevation={0}>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item md={2} sm={12} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack>
              <DatePicker
                label="Data Inicial"
                value={value}
                onChange={(newValue) =>
                  dataFiltroInicial(dayjs(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                dayOfWeekFormatter={(day, date) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>

        <Grid item md={2} sm={12} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack spacing={2}>
              <DatePicker
                label="Data Final"
                value={valueFim}
                onChange={(newValue) =>
                  dataFiltroFinal(dayjs(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                dayOfWeekFormatter={(day, date) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={aprova}
              onChange={handleChange}
              sx={{
                alignItems: "center",
                marginLeft: "16px",
                padding: "0px",
              }}
            >
              <FormControlLabel
                value="N"
                control={<Radio />}
                label="Pendente"
              />
              <FormControlLabel
                value="A"
                control={<Radio />}
                label="Aprovado"
              />
              <FormControlLabel
                value="R"
                control={<Radio />}
                label="Reprovado"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 0,
              marginRight: "9px",
            }}
          >
            <Tooltip
              title={
                selectedRows.length == 0
                  ? "Selecione um ou mais itens no CheckBox."
                  : ""
              }
              open={showErrorR && selectedRows.length == 0}
              placement="top"
              arrow
            >
              <Button
                onClick={handleOpenModalReprova}
                size="small"
                variant="contained"
                sx={{ minWidth: 100, marginRight: 2 }}
                color="error"
                disabled={aprova == "A" || aprova == "R"}
              >
                Reprovar
              </Button>
            </Tooltip>

            <Tooltip
              title={
                selectedRows.length == 0
                  ? "Selecione um ou mais itens no CheckBox."
                  : ""
              }
              open={showErrorA && selectedRows.length == 0}
              placement="top"
              arrow
            >
              <Button
                onClick={handleOpenModalAprova}
                size="small"
                variant="contained"
                color="primary"
                sx={{ minWidth: 100, marginRight: 1 }}
                disabled={aprova == "A" || aprova == "R"}
              >
                Aprovar
              </Button>
            </Tooltip>
          </Box>
        </Grid>

        <Grid item sx={{ padding: 2, overflow: "scroll" }} md={12}>
          {/*  {isMobile ? 
        <GridComponentePecasMobile  /> 
        :   */}
          <GridComponentePecas
            dataInicial={value}
            dataFinal={valueFim}
            aprova={aprova}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          {/*  } */}
        </Grid>

        {openModalA && (
          <>
            {isMobile && (
              <ModalCardMobile
                open={openModalA}
                handleClose={handleCloseModalA}
                chamadosSelecionados={selectedRows}
              />
            )}

            {!isMobile && (
              <ModalConfirmacao
                openModal={openModalA}
                handleClose={handleCloseModalA}
                selectedRows={selectedRows}
              />
            )}
          </>
        )}

        {openModalR && (
          <>
            {isMobile && (
              <ModalCardReprovacaoMobile
                open={openModalR}
                handleClose={handleCloseModalR}
                chamadosSelecionados={selectedRows}
              />
            )}

            {!isMobile && (
              <ModalReprovacao
                openModal={openModalR}
                handleClose={handleCloseModalR}
                selectedRows={selectedRows}
              />
            )}
          </>
        )}
      </Grid>
    </Card>
  );
};

export default FiltrosPecas;
