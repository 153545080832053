import React, { useState } from "react";
import { FormLabel, InputLabel, FormControl, Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import api from "../../services/api";
import { PDFExport } from "@progress/kendo-react-pdf";
import LogoPatrimonio from "../../assets/logoPatrimonio.svg";
import SemImg from "../../assets/sem-foto.svg";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { CircularProgress } from "@mui/material";
import Logo from "../../assets/democrata-logo.svg";
import { addZeroes, formatarDataBr } from "../../utils/utils";
import { QRCodeCanvas } from "qrcode.react";
import { usePatrimonioContext } from "../../contexts/PatrimonioContext";
export const ResultadosChamadosGrid = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );

  const { savePatrimonioId } = usePatrimonioContext();

  /* valores da caixa de dialog 
  md = medio
  xs = pequeno
  sm = default (entre o pequeno  )
  lg = largo
  xl = grande
   */

  const getChamado = async () => {
    const response = await api.get(`/chamado/${id}`);

    savePatrimonioId(response.data.records[0].patrimonio.id);

    return response.data.records;
  };

  const { data: chamado, isLoading: isLoadingChamado } = useQuery(
    ["chamados", page],
    getChamado
  );

  const getMaquinario = async () => {
    const response = await api.get(`/maquinario/${chamado[0].patrimonio.id}`);
    return response.data.records;
  };
  const { data: maquinario, isLoading: isLoadingMaquinario } = useQuery(
    ["maquinarios", chamado],
    getMaquinario
  );

  const getImages = async () => {
    let images = "";
    const response = await api.get(
      `maquinarioalbum/maquinario/${chamado[0]?.patrimonio?.id}`
    );
    return response.data.records;
  };

  const { data: images, isLoadingImage } = useQuery(
    ["imagemPatrimonio", chamado],
    getImages,
    { enabled: !!chamado }
  );

  const getImagesRepaired = async () => {
    const response = await api.get(`/chamadoarquivos/${chamado[0].id}`);
    return response.data.records;
  };

  const { data: patRepaired, isLoading: isLoadingPatRepaired } = useQuery(
    "patReparado",
    getImagesRepaired,
    {
      enabled: !!chamado,
    }
  );

  /**
   * Trata a Renderizacao da imagem para ser exibida
   * de acordo com o valor preenchido no Objeto imagemPrincipal
   * @param {*} imPrincipal
   * @returns
   */
  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              style={`max-Height: 153px;  max-Width: 100%; ${
                images.maquinarioAlbum?.status == 1
                  ? ""
                  : "filter: grayscale();"
              }}`}
              src={`data:image/jpeg;base64, ${images[0]?.blobImage}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 300px}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  if (isLoadingChamado || isLoadingMaquinario) return <CircularProgress />;

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  const htmlResult = () => {
    return (
      <>
        <div className="card">
          <h4 className="mt-1 ml-1">
            <img style={{ width: "190px" }} src={Logo} alt="Logo" />
            <small className="float-right">
              <img style={{ width: "190px" }} src={LogoPatrimonio} />
            </small>
          </h4>
          <div style={{ width: "100%" }}>
            <div className="col-12">
              <h5>Análise do Tempo do Chamado</h5>
            </div>

            <>
              <div className="  ">
                <div className="card-body">
                  <div className="tab-pane active" id="settings">
                    <form className="form-horizontal">
                      <div className="row">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-2 mb-0 ">
                              <QRCodeCanvas
                                style={{ display: "block", margin: "0 auto" }}
                                data-qr={`${maquinario?.[0].numPatrimonio}`}
                                value={`${maquinario?.[0].numPatrimonio}`}
                              />
                            </div>

                            <div
                              className="col-md-10"
                              style={{ margin: "0 auto" }}
                            >
                              <table style={{ border: "0px" }}>
                                <table className=" table-sm">
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#DCDCDC",
                                        height: "10%",
                                      }}
                                    >
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 37,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Chamado
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 50,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Solicitante
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 70,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Motivo Abertura
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 50,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Mecânico
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 100,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Laudo
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 40,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Status
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontSize: 13,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {chamado[0].id}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {chamado[0].solicitante.nome}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {chamado[0].motivoAbertura}
                                      </td>

                                      <td style={{ textAlign: "left" }}>
                                        <Link
                                          to={`/historicoMecanicos/${chamado[0].mecanico?.id}`}
                                        >
                                          {chamado[0]?.mecanico?.usuario.nome}
                                        </Link>
                                      </td>

                                      <td style={{ textAlign: "center" }}>
                                        {chamado[0].laudoTecnico}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {chamado[0].status == 0
                                          ? "Aguardando"
                                          : chamado[0].status == 1
                                          ? "Andamento"
                                          : chamado[0].status == 2
                                          ? "Finalizado"
                                          : chamado[0].status == 3
                                          ? "Homologado"
                                          : chamado[0].status == 4
                                          ? "Retornado"
                                          : chamado[0].status == 5
                                          ? "Recusado"
                                          : chamado[0].status == 6
                                          ? "Encerrado pelo Solicitante"
                                          : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </table>

                              <table style={{ border: "0px" }}>
                                <table className=" table-sm">
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#FBF2B7",
                                        height: "10%",
                                      }}
                                    >
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 40,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Nº Pat.
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Descrição
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Unidade Produção
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Setor
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td style={{ textAlign: "center" }}>
                                        <Link
                                          to={`/maquinarioHistorico/${chamado[0].patrimonio?.id}`}
                                        >
                                          {chamado[0].patrimonio.numPatrimonio}
                                        </Link>
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {chamado[0].patrimonio.descricao}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {
                                          chamado[0].setor.unidadeProducao
                                            .fantasia
                                        }
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {chamado[0].setor.descricao}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </table>

                              <table style={{ border: "0px" }}>
                                <table className=" table-sm">
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#B0E0E6",
                                        height: "10%",
                                      }}
                                    >
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Abertura
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Designado
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "Center",
                                        }}
                                      >
                                        Aceite
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Fechamento
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          width: 60,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        {chamado[0].status == 6
                                          ? "Data Hora encerrado"
                                          : "Homologado"}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td style={{ textAlign: "center" }}>
                                        {formatarDataBr(
                                          chamado[0]?.dataAbertura
                                        )}
                                      </td>

                                      <td style={{ textAlign: "center" }}>
                                        {chamado[0].status == 6
                                          ? ""
                                          : chamado[0]?.dataDesignacao == null
                                          ? ""
                                          : formatarDataBr(
                                              chamado[0]?.dataDesignacao
                                            )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {chamado[0].status == 6
                                          ? ""
                                          : chamado[0]?.dataAceite == null
                                          ? ""
                                          : formatarDataBr(
                                              chamado[0]?.dataAceite
                                            )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {chamado[0].status == 6
                                          ? ""
                                          : chamado[0]?.movimentoMecanico[0]
                                              ?.dataFechamentoMecanico == null
                                          ? ""
                                          : formatarDataBr(
                                              chamado[0]?.movimentoMecanico[0]
                                                ?.dataFechamentoMecanico
                                            )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {chamado[0]?.dataFechamento == null
                                          ? ""
                                          : formatarDataBr(
                                              chamado[0]?.dataFechamento
                                            )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </table>
                            </div>
                          </div>
                        </div>

                        {/* Abaixo da Imagem */}
                        <div className="col-md-12" style={{ margin: "0 auto" }}>
                          <table
                            className="mb-1"
                            style={{ border: "0px" }}
                          ></table>

                          <table style={{ border: "0px" }}>
                            <table className=" table-sm">
                              <thead>
                                <tr
                                  style={{
                                    backgroundColor: "#B0E0E6",
                                    height: "10%",
                                  }}
                                >
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Abertura a Designado
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Designado a Aceite
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Designado a Fechamento
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Aceite a Fechamento
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 50,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Fechamento a Homologado
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Abertura a Fechamento
                                  </th>
                                  <th
                                    style={{
                                      fontSize: 12,
                                      width: 40,
                                      height: 5,
                                      textAlign: "center",
                                      fontSize: 10,
                                    }}
                                  >
                                    Abertura a Homologado
                                  </th>
                                </tr>
                              </thead>

                              {chamado[0].status !== 6 && (
                                <>
                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[0]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[0]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[0]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[0]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[1]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[1]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[1]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[1]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[2]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[2]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[2]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[2]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[3]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[3]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[3]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[3]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[4]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[4]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[4]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[4]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[5]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[5]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[5]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[5]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          backgroundColor: "#D6F0F5",
                                        }}
                                      >
                                        {chamado[0].relacaoTempoAtendimento[6]
                                          ?.horas == null
                                          ? ""
                                          : chamado[0]
                                              .relacaoTempoAtendimento[6]
                                              ?.horas >= 0
                                          ? `${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[6]
                                                ?.horas,
                                              2
                                            )}h ${addZeroes(
                                              chamado[0]
                                                .relacaoTempoAtendimento[6]
                                                ?.minutos,
                                              2
                                            )}m`
                                          : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              )}
                            </table>
                          </table>

                          <br />
                          <div>
                            <h6 style={{ textAlign: "center" }}>
                              {" "}
                              Imagem(s) do patrimônio
                            </h6>
                            {patRepaired && patRepaired.length > 0 ? (
                              patRepaired.map((item, index) => (
                                <img
                                  key={index}
                                  className="mr-1"
                                  src={`${
                                    item.chamadoArquivos.caminho
                                  }?${new Date().getTime()}`}
                                  style={{ width: "200px", height: "auto" }}
                                />
                              ))
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                Nenhuma imagem disponível
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    );
  };

  console.log(maquinario);

  return (
    <>
      <div className="row">
        <div className="col-md-2" style={{ margin: 0, padding: 0 }}>
          <div className="card">
            <div className="card-body">
              {
                <div className=" ">
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "230px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="product-image"
                      Style={`max-height: 250px; ${
                        images?.maquinarioAlbum?.status == 1 ? "" : ""
                      }}`}
                      src={
                        images?.[0]?.blobImage == null
                          ? ""
                          : `data:image/jpeg;base64, ${images[0]?.blobImage}`
                      }
                      alt="Photo"
                    />
                    <div className="ribbon-wrapper ribbon-xl">
                      {maquinario && maquinario[0]?.status === 1 && (
                        <div className="ribbon bg-success">ATIVO</div>
                      )}

                      {maquinario[0]?.status === 0 && (
                        <div className="ribbon bg-warning">INATIVO</div>
                      )}

                      {maquinario[0]?.status === 2 && (
                        <div className="ribbon bg-danger">MANUTENÇÃO</div>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="col-md-10" style={{ margin: 0, padding: 0 }}>
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                <form className="form-horizontal">
                  <div className="row ">
                    <div className="col-md-12 col-sm-8">
                      <div className="row">
                        <>
                          <div className="col-md-2 col-sm-4 form-group">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Nº Chamado
                            </InputLabel>
                            <input
                              style={{ fontSize: 15 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="chamado"
                              name="chamado"
                              value={chamado[0].id}
                            />
                          </div>

                          <div className="col-md-3 col-sm-3">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Solicitante
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="solicitante"
                              name="solicitante"
                              value={chamado[0].solicitante.nome}
                            />
                          </div>

                          <div className="col-md-7 col-sm-3">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Motivo Abertura
                            </InputLabel>
                            <input
                              style={{
                                fontSize: 13,
                                textTransform: "uppercase",
                              }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="Motivo Abertura"
                              name="Motivo Abertura"
                              value={chamado[0].motivoAbertura}
                            />
                          </div>
                        </>
                      </div>

                      <div className="row">
                        <div className="col-md-2 col-sm-4 form-group">
                          <InputLabel
                            htmlFor="inputEmail"
                            className="col-md-12 col-sm-12 col-form-label"
                          >
                            Nº Pat.
                          </InputLabel>
                          <input
                            style={{ fontSize: 15 }}
                            disabled="true"
                            type="text"
                            className="form-control"
                            id="numPatrimonio"
                            name="numPatrimonio"
                            value={chamado[0].patrimonio.numPatrimonio}
                          />
                        </div>

                        <div className="col-md-3 col-sm-3 ">
                          <div className="form-group ">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Descrição
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="Descrição"
                              name="Descrição"
                              value={chamado[0].patrimonio.descricao}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4">
                          <InputLabel
                            htmlFor="inputName"
                            className="col-md-12 col-sm-12 col-form-label"
                          >
                            Unidade Produção
                          </InputLabel>
                          <input
                            style={{ fontSize: 13 }}
                            disabled="true"
                            type="text"
                            className="form-control"
                            id="unidadeProdução"
                            name="unidadeProdução"
                            value={chamado[0].setor.unidadeProducao.fantasia}
                          />
                        </div>

                        <div className="col-md-4 col-sm-5">
                          <InputLabel
                            htmlFor="inputName"
                            className="col-md-12 col-sm-12 col-form-label"
                          >
                            Setor
                          </InputLabel>
                          <input
                            style={{ fontSize: 13 }}
                            disabled="true"
                            type="text"
                            className="form-control"
                            id="setor"
                            name="setor"
                            value={chamado[0].setor.descricao}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <FormControl
                          className="mr-1"
                          sx={{ minWidth: 100, marginTop: 1 }}
                        >
                          <Button
                            textAlign="right"
                            size="medium"
                            onClick={handleExport}
                            variant="outlined"
                          >
                            Gerar PDF
                          </Button>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <PDFExport
          scale={0.5}
          paperSize={"A4"}
          margin="0.8cm"
          fileName="detalhesChamado.pdf"
          forcePageBreak=".page-break"
          pageTemplate={PDFExportPageTemplate}
          ref={pdf}
        >
          {htmlResult()}
        </PDFExport>
      </div>
    </>
  );
};
