import { createContext, useContext, useState } from "react";

const PatrimonioContext = createContext();

export const PatrimonioProvider = ({ children }) => {
  const [numIdPat, setNumIdPat] = useState(null);

  const savePatrimonioId = (id) => {
    setNumIdPat(id);
  };

  return (
    <PatrimonioContext.Provider
      value={{
        numIdPat,
        savePatrimonioId,
      }}
    >
      {children}
    </PatrimonioContext.Provider>
  );
};

export const usePatrimonioContext = () => useContext(PatrimonioContext);
