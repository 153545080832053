import React from "react";
import ReactApexChart from "react-apexcharts";

export const ChamadoChart = ({ patrimonio }) => {

  const total = patrimonio.reduce(
    (accumulator, pat) =>
      accumulator +
      pat.total,
    0
  );
  const [chartPat] = React.useState({
    series: [
      {
        data: patrimonio.map((dadosPat) => dadosPat.total),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#00E4E4"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#304758"],
          fontWeight: 'normal',
          fontSize: '14px',
        },
        formatter: function (val, opt) {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :... Total " + val 
            );
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: patrimonio.map((dadosPat) => dadosPat.nome),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: `Maiores Incidencia de Chamados    |    Total: ${total}`,
        align: "center",
        floating: true,
        style: {
          fontSize: '16px',
        }
      },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  });

  return (
    <div className="shadow-lg bg-white rounded" style={{padding: 10,}}>
      <div id="chart">
        <ReactApexChart
          options={chartPat.options}
          series={chartPat.series}
          type="bar"
          height={600}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};