import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { useState, useRef } from "react";
import apiUpdate from "../../../services/apiUpdate";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownload from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";

export const AberturaEditar = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const fileInputRef = useRef();
  const idAtualiza = useParams();

  const [informacao, setInformacao] = useState("");
  const [version, setVersion] = useState("");
  const [file, setFile] = useState([]);

  const fetchRecords = async () => {
    const response = await apiUpdate.get(
      `atualizacao-app-abertura/${idAtualiza.id}`
    );
    setInformacao(response.data.records[0].informacao);
    setVersion(response.data.records[0].versao);
    return response.data.records[0];
  };

  const { data } = useQuery("versaoData", fetchRecords);

  const mutationDel = useMutation(
    (id) => {
      apiUpdate.post(`atualizacao-app-abertura-apk/apagar-arquivo`, id);
    },
    {
      onSuccess: () => {
        setFile([]);
      },
    }
  );

  const handleDelete = (id) => {
    mutationDel.mutate(id);
  };

  const mutation = useMutation(
    async (dadosJson) => {
      const response = await apiUpdate.post(
        `/atualizacao-app-abertura/`,
        dadosJson
      );
      if (response.status === 200 || response.status === 201) {
        return response.data.records;
      } else {
        throw new Error(`Request failed with status code ${response.status}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("versaoData");
        history.push("/abertura");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleFileChange = async (e) => {
    const dadosJson = {
      app: "patrimonio_acesso",
      versao: version,
      informacao: informacao,
    };

    const versionResponse = await mutation.mutateAsync(dadosJson);

    let id = versionResponse[0].id;

    var data = new FormData();

    data.append("file", e.target.files[0]);
    data.append("idAtualizacaoAppAbertura", id);
    data.append("posicao", 1 + id);

    apiUpdate
      .post(`/atualizacao-app-abertura-apk/enviar-arquivo`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            apiUpdate
              .get(`/atualizacao-app-abertura-apk/` + res.data.records[0].id)
              .then((response) => {
                setFile(response.data.records[0]);
              });
          } else if (res.status == 401) {
            alert("Erro ao enviar o arquivo");
          }
        },
        function (err) {
          alert("Erro no modo de Submeter!");
        }
      );
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Grid container p={2} md={12} spacing={3}>
      {mutation.error && (
        <h5 onClick={() => mutation.reset()}>{mutation.error}</h5>
      )}
      <Grid item md={6}>
        <TextField
          label="Versão"
          placeholder="1.0.0"
          value={version}
          onChange={(e) => setVersion(e.target.value)}
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          fullWidth
          label="Informação"
          id="fullWidth"
          multiline
          value={informacao}
          onChange={(e) => setInformacao(e.target.value)}
          rows={4}
          placeholder="Escreva aqui..."
        />
      </Grid>
      <Grid item md={6}>
        <Button
          variant="contained"
          endIcon={<AttachFileIcon />}
          onClick={handleButtonClick}
        >
          Anexar
        </Button>
        <input
          type="file"
          id="myFile"
          name="filename"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </Grid>
      {idAtualiza !== 0 && (
        <Grid container md={12} m={2} spacing={2}>
          <Grid item md={6}>
            {data &&
              data.aberturaApk.map((apk) => (
                <div className="form-group" key={apk.id}>
                  <IconButton variant="contained" component="label">
                    <a href={apk.caminho} target="_blank" download>
                      <FileDownload />
                    </a>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    component="label"
                    onClick={() => handleDelete(apk.id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                  <label className="col-sm-6">{apk.nome}</label>
                </div>
              ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
