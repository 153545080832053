import React from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { FormControl } from "@mui/material";
import api from "../../../services/api";
import { addZeroes, formatarData, formatarDataBr } from "../../../utils/utils";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/SearchRounded";
import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import { useQuery } from "react-query";

export const KanbanPrint = () => {
  const [mecanico, setMecanico] = useState("Todos");
  const [grupo, setGrupo] = useState("Todos");
  const [listMecanico, setListMecanico] = useState([]);
  const [tipoChamadoDesc, setTipoChamadoDesc] = useState("Todos");
  const [setor, setSetor] = useState("Todos");
  const [unidadeProducao, setUnidadeProducao] = useState("Todos");
  const [listUnidade, setListUnidade] = useState([]);
  const [listSetores, setListSetores] = useState([]);
  const [listGrupo, setListGrupo] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [fantasia, setFantasia] = useState("");
  const [nomeMecanico, setNomeMecanico] = useState("");
  const [setorNome, setSetorNome] = useState("");
  const [grupoNome, setGrupoNome] = useState("");
  const [valueFim, setValueFim] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );
  const [buscaGenerica, setBuscaGenerica] = useState([]);

  const [solicitanteSelecionado, setSolicitanteSelecionado] = useState("Todos");

  const handleChangeMecanico = (event) => {
    let val = event.target.value;
    const mecanicoSelecionado = event.target.value;
    setMecanico(mecanicoSelecionado);
    setTipoChamadoDesc("Todos");
    setBuscaGenerica("");
    if (val != 0) {
      let label = listMecanico.find((option) => option.id === val);
      setNomeMecanico(label.usuario.nome);
    }
  };

  const handleChangeBuscaGenerica = (event) => {
    const buscaSelecionada = event.target.value;
    setBuscaGenerica(buscaSelecionada);
  };

  const handleChangeTipoChamadoDesc = (event) => {
    let tipoChamadoDesc = event.target.value;
    setTipoChamadoDesc(tipoChamadoDesc);
    setBuscaGenerica("");
  };

  const handleChangeUnidade = (event) => {
    let valor = event.target.value;
    const unidadeSelecionado = event.target.value;
    setUnidadeProducao(unidadeSelecionado);
    setSetor("Todos");
    setMecanico("Todos");
    setGrupo("Todos");
    setTipoChamadoDesc("Todos");
    setBuscaGenerica("");

    if (valor != 0) {
      let label = listUnidade.find((option) => option.cgcCpf === valor);
      setFantasia(label.fantasia);
    }
  };

  const handleChangeSetor = (event) => {
    let valorSetor = event.target.value;
    const setorSelecionado = event.target.value;
    setSetor(setorSelecionado);
    setGrupo("Todos");
    setMecanico("Todos");
    setTipoChamadoDesc("Todos");
    setBuscaGenerica("");

    if (valorSetor != 0) {
      let labelSetor = listSetores.find((option) => option.id === valorSetor);
      setSetorNome(labelSetor.descricao);
    }
  };

  const handleChangeGrupo = (event) => {
    let valorGrupo = event.target.value;
    const grupoSelecionado = event.target.value;
    setGrupo(grupoSelecionado);
    setMecanico("Todos");
    setTipoChamadoDesc("Todos");
    setBuscaGenerica("");

    if (valorGrupo != 0) {
      let labelGrupo = listGrupo.find((option) => option.id === valorGrupo);
      setGrupoNome(labelGrupo.descricao);
    }
  };

  const { data: listaSolicitantes, isLoading } = useQuery(
    "lista-solicitantes",
    async () => {
      const response = await api.get(
        "/chamado/lista-solicitantes?maiorOuIgual=2024-03-20&menorOuIgual=2024-03-28"
      );

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeSelectedSolicitante = (event) => {
    setSolicitanteSelecionado(event.target.value);
  };

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  const htmlFormat = () => {
    return (
      <>
        <div className="row">
          <div>
            <Box p={2} className="invoice mb-1">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                    <small className="float-right">
                      <img style={{ width: "190px" }} src={LogoPatrimonio} />
                    </small>
                  </h4>
                  <h5></h5>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <h4>
                    Chamados
                    <small className="float-right">
                      Total de Itens:{" "}
                      <FormLabel>
                        <b>{data.length}</b>
                      </FormLabel>
                    </small>
                    <div style={{ fontSize: 14 }}>
                      {formatarData(value).slice(0, 10)}
                      {" á "}
                      {formatarData(valueFim).slice(0, 10)}
                      {unidadeProducao != "Todos"
                        ? ` / Unidade Produção: ${fantasia}`
                        : ""}
                      {setor != "Todos" ? ` / Setor: ${setorNome}` : ""}
                      {grupo != "Todos" ? ` / Grupo: ${grupoNome}` : ""}
                      {mecanico != "Todos"
                        ? ` / Mecânico: ${nomeMecanico}`
                        : ""}
                      {tipoChamadoDesc != "Todos"
                        ? ` / Gravidade: ${tipoChamadoDesc}`
                        : ""}
                    </div>
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "11%", wordWrap: "break-word" }}>
                          Nº PAT
                        </th>
                        <th style={{ width: "14%", wordWrap: "break-word" }}>
                          Descrição
                        </th>
                        <th style={{ width: "13%", wordWrap: "break-word" }}>
                          Chamado
                        </th>
                        <th style={{ width: "14%", wordWrap: "break-word" }}>
                          Solicitante
                        </th>
                        <th style={{ width: "13%", wordWrap: "break-word" }}>
                          Data Abertura
                        </th>
                        <th style={{ width: "16%", wordWrap: "break-word" }}>
                          Descrição Chamado
                        </th>
                        <th style={{ width: "16%", wordWrap: "break-word" }}>
                          Mecânico
                        </th>
                        <th style={{ width: "14%", wordWrap: "break-word" }}>
                          Status Chamado
                        </th>
                        <th style={{ width: "13%", wordWrap: "break-word" }}>
                          Data Fech.
                        </th>
                        <th style={{ width: "13%", wordWrap: "break-word" }}>
                          Unidade Produção
                        </th>
                        <th style={{ width: "15%", wordWrap: "break-word" }}>
                          Setor
                        </th>
                        <th style={{ width: "14%", wordWrap: "break-word" }}>
                          Gravidade
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {data &&
                        data
                          .sort((a, b) => {
                            if (a.id > b.id) return -1;
                            if (a.id < b.id) return 1;
                            return 0;
                          })
                          .map((item) => (
                            <tr key={item.id}>
                              <td data-label="Nº Patrimônio">
                                {item.patrimonio.numPatrimonio}
                              </td>

                              <td data-label="Descrição">
                                {item.patrimonio.descricao}
                              </td>

                              <td
                                data-label="Chamado"
                                onClick={() => console.log(item)}
                              >
                                {addZeroes(item.id, 7)}
                              </td>

                              <td data-label="Solicitante">
                                {item.solicitante?.nome}
                              </td>

                              <td data-label="Data Abertura">
                                {formatarDataBr(item.dataAbertura)}
                              </td>

                              <td data-label="Data Abertura">
                                {item.motivoAbertura}
                              </td>

                              <td data-label="Mecânico">
                                {item.mecanico?.usuario?.nome}
                              </td>

                              <td data-label="Status do Chamado">
                                {item.status == 0
                                  ? "Aguardando"
                                  : item.status == 1
                                  ? "Andamento"
                                  : item.status == 2
                                  ? "Finalizado"
                                  : item.status == 3
                                  ? "Homologado"
                                  : item.status == 4
                                  ? "Retornado"
                                  : ""}
                              </td>

                              <td data-label="Data Fechamento">
                                {item.dataFechamento != null
                                  ? formatarDataBr(item.dataFechamento)
                                  : item.movimentoMecanico[0]
                                      ?.dataFechamentoMecanico != null
                                  ? formatarDataBr(
                                      item.movimentoMecanico[0]
                                        ?.dataFechamentoMecanico
                                    )
                                  : ""}
                              </td>

                              <td data-label="Unidade de Produção">
                                {item.setor?.unidadeProducao?.fantasia}
                              </td>

                              <td data-label="Setor">
                                {item.setor?.descricao}
                              </td>

                              <td data-label="Gravidade">
                                {item.tipoChamadoDesc[0] &&
                                  item.tipoChamadoDesc[0].tipoChamadoDescricao
                                    .nivelGravidade}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </>
    );
  };

  const htmlNull = () => {
    return (
      <Grid lg={12}>
        <Box className="invoice" align="center" p={2}>
          <Typography variant="h3" color="gray">
            Não Há Informações{" "}
          </Typography>
          <Typography variant="h6" color="gray">
            Defina os Critérios de Pesquisa acima!
          </Typography>
        </Box>
      </Grid>
    );
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const dadosFiltros = async () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));
  };

  useEffect(() => {
    let gravidade =
      tipoChamadoDesc === "Todos" ? "" : `&gravidade=${tipoChamadoDesc}`;
    let unidade =
      unidadeProducao === "Todos" ? "" : `&unidadeProducao=${unidadeProducao}`;
    let setores = setor === "Todos" ? "" : `&setor=${setor}`;
    let grupos = grupo === "Todos" ? "" : `&grupo=${grupo}`;
    let mecanicos = mecanico === "Todos" ? "" : `&mecanico=${mecanico}`;
    let solicitante =
      solicitanteSelecionado === "Todos"
        ? ""
        : `&solicitante=${solicitanteSelecionado}`;

    Promise.all([
      api
        .get(
          `chamado/dataabertura?maiorOuIgual=${value}&menorOuIgual=${valueFim}${gravidade}${mecanicos}${unidade}${setores}${grupos}${solicitante}`
        )
        .then((response) => {
          setData(response.data.records);
        }),

      api.get(`unidadeproducao`).then((response) => {
        setListUnidade(response.data.records);
      }),

      api.get(`setores`).then((response) => {
        setListSetores(response.data.records);
        if (unidadeProducao != "Todos") {
          const listSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == unidadeProducao
          );
          setListSetores(listSetores);
        }
      }),

      api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
      }),

      api.get(`mecanico`).then((response) => {
        setListMecanico(response.data.records);
      }),
    ]);
  }, [
    value,
    valueFim,
    setor,
    tipoChamadoDesc,
    mecanico,
    unidadeProducao,
    grupo,
    solicitanteSelecionado,
  ]);

  return (
    <Grid container p={4} spacing={2}>
      <Grid item lg={1.7} md={2} sm={6} xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <Stack>
            <DatePicker
              label="Data Inicial"
              value={value}
              onChange={(newValue) => {
                setValue(dayjs(newValue).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => <TextField {...params} />}
              dayOfWeekFormatter={(day) => {
                const daysOfWeek = [
                  "Dom",
                  "Seg",
                  "Ter",
                  "Qua",
                  "Qui",
                  "Sex",
                  "Sab",
                ];
                return daysOfWeek[day];
              }}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>

      <Grid item lg={1.7} md={2} sm={6} xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <Stack>
            <DatePicker
              label="Data Final"
              value={valueFim}
              onChange={(newValue) => {
                setValueFim(dayjs(newValue).format("YYYY-MM-DD"));
                dadosFiltros();
              }}
              renderInput={(params) => <TextField {...params} />}
              dayOfWeekFormatter={(day) => {
                const daysOfWeek = [
                  "Dom",
                  "Seg",
                  "Ter",
                  "Qua",
                  "Qui",
                  "Sex",
                  "Sab",
                ];
                return daysOfWeek[day];
              }}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>

      <Grid item lg={1.8} md={4} sm={12} xs={12}>
        <Stack spacing={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Unidade Produção
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Unidade de Produção"
              value={unidadeProducao}
              onChange={handleChangeUnidade}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {listUnidade
                .sort((a, b) => {
                  if (a.fantasia > b.fantasia) return 1;
                  if (a.fantasia < b.fantasia) return -1;
                  return 0;
                })
                .map((item) => (
                  <MenuItem value={item.cgcCpf}>{item.fantasia}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Grid>

      <Grid item lg={1.8} md={4} sm={12} xs={12}>
        <Stack spacing={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Setor
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Setor"
              value={setor}
              onChange={handleChangeSetor}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {listSetores
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1;
                  if (a.descricao < b.descricao) return -1;
                  return 0;
                })
                .filter((set) => set.status == 1)
                .map((item) => (
                  <MenuItem value={item.id}>{item.descricao}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Grid>

      <Grid item lg={1.8} md={4} sm={12} xs={12}>
        <Stack spacing={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Grupo
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Grupo"
              value={grupo}
              onChange={handleChangeGrupo}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {listGrupo
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1;
                  if (a.descricao < b.descricao) return -1;
                  return 0;
                })
                .map((item) => (
                  <MenuItem value={item.id}>{item.descricao}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Grid>

      <Grid item lg={1.9} md={4} sm={12} xs={12}>
        <Stack spacing={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Mecânico
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Mecânico"
              value={mecanico}
              onChange={handleChangeMecanico}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {listMecanico
                .sort((a, b) => {
                  if (a.usuario.nome > b.usuario.nome) return 1;
                  if (a.usuario.nome < b.usuario.nome) return -1;
                  return 0;
                })
                .filter((mec) => mec.status == 1)
                .map((item) => (
                  <MenuItem value={item.id}>{item.usuario.nome}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Grid>

      <Grid item lg={1.25} md={4} sm={12} xs={12}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-controlled-open-select-label">
            Gravidade
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select-label"
            label="Tipo de Chamado"
            value={tipoChamadoDesc}
            onChange={handleChangeTipoChamadoDesc}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="BAIXA">BAIXA</MenuItem>
            <MenuItem value="MEDIA">MEDIA</MenuItem>
            <MenuItem value="ALTA">ALTA</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item lg={3.4} md={4} sm={12} xs={12}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-controlled-open-select-label">
            Solicitante
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select-label"
            label="Solicitante"
            value={solicitanteSelecionado}
            onChange={handleChangeSelectedSolicitante}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            {!isLoading &&
              listaSolicitantes.map((solicitante) => (
                <MenuItem value={solicitante.id}>{solicitante.nome}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item md={12} sm={12} xs={12} align="right" m={2}>
        <Stack spacing={3}>
          <FormControl>
            <Button
              size="small"
              sx={{ m: 0, width: 51, height: 51 }}
              onClick={handleExport}
              variant="outlined"
            >
              Gerar PDF
            </Button>
          </FormControl>
        </Stack>
      </Grid>

      <Grid md={12} sm={12} xs={12}>
        {(() => {
          if (data.length == 0) {
            return htmlNull();
          } else {
            return (
              <PDFExport
                scale={0.5}
                landscape="true"
                paperSize={"A4"}
                margin="0.8cm"
                fileName="maquinario_.pdf"
                forcePageBreak=".page-break"
                pageTemplate={PDFExportPageTemplate}
                ref={pdf}
              >
                {htmlFormat()}
              </PDFExport>
            );
          }
        })()}
      </Grid>
    </Grid>
  );
};
