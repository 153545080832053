import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { HorizontalRule } from "@mui/icons-material";
import api from "../../../services/api";
import { useQuery } from "react-query";
import { formatarDataBr } from "../../../utils/utils";
import dayjs from "dayjs";
import naoLocalizado from "../../../assets/naoLocalizado.png";
import { grey } from "@mui/material/colors";
import { GridComponentePecasMobile } from "./GridComponentePecasMobile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function GridComponentePecas({
  dataInicial,
  dataFinal,
  aprova,
  selectedRows,
  setSelectedRows,
}) {
  const { id } = useParams();

  const { data: chamadoPecas, isLoading: isLoadingChamadoPecas } = useQuery(
    ["chamadoPecas", aprova, dataInicial, dataFinal, id],
    async () => {
      if (dataInicial && dataFinal) {
        const response = await api.get(
          `chamado-pecas/lista-solicitacoes?dataIni=${dayjs(dataInicial).format(
            "DD/MM/YYYY"
          )}&dataFim=${dayjs(dataFinal).format("DD/MM/YYYY")}&status=${aprova}`
        );

        return response.data;
      }

      const response = await api.get(
        `chamado-pecas/${id}?dataIni=${dayjs(dataInicial).format(
          "DD/MM/YYYY"
        )}&dataFim=${dayjs(dataFinal).format("DD/MM/YYYY")}&status=${aprova}`
      );

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    setSelectedRows([]);
  }, [aprova]);

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
    }
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow
          sx={{
            height: "40px",
            "& > *": {
              padding: "0px 1px",
            },
          }}
        >
          <td>
            <Box sx={{ display: "flex" }}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                sx={{ border: "none" }}
              >
                {open ? <HorizontalRule /> : <AddIcon />}
              </IconButton>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: 0, marginTop: 0 }}
                    onChange={(event) => handleCheckboxChange(event, row)}
                    checked={selectedRows.some(
                      (selectedRow) => selectedRow.id === row.id
                    )}
                  />
                }
                sx={{
                  margin: 0,
                }}
              />
            </Box>
          </td>

          <td
            data-label="chamado"
            component="th"
            scope="row"
            sx={{ color: "blue" }}
          >
            {row.id}
          </td>
          <td data-label="Data Abertura" sx={{ padding: 0 }} align="left">
            {formatarDataBr(row.dataAbertura)}
          </td>
          <td
            data-label="Prob.Relatado"
            align="left"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: 0,
            }}
          >
            {row.motivoAbertura}
          </td>
          <td
            data-label="Solicitante"
            align="left"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: 0,
            }}
          >
            {row.solicitante?.nome}
          </td>
          <td
            data-label="Técnico"
            align="left"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: 0,
            }}
          >
            {row?.mecanico?.usuario?.nome}
          </td>
          <td
            data-label="Laudo Técnico"
            align="left"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: 0,
            }}
          >
            {row?.laudoTecnico}
          </td>
          <td data-label="Status" align="center" sx={{ padding: 0 }}>
            {row.chamadoPecas[0].statusAprov == "N"
              ? "Pendente"
              : row.chamadoPecas[0].statusAprov == "R"
              ? "Reprovado"
              : "Aprovado"}
          </td>
        </TableRow>

        {isMobile ? (
          <TableRow>
            <Collapse in={open} timeout="auto">
              <GridComponentePecasMobile chamadoPecas={row.chamadoPecas} />
            </Collapse>
          </TableRow>
        ) : (
          <TableRow>
            <Collapse in={open} timeout="auto">
              <Box sx={{ marginLeft: 10, marginTop: -0.5, marginBottom: -2.5 }}>
                <TableCell className="mobile-header" sx={{ border: "none" }}>
                  <Typography
                    align="left"
                    marginLeft="10px"
                    sx={{ fontSize: "12px", width: "585px", color: "#00666C" }}
                  >
                    Descrição
                  </Typography>
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  <Typography
                    align="center"
                    sx={{ fontSize: "12px", width: "55px", color: "#00666C" }}
                  >
                    Un.Med.
                  </Typography>
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "12px",
                      width: "55px",
                      color: "#00666C",
                     
                    }}
                  >
                    Qtd
                  </Typography>
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  <Typography
                    align="center"
                    sx={{ fontSize: "12px", width: "95px", color: "#00666C" }}
                  >
                    Custo Médio
                  </Typography>
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "12px",
                      width: "75px",
                      color: "#00666C",
                    }}
                  >
                    Estoque
                  </Typography>
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "12px",
                      width: "70px",
                      color: "#00666C",
                      marginRight: "55px"
                    }}
                  >
                    Empenhar
                  </Typography>
                </TableCell>
              </Box>

              <Box sx={{ marginLeft: 10 }}>
                <TableCell
                  size="small"
                  aria-label="purchases"
                  sx={{ border: "none" }}
                >
                  {row.chamadoPecas.map((historyRow, index) => (
                    <React.Fragment key={historyRow.id}>
                      <TableHead sx={{ display: "flex" }}>
                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "570px",
                              height: 30,
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              fontSize: 14,
                              padding: "0 8px",
                              color: "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            {historyRow.matprima.matprima} {" - "}{" "}
                            {historyRow.matprima.descricao}
                          </Typography>
                        </TableCell>

                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <TableCell
                            align="left"
                            onClick={() => console.log(historyRow)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              width: "70px",
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              color: "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 0,
                            }}
                          >
                            {historyRow.matprima.unidade}
                          </TableCell>
                        </TableCell>

                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              width: "70px",
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              color: "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 0, 
                            }}
                          >
                            {historyRow.quantidade}
                          </TableCell>
                        </TableCell>

                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              width: "90px",
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              color: "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 0,
                            }}
                          >
                            {historyRow.custoMedio?.toFixed(2)}
                          </TableCell>
                        </TableCell>

                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              width: "70px",
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              color: "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 0,
                            }}
                          >
                            {historyRow.qtdEstoque}
                          </TableCell>
                        </TableCell>

                        <TableCell
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            border: "none",
                          }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 30,
                              width: "70px",
                              border: "solid 2px #00666C",
                              borderRadius: 2,
                              color:
                                historyRow.qtdEmpenhar > 0 ? "red" : "#00666C",
                              fontSize: "12px",
                              fontWeight: "bold",
                              padding: 0,
                            }}
                          >
                            {historyRow.qtdEmpenhar}
                          </TableCell>
                        </TableCell>
                      </TableHead>
                    </React.Fragment>
                  ))}
                </TableCell>
              </Box>
            </Collapse>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  if (isLoadingChamadoPecas) return <CircularProgress />;

  return (
    /*   <>
    {isMobile ? <GridComponentePecasMobile /> : */

    <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "70px" }}></TableCell>
            <TableCell align="center"
              sx={{ width: "90px", fontSize: "12px", padding: "1px 1px" }}
            >
              Chamado
            </TableCell>
            <TableCell align="center"
              sx={{ width: "170px", fontSize: "12px", padding: "1px 1px" }}
            >
              Data Abertura
            </TableCell>
            <TableCell align="center"
              sx={{ width: "300px", fontSize: "12px", padding: "1px 1px" }}
            >
              Prob.Relatado
            </TableCell>
            <TableCell align="center"
              sx={{ width: "160px", fontSize: "12px", padding: "1px 1px" }}
            >
              Solicitante
            </TableCell>
            <TableCell align="center"
              sx={{ width: "150px", fontSize: "12px", padding: "1px 1px" }}
            >
              Técnico
            </TableCell>
            <TableCell align="center"
              sx={{ width: "150px", fontSize: "12px", padding: "1px 1px" }}
            >
              Laudo Técnico
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontSize: "12px", padding: "1px 1px", width: "115px" }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chamadoPecas &&
            chamadoPecas.map((row) => <Row key={row.id} row={row} />)}
        </TableBody>
      </Table>

      {!isLoadingChamadoPecas && chamadoPecas?.length === 0 && (
        <Box
          sx={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "35px",
            marginBottom: "20px",
          }}
        >
          <img src={naoLocalizado} alt="Nenhum item localizado" />
          <Typography
            color={grey[700]}
            mt={4}
            textTransform={"uppercase"}
            sx={{ alignSelf: "center" }}
          >
            Nenhuma solicitação no período selecionado!
          </Typography>
        </Box>
      )}
    </TableContainer>
    /* } </> */
  );
}
