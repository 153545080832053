import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useState } from "react";
import api from "../../services/api";
import { useQuery } from "react-query";
import { CircularProgress } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Stack } from "react-bootstrap";
import { addZeroes, formatarData, formatarDataBr } from "../../utils/utils";
import { PDFExport } from "@progress/kendo-react-pdf";
import LogoPatrimonio from "../../assets/logoPatrimonio.svg";
import Logo from "../../assets/democrata-logo.svg";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const FiltroAnaliseTempo = () => {
  const { id } = useParams();
  const [value, setValue] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [valueFim, setValueFim] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);
  const [unidadeProducao, setUnidadeProducao] = useState("Todos");
  const [setor, setSetor] = useState("Todos");
  const [setorNome, setSetorNome] = useState("");
  const [grupoNome, setGrupoNome] = useState("");
  const [grupo, setGrupo] = useState("Todos");
  const [mecanico, setMecanico] = useState("Todos");
  const [fantasia, setFantasia] = useState("");
  const [nomeMecanico, setNomeMecanico] = useState("");
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );
  const [gravidade, setGravidade] = useState("Todos");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  const getUnidade = async () => {
    const response = await api.get(`/unidadeproducao/`);
    return response.data.records;
  };

  const getSetor = async () => {
    if (unidadeProducao != "Todos") {
      const response = await api.get(
        `/setores/unidadeproducao/${unidadeProducao}`
      );
      return response.data.records;
    }
    const response = await api.get(`/setores/`);
    return response.data.records;
  };

  const getGrupo = async () => {
    const response = await api.get(`/grupomaquinario/`);
    return response.data.records;
  };

  const getMecanico = async () => {
    const response = await api.get(`/mecanico/`);
    return response.data.records;
  };


  const getChamado = async () => {
    let unidade =
      unidadeProducao === "Todos" ? "" : `&unidadeProducao=${unidadeProducao}`;
    let setores = setor === "Todos" ? "" : `&setor=${setor}`;
    let grupos = grupo === "Todos" ? "" : `&grupo=${grupo}`;
    let mecanicos = mecanico === "Todos" ? "" : `&mecanico=${mecanico}`;
    let gravidadeTipo = gravidade === "Todos" ? "" : `&gravidade=${gravidade}`;

    const response = await api.get(
      `chamado/dataabertura?maiorOuIgual=${value}&menorOuIgual=${valueFim}${unidade}${setores}${grupos}${mecanicos}${gravidadeTipo}`
    );
    return response.data.records;
  };

  const { data: listUnidades, isLoading: isLoadingUnidades } = useQuery(
    "unidadesData",
    getUnidade
  );
  const { data: listSetores, isLoading: isLoadingSetores } = useQuery(
    ["setoresData", unidadeProducao],
    getSetor
  );
  const { data: listGrupo, isLoading: isLoadingGrupos } = useQuery(
    "gruposData",
    getGrupo
  );
  const { data: listMecanico, isLoading: isLoadingMecanicos } = useQuery(
    "mecanicosData",
    getMecanico
  );
  const { data: chamado, isLoading: isLoadingChamado } = useQuery(
    [
      "chamados",
      value,
      valueFim,
      unidadeProducao,
      setor,
      grupo,
      mecanico,
      gravidade,
      page,
      rowsPerPage,
    ],
    getChamado,
  );


  const handleChangeUnidade = (event) => {
    setUnidadeProducao(event.target.value);
    setSetor("Todos");
    setGrupo("Todos");
    setMecanico("Todos");
    setGravidade("Todos");
    setPage(0);
    if (event.target.value != 0) {
      let label = listUnidades.find(
        (option) => option.cgcCpf === event.target.value
      );
      setFantasia(label.fantasia);
    }
  };

  const handleChangeSetor = (event) => {
    const setorSelecionado = event.target.value;
    setSetor(setorSelecionado);
    setGrupo("Todos");
    setMecanico("Todos");
    setGravidade("Todos");
    setPage(0);
    if (event.target.value != 0) {
      let labelSetor = listSetores.find(
        (option) => option.id === event.target.value
      );
      setSetorNome(labelSetor.descricao);
    }
  };

  const handleChangeGrupo = (event) => {
    const grupoSelecionado = event.target.value;
    setGrupo(grupoSelecionado);
    setMecanico("Todos");
    setGravidade("Todos");
    setPage(0);
    if (event.target.value != 0) {
      let labelGrupo = listGrupo.find(
        (option) => option.id === event.target.value
      );
      setGrupoNome(labelGrupo.descricao);
    }
  };

  const handleChangeMecanico = (event) => {
    const mecanicoSelecionado = event.target.value;
    setPage(0);
    setMecanico(mecanicoSelecionado);
    setGravidade("Todos");
    if (event.target.value != 0) {
      let labelMecanico = listMecanico.find(
        (option) => option.id === event.target.value
      );
      setNomeMecanico(labelMecanico.usuario.nome);
    }
  };

  const handleChangeGravidade = (event) => {
    setPage(0);
    setGravidade(event.target.value);
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const dadosFiltros = async () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);
    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));
    setPage(0);
  };

  if (isLoadingChamado || isLoadingUnidades || isLoadingChamado || isLoadingGrupos || isLoadingMecanicos) return <CircularProgress />;

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  const htmlFormat = () => {
    return (
      <>
        <Grid container columnSpacing={2} padding={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="col-sm-12">
              <div className="row">
                <span className="col-6 p-0">
                  <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                </span>
                <span className="col-6 p-0 text-right">
                  <img style={{ width: "190px" }} src={LogoPatrimonio} />
                </span>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <h5>Análise Tempo de Chamados</h5>
                  </div>

                  <span className="col-6 text-right">
                    Itens: {chamado.length}
                  </span>
                </div>
              </div>

              <div className="col-12">
                <span>
                  {formatarData(value).slice(0, 10)}
                  {" a "}
                  {formatarData(valueFim).slice(0, 10)}
                  {unidadeProducao != "Todos"
                    ? ` / Unidade Produção: ${fantasia}`
                    : ""}
                  {setor != "Todos" ? ` / Setor: ${setorNome}` : ""}
                  {grupo != "Todos" ? ` / Grupo: ${grupoNome}` : ""}
                  {mecanico != "Todos" ? ` / Mecânico: ${nomeMecanico}` : ""}
                  {gravidade != "Todos" ? ` / Gravidade: ${gravidade}` : ""}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} padding={1}>
          <div className="col-md-12">
            <div className="row">
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          width: "90%",
                          textAlign: "center",
                          backgroundColor: "#DCDCDC",
                          borderBottom: "1px solid #BBBBBB"
                        }}
                      >
                        Descrição do Atendimento
                      </th>
                      <th
                        style={{
                          width: "31.5%",
                          textAlign: "center",
                          backgroundColor: "#fbf2b7",
                          borderBottom: "1px solid #BBBBBB"
                        }}
                      >
                        Patrimônio
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "28.8%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                          borderBottom: "1px solid #BBBBBB"
                        }}
                      >
                        Tempo
                      </th>
                    </tr>
                  </thead>
                </table>

                <table className="flex-item mb-2">
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "5%",
                          textAlign: "center",
                          backgroundColor: "#DCDCDC",
                        }}
                      >
                        Nº Chamado
                      </th>
                      <th
                        style={{
                          width: "8.5%",
                          textAlign: "left",
                          backgroundColor: "#DCDCDC",
                        }}
                      >
                        Solicitante
                      </th>
                      <th
                        style={{
                          width: "7.5%",
                          textAlign: "left",
                          backgroundColor: "#DCDCDC",
                        }}
                      >
                        Setor
                      </th>
                      <th
                        style={{
                          width: "8.5%",
                          textAlign: "left",
                          backgroundColor: "#DCDCDC",
                        }}
                      >
                        Unidade Produção
                      </th>
                      <th
                        style={{
                          width: "8%",
                          textAlign: "left",
                          backgroundColor: "#DCDCDC",
                        }}
                      >
                        Mecânico
                      </th>
                      <th
                        style={{
                          width: "5%",
                          textAlign: "center",
                          backgroundColor: "#fbf2b7",
                        }}
                      >
                        Nº Pat.
                      </th>
                      <th
                        style={{
                          width: "8%",
                          textAlign: "left",
                          backgroundColor: "#fbf2b7",
                        }}
                      >
                        Descrição
                      </th>
                      <th
                        style={{
                          width: "6%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        Data Abertura
                      </th>
                      <th
                        style={{
                          width: "6%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        Homologado / Encerrado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {chamado
                      .sort((a, b) => {
                        if (a.id < b.id) return 1;
                        if (a.id > b.id) return -1;
                        return 0;
                      })
                      /* .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ) */
                      .map((item) => {
                        return (
                          <tr key={item.id}>
                            <Tooltip title={"Chamado"} arrow>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#F2F2F2",
                                }}
                              >
                                <Link to={`/resultadoChamadosItem/${item.id}`}>
                                  {addZeroes(item.id, 6)}
                                </Link>
                              </td>
                            </Tooltip>

                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "#F2F2F2",
                              }}
                            >
                              {item.solicitante.nome}
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "#F2F2F2",
                              }}
                            >
                              {item.setor.descricao}
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "#F2F2F2",
                              }}
                            >
                              {item.setor.unidadeProducao.fantasia}
                            </td>

                            {item.mecanico ? (
                            <Tooltip title={"Mecânico"} arrow>
                              <td
                                style={{
                                  textAlign: "left",
                                  backgroundColor: "#F2F2F2",
                                }}
                              >
                                <Link
                                  to={`/historicoMecanicos/${item.mecanico?.id}`}
                                >
                                  {item.mecanico?.usuario?.nome}
                                </Link>
                              </td>
                            </Tooltip>
                            ) : ( 
                              <td
                                style={{
                                  textAlign: "left",
                                  backgroundColor: "#F2F2F2",
                                }}
                              >
                                <Link
                                  to={`/historicoMecanicos/${item.mecanico?.id}`}
                                >
                                  {item.mecanico?.usuario?.nome}
                                </Link>
                              </td>
                              )}
                            <Tooltip title={"Patrimônio"} arrow>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#FFF5E6",
                                }}
                              >
                                <Link
                                  to={`/maquinarioHistorico/${item.patrimonio.id}`}
                                >
                                  {addZeroes(item.patrimonio.id, 6)}
                                </Link>
                              </td>
                            </Tooltip>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "#FFF5E6",
                              }}
                            >
                              {item.patrimonio.descricao}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                backgroundColor: "#D6F0F5",
                              }}
                            >
                              {formatarDataBr(item.dataAbertura)}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                backgroundColor: "#D6F0F5",
                              }}
                            >
                              {item.dataFechamento == null
                                ? ""
                                : formatarDataBr(item.dataFechamento)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* <TablePagination
                  component="div"
                  count={chamado.length}
                  page={page}
                  labelRowsPerPage={"Itens"}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[]}
                /> */}
              </div>
            </div>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        justifyContent={"left"}
        alignItems="center"
        columnSpacing={1}
        padding={2}
        rowGap={2}
      >
        <Grid item md={2} sm={12} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack>
              <DatePicker
                label="Data Inicial"
                value={value}
                onChange={(newValue) => {
                  setValue(dayjs(newValue).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                dayOfWeekFormatter={(day) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>

        <Grid item md={2} sm={12} xs={12}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack>
              <DatePicker
                label="Data Final"
                value={valueFim}
                onChange={(newValue) => {
                  setValueFim(dayjs(newValue).format("YYYY-MM-DD"));
                  dadosFiltros();
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                dayOfWeekFormatter={(day) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>

        <Grid item md={1.6} sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Unidade Produção
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Unidade Produção"
              value={unidadeProducao}
              onChange={handleChangeUnidade}
              size="small"
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {listUnidades
                .sort((a, b) => {
                  if (a.fantasia > b.fantasia) return 1;
                  if (a.fantasia < b.fantasia) return -1;
                  return 0;
                })
                .map((item) => (
                  <MenuItem font value={item.cgcCpf}>
                    {item.fantasia}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={1.6} sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Setor
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Setor"
              value={setor}
              onChange={handleChangeSetor}
              size="small"
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {!isLoadingSetores &&
                listSetores
                  .sort((a, b) => {
                    if (a.descricao > b.descricao) return 1;
                    if (a.descricao < b.descricao) return -1;
                    return 0;
                  })
                  .filter((set) => set.status == 1)
                  .map((item) => (
                    <MenuItem value={item.id}>{item.descricao}</MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={1.6} sm={12} xs={12}>
          <Stack>
            <FormControl fullWidth>
              <InputLabel id="demo-controlled-open-select-label">
                Grupo
              </InputLabel>

              <Select
                labelId="demo-controlled-open-select-label"
                id={"demo-controlled-open-select-label"}
                label="Grupo"
                value={grupo}
                onChange={handleChangeGrupo}
                size="small"
              >
                <MenuItem value="Todos">Todos</MenuItem>
                {!isLoadingGrupos &&
                  listGrupo
                    .sort((a, b) => {
                      if (a.descricao > b.descricao) return 1;
                      if (a.descricao < b.descricao) return -1;
                      return 0;
                    })
                    .map((item) => (
                      <MenuItem value={item.id}>{item.descricao}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>

        <Grid item md={1.6} sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Mecânico
            </InputLabel>

            <Select
              labelId="demo-controlled-open-select-label"
              id={"demo-controlled-open-select-label"}
              label="Mecânico"
              value={mecanico}
              onChange={handleChangeMecanico}
              size="small"
            >
              <MenuItem value="Todos">Todos</MenuItem>
              {!isLoadingMecanicos &&
                listMecanico
                  .sort((a, b) => {
                    if (a.usuario.nome > b.usuario.nome) return 1;
                    if (a.usuario.nome < b.usuario.nome) return -1;
                    return 0;
                  })
                  .filter((mec) => mec.status == 1)
                  .map((item) => (
                    <MenuItem value={item.id}>{item.usuario.nome}</MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={1.6} sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-controlled-open-select-label">
              Gravidade
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Gravidade"
              value={gravidade}
              onChange={handleChangeGravidade}
              size="small"
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="BAIXA">BAIXA</MenuItem>
              <MenuItem value="MEDIA">MEDIA</MenuItem>
              <MenuItem value="ALTA">ALTA</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item md={12} sm={12} xs={12} align="right" m={0}>
          <Stack spacing={3}>
            <FormControl>
              <Button
                className="btn"
                size="medium"
                onClick={handleExport}
                variant="outlined"
              >
                Gerar PDF
              </Button>
            </FormControl>
          </Stack>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          {(() => {
            if (chamado.length == 0) {
              return "";
            } else {
              return (
                <PDFExport
                  scale={0.5}
                  landscape="true"
                  paperSize={"A4"}
                  margin="0.8cm"
                  fileName="analiseTempo_.pdf"
                  forcePageBreak=".page-break"
                  pageTemplate={PDFExportPageTemplate}
                  allPages={true}
                  ref={pdf}
                >
                  {htmlFormat()}
                </PDFExport>
              );
            }
          })()}
        </Grid>
      </Grid>
    </>
  );
};
