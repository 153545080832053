import { createContext, useContext, useState } from "react";
import api from "../services/api";
import { useQuery, useQueryClient } from "react-query";

const PecasContext = createContext();

export const PecasContextProvider = ({ children }) => {
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [selectedMatPrima, setSelectedMatPrima] = useState([]);
  const [selectedUnidade, setSelectedUnidade] = useState(null);
  const [isClearable, setIsClearable] = useState(true);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const queryClient = useQueryClient()

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleGroupChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setSelectedGroupId(selectedValues);
  };

  const handleMatPrimaChange = (selectedOption) => {
    const selectedValues = selectedOption.map((option) => option.value);
    setSelectedMatPrima(selectedValues);
  };

  const handleUnidadeChange = (selectUnidades) => {
    const selectedValues = selectUnidades ? selectUnidades.value : null;
    setSelectedUnidade(selectedValues);
  };

  const { data: ListaTable, isLoading: isListTable } = useQuery(
    [
      `listTable`,
      selectedGroupId,
      selectedUnidade,
      search,
      status,
      selectedMatPrima,
    ],
    async () => {
      const body = {};

      if (search !== "") {
        body.busca = search;
      }

      if (selectedGroupId.length > 0) {
        body.grupoMaquinas = selectedGroupId;
      }

      if (selectedMatPrima.length > 0) {
        body.grupoMateriais = selectedMatPrima;
      }

      if (selectedUnidade !== null) {
        body.unidadeFabricacao = selectedUnidade;
      }

      if (status !== "") {
        body.status = status;
      }

      if (Object.keys(body).length === 0) {
        return null;
      }

      let response = await api.post(`matprima-pecas/filtrar`, body);

      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!search || !!selectedGroupId || !!selectedMatPrima || !!selectedUnidade || !!status,
      onError: () => {
        queryClient.setQueryData("listTable", [])
        return [];
      },
    }
  );

  return (
    <PecasContext.Provider
      value={{
        handleGroupChange,
        selectedGroupId,
        handleMatPrimaChange,
        selectedMatPrima,
        handleUnidadeChange,
        search,
        setSearch,
        status,
        setStatus,
        handleStatusChange,
        selectedUnidade,
        ListaTable,
        isListTable,
        isClearable,
        setIsClearable,
      }}
    >
      {children}
    </PecasContext.Provider>
  );
};

export const pecasContext = () => {
  return useContext(PecasContext);
};
