import { Info } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import api from "../../../services/api";

export const ModalReprovacao = ({ openModal, handleClose, selectedRows }) => {
  const [motivo, setMotivo] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState([]);
  const handleMotivoChange = (event) => {
    setMotivo(event.target.value);
  };
  const [corDialog, setCorDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);
  const queryClient = useQueryClient();
  const dadosJson = {
    idChamados: selectedRows.map((row) => row.id),
  };

  const { data: reprovarPecas, isLoading: isLoadingReprovar } = useQuery(
    ["reprovarPecas"],
    async () => {
      const response = await api.post(`chamado-pecas/detalhes`, {
        ids: dadosJson.idChamados,
      });
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutation, isLoading } = useMutation(
    async () => {
      const dadosConfirmar = {
        statusAprov: "R",
        motivo: motivo,
        idChamados: dadosJson.idChamados,
        pecas: reprovarPecas?.pecas,
      };

      const response = await api.put(
        `chamado-pecas/aprovar-reprovar/`,
        dadosConfirmar
      );
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chamadoPecas"]);
        setMensagemDialog(`Reprovação realizada com sucesso!`);
        setOpenMsg(false);
        setTipoDialog("success");
        setCorDialog("success");
        setMotivo("");
        handleClose();
      },
      onError: (error) => {
        setMensagemDialog(
          `Houve um erro na operação de reprovação: ${error.message}`
        );
        setTipoDialog("error");
        setCorDialog("success");
        setOpenMsg(false);
      },
    }
  );
  const handleFecharAlert = () => {
    setOpenMsg(false);
  };

  return (
    <>
      <Modal open={openModal} onClose={!isLoading && handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            maxHeight: "75vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 4,
                right: 8,
                cursor: "pointer",
                fontSize: 15,
                color: "gray",
              }}
              onClick={handleClose}
            >
              X
            </Box>
            <Typography sx={{ fontSize: 18, color: "red" }}>
              <Info sx={{ color: "red", marginRight: 1 }} />
              Reprovar Solicitação
            </Typography>

            <Box sx={{ fontWeight: "Bold" }}>
              <Typography sx={{ textAlign: "center", fontWeight: "inherit" }}>
                Informe, no campo abaixo, o motivo da reprovação da solicitação
                de uso de peças na manutenção do patrimônio.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: 3, marginBottom: 1 }}>
            <Typography>Patrimônio</Typography>
          </Box>

          <Table sx={{ minWidth: 650 }} size="small" aria-label="caption table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "transparent" }}>
                <TableCell
                  sx={{
                    width: "90px",
                    fontSize: "12px",
                    border: "none",
                  }}
                >
                  Chamado
                </TableCell>
                <TableCell
                  sx={{
                    width: "100px",
                    fontSize: "12px",
                    border: "none",
                  }}
                >
                  Patrimônio
                </TableCell>
                <TableCell
                  sx={{ width: "250px", fontSize: "12px", border: "none" }}
                >
                  Descrição
                </TableCell>
                <TableCell sx={{ width: "330px", fontSize: "12px" }}>
                  Setor
                </TableCell>
                <TableCell sx={{ fontSize: "12px", textAlign: "left" }}>
                  Unidade
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedRows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: "transparent",
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {" "}
                    {row.id}{" "}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.patrimonio?.numPatrimonio}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.patrimonio?.descricao}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.setor?.descricao}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.setor?.unidadeProducao?.fantasia}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <hr style={{ border: "1px solid #000", width: "100%" }} />

          <Box>
            <Typography>Motivo</Typography>
          </Box>
          <TextField
            label="Motivo da Reprovação"
            variant="outlined"
            multiline
            fullWidth
            rows={4}
            value={motivo}
            onChange={handleMotivoChange}
            sx={{
              marginTop: 0.5,
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
              },
            }}
          />

          <hr style={{ border: "1px solid #000", width: "100%" }} />

          <Box
            sx={{ display: "flex", marginTop: 2, justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleClose}
              size="small"
              variant="outlined"
              sx={{ color: "gray" }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => mutation()}
              size="small"
              variant="contained"
              sx={{ minWidth: 100, marginLeft: 1 }}
              color="error"
              disabled={!motivo || isLoading}
            >
              Reprovar
            </Button>
          </Box>
        </Box>
      </Modal>
      {openMsg && (
        <Dialog open={openMsg} onClose={isLoadingReprovar && handleFecharAlert}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            /*  onClose={() => {
              setOpenMsg(false);
            }} */
            sx={{
              "& .MuiAlert-icon": {
                color: "blue",
              },
            }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      )}
    </>
  );
};
