import React, { useState, useEffect } from "react";
import { useLocation,  useParams} from "react-router-dom/cjs/react-router-dom.min";

export const MenuMaquinario = () => {
  const { id } = useParams();
  const location = useLocation();
  const rotaAtual = location.pathname;
  const primeiroSimbolo = "/";
  const ultimoSimbolo = "/";

  const inicio = rotaAtual.indexOf(primeiroSimbolo) + 1;
  const fim = rotaAtual.lastIndexOf(ultimoSimbolo);
  const rotaLiberada = rotaAtual.substring(inicio, fim);


  if (
    rotaLiberada != "maquinarios" && id != 0
  ) {
    return (
      <>
        <li className="nav nav-pills">
          {rotaLiberada == "MaquinarioItem" 
            ? (
            <a href={`/maquinarioHistorico/${id}`} className="nav-link">
              <strong style={{ fontSize: 15 }}>Análise de Manutenção</strong>
            </a>
              )
            : rotaLiberada == "maquinarioHistorico"
            ? (
            <a href={`/MaquinarioItem/${id}`} className="nav-link">
              <strong style={{ fontSize: 15 }}>Dados do Patrimônio</strong>
            </a>
            )
            : "" }
        </li>
      </>
    );
  } else "";
};
