import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { pecasContext } from "../../../contexts/PecasContext";
import { Close } from "@mui/icons-material";

export const SearchEstoque = () => {
  const { search, setSearch, status, setStatus, handleStatusChange } =
    pecasContext();


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: isMobile ? " 1fr" : "0.8fr 1fr",
        gap: 2,
      }}
    >
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          boxShadow: "none",
          border: "1px solid #ccc",
          width: "100%",
          height: 40,
        }}
      >
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Paper>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={status}
          onChange={handleStatusChange}
          sx={{display: "flex", alignItems: "center"}}
        >
          <FormControlLabel value="A" control={<Radio />} label="Ativos" />
          <FormControlLabel value="I" control={<Radio />} label="Inativos" />
          {status && (
            <IconButton size="small" onClick={() => setStatus("")} sx={{height: 30, mb: 1}}>
              <Close fontSize="small"/>
            </IconButton > 
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
