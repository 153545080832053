import React, { Component } from 'react';
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { FiltroEstatistica } from './FiltroEstatisticaGeral';


class Estatistica extends Component {


    constructor(props) {
        super(props);
        this.state = {
            nomePagina: "Estatística",
        };

    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <Menu />

                <div>
                    <div className="content-wrapper">

                        <div className="content-header">

                            <div className="container-fluid">

                                <div className="row">

                                    <div className="col-lg-12">

                                        <div className="row mb-2">
                                            <div className="col-sm-6">
                                                <h1 className="m-0">{this.state.nomePagina} </h1>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <FiltroEstatistica />
                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        );
    }
}

export default Estatistica;
