import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import SemImg from "../../../assets/sem-foto.svg";
import { AlertSuccess, AlertDanger } from "./styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, FormLabel } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Radio,
  TextField,
} from "@mui/material";

import Select from "react-select";
import { ProgressBar } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { addZeroes } from "../../../utils/utils";
import VisualizaChamados from "../../Kanban/Chamados";
import { QRCodeCanvas } from "qrcode.react";

export const AtualizacaoEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [atualizacaoId, setAtualizacaoId] = useState(0);
  const [numSerie, setNumSerie] = useState("");

  const [descricao, setDescricao] = useState("");
  const [numPatrimonioAdd, setnumPatrimonioAdd] = useState("");
  const [numPatrimonio, setNumPatrimonio] = useState("");

  const [fornecedor, setFornecedor] = useState("");
  const [listFornecedor, setListFornecedor] = useState([]);
  const [selectedFornecedor, setSelectedFornecedor] = useState(0);

  const [fabricante, setFabricante] = useState("");
  const [listFabricantes, setListFabricantes] = useState([]);
  const [selectedFabricante, setSelectedFabricante] = useState(0);

 
  const [selectedGrupo, setSelectedGrupo] = useState(0);
  const [dadosTecnicos, setDadosTecnicos] = useState("");
  const [manual, setManual] = useState("");
  const [status, setStatus] = useState(1);
  const [statusGarantia, setStatusGarantia] = useState("0");
  const [images, setImages] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [arquivo, setArquivo] = useState([]);
  const [dataCadastro, setDataCadastro] = useState("");
  const [dataCompra, setDataCompra] = useState("");
  const [marcaProduto, setMarcaProduto] = useState("");
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState(0);
  const [valorAquisicao, setValorAquisicao] = useState("");
  const [valorResidual, setValorResidual] = useState("");
  const [vidaUtil, setVidaUtil] = useState("");
  const [estadoConservacao, setEstadoConservacao] = useState("");
  const [taxaDepreciacao, setTaxaDepreciacao] = useState("");
  const [dataGarantia, setDataGarantia] = useState("");

  const [aguardando, setAguardando] = useState(true);
  const [statusImagem, setStatusImagem] = useState(false);
  const [indexPrincipal, setIndexPrincipal] = useState(0);

  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogDel, setOpenConfirmDialogDel] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [value, setValue] = useState(dayjs(new Date()));
  const [progress, setProgress] = useState();
  const [tipoAquisicao, setTipoAquisicao] = useState(null);

  const isStatusValid = status === 2;
  const [showDesignados, setShowDesignados] = useState(false);
  const [maxWidth, setMaxWidth] = useState("md");
  const [numChamado, setNumChamado] = useState("");
  const [dataPatr, setDataPatr] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedChamadoId, setSelectedChamadoId] = useState(null);

  /* valores da caixa de dialog 
  md = medio
  xs = pequeno
  sm = default (entre o pequeno  )
  lg = largo
  xl = grande
   */

  const handleClickOpen = () => {
    setAguardando(true);
    let idReg = localStorage.getItem("idPat");
    api.get(`atualizacaoalbum/atualizacao/` + idReg).then((response) => {
      setImages(response.data.records);
      setImagemPrincipal(response.data.records[indexPrincipal]);
      setAguardando(false);
    });
    setModalIsOpen(false);
    setOpen(true);
  };

  const handleClose = () => {
    if (imagemPrincipal) {
      setOpen(false);

      const redVoltar = setTimeout(() => {
        history.push({
          pathname: "/atualizacoes",
        });
        localStorage.removeItem("idPat");
      }, 1000);

      return () => clearTimeout(redVoltar);
    } else {
      setStatusImagem(true);
    }
  };

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const alteraImagemPrincipal = (item, idx) => {
    setImagemPrincipal(item);
    setIndexPrincipal(idx);
  };
  const telaResponsiva = (ParAjusta) => {
    if (ParAjusta !== "0") {
      return "col-md-9";
    } else {
      return "col-md-12";
    }
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleOpenConfirmDialogDel = () => {
    setOpenConfirmDialogDel(true);
  };

  const deleteItemSubChamado = async (img) => {
    try {
      await api.post(`atualizacaoalbum/apagar-arquivo`, img);
      getImages();
      getArquivo();
    } catch (err) {
      console.log(err);
    }
  };

  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  const converterValores = (string) => {
    let floatValue = replaceAll(string, ".", "");

    return floatValue.replace(",", ".");
  };

  const handleChangeAquisicao = (event) => {
    const { value } = event.target;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Remove todos os caracteres que não sejam números

    const formattedValue = formatMoney(cleanedValue);

    setValorAquisicao(formattedValue);
  };

  const handleChangeResidual = (event) => {
    const { value } = event.target;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Remove todos os caracteres que não sejam números

    const formattedValue = formatMoney(cleanedValue);

    setValorResidual(formattedValue);
  };

  const formatMoney = (value) => {
    const numericValue = parseInt(value, 10) / 100; // Divide por 100 para obter o valor em Reais

    if (value === "") {
      return 0;
    }

    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
  };

  const formatarMoedaReal = (value) => {
    const numericValue = parseFloat(value);

    const formattedValue = numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formattedValue;
  };

  /*Editar Registros*/
  const editAtualizacao = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          numSerie: numSerie,
          numPatrimonioAdd: numPatrimonioAdd,
          descricao: descricao,
          fornecedor: selectedFornecedor,
          numPatrimonio: numPatrimonio,
          fabricante: selectedFabricante,
          grupo: selectedGrupo,
          dadosTecnicos: dadosTecnicos,
          manual: manual,
          status: status,
          dataCadastro: dataCadastro,
          dataCompra: dataCompra,
          marcaProduto: marcaProduto,
          nNotaFiscal: numeroNotaFiscal,
          garantia: statusGarantia,
          dataGarantia: dataGarantia,
          qrcode: qrCode,
          tipoAquisicao: tipoAquisicao,
          valorAquisicao: converterValores(valorAquisicao)
            .replace("R$", "")
            .trim(),
          valorResidual: converterValores(valorResidual)
            .replace("R$", "")
            .trim(),
          vidaUtil: vidaUtil,
          estadoConservacao: estadoConservacao,
          taxaDepreciacao: taxaDepreciacao,
        };

        await api.put(`atualizacao/` + id, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          numSerie: numSerie,
          numPatrimonioAdd: numPatrimonioAdd,
          descricao: descricao,
          fornecedor: selectedFornecedor,
          fabricante: selectedFabricante,
          grupo: selectedGrupo,
          dadosTecnicos: dadosTecnicos,
          manual: manual,
          status: status,
          dataCadastro: dataCadastro,
          dataCompra: dataCompra,
          marcaProduto: marcaProduto,
          nNotaFiscal: numeroNotaFiscal,
          garantia: statusGarantia,
          dataGarantia: dataGarantia,
          qrcode: qrCode,
          tipoAquisicao: tipoAquisicao,
          valorAquisicao: converterValores(valorAquisicao)
            .replace("R$", "")
            .trim(),
          valorResidual: converterValores(valorResidual)
            .replace("R$", "")
            .trim(),
          vidaUtil: vidaUtil,
          estadoConservacao: estadoConservacao,
          taxaDepreciacao: taxaDepreciacao,
        };
        await api.post(`atualizacao/`, dadosJson).then((res) => {
          localStorage.setItem("idPat", res.data.records[0].id);
        });

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        let idReg = localStorage.getItem("idPat");

        if (idReg == "0") {
          history.push({
            pathname: "/atualizacoes",
          });
        } else if (id === "0") {
          handleClickOpen();
        } else {
          history.push({
            pathname: "/atualizacoes",
          });
        }
      }, 1000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  const duplicaRegistro = async (e) => {
    e.preventDefault();
    try {
      if (confirm("Você tem certeza que Deseja Duplicar este Registro ?")) {
        var dadosJson = {
          id: id,
        };

        await api.post(`/atualizacao/duplicar-atualizacao`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro Duplicado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/atualizacoes",
          });
        }, 1000);

        return () => clearTimeout(redirecionar);
      } else {
        // Aqui fazemos algo quando o usuário cancela
      }
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  function uploadImagem(e) {
    var data = new FormData();

    var idReg = localStorage.getItem("idPat");

    data.append("file", e.target.files[0]);
    data.append("idAtualizacao", id !== "0" ? id : idReg);
    data.append("posicao", images.length + 1);

    api
      .post(`atualizacaoalbum/enviar-arquivo`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            setAguardando(true);
            api
              .get(`atualizacaoalbum/atualizacao/` + (id !== "0" ? id : idReg))
              .then((response) => {
                setImages(response.data.records);
                setImagemPrincipal(response.data.records[indexPrincipal]);
                getImages();
                getArquivo();

                setAguardando(false);
              });
          } else if (res.status == 401) {
            alert("Erro ao enviar a imagem");
          }
        },
        function (err) {
          alert("Erro no modo de Submeter!");
        }
      );
  }
  const excluirFoto = async (img) => {
    try {
      //console.log("entrar na api", img)
      await api.post(`atualizacaoalbum/apagar-arquivo`, img);

      getImages();
      getArquivo();
    } catch (err) {
      console.log(err);
    }
  };
 

  useEffect(() => {
    const getAtualizacao = async () => {
      await api.get(`atualizacao/` + id).then((response) => {
        setAtualizacaoId(response.data.records[0].id);
        setNumSerie(response.data.records[0].numSerie);
        setnumPatrimonioAdd(response.data.records[0].numPatrimonioAdd);
        setDescricao(response.data.records[0].descricao.toUpperCase());
        setNumPatrimonio(response.data.records[0].numPatrimonio);
        setFornecedor(response.data.records[0].fornecedor.cgcCpf);
        setFabricante(response.data.records[0].fabricante.id);
        setSelectedFornecedor(response.data.records[0].fornecedor.cgcCpf);
        setSelectedFabricante(response.data.records[0].fabricante.id);
        setSelectedGrupo(response.data.records[0].grupo.id);
        setDadosTecnicos(response.data.records[0].dadosTecnicos.toUpperCase());
        setManual(response.data.records[0].manual);
        setDataCadastro(response.data.records[0].dataCadastro);
        setDataCompra(response.data.records[0].dataCompra);
        setMarcaProduto(response.data.records[0].marcaProduto);
        setNumeroNotaFiscal(response.data.records[0].nroNotaFiscal);
        setStatusGarantia(response.data.records[0].garantia);
        setStatus(response.data.records[0].status);
        setQrCode(response.data.records[0].numPatrimonio);
        setDataGarantia(response.data.records[0].dataGarantia);
        setTipoAquisicao(response.data.records[0].tipoAquisicao);
        setValorAquisicao(
          formatarMoedaReal(
            response.data.records[0].valorAquisicao != null
              ? response.data.records[0].valorAquisicao
              : 0
          )
        );
        setValorResidual(
          formatarMoedaReal(
            response.data.records[0].valorResidual != null
              ? response.data.records[0].valorResidual
              : 0
          )
        );
        setVidaUtil(response.data.records[0].vidaUtil);
        setEstadoConservacao(response.data.records[0].estadoConservacao);
        setTaxaDepreciacao(response.data.records[0].taxaDepreciacao);
      });
    };
     
     

    getArquivo();


    if (id !== "0") {
    //  getAtualizacao();
    }
  }, [id]);


  const getArquivo = async () => {
    var idReg = localStorage.getItem("idPat");

    setArquivo([]);
    setAguardando(true);
    await api
      .get(`atualizacaoalbum/atualizacao/arquivo/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setArquivo(response.data.records[0].atualizacaoAlbum);
        setAguardando(false);
      });
  };




  const options = [
    { value: null, label: "Selecione..." },
    { value: "MECANICO", label: "Uso do Mecânico" },
    { value: "ABERTURA", label: "Uso do Solicitante" },

  ];





  return (
    <>


      <div className="row">


        <div className={telaResponsiva(id)}>
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active " id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editAtualizacao}>
                  <div className="col-sm-12">

                    <div className="row mb-2">

                      <div className="col-md-9 col-sm-8">
                        

                        <div className="row">

                        <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="col-sm-12 control-label"
                          >
                            Tipo de Aquisição
                          </InputLabel>
                          <div>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-controlled-open-select-label"
                             
                            /*   onChange={handleChangeTipos} */
                              options={options}
                              spacing={3}
                              required="required"
                            ></Select>
                          </div>
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>

                          <div className="col-md-8 col-sm-8 form-group was-validated">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-sm-12 col-form-label"
                            >
                              Descrição
                            </InputLabel>
                            <input
                              type="text"
                              className="form-control"
                              id="descricao"
                              name="descricao"
                              value={descricao}
                              onChange={(e) => setDescricao(e.target.value)}
                              placeholder="Descrição do Patrimônio..."
                              required="required"
                            />
                            <div className="valid-feedback">Validado.</div>
                            <div className="invalid-feedback">
                              Preencha esse campo.
                            </div>
                          </div>

                           
                        </div>
                      </div>
                    </div>
 
    

                    

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputExperience"
                            className="col-sm-6 col-form-label"
                          >
                            Dados Técnicos
                          </InputLabel>
                          <textarea
                            className="form-control"
                            type="text"
                            id="dados_tecnicos"
                            placeholder="Dados técnicos..."
                            onChange={(e) => setDadosTecnicos(e.target.value)}
                            defaultValue={dadosTecnicos}
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <div className="form-group">
                          {arquivo.nome != null ? (
                            ""
                          ) : (
                            <label
                              for="arquivoUpload"
                              className="btn btn-primary"
                            >
                              <i className="fas fa-pen" /> Adicionar Documento
                              *Até 150MB
                            </label>
                          )}
                          <input
                            type="file"
                            id="arquivoUpload"
                            Style="display:none"
                            onChange={(e) => uploadImagem(e)}
                            disabled={isStatusValid}
                          ></input>
                          {arquivo.nome != null ? (
                            <a
                              href={arquivo.caminho}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <label className="btn btn-success ml-1">
                                <i className="fas fa-download" />
                              </label>
                            </a>
                          ) : (
                            ""
                          )}
                          {arquivo.nome != null ? (
                            <label
                              className="btn btn-danger ml-1"
                              onClick={(e) => handleOpenConfirmDialog()}
                            >
                              {" "}
                              <i className="fa fa-trash"></i>{" "}
                            </label>
                          ) : (
                            ""
                          )}
                          <label className="col-sm-6">{arquivo.nome}</label>
                        </div>
                      </div>
                    </div>
                    {progress && (
                      <div className="row">
                        <div className="col-sm-12">
                          {progress && (
                            <ProgressBar
                              now={progress}
                              label={`${progress}%`}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="col-sm-2 col-form-label">
                          Status{" "}
                        </label>
                        <div className="col-sm-10">
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 1}
                            onChange={(e) => setStatus(e.target.value)}
                            value="1"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "1" }}
                            disabled={isStatusValid}
                          />
                          <strong className="mr-4" style={{ fontSize: 13 }}>
                            Ativo
                          </strong>
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 0}
                            onChange={(e) => setStatus(e.target.value)}
                            value="0"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "0" }}
                            disabled={isStatusValid}
                          />
                          <strong className="mr-4" style={{ fontSize: 13 }}>
                            Inativo
                          </strong>
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 2}
                            onChange={(e) => setStatus(e.target.value)}
                            value="2"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "2" }}
                            disabled
                          />
                          <strong style={{ fontSize: 13 }}>Manutenção</strong>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isStatusValid}
                        >
                          Gravar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <Dialog
                  open={openConfirmDialog}
                  onClose={() => setOpenConfirmDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Esta ação é permanente!"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Deseja realmente excluir?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)}>
                      Não
                    </Button>
                    <Button
                      onClick={() => {
                        excluirFoto(arquivo);
                        setOpenConfirmDialog(false);
                      }}
                      autoFocus
                    >
                      Sim
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openConfirmDialogDel}
                  onClose={() => setOpenConfirmDialogDel(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Deseja realmente excluir ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenConfirmDialogDel(false)}>
                      Não
                    </Button>
                    <Button
                      autoFocus
                      onClick={() => {
                        excluirFoto(imagemPrincipal.atualizacaoAlbum);
                        setOpenConfirmDialogDel(false);
                      }}
                    >
                      Sim
                    </Button>
                  </DialogActions>
                </Dialog>

                {modalIsOpen && (
                  <div
                    class="modal fade"
                    id="ModalLongoExemplo"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="TituloModalLongoExemplo"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Fechar"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body m-2">
                          <VisualizaChamados
                            id={selectedChamadoId}
                            selecionar={selectedChamadoId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
