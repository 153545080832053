import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../../services/api";
import SemImg from "../../../assets/sem-foto.svg";
import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";
import {
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { addZeroes, formatarData, formatarDataBr } from "../../../utils/utils";
import { useQuery } from "react-query";
import { QRCodeCanvas } from "qrcode.react";
import { PDFExport } from "@progress/kendo-react-pdf";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

export const MaquinarioHistoricoGrid = () => {
  const { id } = useParams();
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  /* valores da caixa de dialog 
  md = medio
  xs = pequeno
  sm = default (entre o pequeno  )
  lg = largo
  xl = grande
   */

  const getMaquinario = async () => {
    const response = await api.get(`/maquinario/${id}`);
    return response.data.records;
  };
  const { data: maquinario, isLoading: isLoadingMaquinario } = useQuery(
    "maquinarios",
    getMaquinario,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getImages = async () => {
    let images = "";
    const response = await api.get(`maquinarioalbum/maquinario/${id}`);
    return response.data.records;
  };

  const { data: images, isLoadingImage } = useQuery(
    "imagemPatrimonio",
    getImages,
    {
      enabled: !!id,
    }
  );

  const getChamadoPat = async () => {
    const response = await api.get(`/chamado/patrimonio/${id}`);
    return response.data.records;
  };
  const { data: chamadoPat, isLoading: isLoadingChamado } = useQuery(
    ["chamados"],
    getChamadoPat
  );

  const abreviacaoUnidade = (abrev) => {
    if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635002128
    ) {
      return "MATRIZ - SANTA QUITERIA";
    } else if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635002047
    ) {
      return "MATRIZ - CAMOCIM II";
    } else if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635000184
    ) {
      return "MATRIZ";
    } else if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635002470
    ) {
      return "FAB. SOLA/PALMILHA S.QUITERIA";
    } else if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635002713
    ) {
      return "MATRIZ - SOBRAL";
    } else if (
      maquinario?.[0]?.maquinarioSetores[0].setores?.unidadeProducao.cgcCpf ==
      52241635002802
    ) {
      return "DEPOSITO WET BLUE";
    } else "";
  };

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Main content */}
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  let totalHorasIndisponivel = maquinario?.[0]?.aberturaFechamentoTotalVO.horas;
  let totalMinutosIndisponivel =
    maquinario?.[0]?.aberturaFechamentoTotalVO.minutos;
  let minutosConvertidos = totalMinutosIndisponivel / 0.6 / 100;
  let totalTempoIndisponivel = totalHorasIndisponivel + minutosConvertidos;

  /**
   * Trata a Renderizacao da imagem para ser exibida
   * de acordo com o valor preenchido no Objeto imagemPrincipal
   * @param {*} imPrincipal
   * @returns
   */
  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div
            className="text-center"
            style={{ height: "230px", width: "100%" }}
          >
            <img
              className="principal-image"
              Style={`max-height: 230px;  max-Width: 100%; ${
                imPrincipal.maquinarioAlbum?.status == 1
                  ? ""
                  : "filter: grayscale();"
              }}`}
              src={`data:image/jpeg;base64, ${imPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 230px;  max-Width: 100%}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  if (isLoadingChamado || isLoadingImage || isLoadingMaquinario)
    return <CircularProgress />;

  const tempoTotal =
    (chamadoPat?.[0]?.patrimonio?.tempoHorasMaqAtivo - totalTempoIndisponivel) /
    chamadoPat.length;
  const horas = Math.floor(tempoTotal);
  const minutos = Math.round((tempoTotal - horas) * 60);

  const tempoParado = totalTempoIndisponivel / chamadoPat.length;
  const horasParado = Math.floor(tempoParado);
  const minutosParado = Math.round((tempoParado - horasParado) * 60);

  const disponibilidade = (tempoTotal / (tempoTotal + tempoParado)) * 100;
  const disponibilidadeFormatada = (disponibilidade / 100).toLocaleString(
    "pt-BR",
    { style: "percent", minimumFractionDigits: 2 }
  );

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  // Função para calcular a soma dos tempos de atendimento
  const calcularTempoTotal = () => {
    let totalHoras = 0;
    let totalMinutos = 0;

    chamadoPat.forEach((chamado) => {
      totalHoras += chamado.relacaoTempoAtendimento[3].horas;
      totalMinutos += chamado.relacaoTempoAtendimento[3].minutos;
    });

    totalHoras += Math.floor(totalMinutos / 60);
    totalMinutos %= 60;
    return `${totalHoras}h ${totalMinutos}m`;
  };

  /* function contadorChamados() {
    let contadorStatusCinco = 0;
  
    const itensMenu = chamadoPat.map((item) => {
      if (item.status === 5) {
        contadorStatusCinco++;  
        console.log("itensMenu dentro do .map --- ", itensMenu);
      };
    return (
      console.log("itensMenu ---- ", itensMenu)
    );
  });

  } */

  const htmlManut = () => {
    return (
      <>
        <div className="card">
          <h4 className="mt-1 ml-1">
            <img style={{ width: "190px" }} src={Logo} alt="Logo" />
            <small className="float-right">
              <img style={{ width: "190px" }} src={LogoPatrimonio} />
            </small>
          </h4>
          <div style={{ width: "100%" }}>
            <div className="col-12">
              <h4 style={{ fontSize: 18, fontWeight: "bold" }}>
                Histórico de Atendimento
                <small className="float-right" style={{ fontSize: 16 }}>
                  Total de Manutenções:{" "}
                  <FormLabel>{chamadoPat.length}</FormLabel>
                </small>
              </h4>
            </div>

            <>
              <div className="  ">
                <div className="card-body">
                  <div className="tab-pane active" id="settings">
                    <form className="form-horizontal">
                      <div className="row">
                        <div>
                          <div className="row">
                            <div className="col-md-2 mb-0">
                              <QRCodeCanvas
                                style={{ display: "block", margin: "0 auto" }}
                                data-qr={`${maquinario[0]?.numPatrimonio}`}
                                value={`${maquinario[0]?.numPatrimonio}`}
                              />
                            </div>
                            <div
                              className="col-md-10"
                              style={{ margin: "0 auto" }}
                            >
                              <table style={{ border: "0px" }}>
                                <table className="table-sm mb-1">
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#FBF2B7",
                                        height: "10%",
                                      }}
                                    >
                                      <th
                                        style={{
                                          fontSize: 12,
                                          height: 5,
                                          textAlign: "center",
                                        }}
                                      >
                                        Nº Pat.
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Descrição
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Un. Produção
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Setor
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 12,
                                          height: 5,
                                          textAlign: "left",
                                        }}
                                      >
                                        Responsável
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td style={{ textAlign: "center" }}>
                                        {maquinario &&
                                          maquinario.length > 0 &&
                                          maquinario[0].numPatrimonio}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {maquinario &&
                                          maquinario.length > 0 &&
                                          maquinario[0].descricao}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {maquinario &&
                                          maquinario.length > 0 &&
                                          maquinario[0].maquinarioSetores[0]
                                            .setores.unidadeProducao.fantasia}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {maquinario &&
                                          maquinario.length > 0 &&
                                          maquinario[0].maquinarioSetores[0]
                                            .setores.descricao}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {maquinario &&
                                          maquinario.length > 0 &&
                                          maquinario[0].maquinarioSetores[0]
                                            .setores.responsavel.nome}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table
                                  className="table-sm mb-1"
                                  style={{ height: "70%" }}
                                >
                                  <thead>
                                    <tr style={{ backgroundColor: "#F0E6D1" }}>
                                      <th
                                        style={{
                                          fontSize: 11,
                                          textAlign: "center",
                                        }}
                                      >
                                        Data Cadastro
                                      </th>
                                      <th
                                        style={{
                                          fontSize: 11,
                                          textAlign: "center",
                                        }}
                                      >
                                        Total de Manutenções
                                      </th>

                                      <Tooltip
                                        title={"Tempo médio entre falhas"}
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -16],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <th
                                          style={{
                                            fontSize: 11,
                                            textAlign: "center",
                                          }}
                                          onClick={handleOpenModal}
                                        >
                                          MTBF
                                        </th>
                                      </Tooltip>

                                      <Tooltip
                                        title={"Tempo médio de reparo"}
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -16],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <th
                                          style={{
                                            fontSize: 11,
                                            textAlign: "center",
                                          }}
                                        >
                                          MTTR
                                        </th>
                                      </Tooltip>

                                      <Tooltip
                                        title={
                                          disponibilidade < 90
                                            ? "Abaixo de 90%, fora da média global"
                                            : "Acima da média 90%"
                                        }
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -16],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <th
                                          style={{
                                            color:
                                              disponibilidade < 90
                                                ? "red"
                                                : "#404E67",
                                            fontSize: 11,
                                            textAlign: "center",
                                          }}
                                        >
                                          Disponibilidade
                                        </th>
                                      </Tooltip>

                                      <Tooltip
                                        title={"Total do Aceite ao Fechamento"}
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                          modifiers: [
                                            {
                                              name: "offset",
                                              options: {
                                                offset: [0, -16],
                                              },
                                            },
                                          ],
                                        }}
                                      >
                                        <th
                                          style={{
                                            fontSize: 11,
                                            textAlign: "center",
                                          }}
                                        >
                                          Tempo Total Manutenção
                                        </th>
                                      </Tooltip>

                                      {/* <Tooltip title={"Informativo para programação de preventiva"} arrow placement="top"
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: 'offset',
                                            options: {
                                              offset: [0, -16],
                                            },
                                          },
                                        ],
                                      }}>
                                      <th
                                        style={{
                                          fontSize: 11,
                                          textAlign: "center",
                                        }}
                                      >
                                        Manutenção Preventiva
                                      </th>
                                      </Tooltip> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr style={{ backgroundColor: "#FFFFFF" }}>
                                      <td style={{ textAlign: "center" }}>
                                        {maquinario?.[0]?.dataCadastro == null
                                          ? ""
                                          : formatarData(
                                              maquinario?.[0]?.dataCadastro
                                            )}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {chamadoPat.length}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {chamadoPat == "" ? "" : horas}h{" "}
                                        {chamadoPat == "" ? "" : minutos}m
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {chamadoPat == "" ? "" : horasParado}h{" "}
                                        {chamadoPat == "" ? "" : minutosParado}m
                                      </td>
                                      <td
                                        style={{
                                          color:
                                            disponibilidade < 90
                                              ? "red"
                                              : "#404E67",
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {disponibilidadeFormatada == "NaN%"
                                          ? "100%"
                                          : disponibilidadeFormatada}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {calcularTempoTotal() == "0h 0m"
                                          ? " - "
                                          : calcularTempoTotal()}
                                      </td>
                                      {/* <td
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {(disponibilidadeFormatada == "NaN%" ? " - " : (horas * 0.7).toFixed(1))}h
                                      </td> */}
                                    </tr>
                                  </tbody>
                                </table>
                              </table>
                            </div>
                          </div>

                          <div>
                            <table style={{ border: "0px" }}>
                              <table className="table-sm mb-1">
                                <thead>
                                  <div style={{ height: "10%" }}>
                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: 10,
                                      }}
                                    >
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        MTBF :
                                      </span>{" "}
                                      Tempo médio entre falhas. Quanto maior
                                      melhor, indicando menos quebras
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Fórmula:
                                      </span>{" "}
                                      (TD - TM / P)
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        TD =
                                      </span>{" "}
                                      Tempo Disponível{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {
                                          chamadoPat?.[0]?.patrimonio
                                            ?.tempoHorasMaqAtivo
                                        }
                                      </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        h
                                      </span>
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        TM =
                                      </span>{" "}
                                      Tempo Manutenção{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {totalTempoIndisponivel.toFixed(1)}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        h
                                      </span>
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        P =
                                      </span>{" "}
                                      Paradas{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {chamadoPat.length}
                                      </span>
                                    </span>

                                    <br />

                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: 10,
                                      }}
                                    >
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        MTTR :
                                      </span>{" "}
                                      Tempo médio de reparo. Quanto menor,
                                      melhor.
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Fórmula:
                                      </span>{" "}
                                      (TM / P)
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        TM =
                                      </span>{" "}
                                      Tempo de Manutenção{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {totalTempoIndisponivel.toFixed(1)}
                                      </span>
                                      <span style={{ fontWeight: "bold" }}>
                                        h
                                      </span>
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        P =
                                      </span>{" "}
                                      Paradas{" "}
                                      <span style={{ fontWeight: "bold" }}>
                                        {chamadoPat.length}
                                      </span>
                                    </span>

                                    <br />

                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: 10,
                                      }}
                                    >
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        DISPONIBILIDADE :
                                      </span>{" "}
                                      % em que o ativo se manteve disponível em
                                      um determinado período. Padrão global
                                      acima 90%.
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Fórmula:
                                      </span>{" "}
                                      (MTBF / (MTBF + MTTR) x 100)
                                    </span>

                                    <br />

                                    <span
                                      style={{
                                        textAlign: "left",
                                        fontSize: 10,
                                      }}
                                    >
                                      <span
                                        className="ml-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        TEMPO TOTAL MANUTENÇÃO :
                                      </span>{" "}
                                      Somatório das horas da abertura de todos
                                      os chamados ao finalizado pelos mecânicos
                                      deste equipamento.
                                    </span>

                                    {/*  <br/>

                                    <span style={{ textAlign: "left", fontSize: 10 }}>
                                      <span className="ml-1" style={{ fontWeight: "bold"}}>MANUTENÇÃO PREVENTIVA :</span> Calcula 70% do tempo médio de falhas "MTBF" para realizar a inspeção.
                                      <span className="ml-1" style={{ fontWeight: "bold"}}>Fórmula:</span> (MTBF x 70%)
                                    </span> */}
                                  </div>
                                </thead>
                              </table>
                            </table>
                          </div>
                        </div>

                        {/* Abaixo do QRCode */}

                        {!isLoadingChamado && chamadoPat.length > 0 ? (
                          <div className="row">
                            <div className="col-12 table-responsive">
                              <table
                                style={{
                                  borderTop: "2px solid",
                                  borderLeft: "2px solid",
                                  borderRight: "2px solid",
                                }}
                              >
                                <table className="table-sm">
                                  {chamadoPat
                                    .sort((a, b) => b.id - a.id)
                                    .map((reg) => (
                                      <React.Fragment key={chamadoPat.id}>
                                        <table>
                                          <thead>
                                            <tr
                                              style={{
                                                backgroundColor: "#D9D9D9",
                                                fontSize: 12,
                                              }}
                                            >
                                              <th style={{ width: "14%" }}>
                                                Chamado
                                              </th>

                                              <th
                                                style={{
                                                  width: "23%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Data Abertura
                                              </th>

                                              <th
                                                style={{
                                                  width: "28%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Problema Relatado
                                              </th>

                                              <th
                                                style={{
                                                  width: "18%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Status
                                              </th>

                                              <th
                                                style={{
                                                  width: "28%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Mecânico
                                              </th>

                                              <th
                                                style={{
                                                  width: "48%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Laudo Técnico
                                              </th>
                                              <th
                                                style={{
                                                  width: "25%",
                                                  textAlign: "left",
                                                }}
                                              >
                                                Observação Técnica
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              style={{
                                                backgroundColor: "#FFFFFF",
                                                fontSize: 14,
                                                textAlign: "center",
                                              }}
                                            >
                                              <td>
                                                <Link
                                                  to={`/resultadoChamadosItem/${reg.id}`}
                                                >
                                                  {addZeroes(reg.id, 6)}
                                                </Link>
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: 12,
                                                  color: "black",
                                                }}
                                              >
                                                {formatarDataBr(
                                                  reg.dataAbertura
                                                )}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {reg.motivoAbertura}
                                              </td>
                                              <td style={{ textAlign: "left" }}>
                                                {reg.status == 0
                                                  ? "Aguardando"
                                                  : reg.status == 1
                                                  ? "Andamento"
                                                  : reg.status == 2
                                                  ? "Finalizado"
                                                  : reg.status == 3
                                                  ? "Homologado"
                                                  : reg.status == 4
                                                  ? "Retornado"
                                                  : reg.status == 5
                                                  ? "Recusado"
                                                  : reg.status == 6
                                                  ? "Encerrado pelo solicitante"
                                                  : ""}
                                              </td>

                                              <td style={{ textAlign: "left" }}>
                                                <Link
                                                  to={`/historicoMecanicos/${reg.mecanico?.id}`}
                                                >
                                                  {reg?.mecanico?.usuario.nome}
                                                </Link>
                                              </td>

                                              <td style={{ textAlign: "left" }}>
                                                {reg?.laudoTecnico == null
                                                  ? ""
                                                  : reg.laudoTecnico}
                                              </td>
                                              <td>
                                                {reg?.movimentoMecanico == null
                                                  ? ""
                                                  : reg?.movimentoMecanico[0]
                                                      ?.observacaoFechamentoMecanico}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {/* tempos */}
                                        <table>
                                          <thead
                                            style={{ alignItems: "center" }}
                                          >
                                            <tr>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Abertura a Designado
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Designado a Aceite
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Designado a Fechamento
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Aceite a Fechamento
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Fechamento a Homologado
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Abertura a Fechamento
                                              </th>
                                              <th
                                                style={{ textAlign: "center" }}
                                              >
                                                Abertura a Homologado
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              style={{
                                                backgroundColor: "#FFFFFF",
                                                borderBottom: "2px solid",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[0]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[0]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[0]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[0]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[1]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[1]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[1]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[1]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[2]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[2]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[2]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[2]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[3]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[3]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[3]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[3]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[4]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[4]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[4]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[4]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[5]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[5]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[5]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[5]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: "center",
                                                  backgroundColor: "#D6F0F5",
                                                }}
                                              >
                                                {reg?.relacaoTempoAtendimento[6]
                                                  ?.horas == null
                                                  ? ""
                                                  : reg
                                                      ?.relacaoTempoAtendimento[6]
                                                      ?.horas >= 0
                                                  ? `${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[6]
                                                        ?.horas,
                                                      2
                                                    )}h ${addZeroes(
                                                      reg
                                                        ?.relacaoTempoAtendimento[6]
                                                        ?.minutos,
                                                      2
                                                    )}m`
                                                  : ""}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </React.Fragment>
                                    ))}
                                </table>
                              </table>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-12"
                            style={{ fontSize: 21, textAlign: "center" }}
                          >
                            <span className=" text-muted mb-0">
                              - - - Não há chamados para este patrimônio ! - - -
                            </span>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    );
  };

  console.log(maquinario);

  return (
    <>
      <div className="row">
        <div className="col-md-2" style={{ margin: 0, padding: 0 }}>
          <div className="card">
            <div className="card-body">
              {
                <div className=" ">
                  <div
                    className=" "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "230px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="product-image"
                      Style={`max-height: 250px; ${
                        images?.maquinarioAlbum?.status == 1 ? "" : ""
                      }}`}
                      src={
                        images?.[0]?.blobImage == null
                          ? ""
                          : `data:image/jpeg;base64, ${images[0]?.blobImage}`
                      }
                      alt="Photo"
                    />
                    <div className="ribbon-wrapper ribbon-xl">
                      {maquinario[0]?.status === 1 && (
                        <div className="ribbon bg-success">ATIVO</div>
                      )}

                      {maquinario[0]?.status === 0 && (
                        <div className="ribbon bg-warning">INATIVO</div>
                      )}

                      {maquinario[0]?.status === 2 && (
                        <div className="ribbon bg-danger">MANUTENÇÃO</div>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="col-md-10" style={{ margin: 0, padding: 0 }}>
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                <form className="form-horizontal">
                  <div className="row ">
                    <div className="col-md-12 col-sm-8">
                      <div className="row">
                        <>
                          <div className="col-md-3 col-sm-4 form-group">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Nº Patrimônio
                            </InputLabel>
                            <input
                              style={{ fontSize: 15 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="descricao"
                              name="descricao"
                              value={`${maquinario?.[0]?.numPatrimonio}`}
                            />
                          </div>
                          <div className="col-md-5 col-sm-8 form-group">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-sm-12 col-form-label"
                            >
                              Descrição
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="descricao"
                              name="descricao"
                              value={`${maquinario?.[0]?.descricao}`}
                            />
                          </div>
                          <div className="col-md-4 col-sm-4">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Grupo
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="grupo"
                              name="grupo"
                              value={`${maquinario?.[0]?.grupo.descricao}`}
                            />
                          </div>
                        </>
                      </div>

                      <div className="row">
                        <div className="col-md-3 col-sm-4">
                          <InputLabel
                            htmlFor="inputName"
                            className="col-md-12 col-sm-12 col-form-label"
                          >
                            Responsável
                          </InputLabel>
                          <input
                            style={{ fontSize: 13 }}
                            disabled="true"
                            type="text"
                            className="form-control"
                            id="responsavel"
                            name="responsavel"
                            value={
                              maquinario[0]?.maquinarioSetores[0].setores
                                ?.responsavel?.nome
                            }
                          />
                        </div>

                        <div className="col-md-5 col-sm-4 ">
                          <div className="form-group ">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Setor
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="Setor"
                              name="Setor"
                              value={`${maquinario?.[0]?.maquinarioSetores[0].setores.descricao}`}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 ">
                          <div className="form-group ">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-md-12 col-sm-12 col-form-label"
                            >
                              Unidade de Produção
                            </InputLabel>
                            <input
                              style={{ fontSize: 13 }}
                              disabled="true"
                              type="text"
                              className="form-control"
                              id="Setor"
                              name="Setor"
                              value={abreviacaoUnidade()}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <FormControl
                          className="mr-1"
                          sx={{ minWidth: 100, marginTop: 1 }}
                        >
                          <Button
                            textAlign="right"
                            size="medium"
                            onClick={handleExport}
                            variant="outlined"
                          >
                            Gerar PDF
                          </Button>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <PDFExport
          scale={0.5}
          paperSize={"A4"}
          margin="0.8cm"
          fileName="maquinario_manutencoes.pdf"
          forcePageBreak=".page-break"
          pageTemplate={PDFExportPageTemplate}
          ref={pdf}
        >
          {htmlManut()}
        </PDFExport>
      </div>
    </>
  );
};
