import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import {
    DataGrid, GridToolbar, ptBR,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import LinearProgress from '@mui/material/LinearProgress';



/**
 * ESPERANDO OS VALORES PASSADOS POR PARAMETROS
 * - COMPONENTE CONTENDO A PAGINAÇÃO (CustomPagination)
 * - DATAGRID QUE FAZ A DISPOSICAO DOS DADOS PASSADOS PELOS 
 * - PARAMETROS ABAIXO.
 * @param {data} dados 
 * @param {columns} Colunas a serem preenchidas
 * @returns 
 */
export const GridComponent = ({ data, columns }) => {

    const [dataLo, setDataLo] = useState({
        loading: true,
        rows: []
    });

    useEffect(() => {
        setTimeout(() => {
            setDataLo({
                loading: false,
                rows: data
            });
        }, 300);
    }, []);

    function CustomToolbar() {
        return (
            <>
            </>

            // <GridToolbarContainer>
            //     <GridToolbarColumnsButton />
            //     <GridToolbarFilterButton />
            //     {/* <GridToolbarDensitySelector /> */}

            // </GridToolbarContainer>
        );
    }

    /**COMPONENTE DE PAGINAÇÃO
    * - RESPONSAVEL MONTAGEM DAS PAGINAS BASEADAS NA QUANTIDADES DE DADOS VINDO DO 'data'
    */
    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    }




    /**
     * RENDERIZACAO DO COMPONENTE 
     * ESTRUTURA BOX 
     * COMPONENTE DATAGRID COM AS ESTRUTURAS PASSADAS POR PARAMETROS
     */
    return (
        
        <div>
            {
                <Box sx={{
                    width: '100%',
                    '& .super-app-theme--header': { //#8A8A8A
                        backgroundColor: '#F9F9F9',
                        color: '#8A8A8A',
                        fontSize: '15px'
                    }
                }}>
                    <DataGrid autoHeight
                        pagination
                        getRowHeight={() => 'auto'}
                        rows={data}
                        columns={columns}
                        pageSize={30}
                        loading={dataLo.loading}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: CustomToolbar,
                            Pagination: CustomPagination,
                            LoadingOverlay: LinearProgress,
                        }}
                        componentsProps={{
                            panel: {
                                sx: {
                                    '& .MuiTypography-root': {
                                        color: 'LightSlateGray',
                                        fontSize: 14,
                                    },
                                    '& .MuiDataGrid-gridMenuList': {
                                        bgcolor: '#FFF',
                                    },
                                },
                            },
                        }}
                        sx={{
                            boxShadow: 1,
                            border: 1,
                            fontSize: 12,
                            width: '100%',
                            textTransform: 'uppercase',
                            borderColor: 'InactiveBorder',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'LightSlateGray',
                            },
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                    />
                </Box>

            }
        </div>
    )
}