import ReactApexChart from "react-apexcharts";
import { useQuery } from "react-query";
import api from "../../../services/api";
import { formatarData } from "../../../utils/utils";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const converterParaHorasMinutos = (mediaHoras) => {
  const horas = Math.floor(mediaHoras);
  const minutos = Math.round((mediaHoras % 1) * 60);
  return `${horas}:${minutos.toString().padStart(2, "0")}`;
};

const ApexChartTeste = ({ filters }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data, isLoading } = useQuery(
    "mttrControlChart",
    async () => {
      const response = await api.get(
        `/chamado/grafico-media-horas?maiorOuIgual=${filters.dataInicial}&menorOuIgual=${filters.dataFinal}&unidadeProducao=${filters.unidadeProducao}&setor=${filters.setores}`
      );

      return response.data.records;
    },
    {
      enabled:
        !!filters.dataInicial &&
        !!filters.dataFinal &&
        !!filters.unidadeProducao &&
        !!filters.setores,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data || Object.keys(data[0]).length === 0) {
    return <div></div>;
  }

  const chartOptions = {
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    chart: {
      height: 350,
      type: "line",
      stacked: false
    },
    series: [
      {
        name: "Média:",
        data: data.flatMap((yearData) =>
          Object.values(yearData).flatMap((monthData) =>
            monthData.map((item) => item.mediaHoras.toString().padStart(2, "0"))
          )
        ),
      },
      {
        name: "Total:",
        data: data.flatMap((yearData) =>
          Object.values(yearData).flatMap((monthData) =>
            monthData.map((item) => item.totalChamados)
          )
        ),
        color: "transparent",
        zIndex: -30,
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const horaFormatada = converterParaHorasMinutos(val);
        const horaFormatadaHMin = horaFormatada.replace(":", "h");
        return `${horaFormatadaHMin}min`;
      },
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "CONTROLE MTTR",
      align: "center",
      style: {
        height: 'auto',
        fontSize: isMobile ? '14px' : '18px',
        fontFamily: "'Roboto', sans-serif",        
      }
    },
    subtitle: {
      text: `Período de ${formatarData(filters.dataInicial)} a ${formatarData(
        filters.dataFinal
      )}`,
      align: "center",
      style: {
        fontSize: isMobile ? '14px' : '18px',
        fontFamily: "'Roboto', sans-serif",    
        height: 'auto'
      },
    },
    colors: ["#546E7A", "transparent"],
    grid: {
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: data.flatMap((yearData) =>
        Object.values(yearData).flatMap((monthData) =>
          monthData.map((item) => item.mes)
        )
      ),
      tooltip: {
        enabled: false,
      },
      tickPlacement: "between",
      group: {
        groups: data.map((yearData) => ({
          title: Object.keys(yearData)[0],
          cols: Object.values(yearData)[0].length,
        })),
      },
    },
    yaxis: [
      {
        title: {
          text: "Média de Horas por chamado",
        },
        labels: { 
          formatter: (value) => { return `${value}h`}
        }
      },

      {
        show: false,
        opposite: true,
        title: {
          text: "Horas",
        },
      },
    ],
    markers: {
      enabled: false,
      show: false,
    },
    legend: {
      customLegendItems: data.flatMap((yearData) =>
        Object.values(yearData).flatMap((monthData) =>
          monthData.map((item) => item.mes)
        )
      ),
      markers: {
        fillColors: ["transparent", "transparent"],
        customHTML: function () {
          return "";
        },
      },
      formatter: function (seriesName, opts) {
        const mediaHoras = opts.w.globals.series[0][opts.seriesIndex];
        const totalChamados = opts.w.globals.series[1][opts.seriesIndex];
        const horaFormatada = converterParaHorasMinutos(mediaHoras);
        const horaFormataHMin = horaFormatada.replace(":", "h");
        return `
          <div style="margin: 10px 0">
            <p style="text-align: center; margin: 0; padding: 0; font-weight: 600">${seriesName}</p>
            <span style="text-align: center">Média: ${horaFormataHMin}min</span>
            /
            <span style="text-align: center">Total: ${totalChamados} chamados</span>
          </div>
        `;
      },
    },
    tooltip: {
      y: [
        {
          formatter: function (val) {
            const horaFormatada = converterParaHorasMinutos(val);
            const horaFormatadaHMin = horaFormatada.replace(":", "h");
            return `${horaFormatadaHMin}min`;
          },
        },
        {
          formatter: function (val) {
            return `${val} chamados`;
          },
        },
      ],
    },
  };

  return (
    <Box sx={{ p: 2 }}className="shadow-lg shadow-sm bg-white rounded">
      <ReactApexChart
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={isMobile ? 650 : 385}
      />
    </Box>
  );
};

export default ApexChartTeste;
