import {
  Radio,
  Stack,
  Autocomplete,
  CircularProgress,
  TextField,
  Box,
} from "@mui/material";
import { object } from "prop-types";

import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

import api from "../../../services/api";

import { AlertSuccess, AlertDanger } from "./styles";

export const SetorEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [descricao, setDescricao] = useState("");
  const [nome, setNome] = useState("");
  const [unidade, setUnidade] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [ccusto, setCcusto] = useState([]);
  const [observacao, setObservacao] = useState();
  const [status, setStatus] = useState("1");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [listUnidades, setListUnidades] = useState([]);
  const [selectedUnidade, setSelectedUnidade] = useState(0);

  const [listResponsaveis, setListResponsaveis] = useState([]);
  const [selectedResponsavel, setSelectedResponsavel] = useState(0);

  const [listCcusto, setListCcusto] = useState([]);
  const [selectedCcusto, setSelectedCcusto] = useState(0);

  const idParam = id === 0 ? null : id;

  /*Editar Registros*/
  const editSetores = async (e) => {
    /**
     * CRIADO UMA LISTA DE JSON SEPARADO
     * DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
     */
    var dadosJson = {
      id: id,
      descricao: nome,
      unidadeProducao: selectedUnidade,
      responsavel:
        selectedResponsavel != null ? selectedResponsavel : responsavel,
      ccusto: selectedCcusto != null ? selectedCcusto : ccusto,
      observacao: observacao,
      status: status,
    };

    e.preventDefault();
    try {
      await api.post(`setores/`, dadosJson);

      if (id !== "0") {
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/setores",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getSetores = async () => {
      if (id != "0") {
        await api.get(`setores/` + id).then((response) => {
          setDescricao(response.data.records[0].descricao.toUpperCase());
          setNome(response.data.records[0].descricao.toUpperCase());

          setUnidade(response.data.records[0].unidadeProducao);
          setSelectedUnidade(response.data.records[0].unidadeProducao.cgcCpf);

          setResponsavel(response.data.records[0].responsavel.id);
          setSelectedResponsavel(response.data.records[0].responsavel.id);

          setCcusto(response.data.records[0].ccusto);
          setSelectedCcusto(response.data.records[0].ccusto.id);

          setObservacao(response.data.records[0].observacao);

          setStatus(
            response.data.records[0].status == null
              ? 0
              : response.data.records[0].status
          );
        });
      }
    };

    getSetores();
  }, [idParam, descricao, responsavel]);

  useEffect(() => {
    const getUnidade = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidades(response.data.records);
        setSelectedUnidade(response.data.records[0].unidadeProducao.cgcCpf);
      });
    };
    getUnidade();
  }, []);

  const [selectedOptionResponsavel, setSelectedOptionResponsavel] =
    useState(null);

  useEffect(() => {
    const getResponsavel = async () => {
      await api.get(`usuarioDetalhe`).then((response) => {
        setListResponsaveis(response.data.records);
        setSelectedOptionResponsavel(response.data.records[0].codUsuario);
      });
    };
    getResponsavel();
  }, []);

  /**
   * RESPONSAVEL POR ARMAZENAR OS VALORES TRAZIDOS PELO GET DO CENTRO DE CUSTO
   * - usado no bloco abaixo armazenando o ID
   */
  const [selectedOptionCentroCusto, setSelectedOptionCentroCusto] =
    useState(null);
  useEffect(() => {
    const getCcusto = async () => {
      await api.get(`ccusto`).then((response) => {
        setListCcusto(response.data.records);
        setSelectedOptionCentroCusto(response.data.records[0].id);
        if (id === "0") {
          setCcusto(response.data.records[0].id);
        }
      });
    };

    getCcusto();
  }, []);

  const optionsUnidade = listUnidades.sort((a, b) => {
    if (a.fantasia > b.fantasia) return 1;
    if (a.fantasia < b.fantasia) return -1;
    return 0;
  })
  .map(function (unid) {
    return { value: unid.cgcCpf, label: unid.fantasia };
  });

  const handleChangeUnidade = (selectedOptionUnidade) => {
    setSelectedUnidade(selectedOptionUnidade.value);
  };

  /**
   * RESPONSAVEL POR ARMAZENAR OS VALORES TRAZIDOS PELO GET DO RESPONSAVEL
   * - usado no bloco abaixo
   */

  /** OPTION RESPONSAVEL
   * Função para formatar os dados que serao utilizado no ReactSelect
   * - o formato que deve retornar é Value e Label
   */
  const optionsResponsavel = listResponsaveis.map(function (respons) {
    return { value: respons.codUsuario, label: respons.descricao };
  });

  /**
   * Responsavel por listar as mudanças feitas no componente
   * onde recebe o const vindo da lista de responsavel
   * @param {*} selectedOptionResponsavel
   */
  const handleChangeResponsavel = (selectedOptionResponsavel) => {
    setSelectedResponsavel(selectedOptionResponsavel.value);
  };

  /** OPTION CENTRO DE CUSTO *******************************************
   * Função para formatar os dados que serao utilizado no ReactSelect
   * - o formato que deve retornar é Value e Label
   */
  const optionsCentroCusto = listCcusto.sort((a, b) => {
    if (a.descricao > b.descricao) return 1;
    if (a.descricao < b.descricao) return -1;
    return 0;
  })
  .map(function (cc) {
    return { value: cc.id, label: cc.descricao };
  });

  /**
   * Responsavel por listar as mudanças feitas no componente
   * onde recebe o const vindo da lista de responsavel
   * @param {*} selectedOptionCentroCusto
   */
  const handleChangeCentroCusto = (selectedOptionCentroCusto) => {
    setSelectedCcusto(selectedOptionCentroCusto.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editSetores}>
                  <div className="row">
                    <div className="col-sm-12 was-validated">
                      <label
                        htmlFor="inputDescricao"
                        className="col-sm-6 col-form-label"
                      >
                        Descrição
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="nome"
                          name="nome"
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                          placeholder="Descrição do Setor..."
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label className="col-sm-6 col-form-label">Unidade</label>

                      <Select
                        value={optionsUnidade.filter(
                          (obj) => obj.value === selectedUnidade
                        )}
                        onChange={handleChangeUnidade}
                        options={optionsUnidade}
                        clearable={"true"}
                        searchable={"true"}
                        labelKey={"fantasia"}
                        valueKey={"cgcCpf"}
                        noOptionsMessage={() => "Nenhum registro encontrado"}
                        required="required"
                      ></Select>
                    </div>

                    <div className="col-sm-4">
                      <label className="col-sm-6 col-form-label">
                        Responsável
                      </label>
                      <div className="col-sm-10"></div>

                      <Select
                        value={optionsResponsavel.filter(
                          (obj) => obj.value === selectedResponsavel
                        )}
                        onChange={handleChangeResponsavel}
                        options={optionsResponsavel}
                        clearable={"true"}
                        searchable={"true"}
                        labelKey={"descricao"}
                        valueKey={"codusuario"}
                        noOptionsMessage={() => "Nenhum registro Encontrado"}
                        required="required"
                      />
                    </div>

                    <div className="col-sm-4">
                      <label className="col-sm-6 col-form-label">
                        Centro de Custo
                      </label>

                      <Select
                        value={optionsCentroCusto.filter(
                          (obj) => obj.value === selectedCcusto
                        )}
                        onChange={handleChangeCentroCusto}
                        options={optionsCentroCusto}
                        clearable={"true"}
                        searchable={"true"}
                        labelKey={"descricao"}
                        valueKey={"id"}
                        noOptionsMessage={() => "Nenhum registro Encontrado"}
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-6 col-form-label">
                      Observação
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="observacao"
                      name="observacao"
                      value={observacao}
                      onChange={(e) => setObservacao(e.target.value)}
                      placeholder="Observação..."
                    />
                  </div>

                  <div className="row">
                    <label className="col-sm-12 col-form-label">Status</label>
                    <div className="col-sm-10">
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Ativo
                      </FormLabel>
                      <Radio
                        checked={status == "1"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "1" }}
                      />
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Inativo
                      </FormLabel>
                      <Radio
                        checked={status == "0"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="0"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "0" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
