import { createContext, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { matprimaContext } from "./MatprimaContext";

const VincularMatPrimaContext = createContext();

export const VincularMatPrimaContextProvider = ({ children }) => {
  const { setIsDialog, selectMatPrima } = matprimaContext();
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const queryClient = useQueryClient();


  const mutation = useMutation(
    async (data) => {
        const response = await api.post("matprima-pecas/vincular-matprima-grupomaq", data);

        return response.data;
    },{
        onSuccess: () => {
            setIsDialog(false);
            queryClient.invalidateQueries(`listTable`)
            
        },
        onError: () => {
          queryClient.invalidateQueries(`listTable`)
            setIsDialog(false);
        }
    }
  );


  const handleSave = () => {
    if (newSelectedItems.length > 0) {
      const formattedData = newSelectedItems.map(item => ({
        matprima: item.matprima,
        idGrupoMaq: item.idGrupoMaq,
      }));
  
      mutation.mutate(formattedData);
    }
  };


  return (
    <VincularMatPrimaContext.Provider value={{newSelectedItems, setNewSelectedItems, handleSave, isLoadingSave: mutation.isLoading}}>
      {children}
    </VincularMatPrimaContext.Provider>
  );
};

export const vincularMatPrimaContext = () => {
  return useContext(VincularMatPrimaContext);
};