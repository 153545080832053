import React, { useState, useEffect } from "react";
const menuJson = require("./menuElement.json");

export const MenuItem = () => {
  const [data, setData] = useState(menuJson.data);

  return (
    <div>
      {data
        .filter((st) => st.ativo == "S")
        .map((item) => (
          <div>
            {(() => {
              if (item.menuIten.length > 0) {
                return (
                  <>
                    <li className="nav-item" key={item.id}>
                      <a href="#" className="nav-link">
                        <i className={item.nomeIcon} />

                        <p>
                          {item.itemPaiLabel}
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>

                      <ul className="nav nav-treeview">
                        {item.menuIten.map((mitem) => (
                          <li className="nav-item" key={mitem.id}>
                            <a href={mitem.link} className="nav-link">
                              <i className={mitem.nomeIcon} />
                              <p>{mitem.elementoLabel}</p>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li className="nav-item" key={item.id}>
                      <a href={item.link}>
                        <p
                          className="breadcrumb-item ml-1"
                          style={{ color: "#b6bbc5" }}
                        >
                          <i className={item.nomeIcon} />
                          {item.itemPaiLabel}
                        </p>
                      </a>
                    </li>
                  </>
                );
              }
            })()}
          </div>
        ))}
    </div>
  );
};
