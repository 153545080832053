import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { formatarData } from "../../utils/utils";
import { ChartPatrimonio } from "./Charts/Pat";
import { ChartFabrica } from "./Charts/Fabrica";
import { ChartMecanico } from "./Charts/Mecanico";
import { ChartUnidade } from "./Charts/UnidadeProd";
import { ChartGravidade } from "./Charts/Gravidade";
import { ChartGrupo } from "./Charts/Grupo";
import { ListFilter } from "./Charts/ListFilter";
import { MecanicoRanking } from "./Charts/MecanicoRanking";
import ApexChartTeste from "./Charts/Mtbf_mttr";

export const GraficosEstatistica = ({
  dataInicial,
  dataFinal,
  filterUnidade,
  filterSetor,
  filterMecanico,
  filterTipo,
  filterGrupo,
  unidade,
  fabrica,
  grupo,
  mecanico,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    setData([]);

    const getDados = async () => {
      let stringBase = `chamados-estatistica/busca?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;
      let stringUnidade = "";
      let stringSetor = "";
      let stringGrupo = "";
      let stringMecanico = "";
      let stringTipo = filterTipo != 0 ? "&porcentagem=1" : "&porcentagem=0";

      setLoading(true);

      if (
        filterUnidade.length != 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length == 0 &&
        filterMecanico.length == 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length == 0 &&
        filterMecanico.length == 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length != 0 &&
        filterMecanico.length == 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;
        stringGrupo = `grupo=` + filterGrupo;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          "&" +
          stringGrupo +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length == 0 &&
        filterMecanico.length != 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;
        stringMecanico = `mecanico=` + filterMecanico;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          "&" +
          stringMecanico +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length != 0 &&
        filterGrupo.length != 0 &&
        filterMecanico.length != 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringSetor = `setor=` + filterSetor;
        stringMecanico = `mecanico=` + filterMecanico;
        stringGrupo = `grupo=` + filterGrupo;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringSetor +
          "&" +
          stringGrupo +
          "&" +
          stringMecanico +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length != 0 &&
        filterMecanico.length == 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringGrupo = `grupo=` + filterGrupo;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringGrupo +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length == 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length == 0 &&
        filterMecanico.length != 0
      ) {
        stringMecanico = `mecanico=` + filterMecanico;

        stringBase =
          `chamados-estatistica/busca?` +
          stringMecanico +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      } else if (
        filterUnidade.length != 0 &&
        filterSetor.length == 0 &&
        filterGrupo.length == 0 &&
        filterMecanico.length != 0
      ) {
        stringUnidade = `unidadeProducao=` + filterUnidade;
        stringMecanico = `mecanico=` + filterMecanico;

        stringBase =
          `chamados-estatistica/busca?` +
          stringUnidade +
          "&" +
          stringMecanico +
          `&dataInicial=${dataInicial}` +
          `&dataFinal=${dataFinal}`;
      }

      try {
        await api.get(stringBase + stringTipo).then((response) => {
          setData(response.data.records);
        });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };
    Promise.all([getDados()]);
  }, [
    dataInicial,
    dataFinal,
    filterUnidade,
    filterSetor,
    filterMecanico,
    filterTipo,
    filterGrupo,
  ]);

  const themes = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const calcularTamanhosGrid = (
    filterUnidade,
    filterSetor,
    filterGrupo,
    filterMecanico
  ) => {
    let tamanhoUnidade = isMobile ? 12 : isTablet ? 12 : 6;
    let tamanhoUnidadeProd = 12;
    let tamanhoGravidade = isMobile ? 12 : isTablet ? 12 : 6;
    let tamanhoSetor = isMobile ? 12 : isTablet ? 12 : 6;
    let tamanhoGrupo = isMobile ? 12 : isTablet ? 12 : 6;
    let tamanhoPatrimonio = 12;
    let tamanhoMecanico = 12;
    let tamanhoMecanicoRanking = 12;

    if (
      filterUnidade.length !== 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length === 0
    ) {
      tamanhoPatrimonio = 6;
      tamanhoGravidade = 12;
      tamanhoMecanico = 12;
      tamanhoUnidadeProd = 12;
      tamanhoMecanicoRanking = 12;

    } else if (filterSetor.length !== 0 && filterGrupo.length === 0) {
      tamanhoGravidade = 12;
      tamanhoPatrimonio = 6;
      tamanhoGrupo = 6;
      tamanhoMecanico = 12;
      tamanhoMecanicoRanking = 12;
      tamanhoUnidadeProd = 12;
    } else if (
      filterSetor.length !== 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length === 0
    ) {
      tamanhoGravidade = 12;
      tamanhoPatrimonio = 6;
      tamanhoMecanico = 6;
      tamanhoMecanicoRanking = 12;
      tamanhoUnidadeProd = 12;
    } else if (
      filterSetor.length !== 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length !== 0
    ) {
      tamanhoGravidade = 12;
      tamanhoPatrimonio = 12;
    } else if (
      filterUnidade.length !== 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length === 0
    ) {
      tamanhoGravidade = 12;
      tamanhoSetor = 6;
      tamanhoMecanico = 6;
      tamanhoMecanicoRanking = 12;

    } else if (
      filterUnidade.length !== 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length !== 0
    ) {
      tamanhoGravidade = 12;
      tamanhoSetor = 12;
    } else if (filterSetor === 0 && filterGrupo.length !== 0) {
      tamanhoSetor = 6;
      tamanhoUnidade = 6;
      tamanhoGravidade = 6;
      tamanhoMecanico = 6;
      tamanhoMecanicoRanking = 12;
    } else if (
      filterUnidade.length === 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length === 0
    ) {
      tamanhoMecanico = 6;
      tamanhoMecanicoRanking = 12;
      tamanhoUnidade = 6;
      tamanhoGravidade = 6;
    } else if (
      filterUnidade.length === 0 &&
      filterSetor.length === 0 &&
      filterGrupo.length === 0 &&
      filterMecanico.length !== 0
    ) {
      tamanhoGravidade = 6;
      tamanhoUnidade = 6;
      tamanhoPatrimonio = 12;
    } else if (filterGrupo.length !== 0 && filterMecanico.length !== 0) {
      tamanhoGravidade = 6;
      tamanhoUnidade = 6;
      tamanhoSetor = 12;
      tamanhoUnidadeProd = 12;
    }

    return {
      tamanhoUnidade,
      tamanhoUnidadeProd,
      tamanhoGravidade,
      tamanhoSetor,
      tamanhoGrupo,
      tamanhoPatrimonio,
      tamanhoMecanico,
      tamanhoMecanicoRanking,
    };
  };
  const tamanhosGrid = calcularTamanhosGrid(
    filterUnidade,
    filterSetor,
    filterGrupo,
    filterMecanico
  );
  const calcularOrdens = (filterSetor, filterGrupo, filterMecanico) => {
    let ordemPatrimonio = 0;
    let ordemGrupo = 0;
    let ordemMecanico = 0;

    if (filterSetor.length !== 0 && filterGrupo.length === 0) {
      ordemPatrimonio = 1;
      ordemGrupo = 2;
      ordemMecanico = 3;
    } else if (
      filterSetor.length !== 0 &&
      filterGrupo.length !== 0 &&
      filterMecanico.length === 0
    ) {
      ordemPatrimonio = 1;
      ordemMecanico = 2;
    }

    return {
      ordemPatrimonio,
      ordemGrupo,
      ordemMecanico,
    };
  };
  const ordens = calcularOrdens(filterSetor, filterGrupo, filterMecanico);

  return (
    <Box style={{ marginTop: "1cm", marginBottom: "1cm" }} m={1}>
      {loading ? (
        <Box sx={{ display: "flex" }} justifyContent="center">
          <CircularProgress size={70} />
        </Box>
      ) : (
        <>
          {data &&
            data.map((task) => {
              return (
                <Grid container columnSpacing={2}>
                  <Grid
                    item
                    width="100%"
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    align="center"
                    gridArea="titulo"
                  >
                    <ThemeProvider theme={theme}>
                      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                        Análise de Ocorrências Realizadas
                      </Typography>

                      <Typography variant="h5" className="mb-2">
                        <span style={{ fontWeight: 'bold' }}>
                          {filterUnidade.length > 0
                            ? ""
                            : "Estatísticas Gerais "}{" "}
                            {dataFinal < dataInicial
                            ? "Data final menor que data inicial !"
                            : `${formatarData(dataInicial)} a
                          ${formatarData(dataFinal)}`}
                        </span>
                      </Typography>
                      
                      <ListFilter
                        unidade={unidade}
                        setor={fabrica}
                        mecanico={mecanico}
                        grupo={grupo}
                        filterUnidade={filterUnidade}
                        filterSetor={filterSetor}
                        filterMecanico={filterMecanico}
                        filterGrupo={filterGrupo}
                      />

                    </ThemeProvider>
                  </Grid>
                  {task.unidadesProducao &&
                    task.unidadesProducao.length > 0 &&
                    filterUnidade == 0 && (
                      <Grid
                        item
                        xs={12}
                        md={tamanhosGrid.tamanhoUnidade}
                        sm={tamanhosGrid.tamanhoUnidade}
                        mt={3}
                        align="center"
                        gridArea="unidade"
                        style={{ width: '100%', height: '100%' }}
                      >
                        <ChartUnidade unidade={task.unidadesProducao}/>
                      </Grid>
                    )}
                  {task.gravidades && task.gravidades.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      md={tamanhosGrid.tamanhoGravidade}
                      sm={tamanhosGrid.tamanhoGravidade}
                      align="center"
                      mt={3}
                      gridArea="gravidade"
                    >
                      <ChartGravidade gravidade={task.gravidades} tam={tamanhosGrid.tamanhoGravidade}/>
                    </Grid>
                  )}
                  {filterSetor.length !== 0 ||
                  (filterGrupo.length === 0 && filterMecanico.length !== 0) ? (
                    <Grid
                      item
                      xs={12}
                      md={tamanhosGrid.tamanhoPatrimonio}
                      sm={tamanhosGrid.tamanhoPatrimonio}
                      mt={5}
                      order={ordens.ordemPatrimonio || 3}
                      gridArea="patrimonio"
                    >
                      <ChartPatrimonio
                        key={`${filterUnidade}-${filterSetor}`}
                        patrimonio={task.patrimonios}
                        filtroTipo={filterTipo}
                      />
                    </Grid>
                  ) : null}
                  {task.setores &&
                    task.setores.length > 0 &&
                    filterSetor == 0 && (
                      <Grid
                        item
                        xs={12}
                        md={tamanhosGrid.tamanhoSetor}
                        sm={tamanhosGrid.tamanhoSetor}
                        gridArea="setor"
                        mt={5}
                      >
                        <ChartFabrica
                          setor={task.setores}
                          filtroTipo={filterTipo}
                        />
                      </Grid>
                    )}
                  {task.grupos &&
                    task.grupos.length > 0 &&
                    filterGrupo == 0 && (
                      <Grid
                        item
                        xs={12}
                        md={tamanhosGrid.tamanhoGrupo}
                        sm={tamanhosGrid.tamanhoGrupo}
                        mt={5}
                        order={ordens.ordemGrupo || 1}
                        gridArea="grupo"
                      >
                        <ChartGrupo
                          grupo={task.grupos}
                          filtroTipo={filterTipo}
                        />
                      </Grid>
                    )}
                  {task.mecanicos &&
                    task.mecanicos.length > 0 &&
                    filterMecanico == 0 && (
                      <Grid
                        item
                        xs={12}
                        mt={5}
                        md={tamanhosGrid.tamanhoMecanico}
                        sm={tamanhosGrid.tamanhoMecanico}
                        order={ordens.ordemMecanico || 2}
                        gridArea="mecanico"
                      >
                        <ChartMecanico
                          mecanico={task.mecanicos}
                          filtroTipo={filterTipo}
                          dataInicial={dataInicial}
                          dataFinal={dataFinal}
                          dataEstatistica={data}
                        />

                       
                      </Grid>
                    )}

                  {task.mecanicos &&
                    task.mecanicos.length > 0 &&
                    filterMecanico == 0 && (
                      <Grid
                        item
                        xs={12}
                        mt={5}
                        md={tamanhosGrid.tamanhoMecanicoRanking}
                        sm={tamanhosGrid.tamanhoMecanicoRanking}
                        order={ordens.ordemMecanico || 2}
                        gridArea="mecanico"
                      >
                        <MecanicoRanking
                          data={task.mecanicos}
                          periodo={{
                            dataInicial: formatarData(dataInicial),
                            dataFinal: formatarData(dataFinal),
                          }}
                        />
                      </Grid>
                    )}

                  <Grid
                    item
                    mt={5}
                    xs={12}
                    md={tamanhosGrid.tamanhoUnidadeProd}
                    sm={tamanhosGrid.tamanhoUnidadeProd}
                    order={ordens.ordemUnidade || 3}
                    gridArea="unidade"
                  >
                    { filterSetor.length == 0 ? "" :
                    <ApexChartTeste 
                    filters={{
                      dataInicial: dataInicial,
                      dataFinal: dataFinal,
                      unidadeProducao: filterUnidade,
                      setores: filterSetor,
                    }}
                  />
                  }
                  </Grid>



                </Grid>
              );
            })}

          {data[0] && !data[0].unidadesProducao && (
            <>
              <div className="col-12">
                <div className="invoice p-3 mb-3">
                  <div className="col-md-12">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          Não Há Informações{" "}
                        </span>
                        <span className="info-box-number text-center text-muted mb-0">
                          Defina os Critérios de Pesquisa acima!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Box>
  );
};
