import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { pecasContext } from "../../../contexts/PecasContext";
import { ModalPecas } from "./ModalPecas";
import { matprimaContext } from "../../../contexts/MatprimaContext";
import { formatReal } from "../../../utils/utils";
import ColorizeIcon from "@mui/icons-material/Edit";
import { ExpandableGroup } from "./GridGrupMaq";

export const GridTable = () => {
  const { ListaTable, isListTable, selectedUnidade } = pecasContext();
  const { setSelectMatPrima, isDialog, setIsDialog } = matprimaContext();
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [materiaPrimaSelecionada, setMateriaPrimaSelecionada] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenModal = (matprima) => {
    setMateriaPrimaSelecionada(matprima);

    setIsDialogOpen(true);
  };

  const rows =
    ListaTable && Array.isArray(ListaTable.data)
      ? ListaTable.data.map((item) => ({
          id: item.matprima,
          matprima: item.matprima,
          empresa: item.empresa,
          grupo:
            item.grupoMaquinas && Array.isArray(item.grupoMaquinas)
              ? item.grupoMaquinas.map((group) => group.grupoMaquina?.descricao)
              : [],
          materiais: item.grupoDescricao ? item.grupoDescricao : [],
          descricao: item.descricao,
          un: item.unidade,
          estoque: item.qtdeEstoque,
          custo: item.custoMedio,
          status: item.status == "I" ? "Inativo" : "Ativo",
          unidadeCnpj: item.unidadeCnpj,
        }))
      : [];

  const cols = [
    {
      field: "matprima",
      headerName: "MAT.PRIMA",
      headerAlign: "left",
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            color: "primary.main",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => {
            handleOpenModal(params.row);
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "empresa",
      headerName: "EMPRESA",
      headerAlign: "left",
      width: 200,
    },
    {
      field: "grupo",
      headerName: "GRUPO MÁQUINAS",
      headerAlign: "left",
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            padding: "4px",
            overflowY: params.value.length > 2 ? "scroll" : "",
            maxHeight: params.value.length > 2 ? 80 : "none",
          }}
        >
          {params.value.map((group, index) => (
            <Tooltip title={group}>
              <Box
                key={index}
                sx={{
                  padding: "4px 12px",
                  borderRadius: "4px",
                  backgroundColor: "#f0f5fa",
                  color: "primary.main",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 230,
                  whiteSpace: "nowrap",
                }}
              >
                {group}
              </Box>
            </Tooltip>
          ))}
        </Box>
      ),
    },
    {
      field: "materiais",
      headerName: "GRUPO MATERIAIS",
      headerAlign: "left",
      width: 240,
    },
    {
      field: "descricao",
      headerName: "DESCRIÇÃO",
      headerAlign: "left",
      width: 290,
    },
    {
      field: "un",
      headerName: "UN",
      headerAlign: "left",
      width: 70,
    },
    {
      field: "estoque",
      headerName: "QTDE.EST.",
      headerAlign: "center",
      width: 100,
    },

    {
      field: "custo",
      headerName: "CUSTO MÉDIO",
      headerAlign: "left",
      width: 120,
      renderCell: (params) => <Box>R$ {formatReal(params.value)}</Box>,
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "left",
      width: 100,
    },
  ];

  const sizePage = 10;

  const totalPages = Math.ceil(rows.length / sizePage);

  const startIndex = (page - 1) * sizePage;
  const endIndex = startIndex + sizePage;
  const currentItems = rows.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const NoRowsOverlay = () => (
    <Typography
      variant="body1"
      align="center"
      sx={{
        position: "relative",
        top: "20%",
        transform: "translateY(-50%)",
        width: "100%",
        color: "black",
        zIndex: 1,
      }}
    >
      Não há dados a serem exibidos.{" "}
    </Typography>
  );

  return (
    <Box
      sx={{
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "#F9F9F9",
          color: "#8A8A8A",
          fontSize: "15px",
        },
      }}
    >
      {!isMobile ? (
        <DataGrid
          autoHeight
          pagination
          getRowHeight={() => "auto"}
          rows={rows}
          columns={cols}
          pageSize={30}
          rowsPerPageOptions={[10]}
          loading={isListTable}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          disableSelectionOnClick
          getRowId={(row) => row.matprima}
          components={{
            NoRowsOverlay,
          }}
          sx={{
            minHeight: rows.length > 0 ? 200 : 400,
            boxShadow: 1,
            border: 1,
            fontSize: 12,
            width: "100%",
            textTransform: "uppercase",
            borderColor: "InactiveBorder",
            "& .MuiDataGrid-cell:hover": {
              color: "LightSlateGray",
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "id", sort: "desc" }],
            },
          }}
        />
      ) : (
        <>
          {rows.map((item) => (
            <Card sx={{ mt: 2, mb: 2 }}>
              <CardContent>
                <Box key={item.id} sx={{ display: "grid", gap: 3 }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        MatPrima:
                      </Typography>
                      <Typography variant="body1">{item.matprima}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        color="warning"
                        onClick={() => handleOpenModal(item)}
                      >
                        <ColorizeIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Descrição:
                    </Typography>
                    <Typography variant="body1">{item.descricao}</Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Grupo Materiais:
                    </Typography>
                    <Typography variant="body1">{item.materiais}</Typography>
                  </Box>

                  <ExpandableGroup item={item} />

                  <Box
                    sx={{
                      display: "grid",
                      gap: 1,
                      gridTemplateColumns: "1fr 1fr",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        UN:
                      </Typography>
                      <Typography variant="body1">{item.un}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Qtde.Estoque:
                      </Typography>
                      <Typography variant="body1">{item.estoque}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Custo:
                      </Typography>
                      <Typography variant="body1">
                        R$ {formatReal(item.custo)}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                      >
                        Status:
                      </Typography>
                      <Typography variant="body1">{item.status}</Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
          {ListaTable !== null && !isListTable && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </>
      )}
      {isDialogOpen && (
        <ModalPecas
          dialogOpen={isDialogOpen}
          matPrimaSelecionada={materiaPrimaSelecionada}
          handleClose={() => setIsDialogOpen(!isDialogOpen)}
        />
      )}
    </Box>
  );
};
