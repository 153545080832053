import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export const ChartGrupo = ({ grupo, filtroTipo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));


  const total = grupo/* slice(0, 12);
  const total = topGrupos */.reduce(
    (accumulator, grupo) => accumulator + grupo.total,
    0
  );

  const [chartGrupo] = React.useState({
    series: [
      {
        data: grupo.map((dadosGrupo) => dadosGrupo.total),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "95%",
          borderRadius: 4,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#00E4E4"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#304758"],
          fontWeight: "normal",
          fontSize: isMobile ? '10px' : '14px',
        },
        formatter: function (val, opt) {
          if (filtroTipo != 0) {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :.. " + val + "%"
            );
          } else {
            return (
              opt.w.globals.labels[opt.dataPointIndex] + " :.. Total: " + val
            );
          }
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: grupo.map((dadosGrupo) => dadosGrupo.nome),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: `GRUPO DE PATRIMÔNIOS`,
        align: "center",
        floating: true,
        margin: 6,
        style: {
          height: 'auto',
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",        
        }
      },
      subtitle: {
        text: `Total: ${total.toFixed(0)}`,
        align: 'right',
        margin: 10,
        offsetX: 3,
        style: {
          fontSize: isMobile ? '14px' : '16px',
          fontFamily: "'Roboto', sans-serif",    
          fontWeight: 'bold',
          height: 'auto'
        }
    },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  });

  return (
    <Box sx={{ p: 2 }} className="shadow-lg bg-white rounded" style={{ padding: 16 }}>
     
        <ReactApexChart
          options={chartGrupo.options}
          series={chartGrupo.series}
          type="bar"
          height={600}
        />
       
      <div id="html-dist"></div>
    </Box>
  );
};
