import { createContext, useContext, useState } from "react";
import { useQuery } from "react-query";
import api from "../services/api";

const MatprimaContext = createContext();

export const MatprimaContextProvider = ({ children }) => {
  const [selectMatPrima, setSelectMatPrima] = useState({
    matprima: null,
    unidade: null,
  });
  const [isDialog, setIsDialog] = useState(false);


  const { data: matPrima, isLoading: isLoadingMatPrima } = useQuery(
    [`matPrima-${selectMatPrima.matprima}-${selectMatPrima.unidade}`, selectMatPrima.matprima, selectMatPrima.unidade],
    async () => {
      let response = await api.get(
        `matprima-pecas/${selectMatPrima.matprima}/${selectMatPrima.unidade}`
      );

      return response.data.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectMatPrima.matprima && !!selectMatPrima.unidade,
      onSuccess: () => {
        
      },
    }
  );

  return (
    <MatprimaContext.Provider
      value={{
        selectMatPrima,
        setSelectMatPrima,
        matPrima,
        isLoadingMatPrima,
        isDialog,
        setIsDialog,
      }}
    >
      {children}
    </MatprimaContext.Provider>
  );
};
export const matprimaContext = () => {
  return useContext(MatprimaContext);
};
