import { Radio, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { AlertSuccess, AlertDanger } from "./styles";
import { QRCodeCanvas } from "qrcode.react";
import { QrCode } from "@mui/icons-material";
import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";

export const EtiquetasEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [tamanho, setTamanho] = useState("1");
  const [medidaA, setMedidaA] = useState([]);
  const [medidaL, setMedidaL] = useState([]);
  const [uso, setUso] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [nome, setNome] = useState("");
  const [status, setStatus] = useState("1");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [unidade, setUnidade] = useState([]);
  const [responsavel, setResponsavel] = useState("");
  const [ccusto, setCcusto] = useState([]);
  const [observacao, setObservacao] = useState();

  const [data, setData] = useState([]);
  const [listUnidades, setListUnidades] = useState([]);
  const [selectedUnidade, setSelectedUnidade] = useState(0);

  const [listResponsaveis, setListResponsaveis] = useState([]);
  const [selectedResponsavel, setSelectedResponsavel] = useState(0);

  /*Editar Registros*/
  const editEtiquetas = async (e) => {
    /**
     * CRIADO UMA LISTA DE JSON SEPARADO
     * DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
     */

    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          descricao: descricao,
          medidaA: medidaA,
          medidaL: medidaL,
          status: status,
          tamanho: tamanho,
          uso: uso,
        };

        await api.put(`etiquetas/` + id, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          descricao: descricao,
          medidaA: medidaA,
          medidaL: medidaL,
          status: status,
          tamanho: tamanho,
          uso: uso,
        };

        await api.post(`etiquetas/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        /* history.push({
          pathname: "/maquinarioEtiquetasItem/id",
        });
      }, 2000); */

        let idReg = localStorage.getItem("idEti");

        if (idReg == "0") {
          history.push({
            pathname: "/maquinarioEtiquetas",
          });
        } else {
          history.push({
            pathname: "/maquinarioEtiquetas",
          });
        }
      }, 1000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getEtiquetas = async () => {
      if (id != "0") {
        await api.get(`etiquetas/` + id).then((response) => {
          setDescricao(response.data.records[0].descricao.toUpperCase());
          setUso(response.data.records[0].uso);
          setMedidaA(response.data.records[0].medidaA);
          setMedidaL(response.data.records[0].medidaL);
          setTamanho(
            response.data.records[0].tamanho == null
              ? 0
              : response.data.records[0].tamanho
          );
          setStatus(
            response.data.records[0].status == null
              ? 0
              : response.data.records[0].status
          );
        });
      }
    };

    getEtiquetas();
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editEtiquetas}>
                  <div className="row">
                    <div className="row col-sm-12 was-validated">
                      <div className="col-sm-6">
                        <label htmlFor="inputDescricao">Descrição</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="descricao"
                            name="descricao"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            placeholder="Descrição do uso da etiqueta..."
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Por favor, preencha esse campo.
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 was-validated">
                        <label htmlFor="inputDescricao">Uso</label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="uso"
                            name="uso"
                            value={uso}
                            onChange={(e) => setUso(e.target.value)}
                            placeholder="Descrição do uso da etiqueta..."
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Por favor, preencha esse campo.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="row col-sm-12 was-validated">
                      <div className="col-sm-6">
                        <label htmlFor="inputDescricao">
                          Medida Largura(mm)
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="medidaL"
                            name="medidaL"
                            value={medidaL}
                            onChange={(e) => setMedidaL(e.target.value)}
                            placeholder="Medidas da etiqueta..."
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Por favor, preencha esse campo.
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6 was-validated">
                        <label htmlFor="inputDescricao">
                          Medida Altura(mm)
                        </label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="medidaA"
                            name="medidaA"
                            value={medidaA}
                            onChange={(e) => setMedidaA(e.target.value)}
                            placeholder="Medidas da etiqueta..."
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Por favor, preencha esse campo.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="generic_price_table">
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="price-heading clearfix">
                              <h5 className="mt-4 mb-4">
                                Selecione um tamanho
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-4 mr-2">
                            <Radio
                              checked={tamanho == "M"}
                              onChange={(e) => setTamanho(e.target.value)}
                              value="M"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "0" }}
                            />
                            <div
                              style={{
                                border: "1.5px solid #3c3c3c",
                                maxWidth: "400px",
                                maxHeight: "150px",
                                width: "auto",
                                height: "auto",
                                backgroundColor: "white",
                              }}
                            >
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-8">
                                    <small>
                                      <img
                                        style={{
                                          width: "190px",
                                          marginBottom: "10px",
                                        }}
                                        src={Logo}
                                        alt="Logo"
                                      />
                                    </small>
                                    <br />
                                    <span>{"DESCRICAO DO MAQUINARIO"}</span>

                                    <br />
                                    <hr />

                                    <span className="mb-2">{"PAT - 0000"}</span>
                                  </div>

                                  <div className="col-sm-4 text-align-right">
                                    <QrCode
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        alignContent: "",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <Radio
                              checked={tamanho == "G"}
                              onChange={(e) => setTamanho(e.target.value)}
                              value="G"
                              name="radio-buttons"
                              inputProps={{ "aria-label": "0" }}
                            />

                            <div
                              style={{
                                border: "1.5px solid #3c3c3c",
                                width: "450px",
                                backgroundColor: "white",
                              }}
                            >
                              <div
                                className="col-md-12"
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  style={{
                                    textAlign: "left",
                                    marginLeft: "42px",
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <span>{"PAT-0000"}</span>
                                  <br />
                                  <span>{"DESCRICAO DO MAQUINARIO"}</span>
                                </div>

                                <QRCodeCanvas
                                  style={{
                                    width: "350px",
                                    height: "350px",
                                  }}
                                  renderAs="svg"
                                />

                                <small>
                                  <img
                                    style={{
                                      width: "190px",
                                      marginBottom: "20px",
                                      marginTop: "10px",
                                    }}
                                    src={Logo}
                                    alt="Logo"
                                  />
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <footer>
                      <a className="bottom_btn" href="#"></a>
                    </footer>
                  </div>

                  <div className="row">
                    <div className="col-3 text-left">
                      <label className="col-sm-12 col-form-label">Status</label>
                      <div className="col-sm-10">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Ativo
                        </FormLabel>
                        <Radio
                          checked={status == "1"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="1"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "1" }}
                        />
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Inativo
                        </FormLabel>
                        <Radio
                          checked={status == "0"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="0"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "0" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
