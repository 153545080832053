import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AdicionarScript } from '../../../components/AdicionarScript';
import { GridComponent } from '../../../components/controles/GridComponente';
import { addZeroes } from '../../../utils/utils';
import api from "../../../services/api";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/**
*   Montar a tela de visualizacao de dados
* - preenchimento do objeto columns para seja formatado 
* - as colunas da tabela para o componente grid
* - const columns = []
* @returns 
 */

export const EtiquetasDataGrid = () => {
  let history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [selectedUnidade, setSelectedUnidade] = useState(0);
  const [tipoUnidade, setTipoUnidade] = useState(0);
  const [listUnidades, setListUnidades] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  const handleClick = (event, cellValues) => {
    history.push({
      pathname: '/maquinarioEtiquetasItem/' + cellValues.row.id
    });
  };

  const handleChange = (value) => {
    setSelectedRows(value);
  }

  const deleteRow = (event) => {
    console.log(event.target.id);
  };

  const viewRow = (event) => {
    console.log(event.target.id);
  };




  /**
   * VARIAVEL COLUMNS
   * - Tipo Objeto 
   * - Elementos principais 
   * - - field (nome do campo), 
   * - - headerName( Label da Coluna )
   * - - headerClassName ( classe estilo definido no componente de retorno )
   * - - outros campos que podera sera usado de acordo com a documentação abaixo 
          field: string;
          renderCell: (cellValues: any) => JSX.Element;
          headerName?: undefined;
          headerClassName?: undefined;
          width?: undefined;
          flex?: undefined;
          minWidth?: undefined;
          editable?: undefined;
          type?: undefined;
          align?: undefined;
   * 
   * - Este deve ser formatado corretamenta para que possa enviar para o 
   * componente GridComponente.js
   */
  const columns = [
    {
      field: " Ações ",
      renderCell: (cellValues) => {
        return (

          <button type="button"
            className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            <i className="fas fa-pen"></i>
          </button>

        );
      }
    },

    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      minWidth: 40,
      align: 'left',
      flex: 15,
      renderCell: params => {
        return <div style={{ color: '#000' }} >
          {" "}
          {addZeroes(params.row.id, process.env.REACT_APP_ZEROES_GRID)}
        </div>;
      }
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      minWidth: 210,
      editable: false,
      flex: 50,
    },

    {
      field: 'status',
      headerName: 'Status',
      minWidth: 80,
      headerClassName: 'super-app-theme--header',
      type: 'number',
      editable: false,
      align: 'center',
      flex: 10,
      renderCell: params => {
        if (params.row.status === 1) {
          return <div style={{ color: '#29B606' }} > <i className="fas fa-check"></i> </div>;
        } else {
          return <div style={{ color: '#B6060F' }} > <i className="fas fa-times"></i> </div>;
        }
      },

    }
  ];

  const handleChangeUnidade = async (event) => {
    let valor = event.target.value;
    setSelectedUnidade(valor);
    setTipoUnidade(valor);
  };


  useEffect(() => {
    setTimeout(() => {
      api.get(`etiquetas`).then((response) => {
        setDataFilter(response.data.records);

        if ((tipoUnidade != 0)) {
          setData(response.data.records.filter(
            (base) => base.unidadeProducao.cgcCpf === tipoUnidade.toString()
          ));
        } else if ((tipoUnidade == 0)) {
          setData(response.data.records);
        }
      });
    }, 500);
  }, [tipoUnidade]);

  /* useEffect(() => {

    const getUnidade = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        
        setListUnidades(response.data.records);
        setSelectedUnidade(response.data.records[0].fantasia);
      });
    };
    getUnidade();
  }, []); */
  

  if (!data) return null;

  return (
    <div>
      <div>
        <form className="form-horizontal">
          <div className="row">
            <div className="d-flex flex-row ">
              <div className="col-xl-6 px-1 ">

                {/* <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Unidade de Produção
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 300 }}
                    label="Unidade de Produção"
                    value={selectedUnidade}
                    onChange={handleChangeUnidade}
                    spacing={3}
                  >
                    <MenuItem value="0">Selecione</MenuItem>
                    {listUnidades.sort((a, b) => {
                      if (a.fantasia > b.fantasia) return 1;
                      if (a.fantasia < b.fantasia) return -1;
                      return 0;
                    })
                      .map((item) => (
                        <MenuItem value={item.cgcCpf}>{item.fantasia}</MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              </div>
            </div>
          </div>
        </form>
      </div>


      {/* GRID COMPONENTE RECEBE OS DADOS E AS COLUNAS QUE SERAO RENDERIZADA */}
      <GridComponent data={data} columns={columns} />

    </div>


  );
}
