import { Box, Typography } from "@mui/material";
import { pecasContext } from "../../../contexts/PecasContext";
import { formatReal } from "../../../utils/utils";

export const TotalEstoque = () => {
  const { ListaTable } = pecasContext();


  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 2,
      }}
    >
      <Box>
        <Typography variant="body2">Total Itens</Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {ListaTable?.totalItens}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">R$ Total</Typography>
        <Typography
          variant="body1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: 200,
            fontWeight: "bold",
          }}
        >
          R$ {formatReal(ListaTable?.total)}
        </Typography>
      </Box>{" "}
    </Box>
  );
};
