import { MenuItem, Radio, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";

export const SubTipoEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [tipo, setTipo] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState([]);
  const [listTipo, setListTipo] = useState([]);
  const [nivelGravidade, setNivelGravidade] = useState("MEDIA");
  const [selectedGravidade, setSelectedGravidade] = useState("");
  const [descricaoError, setDescricaoError] = useState(false);

  const [status, setStatus] = useState("1");
  const [observacao, setObservacao] = useState();

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  /*Editar Registros*/
  const editDescricaoTipo = async (e) => {
    e.preventDefault();

    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          tipoChamado: selectedTipo,
          nivelGravidade: nivelGravidade,
          descricao: descricao,
          observacao: observacao,
          status: status,
        };
        console.log("DADOS UPDATE ", JSON.stringify(dadosJson));
        await api.put(`/tipoChamadoDescricao/` + id, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          id: id,
          tipoChamado: selectedTipo,
          nivelGravidade: nivelGravidade,
          descricao: descricao,
          observacao: observacao,
          status: status,
        };
        console.log("DADOS INSERCAO", JSON.stringify(dadosJson));

        await api.post(`/tipoChamadoDescricao/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }
      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/subtiposchamados",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na edição deste item",
      });
    }
  };

  const handleChangeTipo = async (event) => {
    let valor = event.target.value;
    setSelectedTipo(valor);
  };

  const handleChangeGravidade = (event) => {
    setNivelGravidade(event.target.value);
  };

  useEffect(() => {
    const getTipoDefeito = async () => {
      await api.get(`/tipoChamado`).then((response) => {
        setListTipo(response.data.records);
      });
    };

    const getSubTipo = async () => {
      await api.get(`/tipoChamadoDescricao/` + id).then((response) => {
        setDescricao(response.data.records[0].descricao);
        setStatus(response.data.records[0].status);
        setSelectedTipo(response.data.records[0].tipoChamado.id);
        setNivelGravidade(response.data.records[0].nivelGravidade);
      });
    };

    if (id !== "0") {
      getSubTipo();
    }
    getTipoDefeito();
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                <form className="form-horizontal" onSubmit={editDescricaoTipo}>
                  <div className="row">
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-6 col-form-label">
                          Categoria
                        </label>
                        <div>
                          <span className="p-float-label">
                            <Select
                              labelId="demo-simple-select-label"
                              id="idTipo"
                              sx={{ m: 1, minWidth: 250 }}
                              value={selectedTipo}
                              onChange={handleChangeTipo}
                              spacing={3}
                              clearable={"true"}
                              searchable={"true"}
                              noOptionsMessage={() => "Escolha do Tipo"}
                              required="required"
                            >
                              <MenuItem value="">Selecione</MenuItem>
                              {listTipo
                                .sort((a, b) => {
                                  if (a.tipo > b.tipo) return 1;
                                  if (a.tipo < b.tipo) return -1;
                                  return 0;
                                })
                                .map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.tipo}
                                  </MenuItem>
                                ))}
                            </Select>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="idTipo"
                          className="col-sm-6 col-form-label"
                        >
                          Nível Gravidade
                        </label>
                        <div>
                          <span className="p-float-label">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={nivelGravidade}
                              sx={{ m: 1, minWidth: 250 }}
                              onChange={handleChangeGravidade}
                              spacing={3}
                            >
                              <MenuItem id="text-warning" value={"MEDIA"}>
                                MÉDIA
                              </MenuItem>
                              <MenuItem id="text-primary" value={"BAIXA"}>
                                BAIXA
                              </MenuItem>
                              <MenuItem id="text-danger" value={"ALTA"}>
                                ALTA
                              </MenuItem>
                            </Select>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group was-valited">
                      <label
                        htmlFor="inputName"
                        className="col-sm-10 col-form-label"
                      >
                        Descrição do Sub Tipo de Chamado
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="descricao"
                        name="descricao"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        placeholder="Descricao ..."
                        required="required"
                        maxlength="25"
                      />
                      {descricao.length > 24 ? (
                        <p className="text-danger mt-2">
                          Atenção este campo só aceita 25 caracteres!
                        </p>
                      ) : (
                        ""
                      )}
                      <div className="valid-feedback">Validado.</div>
                      <div className="invalid-feedback">
                        Por favor, preencha esse campo.
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="row">
                      <label className="col-sm-12 col-form-label">
                        Status{" "}
                      </label>
                      <div className="col-sm-10">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Ativo
                        </FormLabel>
                        <Radio
                          checked={status == "1"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="1"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "1" }}
                        />
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Inativo
                        </FormLabel>
                        <Radio
                          checked={status == "0"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="0"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "0" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
