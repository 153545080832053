import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import Logo from "../../assets/democrata-logo.svg";
import api from "../../services/api";
import { login } from "../../services/auth";


import { Form, Container } from "./styles";
import { REACT_APP_ANO, REACT_APP_COPYRIGHT, REACT_APP_DESC_AMBIENTE } from "../../utils/constants";

class SignIn extends Component {
  state = {
    usuario: "",
    senha: "",
    error: ""
  };



  handleSignIn = async e => {
    e.preventDefault();
    const { usuario, senha } = this.state;

    if (!usuario || !senha) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else { // await api.put(`${process.env.REACT_APP_LINK_API_AUTH}/mecanico/` + id,
      try { //REACT_APP_LINK_API_AUTH

        /* this.setState({
          error:
            "O sistema está em Manutenção, a nossa equipe está realizando os ajustes previstos. Logo estaremos liberando para uso!"
        }); */

        const response = await api.post(`${process.env.REACT_APP_LINK_API_AUTH}/auth/`, { usuario, senha });

        login(
          response.data.records[0].token,
          response.data.records[0].id
        );
        this.props.history.push("/Home");

      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o seu Acesso, verifique suas credenciais."
        });
      }
    }
  };

  render() {
    return (

      <Container>
        <Form onSubmit={this.handleSignIn}>
          <img src={Logo} alt="Logo" />
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="usuario"
            placeholder="&#xF007; Usuário "
            onChange={e => this.setState({ usuario: e.target.value })}
          />
          <input
            type="password"
            placeholder="&#xF084; Senha "
            onChange={e => this.setState({ senha: e.target.value })}
          />
          <button type="submit">Login</button>
          <div className="footerLogin">
            © {REACT_APP_COPYRIGHT} / {REACT_APP_ANO} - {REACT_APP_DESC_AMBIENTE} 
          </div>

        </Form>

      </Container>
    );
  }
}

export default withRouter(SignIn);
