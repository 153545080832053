import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { GraficosEstatistica } from "./GraficosEstatistica";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { routesContext } from "../../contexts/RoutesContext";

export const FiltroEstatistica = () => {
  const { filterHistory, handleUpdateFilter } = routesContext();
  const [dateValue2, setDateValue2] = useState(dayjs().format("YYYY-MM-DD"));
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );

  const [listFabrica, setListFabrica] = useState([]);
  const [selectedFabrica, setSelectedFabrica] = useState([]);
  const [filteredFabrica, setFilteredFabrica] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState([]);
  const [listMecanico, setListMecanico] = useState([]);
  const [selectedMecanico, setSelectedMecanico] = useState([]);
  const [listGrupo, setListGrupo] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState(0);

  const fetchFilterUnidade = async () => {
    const response = await api.get(`unidadeproducao`);
    return response.data.records;
  };
  const { data: listUnidadeProducao } = useQuery("filter", fetchFilterUnidade, {
    refetchOnWindowFocus: false,
  });

  const handleChangeTipo = (event) => {
    let valor = event.target.value;
    setSelectedTipo(valor);
  };

  const handleChangeMecanico = (e) => {
    setSelectedMecanico(e.target.value);
  };

  useEffect(() => {
    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListFabrica(response.data.records);
        if (filterHistory.unidadeFabricacao) {
          const filteredFabrica = response.data.records.filter(
            (setor) =>
              setor.unidadeProducao.cgcCpf == filterHistory.unidadeFabricacao
          );
          setFilteredFabrica(filteredFabrica);
        }
      });
    };

    const getMecanicos = async () => {
      await api.get(`mecanico`).then((response) => {
        setListMecanico(response.data.records);
      });
    };
    const getGrupos = async () => {
      await api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
      });
    };

    Promise.all([
      /* getUnProducao(), */ getSetores(),
      getMecanicos(),
      getGrupos(),
    ]);
  }, [filterHistory.unidadeFabricacao, filterHistory.setor, tipoUnidade]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        minWidth: "100%",
        justifyContent: "center",
      }}
      p={2}
    >
      <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }}>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Unidade Produção
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Unidade Produção"
              value={filterHistory.unidadeFabricacao}
              onChange={(e) =>
                handleUpdateFilter(e.target.value, "unidadeFabricacao")
              }
            >
              <MenuItem value="">Selecione</MenuItem>
              {listUnidadeProducao &&
                listUnidadeProducao
                  .sort((a, b) => {
                    if (a.fantasia > b.fantasia) return 1;
                    if (a.fantasia < b.fantasia) return -1;
                    return 0;
                  })
                  .map((item) => (
                    <MenuItem font value={item.cgcCpf}>
                      {item.fantasia}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Setor
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Setor"
              value={filterHistory.setor}
              onChange={(e) => handleUpdateFilter(e.target.value, "setor")}
              spacing={3}
            >
              <MenuItem value="">Selecione</MenuItem>
              {filteredFabrica
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1;
                  if (a.descricao < b.descricao) return -1;
                  return 0;
                })
                .map((item) => (
                  <MenuItem value={item.id}>{item.descricao}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Grupo
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Grupo"
              value={filterHistory.grupo}
              onChange={(e) => handleUpdateFilter(e.target.value, "grupo")}
              spacing={3}
            >
              <MenuItem value="">Selecione</MenuItem>
              {listGrupo
                .sort((a, b) => {
                  if (a.descricao > b.descricao) return 1;
                  if (a.descricao < b.descricao) return -1;
                  return 0;
                })
                .map((item) => (
                  <MenuItem value={item.id}>{item.descricao}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={6} md={2} lg={2}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack>
              <DatePicker
                label="Data Inicial"
                value={filterHistory.dataInicial}
                onChange={(newValue) =>
                  handleUpdateFilter(
                    dayjs(newValue).format("YYYY-MM-DD"),
                    "dataInicial"
                  )
                }
                renderInput={(params) => <TextField {...params} />}
                dayOfWeekFormatter={(day) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} sm={6} md={2} lg={2}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="pt-br"
          >
            <Stack>
              <DatePicker
                label="Data Final"
                value={filterHistory.dataFinal}
                onChange={(newValue) =>
                  handleUpdateFilter(
                    dayjs(newValue).format("YYYY-MM-DD"),
                    "dataFinal"
                  )
                }
                renderInput={(params) => <TextField {...params} />}
                dayOfWeekFormatter={(day) => {
                  const daysOfWeek = [
                    "Dom",
                    "Seg",
                    "Ter",
                    "Qua",
                    "Qui",
                    "Sex",
                    "Sab",
                  ];
                  return daysOfWeek[day];
                }}
              />
            </Stack>
          </LocalizationProvider>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Mecânico
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Mecânico"
              value={selectedMecanico}
              onChange={handleChangeMecanico}
              spacing={3}
            >
              <MenuItem value="">Selecione</MenuItem>
              {listMecanico
                .sort((a, b) => a.usuario.nome.localeCompare(b.usuario.nome))
                .map((item) => (
                  <MenuItem value={item.usuario.nome}>
                    {item.usuario.nome}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Tipo de Dados
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select-label"
              label="Tipo de Dados"
              value={selectedTipo}
              onChange={handleChangeTipo}
              spacing={3}
            >
              <MenuItem value={0}>Total</MenuItem>
              <MenuItem value={1}>Porcentagem</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={2}>
          <FormControl sx={{ minWidth: 100, marginTop: 3 }}>
            <Button
              size="medium"
              onClick={handlePrint}
              variant="outlined"
              startIcon={<PdfIcon />}
            >
              Gerar PDF
            </Button>
          </FormControl>
        </Grid> */}
      </Grid>
      <GraficosEstatistica
        dataInicial={filterHistory.dataInicial}
        dataFinal={filterHistory.dataFinal}
        filterUnidade={filterHistory.unidadeFabricacao}
        filterSetor={filterHistory.setor}
        filterMecanico={selectedMecanico}
        filterGrupo={filterHistory.grupo}
        unidade={listUnidadeProducao}
        fabrica={listFabrica}
        mecanico={listMecanico}
        grupo={listGrupo}
        filterTipo={selectedTipo}
      />
    </Box>
  );
};
