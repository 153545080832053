import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/SearchRounded";
import api from "../../../services/api";
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Stack } from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import dayjs from "dayjs";
import { addZeroes, formatarData } from "../../../utils/utils";
import { async } from "q";

export const AgendamentosGrid = () => {
  const [data, setData] = useState([]);

  const dadosFiltros = async () => {
    let data = JSON.stringify(value);
    let datafinal = JSON.stringify(valueFim);

    setDateValue1(data.slice(1, 11));
    setDateValue2(datafinal.slice(1, 11));

    console.log(dateValue1);
    console.log(dateValue2);
  };

  const [value, setValue] = useState(dayjs(new Date()).subtract(30, "day"));
  const [valueFim, setValueFim] = useState(dayjs(new Date()));
  const [dateValue1, setDateValue1] = useState([]);
  const [dateValue2, setDateValue2] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [listSetor, setListSetor] = useState([]);

  const handleChangeSetor = async (event) => {
    let val = event.target.value;
    setSelectedSetor(val);
  };

  useEffect(() => {
    api.get(`agendamento`).then((response) => {
      setData(response.data.records);
    });
    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
      });
    };

    dadosFiltros();
    getSetores();
  }, [dateValue1, dateValue2]);

  return (
    <div>
      <div className="card-header border-0">
        <div>
          <form className="form-horizontal">
            <div className="row">
              <div className="col-sm-2 col-md-2">
                <div className="form-group ">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Inicial"
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
              
              <div className="col-sm-2 col-md-2">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="pt-br"
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label="Data Final"
                        value={valueFim}
                        onChange={(newValue) => {
                          setValueFim(newValue);
                          dadosFiltros();
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        dayOfWeekFormatter={(day) => {
                          const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                          return daysOfWeek[day];
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                </div>
              
              <div className="d-flex flex-wrap">
                <div className="col-sm-3 col-md-3">
                  <div className="form-group">
                    <Stack spacing={3}>
                      <FormControl>
                        <InputLabel id="demo-controlled-open-select-label">
                          Setor
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id={"demo-controlled-open-select-label"}
                          sx={{ m: 0, minWidth: 250 }}
                          label="Setor"
                          value={selectedSetor}
                          onChange={handleChangeSetor}
                          spacing={3}
                        >
                          <MenuItem value="Todos">Todos</MenuItem>
                          {listSetor
                            .sort((a, b) => {
                              if (a.descricao > b.descricao) return 1;
                              if (a.descricao < b.descricao) return -1;
                              return 0;
                            })
                            .map((item) => (
                              <MenuItem value={item.id}>
                                {item.descricao}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-sm-3 col-md-3">
                  <div className="form-group">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Status Agendamento
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        sx={{ m: 0, width: 250 }}
                        label="Tipo de Chamado"
                        //value={tipoChamadoDesc}
                        //onChange={handleChangeTipoChamadoDesc}
                        spacing={3}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        <MenuItem value="BAIXA">ABERTO</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
              <div className="col-sm-1 col-md-1">
                <FormControl>
                  <Stack spacing={3}>
                    <Button
                      size="large"
                      style={{ height: "55px" }}
                      onClick={dadosFiltros}
                      variant="contained"
                      endIcon={<SearchIcon />}
                    ></Button>
                  </Stack>
                </FormControl>
              </div>
            </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card-body table-responsive p-0">
        <table className="table table-striped table-valign-middle">
          <thead>
            <tr>
              <th>ID-PAT</th>
              <th>Descrição</th>
              <th>Setor</th>
              <th>Responsável</th>
              <th>Data Inicial</th>
              <th>Data Final</th>
              <th>Status Agendamento</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(
                (reg) =>
                  reg.dataAgendada >= dateValue1 &&
                  reg.dataAgendada <= dateValue2 &&
                  (selectedSetor == "" ||
                    reg.maquinario.maquinarioSetores[0].setores.id ==
                      selectedSetor)
              )
              .sort((a, b) => {
                if (a.dataAgendada > b.dataAgendada) return 1;
                if (a.dataAgendada < b.dataAgendada) return -1;
                return 0;
              })
              .map((item) => {
                return (
                  <tr key={item.id}>
                    <td style={{ cursor: "pointer" }}>
                      <a href={`agendamentoItem/${item.id}`}>
                        {addZeroes(item.id, 6)}
                      </a>
                    </td>
                    <td>{item.maquinario.descricao}</td>
                    <td>
                      {item.maquinario.maquinarioSetores[0].setores.descricao}
                    </td>
                    <td>
                      {
                        item.maquinario.maquinarioSetores[0].setores.responsavel
                          .nome
                      }
                    </td>

                    <td>{formatarData(item.dataAgendada)}</td>
                    <td>{formatarData(item.dataAgendada2)}</td>

                    <td>Aberto</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
