import { Box } from "@mui/material";
import FiltrosPecas from "./components/filtros_Pecas";
import GridComponentePecas from "./components/gridComponentePecas";

export const PecasSolicitadas = () => {
  return (
    <Box sx={{ margin: 2, display: "grid", gap: "10px" }}>
      <FiltrosPecas />

      {/* <Box>
        <GridComponentePecas />
      </Box> */}
    </Box>
  );
};
