import axios from "axios";
import { getToken } from "./auth";

const apiUpdate = axios.create({
  baseURL: process.env.REACT_APP_ATUALIZACAO_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

apiUpdate.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



export default apiUpdate;
