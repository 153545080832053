import { Typography } from "@mui/material";
import React from "react";

export const LegendFilter = ({
  unidade,
  setor,
  grupo,
  filterUnidade,
  filterSetor,
  filterGrupo,
}) => {
  const listUnidade = unidade.map((item) => {
    if (filterUnidade == item.cgcCpf) {
      return (
        <Typography variant="h5" key={item.cgcCpf}>
          <b>Unidade Produção: </b> {item.cidade}
        </Typography>
      );
    }
  });

  const listSetor = setor.map((item) => {
    if (filterSetor == item.id) {
      return (
        <Typography variant="h5" key={item.id}>
          <b>Setor: </b> {item.descricao}
        </Typography>
      );
    }
  });

  const listGrupo = grupo.map((item) => {
    if (filterGrupo == item.id) {
      return (
        <Typography variant="h5" key={item.id}>
          <b>Grupo: </b> {item.descricao}
        </Typography>
      );
    }
  });

  return (
    <div className="row d-flex justify-content-center">
      <div className="p-1">{listUnidade}</div>
      <div className="p-1">{listSetor}</div>
      <div className="p-1">{listGrupo}</div>
    </div>
  );
};
