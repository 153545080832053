import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Dashboard from "../../components/Dashboard";
import Footer from "../../components/Footer";
import { FilterProvider } from "../../components/controles/listModal/andamentos/FilterContext";

class Home extends Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <Dashboard />
        <Footer />
      </div>
    );
  }
}

export default Home;
