import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import LogoPatrimonio from "../../../assets/logoPatrimonio.svg";
import Logo from "../../../assets/democrata-logo.svg";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import "./style.css";

import { PDFExport } from "@progress/kendo-react-pdf";

import { addZeroes, formatarData } from "../../../utils/utils";
import { REACT_APP_TOTAL_GRID } from "../../../utils/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import VisualizaChamados from "../../Kanban/Chamados";

export const MaquinarioPrint = () => {
  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );
  const [listGrupo, setListGrupo] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState([]);

  const [statusReg, setStatusReg] = useState(1);
  const [contagemItens, setContagemItens] = useState(1);

  const [selectedUnidadeProducao, setSelectedUnidadeProducao] = useState([]);
  const [listUnidadeProducao, setListUnidadeProducao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [listSetor, setListSetor] = useState([]);

  const [data, setData] = useState([]);
  const [dataChamado, setDataChamado] = useState([]);
  const [buscaGenerica, setBuscaGenerica] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRegistro, setTotalRegistro] = useState(0);
  const [fantasia, setFantasia] = useState("");
  const [setor, setSetor] = useState("");
  const [descrGrupo, setDescrGrupo] = useState("");
  const [tipoRel, setTipoRel] = useState("PAT");
  const [filteredSetores, setFilteredSetores] = useState([]);
  const [vinculoSetor, setVinculoSetor] = useState("S");
  const [checked, setChecked] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedChamadoId, setSelectedChamadoId] = useState(null);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  let limit = parseInt(REACT_APP_TOTAL_GRID);

  const handleOpenModal = (chamadoId) => {
    setModalIsOpen(!modalIsOpen);
    setSelectedChamadoId(chamadoId);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedChamadoId(null);
  };

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Main content */}
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  useEffect(() => {
    setData([]);

    const getUnProducao = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidadeProducao(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);

        if (selectedUnidadeProducao) {
          const filteredSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == selectedUnidadeProducao
          );
          setFilteredSetores(filteredSetores);
        }
      });
    };

    const getGrupos = async () => {
      await api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
      });
    };

    const getDadosBuscaGenerica = () => {
      let pagina = currentPage == 0 ? 1 : currentPage;
      let stringBase = ``;
      let stringBuscaID = "";

      setData([]);

      if (buscaGenerica.length >= 3) {
        if (tipoRel == "PAT") {
          //patrimonio
          stringBuscaID = `id=` + buscaGenerica;
        } else if (tipoRel == "ANT") {
          //patrimonio antigo
          stringBuscaID = `numPatrimonioAdd=` + buscaGenerica;
        } else if (tipoRel == "DES") {
          //descricao
          stringBuscaID = `descricao=` + buscaGenerica.toUpperCase();
        }
        stringBase = `maquinario/busca?` + stringBuscaID + "&page=" + pagina;
      } else {
        stringBase = "";
      }

      api.get(stringBase).then((response) => {
        setData(response.data.records);
        const total = response.data.meta.recordCount;

        setTotalRegistro(total);
      });
    };

    const getDados = () => {
      let pagina = currentPage == 0 ? 1 : currentPage;
      let stringBase = "";
      let stringGrupo = "";
      let stringStatus = "";
      let stringBuscaID = "";
      let stringVinculo = "";
      let stringUnidadeProducao = "";
      let stringSetor = "";

      setData([]);

      if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao != 0 &&
        selectedSetor != 0
      ) {
        stringStatus = `status=` + statusReg;
        stringGrupo = `grupo=` + selectedGrupo;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/busca?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&" +
          stringUnidadeProducao +
          "&" +
          stringSetor;
      }
      //se for UNIDADE e STATUS
      else if (
        selectedUnidadeProducao != 0 &&
        selectedGrupo == 0 &&
        selectedSetor == 0
      ) {
        stringStatus = `status=` + statusReg;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;

        stringBase =
          `maquinario/busca?` + stringStatus + "&" + stringUnidadeProducao;
      }
      //se for GRUPO e SETOR e STATUS
      else if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao == 0 &&
        selectedSetor != 0
      ) {
        stringGrupo = `grupo=` + selectedGrupo;
        stringStatus = `status=` + statusReg;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/busca?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&" +
          stringSetor;
      }
      //se for UNIDADE, GRUPO e STATUS
      else if (
        selectedUnidadeProducao != 0 &&
        selectedGrupo != 0 &&
        selectedSetor == 0
      ) {
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringGrupo = `grupo=` + selectedGrupo;
        stringStatus = `status=` + statusReg;

        stringBase =
          `maquinario/busca?` +
          stringUnidadeProducao +
          "&" +
          stringGrupo +
          "&" +
          stringStatus;
      }
      //se for setor e status e unidade
      else if (
        selectedGrupo == 0 &&
        selectedUnidadeProducao != 0 &&
        selectedSetor != 0
      ) {
        stringStatus = `status=` + statusReg;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/busca?` +
          stringStatus +
          "&" +
          stringUnidadeProducao +
          "&" +
          stringSetor +
          "&page=" +
          pagina;
      } else if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao == 0 &&
        selectedSetor == 0
      ) {
        stringStatus = `status=` + statusReg;
        stringGrupo = `grupo=` + selectedGrupo;
        stringBase =
          `maquinario/busca?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&page=" +
          pagina;
      } else {
        stringBase = "";
      }

      if (vinculoSetor == "S") {
        stringVinculo = "&vinculoSetor=S";
      } else {
        stringVinculo = "&vinculoSetor=N";
      }
      api.get(stringBase + stringVinculo).then((response) => {
        setData(response.data.records);
        const total = response.data.meta.recordCount;

        setTotalRegistro(total);
      });
    };

    const getDadosChamados = () => {
      let pagina = currentPage == 0 ? 1 : currentPage;
      let stringBase = "";
      let stringGrupo = "";
      let stringStatus = "";
      let stringBuscaID = "";
      let stringVinculo = "";
      let stringUnidadeProducao = "";
      let stringSetor = "";

      setDataChamado([]);

      if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao != 0 &&
        selectedSetor != 0
      ) {
        stringStatus = `status=` + statusReg;
        stringGrupo = `grupo=` + selectedGrupo;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/relatorio-completo?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&" +
          stringUnidadeProducao +
          "&" +
          stringSetor;
      }
      //se for UNIDADE e STATUS
      else if (
        selectedUnidadeProducao != 0 &&
        selectedGrupo == 0 &&
        selectedSetor == 0
      ) {
        stringStatus = `status=` + statusReg;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;

        stringBase =
          `maquinario/relatorio-completo?` +
          stringStatus +
          "&" +
          stringUnidadeProducao;
      }
      //se for GRUPO e SETOR e STATUS
      else if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao == 0 &&
        selectedSetor != 0
      ) {
        stringGrupo = `grupo=` + selectedGrupo;
        stringStatus = `status=` + statusReg;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/relatorio-completo?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&" +
          stringSetor;
      }
      //se for UNIDADE, GRUPO e STATUS
      else if (
        selectedUnidadeProducao != 0 &&
        selectedGrupo != 0 &&
        selectedSetor == 0
      ) {
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringGrupo = `grupo=` + selectedGrupo;
        stringStatus = `status=` + statusReg;

        stringBase =
          `maquinario/relatorio-completo?` +
          stringUnidadeProducao +
          "&" +
          stringGrupo +
          "&" +
          stringStatus;
      }
      //se for setor e status e unidade
      else if (
        selectedGrupo == 0 &&
        selectedUnidadeProducao != 0 &&
        selectedSetor != 0
      ) {
        stringStatus = `status=` + statusReg;
        stringUnidadeProducao = `unidadeProducao=` + selectedUnidadeProducao;
        stringSetor = `setor=` + selectedSetor;

        stringBase =
          `maquinario/relatorio-completo?` +
          stringStatus +
          "&" +
          stringUnidadeProducao +
          "&" +
          stringSetor +
          "&page=" +
          pagina;
      } else if (
        selectedGrupo != 0 &&
        selectedUnidadeProducao == 0 &&
        selectedSetor == 0
      ) {
        stringStatus = `status=` + statusReg;
        stringGrupo = `grupo=` + selectedGrupo;
        stringBase =
          `maquinario/relatorio-completo?` +
          stringStatus +
          "&" +
          stringGrupo +
          "&page=" +
          pagina;
      } else if (buscaGenerica.length >= 3) {
        if (tipoRel == "PAT") {
          stringBuscaID = `id=` + buscaGenerica;
        } else if (tipoRel == "ANT") {
          stringBuscaID = `numPatrimonioAdd=` + buscaGenerica;
        } else if (tipoRel == "DES") {
          stringBuscaID = `descricao=` + buscaGenerica.toUpperCase();
        }
        stringBase =
          `maquinario/relatorio-completo?` + stringBuscaID + "&page=" + pagina;
      } else {
        stringBase = "";
      }

      if (vinculoSetor == "S") {
        stringVinculo = "&vinculoSetor=S";
      } else {
        stringVinculo = "&vinculoSetor=N";
      }

      api.get(stringBase + stringVinculo).then((response) => {
        setDataChamado(response.data.records[0].relatorioCompleto);
        
        const total = response.data.records[0].relatorioCompleto.length;

        setTotalRegistro(total);
      });
    };

    getGrupos();
    getUnProducao();
    getSetores();
    checked ? getDadosChamados() : getDados();
    getDadosBuscaGenerica();
  }, [
    selectedGrupo,
    selectedSetor,
    selectedUnidadeProducao,
    statusReg,
    limit,
    currentPage,
    buscaGenerica,
    tipoRel,
    checked,
  ]);

  const handleChangeVinculo = async (event) => {
    event.preventDefault();
    let valor = event.target.value;
    setCurrentPage(0);
    setVinculoSetor(valor);

    setPagina(currentPage);
  };

  const handleChangeUnidadeProducao = async (event) => {
    let valor = event.target.value;
    setSelectedUnidadeProducao(valor);

    if (valor != 0) {
      let label = listUnidadeProducao.find((option) => option.cgcCpf === valor);

      setFantasia(label.fantasia);
    }
    setBuscaGenerica("");
    setSelectedSetor("");
    setSelectedGrupo("");
  };

  const handleChangeSetor = async (event) => {
    let valor = event.target.value;
    setSelectedSetor(valor);
    if (valor != 0) {
      let labelSetor = listSetor.find((option) => option.id == valor);

      setSetor(labelSetor.descricao);
      setSelectedGrupo("");
    }
    setBuscaGenerica("");
    setSelectedGrupo("");
  };

  const handleChangeGrupo = async (event) => {
    let valor = event.target.value;

    {
      selectedUnidadeProducao != ""
        ? setSelectedGrupo(valor)
        : setSelectedGrupo("");
      if (valor != 0) {
        let labelGrupo = listGrupo.find((option) => option.id == valor);
        setDescrGrupo(labelGrupo.descricao);
      }
    }
    setBuscaGenerica("");
  };

  const handleChangeStatus = (event) => {
    let valor = event.target.value;
    setStatusReg(valor);
    setBuscaGenerica("");
  };

  const handleChangeBuscaGenerica = (event) => {
    const buscaSelecionada = event.target.value;
    setBuscaGenerica(buscaSelecionada);
    setSelectedSetor("");
    setSelectedGrupo("");
    setSelectedUnidadeProducao("");
  };

  const handleChangeManut = (event) => {
    setChecked(event.target.checked);
  };

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  const tipoBusca = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };

  const fetchComments = async (currentPage) => {
    let data = "";
    await api.get(`maquinario?page=${currentPage}`).then((response) => {
      data = response.data.records;
    });
    return data;
  };

  const handlePageClick = async (data) => {
    let _currentPage = data.selected + 1;

    setCurrentPage(_currentPage);
  };

  const htmlFormat = () => {
    return (
      <>
        {/* <div className="container-fluid"> */}
        <div className="row">
          <div className="col-12">
            {/* Main content */}
            <div className="invoice p-3 mb-3">
              {/* title row */}
              <div className="row">
                <div className="col-12">
                  <h4>
                    <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                    <small className="float-right">
                      <img style={{ width: "190px" }} src={LogoPatrimonio} />
                    </small>
                  </h4>
                  <h5></h5>
                </div>
                {/* /.col */}
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <h4>
                    Máquinas / Patrimônio
                    <small className="float-right">
                      Total de Itens <FormLabel>{totalRegistro}</FormLabel>
                    </small>
                    <br />
                    <div style={{ fontSize: 14 }}>
                      {listUnidadeProducao != "" &&
                      selectedSetor != 0 &&
                      selectedGrupo != "" &&
                      statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Setor: ${setor}  /  Grupo: ${descrGrupo}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" &&
                          selectedSetor != 0 &&
                          statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Setor: ${setor}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" &&
                          selectedGrupo != "" &&
                          statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Grupo: ${descrGrupo}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : selectedGrupo != ""
                        ? `Grupo: ${descrGrupo} / Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" && statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != ""
                        ? `Unidade de Produção: ${fantasia}`
                        : ""}
                    </div>
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "16%", textAlign: "left"  }}>Controle</th>
                        <th style={{ width: "18%", textAlign: "left"  }}>Patrimônio</th>
                        <th style={{ width: "15%", textAlign: "left"  }}>Pat. Antigo</th>
                        <th style={{ width: "50%", textAlign: "left" }}>Descrição</th>
                        <th style={{ width: "20%", textAlign: "left" }}>Setor</th>
                        <th style={{ width: "30%", textAlign: "left" }}>Uni.Produção</th>
                        <th style={{ width: "30%", textAlign: "left" }}>Grupo</th>
                        <th style={{ width: "18%" }}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((reg) => (
                        <tr key={reg.id}>
                          <td>{addZeroes(reg.id, 6)}</td>
                          <td>{reg.numPatrimonio}</td>
                          <td>{reg.numPatrimonioAdd}</td>
                          <td style={{ textAlign: "left" }}>{reg.descricao}</td>
                          <td style={{ textAlign: "left" }}>{reg.maquinarioSetores[0]?.setores.descricao}</td>
                          <td style={{ textAlign: "left" }}>{reg.maquinarioSetores[0]?.setores.unidadeProducao.fantasia}</td>
                          <td style={{ textAlign: "left" }}>{reg.grupo.descricao}</td>
                          <td>{reg.status == 1 ? (" Ativo") :reg.status == 2 ? (
                          <span className="badge bg-danger">Manutenção{" "}
                            <i class="fa fa-duotone fa-gears"></i>
                          </span>
                            ) : (
                              " Inativo "
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
      </>
    );
  };

  const htmlManut = () => {
    let contador = dataChamado.filter((reg) => reg.chamados !== null).length;
    return (
      <>
        <div className="row">
          <div className="col-12">
            {/* Main content */}
            <div className="invoice p-3 mb-3">
              {/* title row */}
              <div className="row">
                <div className="col-12">
                  <h4>
                    <img style={{ width: "190px" }} src={Logo} alt="Logo" />
                    <small className="float-right">
                      <img style={{ width: "190px" }} src={LogoPatrimonio} />
                    </small>
                  </h4>
                  <h5></h5>
                </div>
                {/* /.col */}
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <h4>
                    Máquinas / Patrimônio
                    <small className="float-right">
                      Total de Itens <FormLabel>{contador}</FormLabel>
                    </small>
                    <br />
                    <div style={{ fontSize: 14 }}>
                      {listUnidadeProducao != "" &&
                      selectedSetor != 0 &&
                      selectedGrupo != "" &&
                      statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Setor: ${setor}  /  Grupo: ${descrGrupo}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" &&
                          selectedSetor != 0 &&
                          statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Setor: ${setor}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" &&
                          selectedGrupo != "" &&
                          statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Grupo: ${descrGrupo}  /  Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : selectedGrupo != ""
                        ? `Grupo: ${descrGrupo} / Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != "" && statusReg != ""
                        ? `Unidade de Produção: ${fantasia} / Status: ${
                            statusReg == 1
                              ? "Ativo"
                              : statusReg == 2
                              ? "Em Manutenção"
                              : statusReg == 0
                              ? "Inativo"
                              : ""
                          }`
                        : listUnidadeProducao != ""
                        ? `Unidade de Produção: ${fantasia}`
                        : ""}
                    </div>
                  </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12 table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "15%", textAlign: "left" }}>N.Pat</th>
                        <th style={{ width: "20%" }}>Total Chamado</th>
                        <th style={{ width: "50%", textAlign: "left" }}>Descrição</th>
                        <th style={{ width: "30%", textAlign: "left" }}>Setor</th>
                        <th style={{ width: "50%", textAlign: "left" }}>Unid.Produção</th>
                        <th style={{ width: "50%", textAlign: "left" }}>Status</th>
                      </tr>
                    </thead>

                    {dataChamado
                      .filter((pat) => pat.chamados !== null)
                      .map((reg) => (
                        <React.Fragment key={reg.maquinario.id}>
                          <tbody>
                            <tr
                              style={{
                                backgroundColor: `${
                                  reg.chamados && reg.chamados.length > 0
                                    ? "#E0E2FA"
                                    : "#FFFFFF"
                                }`,
                              }}
                            >
                              <td style={{ textAlign: "left" }}>{addZeroes(reg.maquinario.id, 6)}</td>
                              <td>{reg.total != null ? reg.total : "0"}</td>
                              <td style={{ textAlign: "left" }}>{reg.maquinario.descricao}</td>
                              <td style={{ textAlign: "left" }}>{reg.maquinario.maquinarioSetores[0].setores.unidadeProducao.fantasia}</td>
                              <td style={{ textAlign: "left" }}>{reg.maquinario.maquinarioSetores[0].setores.descricao}</td>
                              <td style={{ textAlign: "left" }}>{reg.maquinario.status == 1 ? "Ativo" : "Inativo"}</td>
                            </tr>
                          </tbody>
                          {reg.chamados && reg.chamados.length > 0 && (
                            <>
                              <thead>
                                <tr style={{ backgroundColor: "#E0E2FA" }}>
                                  <th style={{width: "15%", backgroundColor: "#FFFFFF"}}>{""}</th>
                                  <th style={{width: "10%", backgroundColor: "#FFFFFF"}}>{""}</th>
                                  <th style={{width: "15%", textAlign: "left" }}>Chamado</th>
                                  <th style={{ width: "40%", textAlign: "left" }}>Problema</th>
                                  <th style={{ width: "10%", textAlign: "left" }}>Abertura</th>
                                  <th style={{ width: "10%", textAlign: "left" }}>Mecânico</th>
                                </tr>
                              </thead>

                              {reg.chamados.map((chamado) => (
                                <tbody>
                                  <tr style={{ backgroundColor: "#F2F2F2" }}>
                                    <td
                                      style={{
                                        width: "15%",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                    >
                                      {""}
                                    </td>
                                    <td
                                      style={{
                                        width: "10%",
                                        backgroundColor: "#FFFFFF",
                                      }}
                                    >
                                      {""}
                                    </td>
                                    <td
                                      style={{
                                        width: "15%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <Link
                                        onClick={() =>
                                          handleOpenModal(chamado.id)
                                        }
                                      >
                                        {addZeroes(chamado.id, 6)}
                                      </Link>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {chamado.motivoAbertura}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {formatarData(chamado.dataAbertura)}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      {chamado.mecanico?.usuario.nome}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                              <div className="text-white">{" . "}</div>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={modalIsOpen}
            onClose={handleCloseModal}
          >
            <DialogTitle></DialogTitle>

            <DialogContent>
              <DialogContentText></DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: "auto",
                  width: "fit-content",
                }}
              >
                <VisualizaChamados selecionar={selectedChamadoId} />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Fechar</Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  };

  const htmlNull = () => {
    return (
      <>
        <div className="col-12">
          <div className="invoice p-3 mb-3">
            <div className="col-md-12">
              <div className="info-box bg-light">
                <div className="info-box-content">
                  <span className="info-box-text text-center text-muted">
                    Não Há Informações{" "}
                  </span>
                  <span className="info-box-number text-center text-muted mb-0">
                    Defina os Critérios de Pesquisa acima!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  //if (!dataMaquinas) return null;

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="form-horizontal">
                <div>
                  <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-controlled-open-select-label">
                      Unidade Produção
                    </InputLabel>
                    <Select
                      /* labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label" */
                      /* sx={{ m: 1 }} */
                      label="Unidade Produção"
                      value={selectedUnidadeProducao}
                      onChange={handleChangeUnidadeProducao}
                      spacing={3}
                    >
                      <MenuItem value="0">Selecione</MenuItem>
                      {listUnidadeProducao
                        .sort((a, b) => {
                          if (a.fantasia > b.fantasia) return 1;
                          if (a.fantasia < b.fantasia) return -1;
                          return 0;
                        })
                        .map((item) => (
                          <MenuItem font value={item.cgcCpf}>
                            {item.fantasia}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <InputLabel id="demo-controlled-open-select-label">
                      Setor
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label"
                      /* sx={{ m: 1 }} */
                      label="Setor"
                      value={selectedSetor}
                      onChange={handleChangeSetor}
                      spacing={3}
                    >
                      <MenuItem value="0">Selecione</MenuItem>
                      {filteredSetores
                        .sort((a, b) => {
                          if (a.descricao > b.descricao) return 1;
                          if (a.descricao < b.descricao) return -1;
                          return 0;
                        })
                        .map((item) => (
                          <MenuItem value={item.id}>{item.descricao}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="demo-controlled-open-select-label">
                      Grupo
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label"
                      /* sx={{ m: 1, minWidth: 250 }} */
                      label="Grupo"
                      value={selectedGrupo}
                      onChange={handleChangeGrupo}
                      spacing={3}
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      {listGrupo
                        .sort((a, b) => {
                          if (a.descricao > b.descricao) return 1;
                          if (a.descricao < b.descricao) return -1;
                          return 0;
                        })
                        .map((item) => (
                          <MenuItem value={item.id}>{item.descricao}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      Vinculado ao Setor
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label"
                      sx={{ m: 1, minWidth: 120, boxSizing: "border-box" }}
                      label="Vinculado"
                      value={vinculoSetor}
                      onChange={handleChangeVinculo}
                      spacing={3}
                    >
                      <MenuItem value="S">Vinculado</MenuItem>
                      <MenuItem value="N">Não Vinculado</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-controlled-open-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label"
                      /* sx={{ m: 1, minWidth: 150 }} */
                      label="Status"
                      value={statusReg}
                      onChange={handleChangeStatus}
                      spacing={3}
                    >
                      <MenuItem value="1">Ativo</MenuItem>
                      <MenuItem value="0">Inativo</MenuItem>
                      <MenuItem value="2">Em Manutenção</MenuItem>
                    </Select>
                  </FormControl>

                  {/* <div className="col-sm-1, text-right">
                    <Button onClick={handleExport}>
                      <i className="fa fa-file-pdf btn btn-info"> Gerar PDF</i>
                    </Button>
                  </div> */}
                </div>
              </form>
              <form className="form-horizontal">
                <div>
                  <FormControl sx={{ m: 1, minWidth: 250 }}>
                    <TextField
                      id="busca"
                      label="Buscar"
                      value={buscaGenerica}
                      type="search"
                      onChange={handleChangeBuscaGenerica}
                      variant="filled"
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 120, marginTop: 3 }}>
                    <RadioGroup
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 24,
                        },
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={tipoRel}
                      onChange={tipoBusca}
                    >
                      <FormControlLabel
                        value="PAT"
                        control={<Radio />}
                        label="Patrimonio"
                        sx={{
                          color: "#5bc0de",
                        }}
                      />

                      <FormControlLabel
                        value="ANT"
                        control={<Radio />}
                        label="Pat.Antigo"
                        sx={{
                          color: "#d9534f",
                        }}
                      />
                      <FormControlLabel
                        value="DES"
                        control={<Radio />}
                        label="Descrição"
                        sx={{
                          color: "#5cb85c",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    sx={{
                      minWidth: 100,
                      marginTop: 4,
                      marginRight: 2,
                      marginLeft: 2,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            checked={checked}
                            onChange={handleChangeManut}
                          />
                        }
                        label=" Hist.Manutenção"
                      />
                    </FormGroup>
                  </FormControl>

                  <FormControl sx={{ minWidth: 100, marginTop: 3 }}>
                    <Button
                      size="medium"
                      onClick={handleExport}
                      variant="outlined"
                    >
                      Gerar PDF
                    </Button>
                  </FormControl>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {(() => {
          if (checked) {
            if (dataChamado.length == 0) {
              return htmlNull();
            } else {
              return (
                <PDFExport
                  scale={0.5}
                  paperSize={"A4"}
                  margin="0.8cm"
                  fileName="maquinario_manutencoes.pdf"
                  forcePageBreak=".page-break"
                  pageTemplate={PDFExportPageTemplate}
                  ref={pdf}
                >
                  {htmlManut()}
                </PDFExport>
              );
            }
          } else {
            if (data.length == 0) {
              return htmlNull();
            } else {
              return (
                <PDFExport
                  scale={0.5}
                  landscape="true"
                  paperSize={"A4"}
                  margin="0.8cm"
                  fileName="maquinario_.pdf"
                  forcePageBreak=".page-break"
                  pageTemplate={PDFExportPageTemplate}
                  ref={pdf}
                >
                  {htmlFormat()}
                </PDFExport>
              );
            }
          }
        })()}
      </div>
    </div>
  );
};
