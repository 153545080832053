import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Link } from "react-router-dom";

const CardDashboardAgendado = () => {
  
  return (

    <>

    <Link to="/kanban" className="">
            <div className="card">
              <div className="">
                <div className="card-body">
                  <div className="media d-flex">
                    <div className="align-self-justify"><br/>
                      <i className="icon-calendar warning font-medium-2 float-left"/>
                    </div>
                     
                    <div className="media-body text-right text-center" style={{ height: 53 }}>
                      <span>
                      <a style={{ color: "blue", fontSize: 13 }}></a><br/>MOVIMENTO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
    
    
    </>

    
  );
};

export default CardDashboardAgendado;
