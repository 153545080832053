import React, { Component } from 'react';
import { SideBarMecanico } from './controles/sideBarMecanico';
import { REACT_APP_ANO, REACT_APP_COPYRIGHT, REACT_APP_DESC_AMBIENTE } from '../utils/constants';



class Footer extends Component {
  render() {
    return (
      <div>
        {/* <aside className="control-sidebar control-sidebar-dark">}
{/* Control sidebar content goes here */}

        {/* <div><SideBarMecanico/></div> */}

        {/* </aside> */}



        <footer className="main-footer">
          <strong>{REACT_APP_COPYRIGHT} / {REACT_APP_ANO} </strong>
          <div className="float-right d-none d-sm-inline-block">
          <b>{REACT_APP_DESC_AMBIENTE}</b>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
