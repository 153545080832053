// RouteContext.js
import dayjs from 'dayjs';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RouteContext = createContext();

export const useRouteContext = () => useContext(RouteContext);

export const RoutesProvider = ({ children }) => {
  const [filterHistory, setFilterHistory] = useState({
    unidadeFabricacao: "",
    setor: "",
    grupo: "",
    mecanico: "",
    tipoDados: "",
    dataInicial: dayjs().subtract(20, "day").format("YYYY-MM-DD"),
    dataFinal: dayjs().format("YYYY-MM-DD"),
  })

  const clearFilters = () => {
    setFilterHistory({
      unidadeFabricacao: "",
      setor: "",
      grupo: "",
      mecanico: "",
      tipoDados: "",
    })
  }

  const handleUpdateFilter = (value, field) => {
    setFilterHistory({
      ...filterHistory,
      [field]: value
    })
  }


  const location = useLocation();
  const route = location.pathname;
  const mainRoute = route.split("/")[1];

  const [currentRoute, setCurrentRoute] = useState(mainRoute);

  const updateRoute = (newRoute) => {
    setCurrentRoute(newRoute);
  };


  useEffect(() => {
    if (mainRoute !== "estatisticaGeral" && mainRoute !== "historicoMecanicos"
     && mainRoute !== "resultadoChamadosItem" && mainRoute !== "maquinarioHistorico") {
      clearFilters()
    }
  }, [mainRoute]);

  
  useEffect(() => {
    setFilterHistory({
      ...filterHistory,
      setor: "",
      grupo: "",
    });
  },[filterHistory.unidadeFabricacao])

  useEffect(() => {
    setFilterHistory({
      ...filterHistory,
      grupo: "",
    });
  },[filterHistory.setor])



  return (
    <RouteContext.Provider value={{ currentRoute, updateRoute, mainRoute,  handleUpdateFilter, filterHistory }}>
      {children}
    </RouteContext.Provider>
  );
};

export const routesContext = () => {
  return useContext(RouteContext);
};
