import React, { useEffect, useState } from "react";
import { Statistic } from "./Statistic";
import {
  Box,
  FormControl,
  Stack,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import api from "../../../../services/api";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export const StatisticFilter = () => {
  const [dateValue1, setDateValue1] = useState(
    dayjs().subtract(20, "day").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(dayjs().format("YYYY-MM-DD"));
  const [listSetor, setListSetor] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [filteredSetores, setFilteredSetores] = useState([]);
  const [listUnidadeProducao, setListUnidadeProducao] = useState([]);
  const [selectedUnidadeProducao, setSelectedUnidadeProducao] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState([]);
  const [listGrupo, setListGrupo] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState([]);

  const handleUnidadeChange = (e) => {
    setSelectedUnidadeProducao(e.target.value);
    setTipoUnidade(e.target.value);
    setSelectedSetor("");
    setSelectedGrupo("");
  };
  const handleChangeSetor = (e) => {
    setSelectedSetor(e.target.value);
    setSelectedGrupo([]);
  };
  const handleChangeGrupo = (e) => {
    setSelectedGrupo(e.target.value);
  };

  useEffect(() => {
    const getUnProducao = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidadeProducao(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
        if (selectedUnidadeProducao) {
          const filteredSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == selectedUnidadeProducao
          );
          setFilteredSetores(filteredSetores);
        }
      });
    };

    const getGrupos = async () => {
      await api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
      });
    };

    getUnProducao();
    getSetores();
    getGrupos();
  }, [selectedUnidadeProducao, tipoUnidade]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          minWidth: "100%",
          justifyContent: "center",
        }}
        p={2}
      >
        <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }}>
          <Grid item xs={2}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="pt-br"
            >
              <Stack>
                <DatePicker
                  label="Data Inicial"
                  value={dateValue1}
                  onChange={(newValue) =>
                    setDateValue1(dayjs(newValue).format("YYYY-MM-DD"))
                  }
                  renderInput={(params) => <TextField {...params} />}
                  dayOfWeekFormatter={(day) => {
                    const daysOfWeek = [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab",
                    ];
                    return daysOfWeek[day];
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="pt-br"
            >
              <Stack>
                <DatePicker
                  label="Data Final"
                  value={dateValue2}
                  onChange={(newValue) =>
                    setDateValue2(dayjs(newValue).format("YYYY-MM-DD"))
                  }
                  renderInput={(params) => <TextField {...params} />}
                  dayOfWeekFormatter={(day) => {
                    const daysOfWeek = [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab",
                    ];
                    return daysOfWeek[day];
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs="auto">
            <FormControl sx={{ width: 230 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Unidade Produção
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                label="Unidade Produção"
                value={selectedUnidadeProducao}
                onChange={handleUnidadeChange}
              >
                <MenuItem value="">Selecione</MenuItem>
                {listUnidadeProducao
                  .sort((a, b) => {
                    if (a.fantasia > b.fantasia) return 1;
                    if (a.fantasia < b.fantasia) return -1;
                    return 0;
                  })
                  .map((item) => (
                    <MenuItem font value={item.cgcCpf}>
                      {item.fantasia}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs="auto">
            <FormControl sx={{ width: 225 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Setor
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                /* sx={{ m: 1 }} */
                label="Setor"
                value={selectedSetor}
                onChange={handleChangeSetor}
                spacing={3}
              >
                <MenuItem value="">Selecione</MenuItem>
                {filteredSetores
                  .sort((a, b) => {
                    if (a.descricao > b.descricao) return 1;
                    if (a.descricao < b.descricao) return -1;
                    return 0;
                  })
                  .map((item) => (
                    <MenuItem value={item.id}>{item.descricao}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs="auto">
            <FormControl sx={{ maxWidth: 220 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Grupo
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                sx={{
                  width: 200,
                }}
                label="Grupo"
                value={selectedGrupo}
                onChange={handleChangeGrupo}
                spacing={3}
              >
                <MenuItem value="">Selecione</MenuItem>
                {listGrupo
                  .sort((a, b) => {
                    if (a.descricao > b.descricao) return 1;
                    if (a.descricao < b.descricao) return -1;
                    return 0;
                  })
                  .map((item) => (
                    <MenuItem value={item.id}>{item.descricao}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Statistic
          dataInicial={dateValue1}
          dataFinal={dateValue2}
          filterUnidade={selectedUnidadeProducao}
          filterSetor={selectedSetor}
          filterGrupo={selectedGrupo}
          unidade={listUnidadeProducao}
          setor={listSetor}
          grupo={listGrupo}
        />
      </Box>
    </>
  );
};
