import {Table, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export const GridComponentePecasMobile = ({chamadoPecas}) => {

  return (
    <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>Descrição</TableCell>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>Un.Med.</TableCell>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>Qtd</TableCell>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>Custo Médio</TableCell>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>estoque</TableCell>
          <TableCell sx={{ width: "90px", padding: "1px 1px" }}>Empenhar</TableCell>
          </TableRow>
      </TableHead>

      <TableRow>
        {chamadoPecas.map((pecas) => (
          <tr key={pecas.id}>
            <td data-label="Descrição" component="th" scope="row">{pecas.matprima.descricao}</td>
            <td data-label="Un. Medida">{pecas.matprima.unidade}</td>
            <td data-label="Quantidade">{pecas.quantidade}</td>
            <td data-label="Custo Médio">{pecas.custoMedio}</td>
            <td data-label="Estoque">{pecas.qtdEstoque}</td>
            <td data-label="Empenhar">{pecas.qtdEmpenhar}</td>
          </tr>
        ))}
      </TableRow>
    </Table>
    </TableContainer>
  );
};
