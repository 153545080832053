import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import Select from "react-select";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../services/api";
import { colourStyles } from "../Css/selectMulti";

export const ModalPecas = ({
  dialogOpen,
  matPrimaSelecionada,
  handleClose,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [deletedItemIds, setDeletedItemIds] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const queryClient = useQueryClient();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: matPrimaData, isLoading: isLoadingMatPrima } = useQuery(
    ["matPrimaData", matPrimaSelecionada],
    async () => {
      const response = await api.get(
        `matprima-pecas/${matPrimaSelecionada.matprima}/${matPrimaSelecionada.unidadeCnpj}`
      );
      return response.data;
    },
    {
      enabled: !!matPrimaSelecionada,
      onSuccess: ({ data }) => {
        const defaultSelectedOptions =
          data[0]?.grupoMaquinas?.map((item) => ({
            value: item.grupoMaquina.id,
            label: item.grupoMaquina.descricao,
            parentId: item.id,
          })) || [];

        setDefaultOptions(defaultSelectedOptions);
        setSelectedOptions(defaultSelectedOptions);
        setDeletedItemIds([]);
      },
    }
  );

  const { data: grupoMaquinarioData, isLoading: isLoadingGrupoMaquinario } =
    useQuery(["grupoMaquinarioData"], async () => {
      const response = await api.get(`/grupomaquinario`);
      return response.data.records;
    });

  const { mutate: salvarGruposNaMatPrima, isLoading: isSaving } = useMutation(
    async () => {
      const defaultIds = defaultOptions.map((option) => option.value);
      const newItems = selectedOptions.filter(
        (option) => !defaultIds.includes(option.value)
      );

      const addNewData = newItems.map((option) => ({
        idGrupoMaq: option.value,
        matprima: matPrimaSelecionada.matprima,
      }));

      await Promise.all([
        api.post(`matprima-pecas/vincular-matprima-grupomaq`, addNewData),
        ...deletedItemIds.map((id) => api.delete(`/matprima-pecas/${id}`)),
      ]);

      return { success: true };
    },
    {
      onSuccess: async (data) => {
        console.log("Grupos de máquinas salvos com sucesso.");
        await queryClient.invalidateQueries(["listTable"]);
        setDeletedItemIds([]);

        handleClose();
      },
      onError: (error) => {
        console.error("Erro ao salvar grupos de máquinas:", error);
      },
    }
  );

  const handleMenuOpen = () => {
    setIsSelectOpen(true);
  };

  const handleMenuClose = () => {
    setIsSelectOpen(false);
  };

  const handleGroupChange = (selected) => {
    setSelectedOptions(selected);

    const newSelectedIds = selected.map((option) => option.value);
    const newDeletedItemIds = defaultOptions
      .filter((item) => !newSelectedIds.includes(item.value))
      .map((item) => item.parentId);

    setDeletedItemIds(newDeletedItemIds);
  };

  const grupoMaquinarioOptions =
    grupoMaquinarioData?.map((item) => ({
      value: item.id,
      label: item.descricao,
    })) || [];

  return (
    <Dialog open={dialogOpen} onClose={handleClose}>
      <DialogContent
        sx={{
          margin: 2,
          height: isSelectOpen ? 700 : 500,
          overflowY: "auto",
        }}
      >
        {isLoadingMatPrima ? (
          <Box sx={{ width: 500, display: "grid", gap: 4 }}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Box sx={{ gridTemplateColumns: "1fr 1fr 1fr", gap: 2 }}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            </Box>
            <Skeleton variant="rounded" width="100%" height={70} />
          </Box>
        ) : (
          matPrimaData?.data.map((item) => (
            <Box key={item.id} sx={{ display: "grid", gap: 5 }}>
              <Box>
                <Typography variant="body1">EMPRESA</Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {item.empresa}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: isMobile ? "0.6fr 0.5fr" : "1fr 1fr 1fr",
                  gap: 2,
                }}
              >
                <Box>
                  <Typography variant="body1">COD.MAT</Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {item.matprima}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body1">DESCRIÇÃO</Typography>
                  <Tooltip title={item.descricao}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: 200,
                      }}
                    >
                      {item.descricao}
                    </Typography>
                  </Tooltip>
                </Box>

                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    background: "#faf5f5",
                    borderRadius: 3,
                    gap: 4,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 11 }}>UN</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {item.unidade}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontSize: 11 }}>QTDE</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {item.qtdeEstoque}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography sx={{ fontSize: 11, whiteSpace: "nowrap" }}>
                      R$ MÉDIO
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                    >
                      R$ {item.custoMedio}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Divider />

              <Box sx={{ display: "grid", gap: 10 }}>
                <Box>
                  <Typography variant="body1">GRUPO DE MAQUINAS</Typography>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={grupoMaquinarioOptions}
                    styles={colourStyles}
                    value={selectedOptions}
                    onChange={handleGroupChange}
                    blurInputOnSelect={false}
                    captureMenuScroll
                    onMenuClose={handleMenuClose}
                    onMenuOpen={handleMenuOpen}
                  />
                </Box>

                <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
                  <Typography variant="body1">Status:</Typography>
                  <Typography variant="subtitle2">
                    {item.status !== "I" ? "ATIVO" : "INATIVO"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => salvarGruposNaMatPrima()}
          disabled={isSaving}
        >
          {isSaving ? "Salvando..." : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
