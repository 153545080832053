import React from "react";
import api from "../../../services/api";
import { useState } from "react";

const PesquisaImagem = ({ maquinario }) => {
  const [imagem, setImagem] = useState([]);
  const [indexPrincipal, setIndexPrincipal] = useState(0);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);

  try {
    api.get(`maquinarioalbum/maquinario/${maquinario}`).then((response) => {
      setImagem(response.data.records);
      console.log(imagem);
      setImagemPrincipal(response.data.records[indexPrincipal]);
    });
  } catch (error) {
    console.error("Impossivel Localizar a Imagem:", error);
  }

  return (
    <>
      <div className="text-center">
        <img
          className="product-image"
          style={{ maxHeight: "200px", maxWidth: "60%" }}
          src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
          alt="Photo"
        />
      </div>
    </>
  );
};

export default PesquisaImagem;
