import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import TextField from "@mui/material/TextField";
import {
  DatePicker,
  LocalizationProvider,
  ptBR,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import CustomizedAndDialogs from "./DialogsAndChamados";
import { Select, MenuItem } from "@mui/material";
import { FormControl } from "@mui/material";
import VisualizaChamadosAdmin from "../VisualizarChamadoAdm";
import { useFilterContext } from "./FilterContext";

const ChamadosAndamentoGrid = ({ calls }) => {
  let history = useHistory();

  const { st } = useParams();
  const [data, setData] = useState([]);
  const [tipoChamadoDesc, setTipoChamadoDesc] = useState("Todos");
  const [selectedTipoChamadoDesc, setSelectedTipoChamadoDesc] = useState([]);
  const [mecanico, setMecanico] = useState("Todos");
  const [unidadeProducao, setUnidadeProducao] = useState("Todos");
  const [menuAberto, setMenuAberto] = useState(false);
  const { dateValue1, dateValue2, updateFilter, updateFilter2 } =
    useFilterContext();

  const dadosFiltros = async () => {
    updateFilter(data.slice(1, 11));
    updateFilter2(datafinal.slice(1, 11));
  };

  const handleChangeTipoChamadoDesc = (event) => {
    let tipoChamadoDesc = event.target.value;
    setTipoChamadoDesc(tipoChamadoDesc);
  };

  const handleChangeMecanico = (event) => {
    let mecanico = event.target.value;
    setMecanico(mecanico);
  };

  const getListUnidade = async () => {
    const response = await api.get(`/unidadeproducao/`);
    return response.data.records;
  };
  const { data: listUnidades, isLoading: isLoadingUnidades } = useQuery(
    "unidadesData",
    getListUnidade
  );

  const handleChangeUnidade = (event) => {
    setUnidadeProducao(event.target.value);
    setTipoChamadoDesc("Todos");
    setMecanico("Todos");
    setMenuAberto(false);
    if (event.target.value != 0) {
      let label = listUnidades.find(
        (option) => option.cgcCpf === event.target.value
      );
      ("");
    }
  };

  const getMecanico = async () => {
    const response = await api.get(`/mecanico/`);
    return response.data.records;
  };
  const { data: listMecanico, isLoading: isLoadingMecanicos } = useQuery(
    "mecanicosData",
    getMecanico
  );

  const handleOpenMenu = () => {
    setMenuAberto(true);
  };

  const handleCloseMenu = () => {
    setMenuAberto(false);
  };

  const getChamado = async () => {
    let parametro = st != null ? st : "ab";
    let gravidade =
      tipoChamadoDesc !== "Todos" ? `&gravidade=${tipoChamadoDesc}` : "";
    let mecanicoP = mecanico === "Todos" ? "" : `&mecanico=${mecanico}`;
    let unidade =
      unidadeProducao === "Todos" ? "" : `&unidadeProducao=${unidadeProducao}`;

    const response = await api.get(
      `chamado/dataabertura?maiorOuIgual=${dateValue1}&menorOuIgual=${dateValue2}&tipo=${parametro}${gravidade}${mecanicoP}${unidade}`
    );
    return response.data.records;
  };

  const { data: chamado, isLoading: isLoadingChamado } = useQuery(
    [
      "chamados",
      st,
      dateValue1,
      dateValue2,
      listUnidades,
      mecanico,
      tipoChamadoDesc,
      unidadeProducao,
    ],
    getChamado
  );

  const CoresLinhas = (nivelGravidade) => {
    if (nivelGravidade === "ALTA") {
      return "callout callout-danger";
    } else if (nivelGravidade === "MEDIA") {
      return "callout callout-warning";
    } else if (nivelGravidade === "BAIXA") {
      return "callout callout-info";
    } else {
      return "callout";
    }
  };

  const calcularQuantidadeTotal = (item) => {
    return item.chamadoPecas.reduce(
      (total, pecas) => total + pecas.quantidade,
      0
    );
  };

  const renderIconAndText = () => {
    switch (st) {
      case "ab":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-calendar-day" sx={{ color: "#17a2b8" }} />
            <Typography ml={1}>Filtrar por Chamados Abertos</Typography>
          </Box>
        );
      case "re":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-rotate-left" sx={{ color: "#dc3545" }} />
            <Typography ml={1}>Filtrar por Chamados Retornados</Typography>
          </Box>
        );
      case "ds":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-gears" sx={{ color: "#28a745" }} />
            <Typography ml={1}>Filtrar por Chamados Designados</Typography>
          </Box>
        );
      case "ag":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-calendar-check" sx={{ color: "#ffc107" }} />
            <Typography ml={1}>Filtrar por Chamados Agendados</Typography>
          </Box>
        );
      case "aguar":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-clock" sx={{ color: "#6c757d" }} />
            <Typography ml={1}>Filtrar por Chamados em Aguardo</Typography>
          </Box>
        );
      case "fin":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-check" sx={{ color: "#28a745" }} />
            <Typography ml={1}>Filtrar por Chamados Finalizados</Typography>
          </Box>
        );
      case "rec":
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fa fa-trash" sx={{ color: "#ff9100" }} />
            <Typography ml={1}>Filtrar por Chamados Recusados</Typography>
          </Box>
        );
      default:
        return (
          <Box display="flex" alignItems="center">
            <Icon className="fas fa-calendar-day" sx={{ color: "#17a2b8" }} />
            <Typography ml={1}>Filtrar por Chamados Abertos</Typography>
          </Box>
        );
    }
  };

  return (
    <>
      <Card marginTop={1}>
        <CardHeader
          title={
            <Grid container alignItems="center">
              <Box display="flex" alignItems="center">
                {renderIconAndText()}
                <Typography ml={1}>
                  / Quantidade: <strong>{chamado && chamado.length}</strong>
                </Typography>
              </Box>
            </Grid>
          }
        />
        <CardContent>
            <Grid container gap={1} >
              <Grid item xs={12} md={12} lg={2}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt-br"
                >
                  <Stack>
                    <DatePicker
                      label="Data Inicial"
                      value={dateValue1}
                      onChange={(newValue) =>
                        updateFilter(dayjs(newValue).format("YYYY-MM-DD"))
                      }
                      renderInput={(params) => <TextField {...params} />}
                      dayOfWeekFormatter={(day) => {
                        const daysOfWeek = [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sab",
                        ];
                        return daysOfWeek[day];
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={12} lg={2}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="pt-br"
                >
                  <Stack>
                    <DatePicker
                      label="Data Final"
                      value={dateValue2}
                      onChange={(newValue) =>
                        updateFilter2(dayjs(newValue).format("YYYY-MM-DD"))
                      }
                      renderInput={(params) => <TextField {...params} />}
                      dayOfWeekFormatter={(day) => {
                        const daysOfWeek = [
                          "Dom",
                          "Seg",
                          "Ter",
                          "Qua",
                          "Qui",
                          "Sex",
                          "Sab",
                        ];
                        return daysOfWeek[day];
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              {st != null && st != "undefined" && (
                <Grid item xs={12} md={12} lg={2.5} >
                  <Stack>
                    <FormControl>
                      <InputLabel id="open-select">
                        Unidade de Produção
                      </InputLabel>
                      <Select
                        labelId="open-select"
                        id="open-select"
                        sx={{ m: 0, minWidth: 190 }}
                        label="Unidade de Produção"
                        value={unidadeProducao}
                        onChange={handleChangeUnidade}
                        onOpen={handleOpenMenu}
                        onClose={handleCloseMenu}
                        open={menuAberto}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        {listUnidades &&
                          listUnidades
                            .sort((a, b) => (a.fantasia > b.fantasia ? 1 : -1))
                            .map((item) => (
                              <MenuItem key={item.cgcCpf} value={item.cgcCpf}>
                                {item.fantasia}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              )}
              {st != "ab" && st != "aguar" && st != "rec" && st != null && (
                <Grid item xs={12} md={12} lg={3}>
                  <Stack>
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        Mecânico
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select-label"
                        sx={{ m: 0, minWidth: 190 }}
                        label="Mecânico"
                        value={mecanico}
                        onChange={handleChangeMecanico}
                      >
                        <MenuItem value="Todos">Todos</MenuItem>
                        {listMecanico &&
                          listMecanico
                            .filter((mec) => mec.status == 1)
                            .sort((a, b) =>
                              a.usuario.nome > b.usuario.nome ? 1 : -1
                            )
                            .map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.usuario.nome}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} md={12} lg={2}>
                <Stack>
                  <FormControl>
                    <InputLabel id="demo-controlled-open-select-label">
                      Gravidade
                    </InputLabel>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select-label"
                      sx={{ m: 0, minWidth: 190 }}
                      label="Gravidade"
                      value={tipoChamadoDesc}
                      onChange={handleChangeTipoChamadoDesc}
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      <MenuItem value="BAIXA">BAIXA</MenuItem>
                      <MenuItem value="MEDIA">MEDIA</MenuItem>
                      <MenuItem value="ALTA">ALTA</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          
        </CardContent>
      </Card>

      {chamado &&
        chamado
          .sort((a, b) => b.id - a.id)
          .map((item) => {
            const quantidadeTotal = calcularQuantidadeTotal(item);
            return (
              <CustomizedAndDialogs
                key={item.id}
                item={item}
                coreslinhas={CoresLinhas(item.tipoChamadoDesc)}
                quantidadeTotal={quantidadeTotal}
              >
                <VisualizaChamadosAdmin selecionar={item.id} />
              </CustomizedAndDialogs>
            );
          })}
    </>
  );
};

export default ChamadosAndamentoGrid;
