import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { REACT_APP_TOTAL_GRID } from "../../../utils/constants";

import api from "../../../services/api";
import { addZeroes } from "../../../utils/utils";
import {
  Alert,
  AlertTitle,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ReactPaginate from "react-paginate";

export const MaquinarioSetorDataGrid = () => {
  let history = useHistory();

  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listUnidades, setListUnidades] = useState([]);
  const [listSetor, setListSetor] = useState([]);
  const [selectedUnidade, setSelectedUnidade] = useState("52241635002713");
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState(0);
  const [tipoSetor, setTipoSetor] = useState(0);
  const [filteredSetores, setFilteredSetores] = useState([]);

  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagina, setPagina] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);

  let limit = parseInt(REACT_APP_TOTAL_GRID);

  const handleClick = (event, codigo) => {
    history.push({
      pathname: "/maquinarioSetorItem/" + codigo,
    });
  };

  useEffect(() => {
    const getDados = () => {

      setPagina(currentPage);

      let stringBase = `maquinario_setores/paginacao/busca?page=${currentPage}&size=${limit}&unidade=52241635002713`;
      let stringSetor = "";
      let stringUnidade = "";

      if (selectedSetor.length != 0) {
        stringSetor = `setor=` + selectedSetor;
        stringBase = `maquinario_setores/paginacao/busca?` + stringSetor + `&page=${currentPage}`+ "&size=" + limit;
      } else if (selectedUnidade.length != 0) {
        stringUnidade = `unidade=` + selectedUnidade;
        stringBase = `maquinario_setores/paginacao/busca?` + stringUnidade + `&page=${currentPage}`+ "&size=" + limit;
      }

      api.get(stringBase).then((response) => {
        setData(response.data.records);
        const data = response.data.records;
        const total = response.data.meta.recordCount;
        setpageCount(Math.ceil(total / limit));
      
        setItem(data);
        setTotalRegistro(total);
      });
    };

    const getUnidade = async () => {
      await api.get(`unidadeproducao`).then((response) => {
        setListUnidades(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
        if (selectedUnidade) {
          const filteredSetores = response.data.records.filter(
            (setor) => setor.unidadeProducao.cgcCpf == selectedUnidade
          );
          setFilteredSetores(filteredSetores);
        }
      });
    };
    getSetores();
    getUnidade();
   getDados();
    setLoading(false);
  }, [tipoUnidade, tipoSetor, limit, currentPage,]);

  const handleChangeUnidade = async (event) => {
    setSelectedUnidade(event.target.value);
    setTipoUnidade(event.target.value);
    setSelectedSetor("");
    setCurrentPage(0);
    setPagina(currentPage);
  };

  const handleChangeSetor = async (event) => {
    let val = event.target.value;
    setSelectedSetor(val);
    setTipoSetor(val);
    setCurrentPage(0);
    setPagina(currentPage);
  };

  const handlePageClick = async (data) => {
    let _currentPage = data.selected;

    setCurrentPage(_currentPage);
  };

  if (!data) return null;

  return (
    <div>
      <div>
        <form className="form-horizontal">
          <div className="row">
            <div className="d-flex flex-row ">
              <div className="col-xl-6 px-1 ">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Unidade
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 300 }}
                    label="Unidade"
                    value={selectedUnidade}
                    onChange={handleChangeUnidade}
                    spacing={3}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {listUnidades
                      .sort((a, b) => {
                        if (a.fantasia > b.fantasia) return 1;
                        if (a.fantasia < b.fantasia) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.cgcCpf}>{item.fantasia}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-6 text-muted">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Setor
                  </InputLabel>
                  <Select
                    sx={{ m: 1, minWidth: 300 }}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    label="Setor"
                    value={selectedSetor}
                    onChange={handleChangeSetor}
                    spacing={3}
                  >
                    <MenuItem value="0">Selecione</MenuItem>
                    {filteredSetores
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.id}>{item.descricao}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>              
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "10%", textAlign: "center" }} scope="col">
                    Ações
                  </th>
                  <th style={{ width: "8%", textAlign: "center" }} scope="col">
                    ID
                  </th>
                  <th style={{ width: "30%", textAlign: "center" }} scope="col">
                    Unidade Produção / Setores{" "}
                  </th>
                  <th style={{ width: "30%", textAlign: "center" }} scope="col">
                    Num. Patrimônio / Maquinário{" "}
                  </th>
                  <th style={{ width: "10%", textAlign: "center" }} scope="col">
                    Status{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((itens) => {
                  return (
                    <tr key={itens.id}>
                      <td>
                        <button
                          type="button"
                          className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                          onClick={(event) => {
                            handleClick(event, itens.id);
                          }}
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                      </td>
                      <td>{addZeroes(itens.id, 5)}</td>
                      <td>
                        {itens.setores.unidadeProducao.fantasia +
                          " | " +
                          itens.setores.descricao}
                      </td>
                      <td>
                        {itens.maquinario.numPatrimonio +
                          " | " +
                          itens.maquinario.descricao}
                      </td>
                      <td>
                        {itens.status == 1 ? (
                          <i
                            className="fas fa-check"
                            style={{ color: "green" }}
                          ></i>
                        ) : (
                          <i className="fas fa-times"></i>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            initialPage={0}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
          </div>
        </div>
      </div>
    </div>
  );
};