import ReactApexChart from "react-apexcharts";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { align } from "@progress/kendo-drawing";

export const MecanicoRanking = ({ data, periodo }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  var options = {
    title: {
      text: "RANKING DE MECÂNICOS",
      align: "center",
      floating: true,
      margin: 18,
      
      style: {
        height: 'auto',
        fontSize: isMobile ? '14px' : '16px',
        fontFamily: "'Roboto', sans-serif",        
      }
    },
    subtitle: {
      text: `Período: ${periodo.dataInicial} a ${periodo.dataFinal}`,
      align: "center",
      style: {
        height: 'auto',
        fontSize: isMobile ? '14px' : '16px',
        fontFamily: "'Roboto', sans-serif",        
      }
    },
    series: [
      {
        name: "Horas",
        data: data
          //.sort((a, b) => b.totalHorasAtendimento - a.totalHorasAtendimento)
          .map((item) => item.totalHorasAtendimento),
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    plotOptions: {
      bar: {
        width: 60,
        borderRadius: 4,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const index = opts.dataPointIndex;
        const minutes = data[index].totalMinutosAtendimento;
        const formattedMinutes = minutes.toString().padStart(2, "0");

        return `${val}h${formattedMinutes}min`;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val, opts) {
          const index = opts.dataPointIndex;
          const minutes = data[index].totalMinutosAtendimento;
          const formattedMinutes = minutes.toString().padStart(2, "0");

          return `${val}h${formattedMinutes}min`;
        },
      },
    },
    xaxis: {
      categories: data
        //.sort((a, b) => b.totalHorasAtendimento - a.totalHorasAtendimento)
        .map((item) => item.usuario.nome),
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "Horas",
      },
      labels: {
        formatter: function (val) {
          return `${val}h`;
        },
      },
    },
  };

  return (
    <Box sx={{ p: 2 }} className="shadow-lg bg-white rounded">
      <ReactApexChart
        options={options}
        key={"ranking-chart"}
        series={options.series}
        type="bar"
        height={350}
      />
    </Box>
  );
};
