import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import CustomizedChamados from "./DialogsChamados";
import VisualizaChamados from "../Chamados";

export const Designados = ({
  parametro,
  dataInicial,
  dataFinal,
  mecan,
  tipoCham,
}) => {
  let history = useHistory();
  const [data, setData] = useState([]);

  /* Editar Registros */

  useEffect(() => {
    const getFiltro = async () => {
      console.log(
        `chamado/dataabertura?maiorOuIgual=${dataInicial}&menorOuIgual=${dataFinal}`
      );

      await api
        .get(
          `chamado/dataabertura?maiorOuIgual=${dataInicial}&menorOuIgual=${dataFinal}`
        )

        .then((response) => {
          ///await api.get(`chamado`).then((response) => {

          setData(response.data.records);
        });
    };

    getFiltro();
  }, [dataInicial, dataFinal]);

  const Titulo = (parTitulo) => {
    switch (parTitulo) {
      case 0:
        return <h3 className="card-title" style={{fontSize: 16}}>Aguardando</h3>;
      case 1:
        return <h3 className="card-title" style={{fontSize: 16}}>Andamento</h3>;
      case 2:
        return <h3 className="card-title" style={{fontSize: 16}}>Finalizado</h3>;
      case 3:
        return <h3 className="card-title" style={{fontSize: 16}}>Homologado</h3>;
      case 4:
        return <h3 className="card-title" style={{fontSize: 16}}>Retornados</h3>;
      default:
        return null;
    }
  };

  const Cores = (ParCor) => {
    if (ParCor === 0) {
      return "card card-row card-warning";
    } else if (ParCor === 1) {
      return "card card-row card-primary";
    } else if (ParCor == 4) {
      return "card card-row card-danger";
    } else {
      return "card card-row card-success";
    }
  };
  const filteredData = data.filter((chamado) => {
    // Base conditions: Check for status and presence of mecanico.
    if (chamado.status !== parametro || !chamado.mecanico) {
      return false;
    }

    // If tipoCham is not "Todos", filter by nivelGravidade.
    if (
      tipoCham !== "Todos" &&
      chamado.tipoChamadoDesc[0] &&
      chamado.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade !==
        tipoCham
    ) {
      return false;
    }

    // If mecan is not "Todos", filter by mecanico id.
    if (mecan !== "Todos" && chamado.mecanico.id !== mecan) {
      return false;
    }

    return true; // All conditions passed.
  });

  const orderUrgencia = {
    BAIXA: 0,
    MEDIA: 1,
    ALTA: 2,
  };

  return (
    <>
      <div style={{ tableLayout: "auto", width: "20%" }}>
        <div className="container-fluid h-100">
          <div className={Cores(parametro)}>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'center' }}>{Titulo(parametro)}<span style={{ fontSize: 15, marginLeft: 5}}>{filteredData.length}</span></div>
            <div className=" " style={{ padding: "0 0.3rem" }}>
              <div className="card-default color-palette-box">
                <div className="">
                  {filteredData
                    .sort((a, b) => {
                      return orderUrgencia[
                        a.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade
                      ] >
                        orderUrgencia[
                          b.tipoChamadoDesc[0].tipoChamadoDescricao
                            .nivelGravidade
                        ]
                        ? -1
                        : 1;
                    })
                    .map((item) => (
                      <div key={item.id} className="card">
                        <div style={{ height: "0.1rem" }} />
                        <div
                          className="user-block"
                          style={{
                            backgroundColor: "#fff",
                            backgroundClip: "border-box",
                            border: "1 solid rgba(141,154,168,.125)",
                            borderRadius: "0.5rem",
                            boxShadow: "1rem 1rem 3rem rgba(0,0,0,.175)",
                            width: "100%",
                          }}
                        >
                          <CustomizedChamados
                            title=""
                            item={item}
                            blobImage={item.mecanico?.imagem}
                          >
                            <VisualizaChamados selecionar={item.id} />
                          </CustomizedChamados>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
