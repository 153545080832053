import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  Alert,
  Dialog,
  AlertTitle,
  InputLabel,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import api from "../../../services/api";
import { QRCodeCanvas } from "qrcode.react";
import SemImg from "../../../assets/sem-foto.svg";
import { addZeroes, formatarDataBr } from "../../../utils/utils";
import dayjs from "dayjs";
import { FormControl } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const VisualizaChamadosAdmin = ({ selecionar: registro }) => {
  const paperStyle = { padding: "5px 5px 5px 5px" };

  const [tipoMecanicoCombo, setTipoMecanicoCombo] = useState([]);
  const [selectedMecanico, setSelectedMecanico] = useState(null);

  const [listMecanicos, setListMecanicos] = useState([]);
  const [solicitante, setSolicitante] = useState("");
  const [setor, setSetor] = useState("");
  const [patrimonio, setPatrimonio] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [problrelatado, setProblrelatado] = useState("");
  const [descricao, setDescricao] = useState();
  const [status, setStatus] = useState("1");
  const [dataAbertura, setDataAbertura] = useState("");
  const [images, setImages] = useState([]);
  const [dataImagem, setDataImagem] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [indexPrincipal, setIndexPrincipal] = useState(0);
  const [tipoChamadoCombo, setTipoChamadoCombo] = useState("");
  const [laudotecnico, setLaudotecnico] = useState("");
  const [unidadeProducao, setUnidadeProducao] = useState([]);
  const [dataAgendamento, setDataAgendamento] = useState("");
  const [openMsg, setOpenMsg] = useState(false);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [resumoFechamento, setResumoFechamento] = useState("");
  const [aprovacaoHomologacao, setAprovacaoHomologacao] = useState("");
  const [dataFechamentoMecanico, setDataFechamentoMecanico] = useState("");
  const [dataFechamento, setDataFechamento] = useState("");
  const [observacaoTecnica, setObservacaoTecnica] = useState("");
  const [observacaoFechamentoMecanico, setObservacaoFechamentoMecanico] =
    useState("");
  const [observacaoFechamento, setObservacaoFechamento] = useState("");
  const [imageMecBlob, setImageMecBlob] = useState("");
  const [nomeMec, setNomeMec] = useState("");
  const [especialidades, setEspecialidades] = useState("");

  const [movimento, setMovimento] = useState([]);
  const [movimentoMecanico, setMovimentoMecanico] = useState([]);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [openHistory, setOpenHistory] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openModal, setOpenModal] = useState(false);
  const [onClose, setOnClose] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [confirmationModalMecanico, setConfirmationModalMecanico] =
    useState(false);

  const [newMecanico, setNewMecanico] = useState(null);

  const handleClickOpen = () => {
    if (observacaoTecnica === null || observacaoTecnica === "") {
      setMensagemDialog("Preencha o campo Observaçâo.");
      setTipoDialog("warning");
      setCorDialog("warning");
      setOpenMsg(!openMsg);
      return;
    }

    if (observacaoTecnica.length < 5) {
      setMensagemDialog("Observação técnica deve ter pelo menos 5 caracteres.");
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
      return;
    }

    if (selectedMecanico === 0 && observacaoTecnica == null) {
      try {
        api.put(`chamado/` + registro, {
          observacaoTecnica,
        });
      } catch (err) {
        console.log(err);

        setMensagemDialog("Houve um Problema ao atualizar o registro");
        setTipoDialog("error");
        setCorDialog("error");
      }
    } else if (selectedMecanico === 0 && observacaoTecnica !== 0) {
      api.put(`chamado/` + registro, {
        observacaoTecnica,
      });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    try {
      var dadosJsonAtualizar = {
        observacaoTecnica: observacaoTecnica,
        status: 5,
      };

      api.put(`chamado/` + registro, dadosJsonAtualizar);

      handleClose();
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um Problema ao atualizar o registro");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleClick = () => {
    setOpenMsg(!openMsg);
  };

  useEffect(() => {
    const getChamado = async () => {
      await api.get(`chamado/` + registro).then((response) => {
        setIsLoading(true);
        setSolicitante(response.data.records[0].solicitante.nome);
        setDescricao(response.data.records[0].patrimonio.descricao);
        setSetor(response.data.records[0].setor.descricao);
        setPatrimonio(response.data.records[0].patrimonio.numPatrimonio);
        setDataFechamentoMecanico(
          response.data.records[0].dataFechamentoMecanico
        );
        setDataFechamento(response.data.records[0].dataFechamento);
        setDataAgendamento(response.data.records[0].agendamento);
        setObservacaoFechamentoMecanico(
          response.data.records[0].observacaoFechamentoMecanico
        );
        setObservacaoFechamento(response.data.records[0].observacaoFechamento);
        setObservacaoTecnica(response.data.records[0].observacaoTecnica);
        setLaudotecnico(response.data.records[0].laudoTecnico);
        setQrcode(response.data.records[0].patrimonio.numPatrimonio);
        setFabricante(response.data.records[0].patrimonio.fabricante.nome);
        setProblrelatado(response.data.records[0].motivoAbertura);
        setDataAbertura(response.data.records[0].dataAbertura);
        setUnidadeProducao(
          response.data.records[0].setor.unidadeProducao.fantasia
        );
        setMovimentoMecanico(response.data.records[0].movimentoMecanico);

        setMovimento(response.data.records[0].movimento);

        let tipoDoChamado = response.data.records[0].tipoChamado;
        let mecanicoId = response.data.records[0].mecanico?.id || 0;

        setSelectedMecanico(mecanicoId);

        setTipoChamadoCombo(tipoDoChamado);
        setTipoMecanicoCombo(mecanicoId);
        setStatus(response.data.records[0].status);

        api
          .get(
            `/maquinarioalbum/maquinario/` +
              response.data.records[0].patrimonio.id
          )
          .then((response) => {
            setImages(response.data.records);
            setImagemPrincipal(response.data.records[indexPrincipal]);
          });

        mecanicoId !== 0 &&
          api.get(`mecanico/` + mecanicoId).then((response) => {
            setImageMecBlob(response.data.records[0].blobImage);
            setNomeMec(response.data.records[0].usuario.nome);
            setEspecialidades(
              response.data.records[0].especialidades[0].especialidades.nome
            );
          });
      });
    };

    const getFiles = async () => {
      await api.get(`/chamadoarquivos/` + registro).then((response) => {
        setDataImagem(response.data.records);
      });
    };

    const getMecanico = async () => {
      await api.get(`mecanico`).then((response) => {
        setListMecanicos(response.data.records);
      });

      setIsLoading(false);
    };

    Promise.all[(getChamado(), getFiles(), getMecanico())];
  }, [registro]);

  const StatusChamado = (parTitulo) => {
    switch (parTitulo) {
      case 0:
        return (
          <b className="text-lg" style={{ color: "orange" }}>
            AGUARDANDO
          </b>
        );
      case 1:
        return (
          <b className="text-lg" style={{ color: "blue" }}>
            ANDAMENTO
          </b>
        );
      case 2:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            FINALIZADO
          </b>
        );
      case 3:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            HOMOLOGADO
          </b>
        );
      case 4:
        return (
          <b className="text-lg" style={{ color: "red" }}>
            RETORNADO
          </b>
        );
      case 5:
        return (
          <b className="text-lg" style={{ color: "red" }}>
            RECUSADO
          </b>
        );
        case 6:
        return (
          <b className="text-lg" style={{ color: "green" }}>
            ENCERRADO PELO SOLICITANTE
          </b>
        );

      default: {
        return "";
      }
    }
  };

  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className=""
              Style={`max-width: 100%; height: auto; width: 100%}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className=""
              Style={`max-width: 100%; height: auto; width: 100%}`}
              src={SemImg}
              alt="Foto"
            />
          </div>
        </div>
      );
    }
  }

  function verificarAvatar(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image profile-user-img img-fluid img-circle"
              Style={`height: 80px; width: 90px;`}
              src={`data:image/jpeg;base64, ${imPrincipal}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="principal-image"
              Style={`max-height: 120px;}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  const handleChangeMecanico = async (event) => {
    let dataBruta = JSON.stringify(dayjs());

    var formatData = dataBruta.slice(1, 11);
    var formatHora = dataBruta.slice(12, 20);

    let dataDesignacao = `${formatData} ${formatHora}`;

    newMecanico === 0 && (await clearObservacaoTecnica(newMecanico));

    try {
      await api.put(`chamado/` + registro, {
        mecanico: {
          id: newMecanico,
        },
      });
      const dialogMessage =
        newMecanico === 0
          ? "Mecanico alterado com sucesso"
          : "Mecanico designado com sucesso";

      setMensagemDialog(dialogMessage);
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um erro na operação deste item!");
      setTipoDialog("error");
      setCorDialog("error");
    } finally {
      setSelectedMecanico(newMecanico);
      setConfirmationModalMecanico(!confirmationModalMecanico);
    }
  };

  const clearObservacaoTecnica = async (mecanicoId) => {
    if (mecanicoId === 0) {
      await api.put(`chamado/` + registro, {
        observacaoTecnica: "",
      });

      setObservacaoTecnica("");
    }
  };

  const handleConfirmationModalMecanico = (event) => {
    setNewMecanico(event.target.value);

    setConfirmationModalMecanico(!confirmationModalMecanico);
  };

  const handleChangeDataAgendamento = async (newDate) => {
    let dataBruta = JSON.stringify(newDate);
    var formatData = dataBruta.slice(1, 11);
    var formatHora = dataBruta.slice(12, 20);
    let dataAgendada = `${formatData} ${formatHora}`;

    setDataAgendamento(dataAgendada);

    try {
      var dadosJson = {
        chamado: registro,
        dataAgendada: dataAgendada,
        mecanico: tipoMecanicoCombo,
      };
      await api.post(`agendamento/`, dadosJson);
      setMensagemDialog("Agendamento cadastrado com Sucesso");
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);
      setMensagemDialog("Houve um Problema ao cadastrar o registro");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  const handleUpdateObservacaoTecnica = async () => {
    try {
      await api.put(`chamado/` + registro, {
        observacaoTecnica,
      });

      setMensagemDialog("Registro Atualizado com Sucesso");
      setTipoDialog("success");
      setCorDialog("success");

      setOpenMsg(!openMsg);
    } catch (err) {
      console.log(err);

      setMensagemDialog("Houve um Problema ao atualizar o registro");
      setTipoDialog("error");
      setCorDialog("error");
    }
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div className="opacity-100">
      {!isLoading && (
        <Grid style={{ width: "100%" }}>
          <Paper elevation={0} style={paperStyle}>
            <div className="row">
              <div className="col-md-6 ">
                <div className="breadcrumb-item active">
                  <strong className="text-lg">
                    {status == 0 ? "MOVIMENTO" : "DETALHES DO CHAMADO"}
                  </strong>
                </div>

                <div>
                  <td className="text-muted text-sm">
                    <b>SOLICITANTE: </b>
                    <span style={{ color: "black" }}>
                      <b>{solicitante}</b>
                    </span>
                  </td>
                </div>

                <div>
                  <td className="text-muted text-sm">
                    <b>SETOR: </b>
                    <span style={{ color: "black" }}>
                      <b>{setor}</b>
                    </span>
                  </td>
                </div>
              </div>

              <div className="col-md-6 text-right">
                <span>
                  <h5>
                    <b>{addZeroes(registro, 8)}</b>
                    <br />
                    {StatusChamado(status)}
                    <br />
                    {movimento.length >= 1 && (
                      <Button onClick={handleClickOpenHistory}>
                        <span
                          className="far fa-file-alt  "
                          style={{
                            color: "#000",
                            fontSize: 20,
                            color: "brown",
                          }}
                        >
                          {" "}
                          <span
                            className="mr-0"
                            style={{ fontSize: 15, fontFamily: "sans-serif" }}
                          >
                            <b>Histórico de Retorno</b>
                          </span>
                        </span>
                      </Button>
                    )}
                  </h5>
                </span>
              </div>
            </div>
            <br />
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="form-group">
                        {verificarImagem(imagemPrincipal)}
                      </div>
                      <br />
                      <div>
                        {status == 1 || status == 2 || status == 3 ? (
                          <div className="card-header">
                            {verificarAvatar(imageMecBlob)}
                            <div className="text-center mt-1">
                              <strong>{nomeMec}</strong>
                              <br />
                              <strong>{especialidades}</strong>
                            </div>
                          </div>
                        ) : (
                          <FormControl fullWidth sx={{ flex: 1 }}>
                            <InputLabel id="demo-controlled-open-select-label">
                              Mecânico
                            </InputLabel>

                            <Select
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select-label"
                              value={selectedMecanico || 0}
                              onChange={handleConfirmationModalMecanico}
                              label="Mecânico"
                              disabled={status === 5}
                            >
                              <MenuItem value={0}>Selecione</MenuItem>

                              {listMecanicos
                                .filter((mec) => mec.status == 1)
                                .sort((a, b) =>
                                  a.usuario.nome.localeCompare(b.usuario.nome)
                                )
                                .map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.usuario.nome}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-4 text-center">
                          <QRCodeCanvas
                            style={{ width: "100px", height: "100px" }}
                            data-qr={qrcode}
                            value={qrcode}
                          />
                        </div>

                        <div className="col-sm-8 text-left">
                          <div>
                            <span>
                              DESCRIÇÃO:
                              <b className="ml-2">{descricao}</b>
                            </span>
                          </div>

                          <div>
                            <span>
                              CÓDIGO:
                              <b className="ml-2">{patrimonio}</b>
                            </span>
                          </div>

                          <div>
                            <span>
                              FABRICANTE:
                              <b className="ml-2">{fabricante}</b>
                            </span>
                          </div>

                          <div>
                            <span>
                              UN. PRODUÇÃO:
                              <b className="ml-2">{unidadeProducao}</b>
                            </span>
                          </div>

                          {(() => {
                            if (status == 2 || status == 3) {
                              return (
                                <>
                                  <div>
                                    <td>
                                      <span className="text-xs">
                                        DATA ABERTURA :{" "}
                                      </span>
                                      <strong
                                        style={{
                                          color: "black",
                                          borderBottom:
                                            "3px solid rgba(0, 0, 0, 0.05)",
                                        }}
                                      >
                                        {formatarDataBr(dataAbertura)}{" "}
                                      </strong>
                                    </td>
                                  </div>
                                  <div>
                                    <td>
                                      <span className="text-xs">
                                        {status == 2
                                          ? "DATA FECHAMENTO :"
                                          : status == 3
                                          ? "DATA HOMOLOGADO : "
                                          : " "}
                                      </span>
                                      <strong
                                        style={{
                                          color: "black",
                                          borderBottom:
                                            "3px solid rgba(0, 0, 0, 0.05)",
                                        }}
                                      >
                                        {dataFechamento
                                          ? formatarDataBr(dataFechamento)
                                          : ""}
                                      </strong>
                                    </td>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <td>
                                  <span className="text-xs">
                                    DATA ABERTURA :{" "}
                                  </span>
                                  <strong
                                    style={{
                                      color: "black",
                                      borderBottom:
                                        "3px solid rgba(0, 0, 0, 0.05)",
                                    }}
                                  >
                                    {formatarDataBr(dataAbertura)}{" "}
                                  </strong>
                                </td>
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <br />

                      {problrelatado ? (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="callout callout-danger">
                              <td>
                                <b>PROBLEMA RELATADO: </b>
                                <br />
                                <span>{problrelatado}</span>
                              </td>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {laudotecnico ? (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="callout callout-warning">
                              <td>
                                <b className="text-xs">LAUDO TÉCNICO: </b>
                                <br />
                                <span>{laudotecnico}</span>
                              </td>
                            </div>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}

                      {movimentoMecanico ? (
                        <>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="callout callout-info">
                                <td>
                                  <b>RESUMO FECHAMENTO MECÂNICO:</b>
                                  <br />
                                  {movimentoMecanico
                                    .sort((a, b) => b.id - a.id)
                                    .map((item) => {
                                      return (
                                        <div className="p-1">
                                          <span>
                                            {formatarDataBr(
                                              item.dataFechamentoMecanico
                                            )}
                                            {" - "}
                                            {item.observacaoFechamentoMecanico}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </td>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {(() => {
                        if (status == 4) {
                          return (
                            <div className="col-12 mb-3">
                              <Alert severity="error">
                                Chamado recusado pelo solicitante — verificar{" "}
                                <a
                                  className="text-underline text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClickOpenHistory}
                                >
                                  histórico
                                </a>{" "}
                                de retorno!
                              </Alert>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })()}

                      {dataFechamento ? (
                        <>
                          {(() => {
                            if (status == 2 || status == 3) {
                              return (
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="callout callout-success">
                                      <td>
                                        <b>
                                          APROVAÇÃO E HOMOLOGAÇÃO PELO
                                          SOLICITANTE:
                                        </b>
                                        <br />
                                        <span>
                                          {formatarDataBr(dataFechamento)}
                                          {" - "}
                                          {observacaoFechamento}
                                        </span>
                                      </td>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })()}
                        </>
                      ) : (
                        ""
                      )}

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="callout callout-info">
                            <label className="col-sm-12 col-label">
                              OBSERVAÇÕES
                            </label>

                            <textarea
                              className="form-control"
                              style={{ fontSize: 12 }}
                              id="observacao_tecnica"
                              placeholder="Observacao Técnica..."
                              onChange={(e) =>
                                setObservacaoTecnica(e.target.value)
                              }
                              value={observacaoTecnica || ""}
                              disabled={status === 5}
                            />
                            <br />

                            <div className="row">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-5 text-left">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="btn-group btn-group-sm"
                                      disabled={status === 5}
                                      onClick={handleUpdateObservacaoTecnica}
                                    >
                                      Atualizar
                                    </Button>
                                  </div>

                                  {selectedMecanico === 0 && (
                                    <div className="col-7 text-right">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        onClick={handleClickOpen}
                                        disabled={
                                          selectedMecanico !== null &&
                                          selectedMecanico === 0 &&
                                          observacaoTecnica !== 0 &&
                                          status === 5
                                        }
                                      >
                                        Responder ao usuário
                                      </Button>
                                    </div>
                                  )}

                                  <Dialog
                                    fullScreen={fullScreen}
                                    open={openModal}
                                    onClose={handleClose}
                                    aria-labelledby="responsive-dialog-title"
                                    maxWidth="sm"
                                  >
                                    <DialogTitle>
                                      <Typography variant="h5">
                                        Recusar
                                      </Typography>
                                    </DialogTitle>

                                    <DialogContent>
                                      <DialogContentText variant="body1">
                                        Deseja realmente recusar este chamado?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Box
                                        columns={2}
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                        }}
                                      >
                                        <Button
                                          onClick={handleCloseModal}
                                          size="medium"
                                          variant="contained"
                                          color="primary"
                                        >
                                          SIM
                                        </Button>

                                        <Button
                                          onClick={handleClose}
                                          size="medium"
                                          variant="contained"
                                          color="error"
                                        >
                                          NÃO
                                        </Button>
                                      </Box>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {openMsg && (
                        <Dialog open={openMsg} onClose={handleClick}>
                          <Alert
                            severity={tipoDialog}
                            color={corDialog}
                            role="button"
                            onClose={() => {
                              setOpenMsg(false);
                            }}
                            sx={{
                              "& .MuiAlert-icon": {
                                color: "blue",
                              },
                            }}
                          >
                            <AlertTitle></AlertTitle>
                            {mensagemDialog}
                          </Alert>
                        </Dialog>
                      )}

                      <div className="col-12 product-image-thumbs">
                        {dataImagem.map((mitem) => (
                          <div className="product-image-thumb" key={mitem.id}>
                            <img
                              src={`data:image/jpeg;base64, ${mitem.arquivo}`}
                              alt={`Product Thumbnail ${mitem.id}`}
                            />
                          </div>
                        ))}
                      </div>
                      {/* Mostrar a primeira imagem acima */}
                      <div className="col-12">
                        {dataImagem.length > 0 && (
                          <img
                            style={{ width: "100%", height: "auto" }}
                            src={`data:image/jpeg;base64, ${dataImagem[0].arquivo}`}
                            className="product-image"
                            alt="Product Image"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openHistory}
              onClose={handleCloseHistory}
            >
              <DialogTitle>Histórico</DialogTitle>
              <DialogContent>
                <DialogContentText>Motivo do retorno.</DialogContentText>

                <div className="row">
                  <div className="col-12">
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{ width: "20%", textAlign: "left" }}
                            scope="col"
                          >
                            Data Hora{" "}
                          </th>
                          <th
                            style={{ width: "80%", textAlign: "left" }}
                            scope="col"
                          >
                            Descrição do Motivo{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {movimento
                          .sort((a, b) => b.id - a.id)
                          .map((item) => {
                            return (
                              <tr key={item.id}>
                                <td
                                  style={{ textAlign: "left" }}
                                  data-label="Data Hora"
                                >
                                  {" "}
                                  {formatarDataBr(item.datahora, "DataHora")}
                                </td>
                                <td
                                  style={{ textAlign: "left" }}
                                  data-label="Descrição do movimento"
                                >
                                  {" "}
                                  {item.descricao}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseHistory}>Fechar</Button>
              </DialogActions>
            </Dialog>

            {confirmationModalMecanico && (
              <Dialog
                open={confirmationModalMecanico}
                onClose={handleConfirmationModalMecanico}
              >
                <DialogTitle>Alteração de Mecânico</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Deseja realmente alterar o mecânico deste chamado?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleConfirmationModalMecanico}
                    color="error"
                  >
                    Cancelar
                  </Button>
                  <Button onClick={handleChangeMecanico} variant="contained">
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default VisualizaChamadosAdmin;
