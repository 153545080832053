import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import HandymanIcon from "@mui/icons-material/Handyman";
import {
  CorGravidade,
  CorGrav,
  addZeroes,
  formatarData,
  formatarDataBaseDados,
  formatarDataBr,
} from "../../../../utils/utils";
import { DialogsAndChamadosCard } from "./DialogsAndChamadosCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CustomizedAndDialogs({
  children,
  item,
  coreslinhas,
  quantidadeTotal,
}) {
  const [open, setOpen] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  var _contadorLink = "#collapse" + item.id;
  var _contadorId = "collapse" + item.id;

  console.log("CorGravidade", item.tipoChamadoDesc);

  const history = useHistory();

  return (
    <>
      {isMobile ? (
        <DialogsAndChamadosCard
          item={item}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ) : (
        <Accordion
        className={CorGrav(item.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade)}
          sx={{
            mb: 2,
            borderRadius: 2,
            padding: 2,
            marginTop: 2,
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Grid
              container
              spacing={1}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                whiteSpace: "nowrap",
                width: "100%",
                flexWrap: "wrap",
                marginTop: 1,
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={1}
                sx={{ textAlign: "center" }}
              >
                O.S: <br /> <b>{addZeroes(item.id, 8)}</b>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ textAlign: "center" }}
              >
                Abertura: <br /> <b>{formatarDataBr(item.dataAbertura)}</b>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ textAlign: "center" }}
              >
                Solicitante: <br /> <b>{item.solicitante.nome}</b>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={1}
                sx={{ textAlign: "center" }}
              >
                <b>{item.patrimonio.numPatrimonio}</b>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2}
                sx={{ textAlign: "center" }}
              >
                Gravidade:
                <br />
                <b>
                  {item.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade}
                </b>
              </Grid>

              {item.mecanico && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={2}
                  sx={{ textAlign: "center" }}
                >
                  Mecânico:
                  <br /> <b>{item.mecanico?.usuario?.nome}</b>
                </Grid>
              )}

              {item.chamadoPecas.length > 0 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    marginTop: { xs: 2, lg: 0 },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/solicitacaoPecas/${item.id}`);
                    }}
                  >
                    <Box
                      style={{
                        marginRight: "10px",
                        background: "white",
                        borderRadius: "50%",
                        padding: "4px 10px",
                        color: "black",
                      }}
                    >
                      {quantidadeTotal}
                    </Box>
                    SOLICITAÇÃO DE PEÇAS
                  </Button>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>

          <Divider />

          <AccordionDetails
            onClick={handleClickOpen}
            sx={{ cursor: "pointer", marginTop: 3 }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                whiteSpace: "nowrap",
                width: "100%",
                marginInline: -1,
              }}
            >
              <Grid sx={{ textAlign: "center" }}>
                <Typography variant="subtitle2">Descrição</Typography>
                <br />
                <Typography variant="caption">
                  {item.patrimonio.descricao}
                </Typography>
              </Grid>

              <Grid sx={{ textAlign: "center" }}>
                <Typography variant="subtitle2">Setor</Typography>
                <br />
                <Typography variant="caption">
                  {item.setor.descricao}
                </Typography>
              </Grid>

              <Grid sx={{ textAlign: "center" }}>
                <Typography variant="subtitle2">Unid.Produção</Typography>
                <br />
                <Typography variant="caption">
                  {item.setor?.unidadeProducao?.fantasia}
                </Typography>
              </Grid>

              <Grid sx={{ textAlign: "center" }}>
                <Typography variant="subtitle2">
                  Responsável do Setor
                </Typography>
                <br />
                <Typography variant="caption">
                  {item.setor?.responsavel?.nome}
                </Typography>
              </Grid>

              {item.agendamento?.length > 0 && (
                <>
                  <Grid>
                    <Typography variant="subtitle2">
                      Data Agendamento
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {formatarData(item.agendamento[0]?.dataAgendada)}
                    </Typography>
                  </Grid>

                  <Grid>
                    <Typography variant="subtitle2">
                      Data Encerramento
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      {formatarData(item.agendamento[0]?.dataAgendada2)}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {children}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
