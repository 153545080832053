import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";

import { AlertSuccess, AlertDanger } from "./styles";
import { InputLabel, MenuItem, Select, FormControl } from "@mui/material";
import { addZeroes } from "../../../utils/utils";
import "./grid.css";

export const GrupoMaquinaEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [descricao, setDescricao] = useState("");

  const [selectedEtiqueta, setSelectedEtiqueta] = useState([]);
  const [listEtiqueta, setListEtiqueta] = useState([]);
  const [etiqueta, setEtiqueta] = useState([]);
  
  const [dataPatrimonio, setDataPatrimonio] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  /*Editar Registros*/
  const editGrupoMaquinas = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          descricao: descricao,
          etiquetas: selectedEtiqueta,
        };

        await api.put(`grupomaquinario/` + id, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          descricao: descricao,
          etiquetas: selectedEtiqueta,
        };

        //console.log(JSON.stringify(dadosJson))
        await api.post(`grupomaquinario/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/grupomaquinas",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getGrupoMaquinas = async () => {
      await api.get(`grupomaquinario/` + id).then((response) => {
        setDescricao(response.data.records[0].descricao.toUpperCase());
        setSelectedEtiqueta(response.data.records[0].etiquetas.id);
      });
    };

    const getPatrimonio = async () => {
      await api.get(`maquinario/grupo/` + id).then((response) => {
        setDataPatrimonio(response.data.records);
      });
    };

    const getEtiquetas = async () => {
      await api.get(`etiquetas`).then((response) => {
        setListEtiqueta(response.data.records);
        setEtiqueta(response.data.records[0].etiquetas);
      });
    };

    getPatrimonio();
    getGrupoMaquinas();
    getEtiquetas();
  }, [id]);

  const optionsEtiqueta = listEtiqueta.map(function (eti) {
    return { value: eti.id, label: eti.descricao };
  });

  const handleChangeEtiqueta = async (event) => {
    let valor = event.target.value;

    setSelectedEtiqueta(valor);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editGrupoMaquinas}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group was-validated">
                        <InputLabel htmlFor="Inputnome">Descrição</InputLabel>
                        <input
                          type="nome"
                          className="form-control"
                          id="InputNomeGrupoMaquina"
                          placeholder="Descrição..."
                          value={descricao}
                          onChange={(e) => setDescricao(e.target.value)}
                          required="required"
                        />
                        <div className="valid-feedback">Validado.</div>
                        <div className="invalid-feedback">
                          Por favor, preencha esse campo.
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <FormControl>
                        <InputLabel id="demo-controlled-open-select-label">
                          Selecionar Etiqueta
                        </InputLabel>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select-label"
                          sx={{ m: 0, minWidth: 200 }}
                          label="Etiqueta"
                          value={selectedEtiqueta}
                          onChange={handleChangeEtiqueta}
                          spacing={3}
                        >
                          <MenuItem disabled value="0">
                            Selecione
                          </MenuItem>
                          {listEtiqueta
                            .sort((a, b) => a.descricao - b.descricao)
                            .map((item) => (
                              <MenuItem value={item.id}>
                                {item.descricao}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card float-right">
                        <button type="submit" className="btn btn-success">
                          Gravar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-12 table-responsive">
                    <InputLabel htmlFor="Inputnome">
                      Máquinas Vinculadas
                    </InputLabel>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>ID</th>
                          <th style={{ width: "10%" }}>Nº PAT.</th>
                          <th style={{ width: "20%" }}>Patrimônio</th>
                          <th style={{ width: "20%" }}>Setor</th>
                          <th style={{ width: "20%" }}>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataPatrimonio.map((gru) => (
                          <tr key={gru.id}>
                            <td  data-label="ID " >{addZeroes(gru.id, 6)}</td>

                            <td data-label="Nº PAT. ">
                              {gru.numPatrimonio}
                            </td>

                            <td data-label="Patrimônio ">
                              {gru.descricao}
                            </td>

                            <td data-label="Setor ">
                              {gru.maquinarioSetores[0].setores.descricao}
                            </td>

                            <td data-label="Status">
                              {gru.grupo.etiquetas.status == 1 ? " Ativo" : " Inativo "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
