import React, { Component } from "react";
import { KanbanPrint } from "./KanbanPrint";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import Menu from "../../../components/Menu";
import { blue } from "@mui/material/colors";

class KanbanRel extends Component {
  handlePrint = (e) => {
    this.props.history.push("/kanbanPrint");
  };

  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Ambiente de Impressão Kanban ",
      nomeNivelCrumb: "Designados / Kanban",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />

        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-9">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>
                      <div className="col-sm-3">
                        <a className="mr-2 breadcrumb float-sm-right"
                        style={{ color: "blue", fontSize: 13 }} href="/kanban">{this.state.nomeNivelCrumb}</a>
                      </div>
                    </div>
                    <div className="card">
                      <KanbanPrint />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default KanbanRel;
