import React, { Component } from 'react';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Dashboard from '../../components/Dashboard';
import Footer from '../../components/Footer';



class NaoEncontrada extends Component {


    render() {
        return (
            <div className="wrapper">

                <Header />
                <Menu />

                <div className="content-wrapper">


                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                   
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                        <li className="breadcrumb-item active">Página não encontrada</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="error-page">
                            <h2 className="headline text-warning"> 404</h2>
                            <div className="error-content">
                                <h3><i className="fas fa-exclamation-triangle text-warning" /> Haaa nãooo! Página não encontrada.</h3>
                                <p>
                                    A página que esta tentando acessar, não foi encontrada. Clique no meu lateral para continuar navegando nas opções disponíveis.
                                    
                                </p>
                                
                            </div>
                        </div>
                    </section>



                </div>


                <Footer />

            </div>
        );
    }
}

export default NaoEncontrada;
