import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../../services/api";
import { useState } from "react";

export const ModalCardMobile = ({
  open,
  handleClose,
  chamadosSelecionados,
}) => {
  const [mensagemDialog, setMensagemDialog] = useState([]);

  const [corDialog, setCorDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);

  const queryClient = useQueryClient();

  const dadosJson = {
    idChamados: chamadosSelecionados.map((row) => row.id),
  };

  const { data: aprovarPecas, isLoading: isLoadingAprovar } = useQuery(
    ["aprovarPecas"],
    async () => {
      const response = await api.post(`chamado-pecas/detalhes`, {
        ids: dadosJson.idChamados,
      });
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutation, isLoading } = useMutation(
    async () => {
      const dadosConfirmar = {
        statusAprov: "A",
        motivo: "",
        idChamados: dadosJson.idChamados,
        pecas: aprovarPecas?.pecas,
      };

      const response = await api.put(
        `chamado-pecas/aprovar-reprovar/`,
        dadosConfirmar
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chamadoPecas"]);
        setMensagemDialog(`Aprovação realizada com sucesso!`);
        handleClose();
        setOpenMsg(!openMsg);
        setTipoDialog("success");
        setCorDialog("success");
      },
      onError: (error) => {
        setMensagemDialog(
          `Houve um erro na operação de aprovação: ${error.message}`
        );
        setTipoDialog("error");
        setCorDialog("success");
      },
    }
  );

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  return (
    <Dialog open={open}>
      <DialogContent sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "2px dashed",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 4,
              right: 8,
              cursor: "pointer",
              fontSize: 15,
              color: "gray",
            }}
            onClick={handleClose}
          >
            X
          </Box>
          <Typography sx={{ fontSize: 18, color: "green" }}>
            <CheckCircle sx={{ color: "green", marginRight: 1 }} />
            Confirmar Aprovação
          </Typography>

          <Box sx={{ fontWeight: "Bold" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "inherit",
                marginLeft: 2,
                marginRight: 1,
              }}
            >
              Esteja ciente de que, ao liberar o uso dessas peças, será emitida
              uma requisição para saída do almoxarifado. Caso seja necessário
              utilizar o empenho informado, haverá um novo pedido de compra,
              incluindo a quantidade padrão exigida.
            </Typography>
          </Box>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography variant="h5">Patrimônio</Typography>

          {chamadosSelecionados.map((chamado) => (
            <>
              <Card sx={{ my: 2 }}>
                <CardContent sx={{ display: "grid", gap: 1 }}>
                  <BoxWithSpacedGrid label="Chamado" value={chamado?.id} />
                  <BoxWithSpacedGrid
                    label="Patrimônio"
                    value={`PAT-${chamado.patrimonio.id}`}
                  />
                  <BoxWithSpacedGrid
                    label="Descrição"
                    value={chamado?.patrimonio.descricao}
                  />
                  <BoxWithSpacedGrid
                    label="Setor"
                    value={chamado?.setor.descricao}
                  />
                  <BoxWithSpacedGrid
                    label="Unidade"
                    value={chamado?.setor.unidadeProducao.fantasia}
                  />
                </CardContent>
              </Card>

              <Box sx={{ my: 2 }}>
                <Typography variant="h5">Necessidades</Typography>

                {chamado.chamadoPecas?.map((peca) => (
                  <Card sx={{ my: 2 }}>
                    <CardContent sx={{ display: "grid", gap: 1 }}>
                      <BoxWithSpacedGrid label="Chamado" value={chamado?.id} />
                      <BoxWithSpacedGrid
                        label="Cód. Descrição"
                        value={`${peca.matprima.matprima} - ${peca.matprima.descricao}`}
                      />
                      <BoxWithSpacedGrid
                        label="UN."
                        value={peca.matprima.unidade}
                      />
                      <BoxWithSpacedGrid label="Qtde" value={peca.quantidade} />
                      <BoxWithSpacedGrid
                        label="Custo Médio"
                        value={peca.custoMedio}
                      />
                      <BoxWithSpacedGrid
                        label="Estoque"
                        value={peca.qtdEstoque}
                      />
                      <BoxWithSpacedGrid
                        label="Empenhar"
                        value={peca.qtdEmpenhar}
                      />
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            sx={{ color: "gray" }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="success"
            sx={{ minWidth: 100, marginLeft: 1 }}
            onClick={() => mutation()}
            disabled={isLoading}
          >
            Confirmar
          </Button>
        </Box>
      </DialogActions>

      {openMsg && (
        <Dialog open={openMsg} onClose={isLoadingAprovar && handleFecharAlert}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            onClose={() => {
              setOpenMsg(false);
            }}
            sx={{
              "& .MuiAlert-icon": {
                color: "blue",
              },
            }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      )}
    </Dialog>
  );
};

export const BoxWithSpacedGrid = ({ label, value }) => {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <Typography sx={{ fontWeight: 500 }}>{label}</Typography>
      <Typography sx={{ textAlign: "right" }}>{value || ""}</Typography>
    </Box>
  );
};
