import React from "react";
import ReactApexChart from "react-apexcharts";

export const GravidadeChart = ({ gravidade }) => {


  const [gravidades] = React.useState({
    series: gravidade.map((gravid) => gravid.total),
    options: {
      chart: {
        width: 200,
        type: "pie",
      },
      labels: gravidade.map((gravid) => gravid.nome),
      legend : {
        offsetY: 70,
        height: 370,
        fontSize: '14px'
      },
      colors: ["#D1131C", "#044ECD", "#FFD74E"],
      title: {
        text: "GRAVIDADE DE OCORRÊNCIAS",
        align: "left",
        margin: 6,
        style: {
          height: 'auto',
          fontSize: '17px',          
        }
      },
      
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              type: "pie",
            },
            legend: {
              position: "bottom",
              offsetY: 70,
              height: 370,
            },
          },
        },
      ],
    },
  });

  return (
    <div className="shadow-lg bg-white rounded" style={{ padding: 10 }}>
      <div id="chart">
        <ReactApexChart
          options={gravidades.options}
          series={gravidades.series}
          type="pie"
          width={300}
        />
      </div>
    </div>
  );
};
