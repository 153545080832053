import React, { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import { MaquinarioHistoricoGrid } from "./Component/MaquinarioHistoricoGrid";
class MaquinarioHistorico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Análise de Manutenção",
      nomeNivel3Crumb: "analise Tempo",
      nomeNivelCrumb: "Dados do Patrimônio",
      nomeNivel2Crumb: "Lista Registros",
    };
  }

  render() {
    const id = this.props.match.params.id;
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6">
                    <h1 className="m-0" style={{ fontSize: 22 }}>{this.state.nomePagina}{" "}</h1>
                  </div>

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href={`/MaquinarioItem/${id}`}>{this.state.nomeNivelCrumb}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/analiseTempo/">{this.state.nomeNivel3Crumb}</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/maquinarios/">{this.state.nomeNivel2Crumb}</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <MaquinarioHistoricoGrid />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default MaquinarioHistorico;
