import { FormControl, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AlertSuccess, AlertDanger } from "../../Maquinario/Component/styles";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";
import PesquisaMaquinario from "./PesquisaMaquinario";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { formatarDataBaseDadosNew } from "../../../utils/utils";
import dayjs from "dayjs";

const AgendamentosNovo = () => {
  let history = useHistory();
  const { id } = useParams();

  const [observacao, setObservacao] = useState("");
  const [automatizaOS, setAutomatizaOS] = useState(7);

  const [dataInicial, setDataInicial] = useState(
    dayjs(new Date()).subtract(30, "day")
  );
  const [dataFinal, setDataFinal] = useState(dayjs(new Date()));

  const [maquinario, setMaquinario] = useState([]);
  const [descMaqui, setDescMaqui] = useState([]);

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      api //maquinario_setores/maquinario/{id}
        .get(`/maquinario_setores/maquinario/${maquinario}`)
        .then((response) => {
          setDescMaqui(response.data.records);

          setButtonDisabled(response.data.records[0].maquinario.status !== 1 ? true : false);
        });
    } catch (error) {
      console.error("Erro ao consultar a API:", error);
    }
  };

  const editAgendamento = async (e) => {
    var dadosJson = {
      id: id,
      maquinario: parseInt(maquinario),
      dataAgendada: formatarDataBaseDadosNew(dataInicial),
      dataAgendada2: formatarDataBaseDadosNew(dataFinal),
      observacao: observacao,
      automatizaOS: automatizaOS,
    };

    e.preventDefault();

    try {
      if (id != 0) {
        //console.log("Dados Alterados : " + JSON.stringify(dadosJson));
        await api.put(`/agendamento/` + id, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        await api.post(`/agendamento/`, dadosJson);
        //console.log("Dados Adicionado : " + JSON.stringify(dadosJson));
        setStatusRecord({
          type: "success",
          mensagem: "Registro salvo com sucesso",
        });
      }
      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/agendamentos",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro ao salvar este item",
      });
    }
  };

  useEffect(() => {
    const getAgendamentos = async () => {
      //console.log("Dados Adicionado : " + JSON.stringify(dadosJson));
      await api.get(`agendamento/` + id).then((response) => {
        setObservacao(response.data.records[0].observacao.toUpperCase());
        setAutomatizaOS(response.data.records[0].automatizaOS);
        setMaquinario(response.data.records[0].maquinario.id);
        setDataInicial(response.data.records[0].dataAgendada);
        setDataFinal(response.data.records[0].dataAgendada2);
        api //maquinario_setores/maquinario/{id}
          .get(
            `/maquinario_setores/maquinario/` +
              response.data.records[0].maquinario.id
          )
          .then((response) => {
            setDescMaqui(response.data.records);
          });
      });
    };

    getAgendamentos();
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editAgendamento}>
                  <div className="col-md-12">
                    <div className="col-md-12 p-2">
                      <div className="row">
                        {/* <div>
                        <ul>
                          {imagem.map((result) => (
                            <li key={result.id}>
                              <img src={result.maquinarioAlbum.caminho} />
                            </li>
                          ))}
                        </ul>
                      </div> */}
                        <div className="col-md-2 col-sm-2 mt-2">
                          <FormControl>
                            <Stack spacing={3}>
                              <TextField
                                id="my-input"
                                size="small"
                                aria-describedby="my-helper-text"
                                onBlur={handleSubmit}
                                focused
                                variant="outlined"
                                type="text"
                                label="Nº Patrimônio"
                                fullWidth={true}
                                value={maquinario}
                                onChange={(e) => setMaquinario(e.target.value)}
                              />
                            </Stack>
                          </FormControl>
                        </div>
                        <div className="col-md-2 col-sm-2 mt-2">
                          <div className="form-group ">
                            <LocalizationProvider
                              dateAdapter={AdapterMoment}
                              adapterLocale="pt-br"
                            >
                              <Stack spacing={3}>
                                <DatePicker
                                  label="Data Inicial"
                                  value={dataInicial}
                                  onChange={(newValue) => {
                                    setDataInicial(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label="Size"
                                      id="outlined-size-small"
                                      defaultValue="Small"
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                  dayOfWeekFormatter={(day) => {
                                    const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                    return daysOfWeek[day];
                                  }}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>

                        <div className="col-md-2 col-sm-2 mt-2">
                          <div className="form-group">
                            <LocalizationProvider
                              dateAdapter={AdapterMoment}
                              adapterLocale="pt-br"
                            >
                              <Stack spacing={3}>
                                <DatePicker
                                  label="Data Final"
                                  value={dataFinal}
                                  onChange={(newValue) =>
                                    setDataFinal(newValue)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      label="Size"
                                      id="outlined-size-small"
                                      defaultValue="Small"
                                      size="small"
                                      {...params}
                                    />
                                  )}
                                  dayOfWeekFormatter={(day) => {
                                    const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                    return daysOfWeek[day];
                                  }}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                        </div>
                        <div className="col-sm-2 col-md-2 mt-2">
                          <FormControl
                          required
                          title="Quantidade de dias">
                            <Stack spacing={3}>
                              <TextField                              
                                id="my-input"
                                sx={{maxWidth: 120}}
                                size="small"
                                aria-describedby="my-helper-text"
                                value={automatizaOS}
                                focused
                                variant="outlined"
                                type="text"
                                label="Automatiza OS"                           
                                onChange={(e) => setAutomatizaOS(e.target.value)}
                              />
                            </Stack>
                          </FormControl>
                        </div>
                        <div className="col-md-12">
                          {descMaqui.length > 0 && (
                            <PesquisaMaquinario maquinario={descMaqui} />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            htmlFor="inputExperience"
                            className="col-sm-6 col-form-label"
                          >
                            Observação
                          </label>
                          <textarea
                            className="form-control"
                            id="observacao"
                            placeholder="Observação..."
                            value={observacao}
                            onChange={(e) => setObservacao(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-success" disabled={buttonDisabled}>
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};

export default AgendamentosNovo;
