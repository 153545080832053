import {
  Radio,
  Stack,
  Autocomplete,
  CircularProgress,
  TextField,
  Box,
} from "@mui/material";
import { func, object } from "prop-types";
import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import api from "../../../services/api";
import SemImg from "../../../assets/sem-foto.svg";
import { AlertSuccess, AlertDanger } from "./styles";
import { addZeroes, formatarDataBr } from "../../../utils/utils";

export const MecanicoEditar = (props) => {
  let history = useHistory();

  const { id } = useParams();
  const [usuario, setUsuario] = useState("");
  const [setor, setSetor] = useState([]);
  const [dataChamados, setDataChamados] = useState([]);
  const [observacao, setObservacao] = useState();
  const [status, setStatus] = useState("1");
  const [imagem, setImagem] = useState("");
  const [blobImage, setBlobImage] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });
  const [listUsuario, setListUsuario] = useState([]);
  const [listSetor, setListSetor] = useState([]);

  const [listEspecialidades, setListEspecialidades] = useState([]);

  const [selectedUsuario, setSelectedUsuario] = useState(0);
  const [selectedSetor, setSelectedSetor] = useState([]);
  const [selectedSetorArray, setSelectedSetorArray] = useState();
  const [selectedEspecialidadesArray, setSelectedEspecialidadesArray] =
    useState();

  const [values, setValues] = useState([]);

  const idParam = id === 0 ? null : id;
  const [aguardando, setAguardando] = useState(true);

  const [mecSetores, setMecSetores] = useState([]);
  const [mecEspecialidades, setMecEspecialidades] = useState([]);

  const [uDescricao, setUDescricao] = useState([]);
  const [uEmail, setUEmail] = useState([]);

  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [images, setImages] = useState([]);
  const [indexPrincipal, setIndexPrincipal] = useState(0);
  const [imagemPatrimonio, setImagemPatrimonio] = useState([]);

  /*Editar Registros*/
  const editMecanico = async (e) => {
    /**
        LISTA DE IDS PREPARANDO PARA COMPOR O JSON DE INSERCAO OU UPDATE          
         VISTO QUE O COMPONENTE REACTSELECT TRATA O ID COMO VALUE 
         E POR ISSO É NECESSARIO REALIZAR ESSA CONVESAO 
         */

    const setorArray = [];
    mecSetores.map(function (ms) {
      setorArray.push(`${parseInt(ms.value)}`);
    });

    const especialidadesArray = [];
    mecEspecialidades.map(function (ms) {
      especialidadesArray.push(`${parseInt(ms.value)}`);
    });

    var dadosJson = {
      id: id,
      setores: setorArray,
      especialidades: especialidadesArray,
      observacao: observacao,
      usuario: selectedUsuario != null ? selectedUsuario : usuario,
      status: status,
      imagem: imagem,
    };

    e.preventDefault();
    try {
      if (id !== "0") {
        await api.put(`mecanico/` + id, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        await api.post(`mecanico/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/mecanicos",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  const telaResponsiva = (ParAjusta) => {
    if (ParAjusta !== "0") {
      return "col-md-9";
    } else {
      return "col-md-12";
    }
  };

  useEffect(() => {
    const getMecanico = async () => {
      if (id != "0") {
        await api.get(`mecanico/` + id).then((response) => {
          const arrSMec = [];
          const arrSMecEspecialidades = [];

          response.data.records[0].setores.map(function (ms) {
            arrSMec.push({
              value: `${parseInt(ms.setor.id)}`,
              label: `${ms.setor.descricao}`,
            });
          });

          response.data.records[0].especialidades.map(function (ms) {
            arrSMecEspecialidades.push({
              value: `${parseInt(ms.especialidades.id)}`,
              label: `${ms.especialidades.nome}`,
            });
          });

          api
            .get(`usuarioDetalhe/` + response.data.records[0].usuario.id)
            .then((response) => {
              setUDescricao(response.data.records[0].descricao);
              setUEmail(response.data.records[0].email);
            });
          setMecSetores(arrSMec);
          setMecEspecialidades(arrSMecEspecialidades);

          setSetor(response.data.records[0].setores);
          setSelectedSetor(response.data.records[0].setores);

          setObservacao(
            response.data.records[0].observacao == null
              ? ""
              : response.data.records[0].observacao
          );

          setUsuario(response.data.records[0].usuario.id);
          setSelectedUsuario(response.data.records[0].usuario.id);
          setStatus(
            response.data.records[0].status == null
              ? 0
              : response.data.records[0].status
          );
          setImagem(response.data.records[0].imagem);
          setBlobImage(response.data.records[0].blobImage);
          setAguardando(false);
        });
      }
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
        if (id === "0") {
          setSetor(response.data.records[0].id);
        }
      });
    };

    const getChamados = async () => {
      await api.get(`chamado/mecanico/` + id).then((response) => {
        setDataChamados(response.data.records);
      });
    };

    const getEspecialidades = async () => {
      await api.get(`especialidades`).then((response) => {
        setListEspecialidades(response.data.records);
      });
    };

    getSetores();
    getEspecialidades();
    if (id !== "0") {
      getMecanico();
      getChamados();
    }
  }, [idParam]);

  /**
   * RESPONSAVEL POR ARMAZENAR OS VALORES TRAZIDOS PELO GET DO CENTRO DE CUSTO
   * - usado no bloco abaixo armazenando o ID
   */
  const [selectedOptionSetor, setSelectedOptionSetor] = useState(null);
  useEffect(() => {
    const getSetor = async () => {
      await api.get(`setores`).then((response) => {
        setSelectedOptionSetor(response.data.records[0].id);
        if (id === "0") {
          setSetor(response.data.records[0].id);
        }
      });
    };

    getSetor();
  }, []);

  /**
   * RESPONSAVEL POR ARMAZENAR OS VALORES TRAZIDOS PELO GET DO RESPONSAVEL
   * - usado no bloco abaixo
   */
  const [selectedOptionUsuario, setSelectedOptionUsuario] = useState(null);

  useEffect(() => {
    const getUsuario = async () => {
      await api.get(`usuarioDetalhe`).then((response) => {
        setListUsuario(response.data.records);
        setSelectedOptionUsuario(response.data.records[0].codUsuario);
      });
    };
    getUsuario();
  }, []);

  /** OPTION RESPONSAVEL
   * Função para formatar os dados que serao utilizado no ReactSelect
   * - o formato que deve retornar é Value e Label
   */
  const optionsUsuario = listUsuario.map(function (respons) {
    return { value: respons.codUsuario, label: respons.descricao };
  });

  /**
   * Responsavel por listar as mudanças feitas no componente
   * onde recebe o const vindo da lista de responsavel
   * @param {*} selectedOptionUsuario
   */
  const handleChangeUsuario = (selectedOptionUsuario) => {
    setSelectedUsuario(selectedOptionUsuario.value);
  };

  /** OPTION CENTRO DE CUSTO *******************************************
   * Função para formatar os dados que serao utilizado no ReactSelect
   * - o formato que deve retornar é Value e Label
   */
  const optionsSetor = listSetor.map(function (paramSetor) {
    return { value: paramSetor.id, label: paramSetor.descricao };
  });

  /**
   * Responsavel por listar as mudanças feitas no componente
   * onde recebe o const vindo da lista de responsavel
   * @param {*} selectedOptionSetor
   */
  const handleChangeSetor = (selectedOptionSetor) => {
    setSelectedSetorArray([]);

    const arr = [];
    selectedOptionSetor.map(function (ms) {
      arr.push({ value: `${parseInt(ms.value)}`, label: `${ms.label}` });
      setSelectedSetorArray(arr);
      console.log("Dados JSON " + `${JSON.stringify(selectedSetorArray)}`);
    });

    setMecSetores(arr);
  };

  const handleChangeEspecialidades = (selectedOptionEspecialidades) => {
    setSelectedEspecialidadesArray([]);

    const arr = [];
    selectedOptionEspecialidades.map(function (ms) {
      arr.push({ value: `${parseInt(ms.value)}`, label: `${ms.label}` });
      setSelectedEspecialidadesArray(arr);
      console.log("Dados JSON " + `${JSON.stringify(selectedEspecialidadesArray)}`
      );
    });

    setMecEspecialidades(arr);
  };

  const getMecanicoImagem = async () => {
    await api.get(`mecanico/` + id).then((response) => {
      setImagem(response.data.records[0].imagem);
      setBlobImage(response.data.records[0].blobImage);
      setAguardando(false);
    });
  };

  function uploadImagem(e) {
    var data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("codigoMecanico", id);

    api
      .post(`mecanico/avatar`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            getMecanicoImagem();
          } else if (res.status == 401) {
            alert("Algo Deu errado ao enviar imagem");
          }
        },
        function (err) {
          alert("Error submitting form!");
        }
      );
  }

  const getImagesPatrimonio = (idChamado) => {
    api.get(`maquinarioalbum/maquinario/` + idChamado).then((response) => {
      setImagemPatrimonio(response.data.records[indexPrincipal]);
    });
  };

  /**
   * Trata a Renderizacao da imagem para ser exibida
   * de acordo com o valor preenchido no Objeto imagemPrincipal
   * @param {*} imPrincipal
   * @returns
   */
  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image profile-user-img img-fluid img-circle"
              Style={`height: 150px; width: 150px;`}
              src={`data:image/jpeg;base64, ${imPrincipal}`}
              alt="Photo"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  function verificarImagemMaq(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            {/* <img
                              className="attachment-img"
                              src={`data:image/jpeg;base64, ${verImageMaq(chamadosMec.id)}`}
                            /> */}
            <img
              className="attachment-img"
              Style={`max-height: 100px; max-height: 100px}`}
              src={`data:image/jpeg;base64, ${imPrincipal}`}
              alt="Foto"
            />
          </div>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="attachment-img"
              Style={`max-height: 150px; max-height: 150px}`}
              src={SemImg}
              alt="Foto"
            />
          </div>
        </div>
      );
    }
  }

  const optionsSetores = listSetor.map(function (ms) {
    return { value: ms.id, label: ms.descricao };
  });

  const optionsEspecialidades = listEspecialidades.map(function (ms) {
    return { value: ms.id, label: ms.nome };
  });

  const handleChangeMecSetores = (selected) => {
    mecSetores.push({ value: selected[0].value, label: selected[0].label });

    mecSetores.map(function (ms) {
      //console.log(">>>>>>> " + `${JSON.stringify(ms.value)}`);
    });
  };

  console.log("DataChamados ......................... ", dataChamados);

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          {!aguardando && (
            <div>
              <div className="card card-comment card-outline">
                <div className="card-body">
                  {verificarImagem(blobImage)}

                  <h3
                    className="profile-username text-center"
                    style={{ color: "FFF", fontSize: "18px" }}
                  >
                    {uDescricao}
                  </h3>
                  <h3
                    className="profile-username text-center"
                    style={{ color: "FFF", fontSize: "14px" }}
                  >
                    {uEmail}
                  </h3>

                  <div className="row">
                    {id === "0" ? (
                      <p>Gravar o registro para inserir imagens!</p>
                    ) : (
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <label
                            for="arquivo"
                            className="btn btn-primary btn-block"
                          >
                            Adicionar Imagem
                          </label>
                          <input
                            type="file"
                            id="arquivo"
                            Style="display:none"
                            onChange={(e) => uploadImagem(e)}
                          ></input>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={telaResponsiva(id)}>
          <div className="card">
            <div className="card-body">
              <ul
                className="nav nav-pills"
                id="custom-content-below-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-content-below-dadosmecanico-tab"
                    data-toggle="pill"
                    href="#custom-content-below-dadosmecanico"
                    role="tab"
                    aria-controls="custom-content-below-dadosmecanico"
                    aria-selected="true"
                  >
                    <i className="fa fa-user-circle"></i> Dados do Mecânico
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-content-below-atividades-tab"
                    data-toggle="pill"
                    href="#custom-content-below-atividades"
                    role="tab"
                    aria-controls="custom-content-below-atividades"
                    aria-selected="false"
                  >
                    <i className="fa fa-wrench"></i> Atividades Concluídas
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="custom-content-below-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-content-below-dadosmecanico"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-dadosmecanico-tab"
                >
                  {statusRecord.type === "erro" ? (
                    <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                  ) : (
                    ""
                  )}
                  {statusRecord.type === "success" ? (
                    <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                  ) : (
                    ""
                  )}

                  <form className="form-horizontal" onSubmit={editMecanico}>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="col-sm-6 col-form-label">
                          Usuário
                        </label>

                        <Select
                          value={optionsUsuario.filter(
                            (obj) => obj.value === selectedUsuario
                          )}
                          onChange={handleChangeUsuario}
                          options={optionsUsuario}
                          clearable={"true"}
                          searchable={"true"}
                          labelKey={"descricao"}
                          valueKey={"codusuario"}
                          noOptionsMessage={() => "Nenhum registro Encontrado"}
                          required="required"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-6 col-form-label">Setor</label>

                        <Select
                          value={mecSetores}
                          isMulti
                          name="_mecSetores"
                          onChange={handleChangeSetor}
                          options={optionsSetores}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          noOptionsMessage={() =>
                            "Nenhum registro Para ser selecionado"
                          }
                          required="required"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label className="col-sm-6 col-form-label">
                          Especialidades
                        </label>

                        <Select
                          value={mecEspecialidades}
                          isMulti
                          name="_mecEspecialidades"
                          onChange={handleChangeEspecialidades}
                          options={optionsEspecialidades}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          noOptionsMessage={() =>
                            "Nenhum registro Para ser selecionado"
                          }
                          required="required"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-sm-6 col-form-label">
                        Observação
                      </label>
                      <div className="col-sm-12">
                        <textarea
                          className="form-control"
                          id="observacao"
                          name="observacao"
                          onChange={(e) => setObservacao(e.target.value)}
                          placeholder="Observação"
                          defaultValue={observacao}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <label className="col-sm-2 col-form-label">Status </label>
                      <div className="col-sm-12">
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Ativo
                        </FormLabel>
                        <Radio
                          checked={status == "1"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="1"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "1" }}
                        />
                        <FormLabel id="demo-controlled-radio-buttons-group">
                          Inativo
                        </FormLabel>
                        <Radio
                          checked={status == "0"}
                          onChange={(e) => setStatus(e.target.value)}
                          value="0"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "0" }}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-10">
                        <button type="submit" className="btn btn-success">
                          {" "}
                          Gravar{" "}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-content-below-atividades"
                  role="tabpanel"
                  aria-labelledby="custom-content-below-atividades-tab"
                >

                  <div className="accordion" id="accordionExample">
                    {dataChamados
                    .filter(chamadosMec => chamadosMec.status == 3)
                    .map((chamadosMec) => (
                      <div className="card" key={chamadosMec.id}>
                        <div className="card-header" id="headingOne">
                          <h6 className="mb-0">
                            <button className="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapse" + chamadosMec.id} aria-expanded="true" aria-controls={"collapse" + chamadosMec.id}>
                           
                              <strong>
                                      <a className="mr-2">Chamado N°. : {addZeroes(chamadosMec.id, 8)} - </a>
                                      
                                      <a className="mr-2" >Setor : {chamadosMec.setor.descricao} - </a>
                                      <a className="mr-2">{formatarDataBr(chamadosMec.dataAbertura)}
                                      </a>
                              </strong>

                            </button>
                          </h6>
                        </div>
                        <div id={"collapse" + chamadosMec.id} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div className="card-body">
                            
                            <div className="card-body">
                              {/* post text */}
                              <span className="description">
                                <p>
                                  <a>
                                    <strong className="mr-2">Solicitante: </strong>
                                    {chamadosMec.solicitante.nome}
                                  </a>
                                  </p>

                                  <p>
                                  <a>
                                    <strong className="mr-2" >Motivo da Abertura: </strong>
                                    {chamadosMec.motivoAbertura}
                                  </a>
                                  </p>
                                  <p>
                                  <a>
                                    <strong>Laudo Técnico :</strong>
                                    {chamadosMec.laudoTecnico}
                                  </a>
                                </p>
                              </span>
                              {/* Attachment */}
                              <div>
                                <div>
                                  {/* {getImagesPatrimonio(chamadosMec.patrimonio.id)} */}
                                  {/* {verificarImagemMaq(imagemPatrimonio?.blobImage)} */}
                                  <div className="attachment-pushed">
                                    <div className="attachment-heading">
                                      <a>
                                        <a>
                                          <strong>Equipamento:</strong>{" "}
                                          {chamadosMec.patrimonio.descricao}
                                        </a>
                                        <br />
                                        <a>
                                          <strong>Nº Série: </strong>
                                          {chamadosMec.patrimonio.numSerie}
                                        </a>
                                        <br />
                                        <a>
                                          <strong>Nº Patrimônio: </strong>
                                          {chamadosMec.patrimonio.numPatrimonio}
                                        </a>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
