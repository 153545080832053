import { Radio } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import {
  AlertSuccess,
  AlertDanger,
} from "../../../Especialidades/Component/styles";
import api from "../../../../services/api";

export const TipoChamadoEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [tipo, setTipo] = useState("");
  const [observacao, setObservacao] = useState();
  const [status, setStatus] = useState("1");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  /*Editar Registros*/
  const editTipoChamado = async (e) => {
    e.preventDefault();
    try {
      await api.post(`/tipoChamado/`, { id, tipo, observacao, status });

      setStatusRecord({
        type: "success",
        mensagem: "Registro atualizado com sucesso",
      });

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/tipochamados",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na edição deste item",
      });
    }
  };

  useEffect(() => {
    const getTipoChamado = async () => {
      await api.get(`/tipoChamado/` + id).then((response) => {
        setTipo(response.data.records[0].tipo.toUpperCase());
        setObservacao(response.data.records[0].observacao);

        setStatus(
          response.data.records[0].status == null
            ? 0
            : response.data.records[0].status
        );
      });
    };
getTipoChamado();
    if (id !== "0") {
      
    }
  }, [id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editTipoChamado}>
                  <label
                    htmlFor="inputEmail"
                    className="col-sm-2 col-form-label"
                  >
                    Tipo
                  </label>
                  <div className="row">
                    <div className="col-md-12 was-validated">
                      <input
                        type="text"
                        className="form-control"
                        id="tipo"
                        name="tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        placeholder="Tipo..."
                        required="required"
                      />
                      <div className="valid-feedback">Validado.</div>
                      <div className="invalid-feedback">
                        Por favor, preencha esse campo.
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-md-12 col-form-label">
                      Observação
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="observacao"
                      name="observacao"
                      value={observacao}
                      onChange={(e) => setObservacao(e.target.value)}
                      placeholder="Observação..."
                    />
                  </div>

                  <div className="row">
                    <label className="col-sm-12 col-form-label">Status</label>
                    <div className="col-sm-10">
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Ativo
                      </FormLabel>
                      <Radio
                        checked={status == "1"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="1"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "1" }}
                      />
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Inativo
                      </FormLabel>
                      <Radio
                        checked={status == "0"}
                        onChange={(e) => setStatus(e.target.value)}
                        value="0"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "0" }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-success">
                        {" "}
                        Gravar{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
