import moment from "moment";
import api from "../services/api";


export const addZeroes = (num, len) => {
    let numberWithZeroes = String(num);
    let counter = numberWithZeroes.length;

    while (counter < len) {

        numberWithZeroes = "0" + numberWithZeroes;

        counter++;

    }

    return numberWithZeroes;
}

export const remover_acentos = (str) => {

    return str.normalize("NFD").replace(/[^a-zA-Z\s]/g, "");

}

export const formatarData = (stringData, DataHora) => {

  let dataInput = stringData;
  let data = new Date(dataInput);
  let dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  
  const horaFormatada = data.toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  
  if (DataHora == "DataHora"){
      return dataFormatada + " " + horaFormatada;
  } else {
      return dataFormatada;
  }
  
}

export const formatarDataBr = (stringData) => {

    let dataInput = stringData;
    let data = new Date(dataInput);
    let dataFormatada = data.toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    const horaFormatada = data.toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' });

    return dataFormatada + " " + horaFormatada;
}

export const formatarDataBaseDados = (stringData) => {

  let dateObj = new Date(stringData);

  /* let dateString = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit'
  }).replace(/\//g, '-') */

//console.log(moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    
      return moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');

  
}
export const formatarDataBaseDadosNew = (stringData) => {

  let dateObj = new Date(stringData);

  /* let dateString = dateObj.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit'
  }).replace(/\//g, '-') */

//console.log(moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
    
      return moment(dateObj, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

  
}

export const CorGrav = (ParCor) => {
  if (ParCor === "ALTA") {
    return " card-danger card-outline";
  } else if (ParCor === "MEDIA") {
    return " card-warning card-outline";
  } else {
    return " card-info card-outline";
  }
};

export const CorGravidade = (ParCor) => {
    if (ParCor === "ALTA") {
      return "card card-danger card-outline";
    } else if (ParCor === "MEDIA") {
      return "card card-warning card-outline";
    } else {
      return "card card-info card-outline";
    }
  };

  export const CorGravidadeKanban = (ParCor) => {
    if (ParCor === "ALTA") {
      return "card-danger card-outline";
    } else if (ParCor === "MEDIA") {
      return "card-warning card-outline";
    } else {
      return "card-info card-outline";
    }
  };

  export const formatReal = (value) => {
    let number = parseFloat(value);

    // Verifica se o número é inteiro
    if (Number.isInteger(number)) {
        return `${number},00`;
    } else {
        return number.toFixed(2).replace('.', ',');
    }
}
 
 


export default { addZeroes, formatarDataBr, formatarDataBaseDados, formatarDataBaseDadosNew, formatarData, CorGravidadeKanban, formatReal};

