import dayjs from "dayjs";
import React, { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [dateValue1, setDateValue1] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(dayjs().format("YYYY-MM-DD"));

  const updateFilter = (newFilter) => {
    setDateValue1(newFilter);
    
  };
const updateFilter2 = (newFilter) => {
    setDateValue2(newFilter);
}
  return (
    <FilterContext.Provider value={{ dateValue1, dateValue2, updateFilter, updateFilter2 }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
    return useContext(FilterContext)
}