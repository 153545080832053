import React, { Component } from "react";
import { MenuMaquinario } from "../pages/Maquinario/Component/MenuMaquinario";
import { Search } from "./Search";

class Header extends Component {
  render() {
    
    return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
            <MenuMaquinario />
          </ul>
          <ul className="navbar-nav ml-auto">
            <div className="">
            <Search />
            </div>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Header;
