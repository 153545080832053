import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import SemImg from "../../../assets/sem-foto.svg";
import { AlertSuccess, AlertDanger } from "./styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Button, FormLabel } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Radio,
  TextField,
} from "@mui/material";

import Select from "react-select";
import { ProgressBar } from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import 'dayjs/locale/pt-br';
import "moment/locale/pt-br";
import dayjs from "dayjs";
import { addZeroes } from "../../../utils/utils";
import VisualizaChamados from "../../Kanban/Chamados";
import { QRCodeCanvas } from "qrcode.react";

export const MaquinarioEditar = () => {
  let history = useHistory();

  const { id } = useParams();

  const [maquinarioId, setMaquinarioId] = useState(0);
  const [numSerie, setNumSerie] = useState("");

  const [descricao, setDescricao] = useState("");
  const [numPatrimonioAdd, setnumPatrimonioAdd] = useState("");
  const [numPatrimonio, setNumPatrimonio] = useState("");

  const [fornecedor, setFornecedor] = useState("");
  const [listFornecedor, setListFornecedor] = useState([]);
  const [selectedFornecedor, setSelectedFornecedor] = useState(0);

  const [fabricante, setFabricante] = useState("");
  const [listFabricantes, setListFabricantes] = useState([]);
  const [selectedFabricante, setSelectedFabricante] = useState(0);

  const [grupo, setGrupo] = useState("");
  const [listGrupo, setListGrupo] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState(0);

  const [setor, setSetor] = useState("");
  const [listSetor, setListSetor] = useState([]);

  const [dadosTecnicos, setDadosTecnicos] = useState("");
  const [manual, setManual] = useState("");
  const [status, setStatus] = useState(1);
  const [statusGarantia, setStatusGarantia] = useState("0");
  const [images, setImages] = useState([]);
  const [imagemPrincipal, setImagemPrincipal] = useState([]);
  const [arquivo, setArquivo] = useState([]);
  const [dataCadastro, setDataCadastro] = useState("");
  const [dataCompra, setDataCompra] = useState("");
  const [marcaProduto, setMarcaProduto] = useState("");
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState(0);
  const [valorAquisicao, setValorAquisicao] = useState("");
  const [valorResidual, setValorResidual] = useState("");
  const [vidaUtil, setVidaUtil] = useState("");
  const [estadoConservacao, setEstadoConservacao] = useState("");
  const [taxaDepreciacao, setTaxaDepreciacao] = useState("");
  const [dataGarantia, setDataGarantia] = useState("");

  const [aguardando, setAguardando] = useState(true);
  const [statusImagem, setStatusImagem] = useState(false);
  const [indexPrincipal, setIndexPrincipal] = useState(0);

  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmDialogDel, setOpenConfirmDialogDel] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [value, setValue] = useState(dayjs(new Date()));
  const [progress, setProgress] = useState();
  const [tipoAquisicao, setTipoAquisicao] = useState(null);

  const isStatusValid = status === 2;
  const [showDesignados, setShowDesignados] = useState(false);
  const [maxWidth, setMaxWidth] = useState("md");
  const [numChamado, setNumChamado] = useState("");
  const [dataPatr, setDataPatr] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedChamadoId, setSelectedChamadoId] = useState(null);

  /* valores da caixa de dialog 
  md = medio
  xs = pequeno
  sm = default (entre o pequeno  )
  lg = largo
  xl = grande
   */

  const handleClickOpen = () => {
    setAguardando(true);
    let idReg = localStorage.getItem("idPat");
    api.get(`maquinarioalbum/maquinario/` + idReg).then((response) => {
      setImages(response.data.records);
      setImagemPrincipal(response.data.records[indexPrincipal]);
      setAguardando(false);
    });
    setModalIsOpen(false);
    setOpen(true);
  };

  const handleClose = () => {
    if (imagemPrincipal) {
      setOpen(false);

      const redVoltar = setTimeout(() => {
        history.push({
          pathname: "/maquinarios",
        });
        localStorage.removeItem("idPat");
      }, 1000);

      return () => clearTimeout(redVoltar);
    } else {
      setStatusImagem(true);
    }
  };

  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const alteraImagemPrincipal = (item, idx) => {
    setImagemPrincipal(item);
    setIndexPrincipal(idx);
  };
  const telaResponsiva = (ParAjusta) => {
    if (ParAjusta !== "0") {
      return "col-md-9";
    } else {
      return "col-md-12";
    }
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleOpenConfirmDialogDel = () => {
    setOpenConfirmDialogDel(true);
  };

  const deleteItemSubChamado = async (img) => {
    try {
      await api.post(`maquinarioalbum/apagar-arquivo`, img);
      getImages();
      getArquivo();
    } catch (err) {
      console.log(err);
    }
  };

  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  const converterValores = (string) => {
    let floatValue = replaceAll(string, ".", "");

    return floatValue.replace(",", ".");
  };

  const handleChangeAquisicao = (event) => {
    const { value } = event.target;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Remove todos os caracteres que não sejam números

    const formattedValue = formatMoney(cleanedValue);

    setValorAquisicao(formattedValue);
  };

  const handleChangeResidual = (event) => {
    const { value } = event.target;
    const cleanedValue = value.replace(/[^0-9]/g, ""); // Remove todos os caracteres que não sejam números

    const formattedValue = formatMoney(cleanedValue);

    setValorResidual(formattedValue);
  };

  const formatMoney = (value) => {
    const numericValue = parseInt(value, 10) / 100; // Divide por 100 para obter o valor em Reais

    if (value === "") {
      return 0;
    }

    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
  };

  const formatarMoedaReal = (value) => {
    const numericValue = parseFloat(value);

    const formattedValue = numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formattedValue;
  };

  /*Editar Registros*/
  const editMaquinario = async (e) => {
    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          numSerie: numSerie,
          numPatrimonioAdd: numPatrimonioAdd,
          descricao: descricao,
          fornecedor: selectedFornecedor,
          numPatrimonio: numPatrimonio,
          fabricante: selectedFabricante,
          grupo: selectedGrupo,
          dadosTecnicos: dadosTecnicos,
          manual: manual,
          status: status,
          dataCadastro: dataCadastro,
          dataCompra: dataCompra,
          marcaProduto: marcaProduto,
          nNotaFiscal: numeroNotaFiscal,
          garantia: statusGarantia,
          dataGarantia: dataGarantia,
          qrcode: qrCode,
          tipoAquisicao: tipoAquisicao,
          valorAquisicao: converterValores(valorAquisicao)
            .replace("R$", "")
            .trim(),
          valorResidual: converterValores(valorResidual)
            .replace("R$", "")
            .trim(),
          vidaUtil: vidaUtil,
          estadoConservacao: estadoConservacao,
          taxaDepreciacao: taxaDepreciacao,
        };

        await api.put(`maquinario/` + id, dadosJson);
        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          numSerie: numSerie,
          numPatrimonioAdd: numPatrimonioAdd,
          descricao: descricao,
          fornecedor: selectedFornecedor,
          fabricante: selectedFabricante,
          grupo: selectedGrupo,
          dadosTecnicos: dadosTecnicos,
          manual: manual,
          status: status,
          dataCadastro: dataCadastro,
          dataCompra: dataCompra,
          marcaProduto: marcaProduto,
          nNotaFiscal: numeroNotaFiscal,
          garantia: statusGarantia,
          dataGarantia: dataGarantia,
          qrcode: qrCode,
          tipoAquisicao: tipoAquisicao,
          valorAquisicao: converterValores(valorAquisicao)
            .replace("R$", "")
            .trim(),
          valorResidual: converterValores(valorResidual)
            .replace("R$", "")
            .trim(),
          vidaUtil: vidaUtil,
          estadoConservacao: estadoConservacao,
          taxaDepreciacao: taxaDepreciacao,
        };
        await api.post(`maquinario/`, dadosJson).then((res) => {
          localStorage.setItem("idPat", res.data.records[0].id);
        });

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        let idReg = localStorage.getItem("idPat");

        if (idReg == "0") {
          history.push({
            pathname: "/maquinarios",
          });
        } else if (id === "0") {
          handleClickOpen();
        } else {
          history.push({
            pathname: "/maquinarios",
          });
        }
      }, 1000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  const duplicaRegistro = async (e) => {
    e.preventDefault();
    try {
      if (confirm("Você tem certeza que Deseja Duplicar este Registro ?")) {
        var dadosJson = {
          id: id,
        };

        await api.post(`/maquinario/duplicar-maquinario`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro Duplicado com sucesso",
        });

        const redirecionar = setTimeout(() => {
          history.push({
            pathname: "/maquinarios",
          });
        }, 1000);

        return () => clearTimeout(redirecionar);
      } else {
        // Aqui fazemos algo quando o usuário cancela
      }
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na Operação deste item",
      });
    }
  };

  function uploadImagem(e) {
    var data = new FormData();

    var idReg = localStorage.getItem("idPat");

    data.append("file", e.target.files[0]);
    data.append("idMaquinario", id !== "0" ? id : idReg);
    data.append("posicao", images.length + 1);

    api
      .post(`maquinarioalbum/enviar-arquivo`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            setAguardando(true);
            api
              .get(`maquinarioalbum/maquinario/` + (id !== "0" ? id : idReg))
              .then((response) => {
                setImages(response.data.records);
                setImagemPrincipal(response.data.records[indexPrincipal]);
                getImages();
                getArquivo();

                setAguardando(false);
              });
          } else if (res.status == 401) {
            alert("Erro ao enviar a imagem");
          }
        },
        function (err) {
          alert("Erro no modo de Submeter!");
        }
      );
  }
  const excluirFoto = async (img) => {
    try {
      //console.log("entrar na api", img)
      await api.post(`maquinarioalbum/apagar-arquivo`, img);

      getImages();


      
      getArquivo();

    } catch (err) {
      console.log(err);
    }
  };
  const alteraStatusImagem = async (img) => {
    try {
      await api.put(`maquinarioalbum/` + img.id, img);

      getImages();
    } catch (err) {
      console.log(err);
      setNumPatrimonio;
    }
  };

  useEffect(() => {
    const getMaquinario = async () => {
      await api.get(`maquinario/` + id).then((response) => {
        setMaquinarioId(response.data.records[0].id);
        setNumSerie(response.data.records[0].numSerie);
        setnumPatrimonioAdd(response.data.records[0].numPatrimonioAdd);
        setDescricao(response.data.records[0].descricao.toUpperCase());
        setNumPatrimonio(response.data.records[0].numPatrimonio);
        setFornecedor(response.data.records[0].fornecedor.cgcCpf);
        setFabricante(response.data.records[0].fabricante.id);
        setSelectedFornecedor(response.data.records[0].fornecedor.cgcCpf);
        setSelectedFabricante(response.data.records[0].fabricante.id);
        setSelectedGrupo(response.data.records[0].grupo.id);
        setDadosTecnicos(response.data.records[0].dadosTecnicos.toUpperCase());
        setManual(response.data.records[0].manual);
        setDataCadastro(response.data.records[0].dataCadastro);
        setDataCompra(response.data.records[0].dataCompra);
        setMarcaProduto(response.data.records[0].marcaProduto);
        setNumeroNotaFiscal(response.data.records[0].nroNotaFiscal);
        setStatusGarantia(response.data.records[0].garantia);
        setStatus(response.data.records[0].status);
        setQrCode(response.data.records[0].numPatrimonio);
        setDataGarantia(response.data.records[0].dataGarantia);
        setTipoAquisicao(response.data.records[0].tipoAquisicao);
        setValorAquisicao(
          formatarMoedaReal(
            response.data.records[0].valorAquisicao != null
              ? response.data.records[0].valorAquisicao
              : 0
          )
        );
        setValorResidual(
          formatarMoedaReal(
            response.data.records[0].valorResidual != null
              ? response.data.records[0].valorResidual
              : 0
          )
        );
        setVidaUtil(response.data.records[0].vidaUtil);
        setEstadoConservacao(response.data.records[0].estadoConservacao);
        setTaxaDepreciacao(response.data.records[0].taxaDepreciacao);
      });
    };
    const getFabricantes = async () => {
      await api.get(`fabricante`).then((response) => {
        setListFabricantes(response.data.records);
        //if (id === "0") { setFabricante(response.data.records[0].id) }
      });
    };
    const getChamado = async () => {
      await api.get(`chamado/patrimonio/` + id).then((response) => {
        const chamadoData = response.data.records.filter(
          (base) => base.status == 1 || base.status == 2
        );
        const chamadoId = chamadoData.map((item) => item.id);
        const chamadoAberturaData = chamadoData.map(
          (item) => item.dataAbertura
        );
        setNumChamado(chamadoId);
        setDataPatr(chamadoAberturaData);
      });
    };

    const getFornecedores = async () => {
      await api.get(`fornecedor`).then((response) => {
        setListFornecedor(response.data.records);
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetor(response.data.records);
        if (id === "0") {
          setSetor(response.data.records[0].id);
        }
      });
    };

    const getGrupos = async () => {
      await api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
        if (id === "0") {
          setGrupo(response.data.records[0].id);
        }
      });
    };

    getSetores();
    getGrupos();
    getFabricantes();
    getFornecedores();
    getImages();
    getArquivo();
    getChamado();

    if (id !== "0") {
      getMaquinario();
    }
  }, [id]);

  const getImages = async () => {
    var idReg = localStorage.getItem("idPat");

    setAguardando(true);
    await api
      .get(`maquinarioalbum/maquinario/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setImages(response.data.records);
        setImagemPrincipal(response.data.records[0]);
        setAguardando(false);
      });
  };
  const getArquivo = async () => {
    var idReg = localStorage.getItem("idPat");

    setArquivo([]);
    setAguardando(true);
    await api
      .get(`maquinarioalbum/maquinario/arquivo/` + (id !== "0" ? id : idReg))
      .then((response) => {
        setArquivo(response.data.records[0].maquinarioAlbum);
        setAguardando(false);
      });
  };

  /**
   *
   * Trata a Renderizacao da imagem para ser exibida
   * de acordo com o valor preenchido no Objeto imagemPrincipal
   * @param {*} imPrincipal
   * @returns
   */
  function verificarImagem(imPrincipal) {
    if (imPrincipal) {
      //MOSTRAR IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px; ${
                imagemPrincipal.maquinarioAlbum?.status == 1
                  ? ""
                  : "filter: grayscale();"
              }}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
          <p className="text-muted text-center">
            Principal:{" "}
            {imagemPrincipal.maquinarioAlbum?.status == 1 ? "Ativo" : "Inativo"}{" "}
            <i
              className="fas fa-sync"
              onClick={(e) =>
                alteraStatusImagem(imagemPrincipal.maquinarioAlbum)
              }
            ></i>
          </p>
        </div>
      );
    } else {
      //ESCONDE IMAGEM
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 300px}`}
              src={SemImg}
              alt="Photo"
            />
          </div>
        </div>
      );
    }
  }

  function verificarImagemModal(imPrincipal) {
    //MOSTRAR IMAGEM
    if (imPrincipal) {
      return (
        <div>
          <div className="text-center">
            <img
              className="product-image"
              Style={`max-height: 150px}`}
              src={`data:image/jpeg;base64, ${imagemPrincipal.blobImage}`}
              alt="Photo"
            />
          </div>
          <p className="text-muted text-center"></p>
        </div>
      );
    };
  };

  const optionsFornecedor = listFornecedor
    .sort((a, b) => a.razao - b.razao)
    .map(function (ff) {
      return { value: ff.cgcCpf, label: ff.razao };
    });

  const optionsFabricante = listFabricantes
    .sort((a, b) => a.nome - b.nome)
    .map(function (ff) {
      return { value: ff.id, label: ff.nome };
    });

  const optionsSetor = listSetor.map(function (set) {
    return { value: set.id, label: set.descricao };
  });

  const optionsGrupo = listGrupo
    .sort((a, b) => a.descricao - b.descricao)
    .map(function (gru) {
      return { value: gru.id, label: gru.descricao };
    });

  const handleChangeFornecedor = (selectedOptionFornecedor) => {
    setSelectedFornecedor(selectedOptionFornecedor.value);
  };

  const handleChangeFabricante = (selectedOptionFabricante) => {
    setSelectedFabricante(selectedOptionFabricante.value);
  };

  const handleChangeGrupo = (selectedOptionGrupo) => {
    setSelectedGrupo(selectedOptionGrupo.value);
  };

  const handleChangeTipos = (selectedOption) => {
    setTipoAquisicao(selectedOption.value);
  };

  const handleClick = () => {
    setShowDesignados(true);
  };

  const options = [
    { value: null, label: "Selecione..." },
    { value: "COMPRA", label: "Compra" },
    { value: "DOACAO", label: "Doação" },
    { value: "CESSAO_USO", label: "Cessão de Uso" },
    { value: "EMPRESTIMO", label: "Empréstimo" },
  ];

  const handleChangeEstado = (selectedEstado) => {
    setEstadoConservacao(selectedEstado.value);
  };

  const optionsEstado = [
    { value: null, label: "Selecione..." },
    { value: "ÓTIMO", label: "Ótimo" },
    { value: "BOM", label: "Bom" },
    { value: "REGULAR", label: "Regular" },
    { value: "RUIM", label: "Ruim" },
    { value: "INSERVÍVEL", label: "Inservível" },
  ];

  const handleOpenModal = (chamadoId) => {
    setSelectedChamadoId(chamadoId);
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Adicionar Imagens</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="callout callout-info">
              <h5>Obrigatório</h5>
              <p>Adicione as imagens que representam o Patrimônio</p>
            </div>
            {(() => {
              if (statusImagem == true) {
                return (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-hidden="true"
                    ></button>
                    <h5>
                      <i className="icon fas fa-ban"></i> Alerta!
                    </h5>
                    Obrigatório a escolha de imagem para este Patrimônio.
                  </div>
                );
              }
            })()}
          </DialogContentText>

          <div className="col-md-12">
            {(() => {
              if (localStorage.getItem("idPat") !== "0") {
                return (
                  <div className="col-md-12">
                    {/* Profile Image */}
                    {!aguardando && (
                      <div>
                        <div className="card card-comment card-outline">
                          <div className="card-body">
                            {verificarImagemModal(imagemPrincipal)}

                            <div className="row">
                              <div className="text-left col-10">
                                <label
                                  for="arquivo"
                                  className="btn btn-success"
                                >
                                  Adicionar Imagem{" "}
                                  <i className="fa fa-plus"></i>
                                </label>
                                <input
                                  type="file"
                                  id="arquivo"
                                  Style="display:none"
                                  onChange={(e) => uploadImagem(e)}
                                ></input>
                              </div>

                              {imagemPrincipal && (
                                <div className="text-right col-6">
                                  <button
                                    className="btn btn-danger"
                                    onClick={(e) =>
                                      handleOpenConfirmDialogDel()
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {images.map(
                            (
                              item,
                              idx //border-radius: 20px;
                            ) => (
                              <div className="col-sm-5">
                                <div
                                  className="product-image-thumbs"
                                  onClick={(e) =>
                                    alteraImagemPrincipal(item, idx)
                                  }
                                >
                                  <div className="product-image-thumb">
                                    <img
                                      Style={`${
                                        item && item.maquinarioAlbum.status == 1
                                          ? ""
                                          : "filter: grayscale();"
                                      }}`}
                                      src={`data:image/jpeg;base64, ${item.blobImage}`}
                                      alt="Photo"
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })()}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <div className="row">
        {(() => {
          if (id !== "0") {
            return (
              <div className="col-md-3">
                {/* Profile Image */}
                {!aguardando && (
                  <div>
                    <div className="card card-comment card-outline">
                      <div className="card-body">
                        {verificarImagem(imagemPrincipal)}

                        <div className="row">
                          <div className="ribbon-wrapper ribbon-xl">
                            {(() => {
                              if (status == 1) {
                                return (
                                  <div className="ribbon bg-success">ATIVO</div>
                                );
                              } else if (status == 0) {
                                return (
                                  <div className="ribbon bg-warning">
                                    INATIVO
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="ribbon bg-danger">
                                    MANUTENÇÃO
                                  </div>
                                );
                              }
                            })()}
                          </div>

                          <div className="row">
                            {id === "0" ? (
                              <p>Grave o registro para inserir imagens!</p>
                            ) : (
                              <div className="text-left col-6">
                                <label
                                  for="arquivo"
                                  className="btn btn-success"
                                >
                                  <i className="fa fa-plus"></i>
                                </label>
                                <input
                                  type="file"
                                  id="arquivo"
                                  Style="display:none"
                                  onChange={(e) => uploadImagem(e)}
                                  disabled={isStatusValid}
                                ></input>
                              </div>
                            )}
                            {imagemPrincipal && (
                              <div className="text-right col-6">
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => handleOpenConfirmDialogDel()}
                                  disabled={images.length == 1 ? true : false}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {images.map(
                        (
                          item,
                          idx //border-radius: 20px;
                        ) => (
                          <div className="col-sm-5">
                            <div
                              className="product-image-thumbs"
                              onClick={(e) => alteraImagemPrincipal(item, idx)}
                            >
                              <div className="product-image-thumb">
                                <img
                                  Style={`${
                                    item && item.maquinarioAlbum.status == 1
                                      ? ""
                                      : "filter: grayscale();"
                                  }}`}
                                  src={`data:image/jpeg;base64, ${item.blobImage}`}
                                  alt="Photo"
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                {/* NÃO APAGAR ESTE TRECHO ABAIXO DE DUPLICAÇÃO DO REGISTRO */}
                {/* <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Manutenção do Registro</h3>
                    <div className="card-tools"></div>
                  </div>
                  <div className="card-body p-0">
                    <ul className="nav nav-pills flex-column">
                      <li className="nav-item">
                        <a
                          onClick={duplicaRegistro}
                          href="#"
                          className="nav-link"
                        >
                          Duplicar Registro
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {/*    <MaquinarioSetorPatrimonio id={maquinarioId} /> */}
              </div>
            );
          }
        })()}

        <div className={telaResponsiva(id)}>
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active " id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form className="form-horizontal" onSubmit={editMaquinario}>
                  <div className="col-sm-12">

                    <div className="row mb-2">
                      {(() => {
                        if (id != "0") {
                          return (
                            <>
                              <div className="d-flex -flex-wrap">
                                <div className="col-md-3 col-sm-2">
                                  <div style={{ textAlign: "center" }}>
                                    <QRCodeCanvas
                                      style={{
                                        width: "140px",
                                        height: "140px",
                                      }}
                                      data-qr={qrCode}
                                      value={qrCode}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })()}
                      <div className="col-md-9 col-sm-8">
                        <div className="row mb-2">
                          {(() => {
                            if (id != "0") {
                              return (
                                <>
                                  <div className="col-md-4 col-sm-4">
                                    <InputLabel
                                      htmlFor="inputEmail"
                                      className="col-md-12 col-sm-12 col-form-label"
                                    >
                                      Nº Patrimônio
                                    </InputLabel>
                                    <input
                                      disabled="true"
                                      type="text"
                                      className="form-control"
                                      id="descricao"
                                      name="descricao"
                                      value={numPatrimonio}
                                      onChange={(e) =>
                                        setNumPatrimonio(e.target.value)
                                      }
                                      placeholder="Descrição do Patrimônio..."
                                    />
                                  </div>
                                </>
                              );
                            }
                          })()}
                          <div className="col-md-4 col-sm-4">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-sm-12 col-form-label"
                            >
                              N° Série
                            </InputLabel>
                            <input
                              type="text"
                              className="form-control"
                              id="numSerie"
                              name="numSerie"
                              value={numSerie}
                              onChange={(e) => setNumSerie(e.target.value)}
                              placeholder="Série de Fabricação..."
                            />
                          </div>

                          <div className="col-md-4 col-sm-4">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-sm-12 col-form-label"
                            >
                              N° Pat. Antigo
                            </InputLabel>
                            <input
                              type="text"
                              className="form-control"
                              id="numPat"
                              name="numPat"
                              value={numPatrimonioAdd}
                              onChange={(e) =>
                                setnumPatrimonioAdd(e.target.value)
                              }
                              placeholder="Numero Patrimônio..."
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-8 col-sm-8 form-group was-validated">
                            <InputLabel
                              htmlFor="inputEmail"
                              className="col-sm-12 col-form-label"
                            >
                              Descrição
                            </InputLabel>
                            <input
                              type="text"
                              className="form-control"
                              id="descricao"
                              name="descricao"
                              value={descricao}
                              onChange={(e) => setDescricao(e.target.value)}
                              placeholder="Descrição do Patrimônio..."
                              required="required"
                            />
                            <div className="valid-feedback">Validado.</div>
                            <div className="invalid-feedback">
                              Preencha esse campo.
                            </div>
                          </div>

                          <div className="col-md-4 col-sm-4 form-group was-valited">
                            <InputLabel
                              htmlFor="inputName"
                              className="col-sm-12 col-form-label"
                            >
                              Marca do Produto
                            </InputLabel>
                            <input
                              type="text"
                              className="form-control"
                              id="marcaproduto"
                              name="marcaproduto"
                              value={marcaProduto}
                              onChange={(e) => setMarcaProduto(e.target.value)}
                              placeholder="Marca do produto..."
                              required="required"
                            />
                            <div className="valid-feedback">Validado.</div>
                            <div className="invalid-feedback">
                              Preencha esse campo.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="col-sm-12 col-form-label"
                          >
                            Fabricante
                          </InputLabel>

                          <Select
                            value={optionsFabricante.filter(
                              (obj) => obj.value === selectedFabricante
                            )}
                            onChange={handleChangeFabricante}
                            options={optionsFabricante}
                            clearable={"true"}
                            searchable={"true"}
                            labelKey={"nome"}
                            valueKey={"id"}
                            noOptionsMessage={() =>
                              "Nenhum registro Encontrado"
                            }
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <InputLabel
                          htmlFor="inputSkills"
                          className="col-sm-12 col-form-label"
                        >
                          Fornecedor
                        </InputLabel>
                        <Select
                          value={optionsFornecedor.filter(
                            (obj) => obj.value === selectedFornecedor
                          )}
                          onChange={handleChangeFornecedor}
                          options={optionsFornecedor}
                          clearable={"true"}
                          searchable={"true"}
                          labelKey={"razao"}
                          valueKey={"cgcCpf"}
                          noOptionsMessage={() => "Nenhum registro Encontrado"}
                        />
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="col-sm-12 col-form-label"
                          >
                            Grupo
                          </InputLabel>

                          <Select
                            value={optionsGrupo.filter(
                              (obj) => obj.value === selectedGrupo
                            )}
                            onChange={handleChangeGrupo}
                            options={optionsGrupo}
                            clearable={"true"}
                            searchable={"true"}
                            labelKey={"descricao"}
                            valueKey={"id"}
                            noOptionsMessage={() =>
                              "Nenhum registro Encontrado"
                            }
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-sm-4 form-group">
                        <InputLabel
                          htmlFor="inputEmail"
                          className="col-sm-12 col-form-label"
                        >
                          Data de Cadastro
                        </InputLabel>

                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="pt-br"
                        >
                          <div
                            className="input-group date"
                            data-provide="datepicker"
                          >
                            <DatePicker
                              className="form-control"
                              value={dataCadastro}
                              onChange={(newValue) => {
                                setDataCadastro(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                              dayOfWeekFormatter={(day) => {
                                const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                return daysOfWeek[day];
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>

                      <div className="col-md-4 col-sm-4 form-group">
                        <InputLabel
                          htmlFor="inputEmail"
                          className="col-sm-12 col-form-label"
                        >
                          Data de Compra
                        </InputLabel>

                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="pt-br"
                        >
                          <div
                            className="input-group date"
                            data-provide="datepicker"
                          >
                            <DatePicker
                              className="form-control"
                              value={dataCompra}
                              onChange={(newValue) => setDataCompra(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                              dayOfWeekFormatter={(day) => {
                                const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                return daysOfWeek[day];
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <InputLabel
                          htmlFor="inputName"
                          className="col-sm-12 col-form-label"
                        >
                          N° da Nota Fiscal
                        </InputLabel>
                        <input
                          type="text"
                          className="form-control"
                          id="numNF"
                          name="numNF"
                          value={numeroNotaFiscal}
                          onChange={(e) => setNumeroNotaFiscal(e.target.value)}
                          placeholder="Numero da Nota Fiscal ..."
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="col-sm-12 control-label"
                          >
                            Tipo de Aquisição
                          </InputLabel>
                          <div>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-controlled-open-select-label"
                              value={options.find(
                                (option) => option.value === tipoAquisicao
                              )}
                              onChange={handleChangeTipos}
                              options={options}
                              spacing={3}
                              required="required"
                            ></Select>
                          </div>
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="control-label col-12"
                          >
                            Aquisição (R$)
                          </InputLabel>

                          <input
                            type="text"
                            className="form-control"
                            id="Valor de Aquisição"
                            name="ValorAquisição"
                            value={valorAquisicao}
                            onChange={handleChangeAquisicao}
                            placeholder="Valor da aquisição..."
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputSkills"
                            className="control-label"
                          >
                            Residual (R$)
                          </InputLabel>
                          <input
                            type="text"
                            className="form-control"
                            id="Valor Residual"
                            name="ValorResidual"
                            value={valorResidual}
                            onChange={handleChangeResidual}
                            placeholder="Valor residual..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-sm-4 ">
                        <div className="form-group ">
                          <InputLabel
                            htmlFor="inputEmail"
                            className="control-label"
                          >
                            Vida Útil
                          </InputLabel>
                          <input
                            type="text"
                            className="form-control"
                            id="Vida Útil"
                            name="VidaUtil"
                            value={vidaUtil}
                            onChange={(e) => setVidaUtil(e.target.value)}
                            placeholder="Vida útil..."
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <InputLabel
                            htmlFor="inputEmail"
                            className="col-sm-12 col-form-label mb-2"
                          >
                            Estado de Conservação
                          </InputLabel>
                          <div>
                            <span className="p-float-label">
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-controlled-open-select-label"
                                value={optionsEstado.find(
                                  (opt) => opt.value === estadoConservacao
                                )}
                                onChange={handleChangeEstado}
                                options={optionsEstado}
                                spacing={3}
                              ></Select>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-6">
                        <div className="form-group">
                          <InputLabel
                            htmlFor="inputEmail"
                            className="col-sm-12 col-form-label  mb-2"
                          >
                            Taxa Depreciação (%)
                          </InputLabel>
                          <input
                            type="text"
                            className="form-control"
                            id="Valor Residual"
                            name="ValorResidual"
                            value={taxaDepreciacao}
                            onChange={(e) => setTaxaDepreciacao(e.target.value)}
                            placeholder="Valor residual..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className=" col-md-4 col-sm-5 form-group">
                        <InputLabel
                          htmlFor="inputSkills"
                          className="col-sm-12 col-form-label"
                        >
                          Garantia
                        </InputLabel>

                        <div className="col-lg-12 col-md-12 col-sm-12 text-muted">
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Não
                          </FormLabel>
                          <Radio
                            checked={statusGarantia == "N"}
                            onChange={(e) => setStatusGarantia(e.target.value)}
                            value="N"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "N" }}
                          />

                          <FormLabel id="demo-controlled-radio-buttons-group text-muted">
                            Sim
                          </FormLabel>
                          <Radio
                            checked={statusGarantia == "S"}
                            onChange={(e) => setStatusGarantia(e.target.value)}
                            value="S"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "S" }}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-4 form-group">
                        <InputLabel
                          htmlFor="inputEmail"
                          className="col-sm-12 col-form-label"
                        >
                          Data de Garantia
                        </InputLabel>

                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="pt-br"
                        >
                          <div
                            className="input-group date"
                            data-provide="datepicker"
                          >
                            <DatePicker
                              className="form-control"
                              disabled={statusGarantia == "S" ? false : true}
                              value={dataGarantia}
                              onChange={(newValue) => setDataGarantia(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  label="Size"
                                  id="outlined-size-small"
                                  defaultValue="Small"
                                  size="small"
                                  {...params}
                                />
                              )}
                              dayOfWeekFormatter={(day) => {
                                const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
                                return daysOfWeek[day];
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-12 col-sm-12">
                        <InputLabel
                          htmlFor="inputExperience"
                          className="col-sm-12 col-form-label"
                        >
                          Link do Manual do Fabricante
                        </InputLabel>
                        <input
                          type="text"
                          className="form-control"
                          id="manual"
                          name="manual"
                          value={manual}
                          onChange={(e) => setManual(e.target.value)}
                          placeholder="Link Manual do Fabricante..."
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group was-validated">
                          <InputLabel
                            htmlFor="inputExperience"
                            className="col-sm-6 col-form-label"
                          >
                            Dados Técnicos
                          </InputLabel>
                          <textarea
                            className="form-control"
                            type="text"
                            id="dados_tecnicos"
                            placeholder="Dados técnicos..."
                            onChange={(e) => setDadosTecnicos(e.target.value)}
                            defaultValue={dadosTecnicos}
                            required="required"
                          />
                          <div className="valid-feedback">Validado.</div>
                          <div className="invalid-feedback">
                            Preencha esse campo.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <div className="form-group">
                          {arquivo.nome != null ? (
                            ""
                          ) : (
                            <label
                              for="arquivoUpload"
                              className="btn btn-primary"
                            >
                              <i className="fas fa-pen" /> Adicionar Documento
                              *Até 150MB
                            </label>
                          )}
                          <input
                            type="file"
                            id="arquivoUpload"
                            Style="display:none"
                            onChange={(e) => uploadImagem(e)}
                            disabled={isStatusValid}
                          ></input>
                          {arquivo.nome != null ? (
                            <a
                              href={arquivo.caminho}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <label className="btn btn-success ml-1">
                                <i className="fas fa-download" />
                              </label>
                            </a>
                          ) : (
                            ""
                          )}
                          {arquivo.nome != null ? (
                            <label
                              className="btn btn-danger ml-1"
                              onClick={(e) => handleOpenConfirmDialog()}
                            >
                              {" "}
                              <i className="fa fa-trash"></i>{" "}
                            </label>
                          ) : (
                            ""
                          )}
                          <label className="col-sm-6">{arquivo.nome}</label>
                        </div>
                      </div>
                    </div>
                    {progress && (
                      <div className="row">
                        <div className="col-sm-12">
                          {progress && (
                            <ProgressBar
                              now={progress}
                              label={`${progress}%`}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="col-sm-2 col-form-label">
                          Status{" "}
                        </label>
                        <div className="col-sm-10">
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 1}
                            onChange={(e) => setStatus(e.target.value)}
                            value="1"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "1" }}
                            disabled={isStatusValid}
                          />
                          <strong className="mr-4" style={{ fontSize: 13 }}>
                            Ativo
                          </strong>
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 0}
                            onChange={(e) => setStatus(e.target.value)}
                            value="0"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "0" }}
                            disabled={isStatusValid}
                          />
                          <strong className="mr-4" style={{ fontSize: 13 }}>
                            Inativo
                          </strong>
                          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                          <Radio
                            checked={status == 2}
                            onChange={(e) => setStatus(e.target.value)}
                            value="2"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "2" }}
                            disabled
                          />
                          <strong style={{ fontSize: 13 }}>Manutenção</strong>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={isStatusValid}
                        >
                          Gravar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <Dialog
                  open={openConfirmDialog}
                  onClose={() => setOpenConfirmDialog(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Esta ação é permanente!"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Deseja realmente excluir?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)}>
                      Não
                    </Button>
                    <Button
                      onClick={() => {
                        excluirFoto(arquivo);
                        setOpenConfirmDialog(false);
                      }}
                      autoFocus
                    >
                      Sim
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openConfirmDialogDel}
                  onClose={() => setOpenConfirmDialogDel(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Deseja realmente excluir ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenConfirmDialogDel(false)}>
                      Não
                    </Button>
                    <Button
                      autoFocus
                      onClick={() => {
                        excluirFoto(imagemPrincipal.maquinarioAlbum);
                        setOpenConfirmDialogDel(false);
                      }}
                    >
                      Sim
                    </Button>
                  </DialogActions>
                </Dialog>

                {modalIsOpen && (
                  <div
                    class="modal fade"
                    id="ModalLongoExemplo"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="TituloModalLongoExemplo"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Fechar"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body m-2">
                          <VisualizaChamados
                            id={selectedChamadoId}
                            selecionar={selectedChamadoId}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
