import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Alert,
  AlertTitle,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import api from "../../../services/api";

import "./grid.css";
import ReactPaginate from "react-paginate";
import { addZeroes } from "../../../utils/utils";
import { REACT_APP_TOTAL_GRID } from "../../../utils/constants";
import { getControle } from "../../../services/auth";
import ConfirmBox from "../../../components/controles/ConfirmBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const AtualizacaoGrid = () => {
  const [data, setData] = useState([]);
  const [listGrupo, setListGrupo] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState([]);
  const [statusReg, setStatusReg] = useState([]);
  const [vinculoSetor, setVinculoSetor] = useState("S");
  const [buscaGenerica, setBuscaGenerica] = useState([]);

  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagina, setPagina] = useState(0);
  const [totalRegistro, setTotalRegistro] = useState(0);
  
  const [openMsg, setOpenMsg] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("");
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [deleteData, setDeleteData] = useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [tipoRel, setTipoRel] = useState("PAT");

  let limit = parseInt(REACT_APP_TOTAL_GRID);

  useEffect(() => {

    const getGrupos = async () => {
      await api.get(`grupomaquinario`).then((response) => {
        setListGrupo(response.data.records);
      });
    };

    const getDados = () => {
      
      setPagina(currentPage);

      let stringBase = `maquinario/busca?page=${pagina}&size=${limit}&vinculoSetor=S`;
      let stringGrupo = "";
      let stringVinculo = "";
      let stringStatus = "";
      let stringBuscaID = "";
      
      if(vinculoSetor == 'S'){
              
        stringVinculo = '&vinculoSetor=S';
        stringBase = `maquinario/busca?page=${pagina}&size=${limit}&vinculoSetor=S`;
      
    } else {
        stringVinculo = '&vinculoSetor=N';
        stringBase = `maquinario/busca?page=${pagina}&size=${limit}&vinculoSetor=N`;
    } 

    
    if (statusReg.length != 0) {
      
      stringStatus = `status=` + statusReg;
      stringBase = `maquinario/busca?` + stringStatus + stringVinculo  + `&page=${pagina}`+ "&size=" + limit;
    } else
      if (selectedGrupo.length != 0) {
        stringGrupo = `grupo=` + selectedGrupo;
        stringBase = `maquinario/busca?` + stringGrupo + stringVinculo + `&page=${pagina}` + "&size=" + limit;
      } else if (buscaGenerica.length >= 3) {
        if (tipoRel == "PAT") {
          //patrimonio
          stringBuscaID = `id=` + buscaGenerica  ;
        } else if (tipoRel == "ANT") {
          //patrimonio antigo
          stringBuscaID = `numPatrimonioAdd=` + buscaGenerica + stringVinculo ;
        } else if (tipoRel == "DES") {
          //descricao
          stringBuscaID = `descricao=` + buscaGenerica.toUpperCase() + stringVinculo ;
        }
        stringBase = `maquinario/busca?` + stringBuscaID +  stringVinculo + `&page=${pagina}` + "&size=" + limit;
      } else if (selectedGrupo.length != 0) {
        stringBase = `maquinario/busca?` + stringGrupo + stringVinculo + `&page=${pagina}` + "&size=" + limit;
      } 


    

       api.get(stringBase).then((response) => {

        setData(response.data.records);
        const data = response.data.records;
        const total = response.data.meta.recordCount;
        setpageCount(Math.ceil(total / limit));
      
        setItems(data);
        setTotalRegistro(total);
      });
    };

    getGrupos();
    getDados();
  }, [selectedGrupo, statusReg, limit, currentPage, buscaGenerica, tipoRel, vinculoSetor]);

  const tipoBusca = async (event) => {
    let rel = event.target.value;
    setTipoRel(rel);
  };



  const handleChangeGrupo = async (event) => {
    let valor = event.target.value;
    setSelectedGrupo(valor);
    setBuscaGenerica("");
    setCurrentPage(0);
    setPagina(currentPage);
   
  };

  const handleChangeStatus = (event) => {
    
    let valor = event.target.value;
  
    setStatusReg(valor);
    setBuscaGenerica("");
    setCurrentPage(0);
    setPagina(currentPage);
    
  };

  const handleChangeVinculo = async (event) => {
    event.preventDefault();
    let valor = event.target.value;
    setCurrentPage(0);
    setVinculoSetor(valor);

    setPagina(currentPage);
    
  };




  const handleChangeBuscaGenerica = (event) => {
    const buscaSelecionada = event.target.value;
    setBuscaGenerica(buscaSelecionada);
    setStatusReg("");
    setSelectedGrupo("");
  }

  const handleFecharAlert = () => {
    setOpenMsg(!openMsg);
  };

  const fetchComments = async (currentPage) => {
    let data = "";
    await api.get(`maquinario?page=${currentPage}`).then((response) => {
      data = response.data.records;
    });
    return data;
  };

  const handlePageClick = async (data) => {
    let _currentPage = data.selected + 1;

    setCurrentPage(_currentPage);
    setPagina(currentPage);
  };
  /**
   *
   * @param {*parametro para checar o cabeçalho} cabec
   * @param {*} idPat
   * @returns
   */
  const LiberarExcluir = (cabec) => {
    if (
      getControle() == 6359 || // Iara
      getControle() == 7429 || // Laila
      getControle() == 6374 ||
      getControle() == 4092 // Leandro
    ) {
      if (cabec == "s") {
        return (
          <th style={{ width: "10%", textAlign: "center" }} scope="col">
            Excluir
          </th>
        );
      }
    } else {
      return null;
    }
  };

  function openDelete(data) {
    setOpen(true);
    setDeleteData(data);
  }

  function deleteUser() {
    try {
      api.delete(`maquinario/` + deleteData.id);

      setMensagemDialog(
        `Operação realizada com sucesso! ${deleteData?.descricao}`
      );
      setTipoDialog("success");
      setCorDialog("success");
      setOpenMsg(!openMsg);
      setOpen(false);
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      setOpenMsg(!openMsg);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <form className="form-horizontal">
          <div className="row">
            <div className="d-flex flex-row ">
              <div className="col-xl-6 px-1 ">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Grupo
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 250 }}
                    label="Grupo"
                    value={selectedGrupo}
                    onChange={handleChangeGrupo}
                    spacing={3}
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    {listGrupo
                      .sort((a, b) => {
                        if (a.descricao > b.descricao) return 1;
                        if (a.descricao < b.descricao) return -1;
                        return 0;
                      })
                      .map((item) => (
                        <MenuItem value={item.id}>{item.descricao}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="d-flex flex-row ">
              <div className="col-sm-6 pl-1 ">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 150, boxSizing: "border-box" }}
                    label="Status"
                    value={statusReg}
                    onChange={handleChangeStatus}
                    spacing={3}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="1">Ativo</MenuItem>
                    <MenuItem value="0">Inativo</MenuItem>
                    <MenuItem value="2">Em Manutenção</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="d-flex flex-row ">
              <div className="col-sm-6 pl-1 ">
                <FormControl>
                  <InputLabel id="demo-controlled-open-select-label">
                    Vinculado ao Setor
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 150, boxSizing: "border-box" }}
                    label="Vinculado"
                    value={vinculoSetor}
                    onChange={handleChangeVinculo}
                    spacing={3}
                  >
                    
                    <MenuItem value="S">Vinculado</MenuItem>
                    <MenuItem value="N">Não Vinculado</MenuItem>
                    
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="d-flex flex-row ">
              <div className="col-sm-12 ">
                <FormControl>
                  <TextField
                    id="demo-controlled-open-select-label"
                    sx={{ m: 1, minWidth: 250 }}
                    labelId="demo-controlled-open-select-label"
                    label="Buscar"
                    value={buscaGenerica}
                    type="search"
                    onBlur={handleChangeBuscaGenerica}                    
                    onChange={(e) => setBuscaGenerica(e.target.value)}
                    spacing={3}
                    variant="filled"
                  />
                </FormControl>

                <FormControl spacing={3}>
                  <FormLabel></FormLabel>
                  <RadioGroup
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 24,
                      },
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tipoRel}
                    onChange={tipoBusca}
                  >
                    <FormControlLabel
                      value="PAT"
                      control={<Radio />}
                      label="Patrimonio"
                      sx={{
                        color: "#5bc0de",
                      }}
                    />

                    <FormControlLabel
                      value="ANT"
                      control={<Radio />}
                      label="Pat.Antigo"
                      sx={{
                        color: "#d9534f",
                      }}
                    />
                    <FormControlLabel
                      value="DES"
                      control={<Radio />}
                      label="Descrição"
                      sx={{
                        color: "#5cb85c",
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="d-flex flex-row ">
              <div className="col-sm-12 mr-3">
                <h4>
                  <small class="float-right">Registro(s):{" "} 
                  <b style={{ fontSize: 18 }}>
                  </b>{totalRegistro}{/* {selectedGrupo != "" && statusReg != ""
                  ? data.filter((item) => item.grupo.id == selectedGrupo && item.status == statusReg).length
                  : data.length} */}
                  </small>
                </h4>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-12">
          <table>
            <caption></caption>
            <thead>
              <tr>
                <th style={{ width: "8%", textAlign: "center" }} scope="col">
                  ID{" "}
                </th>
                <th style={{ width: "8%", textAlign: "center" }} scope="col">
                  Nº Pat.{" "}
                </th>
                <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  Nº Pat. Antigo{" "}
                </th>
                <th style={{ width: "20%", textAlign: "center" }} scope="col">
                  Descrição{" "}
                </th>
                <th style={{ width: "20%", textAlign: "center" }} scope="col">
                  Grupo{" "}
                </th>
                <th style={{ width: "30%", textAlign: "center" }} scope="col">
                  Fornecedor{" "}
                </th>
                <th style={{ width: "10%", textAlign: "center" }} scope="col">
                  Status
                </th>

                {LiberarExcluir("s")}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      item.status === 2
                        ? "border border-danger"
                        : item.status === 1
                        ? "active-class"
                        : item.status === 0
                        ? "inactive-class"
                        : "default-class"
                    }
                  >
                    <td data-label="ID">
                      <Link to={`/MaquinarioItem/${item.id}`}>
                        {addZeroes(item.id, 6)}
                      </Link>
                    </td>
                    <td data-label="Nº Pat. "> {item.numPatrimonio}</td>
                    <td data-label="Nº Pat. Antigo ">
                      {item.numPatrimonioAdd}
                    </td>
                    <td data-label="Descrição "> {item.descricao}</td>
                    <td data-label="Grupo ">{item.grupo.descricao}</td>
                    <td data-label="Fornecedor ">{item.fornecedor.razao}</td>
                    <td data-label="Status">
                      <div>
                        {(() => {
                           if (item.status == 0) {
                            return <span data-label="Status">Inativo</span>;
                          } else if (item.status == 2) {
                            return (
                              <span className="badge bg-danger">
                                Manutenção{" "}
                                <i class="fa fa-duotone fa-gears"></i>
                              </span>
                            );
                          } else {
                            return (
                              <span>Ativo</span>
                            );
                          }
                        })()}
                      </div>
                    </td>
                    <td>
                      {(() => {
                        if (
                          getControle() == 6359 || // Iara
                          getControle() == 7429 || // Laila
                          getControle() == 6374 ||
                          getControle() == 4092 // Leandro
                        ) {
                          if (item.status !== 2) {
                            return (
                              <div className="btn btn">
                                <Link
                                  onClick={() => openDelete(item)}
                                  to={"#"}
                                  className="bg-red-600 text-red text-center px-6 py-2 rounded-lg"
                                >
                                  <i className="fa fa-solid fa-trash"></i>
                                </Link>
                              </div>
                            );
                          }
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={currentPage}
            renderOnZeroPageCount={null}
          />




        </div>
      </div>
      <Dialog open={openMsg} onClose={handleFecharAlert}>
        <Alert
          severity={tipoDialog}
          color={corDialog}
          role="button"
          onClose={() => {
            setOpenMsg(false);
          }}
          sx={{
            //width: '80%',
            //margin: 'auto',
            "& .MuiAlert-icon": {
              color: "blue",
            },
            //backgroundColor: "green"
          }}
        >
          <AlertTitle></AlertTitle>
          {mensagemDialog}
        </Alert>
      </Dialog>

      <ConfirmBox
        open={open}
        closeDialog={() => setOpen(false)}
        title={deleteData?.numPatrimonio}
        deleteFunction={deleteUser}
      />
    </div>
  );
};
