import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CardDashboardRecusado from "./CardDashboardRecusado";
import CardDashboardAberto from "./CardDashboardAberto";
import CardDashboardRetornoOS from "./CardDashboardRetornoOS";
import CardDashboardDesignados from "./CardDashboardDesignados";
import CardDashboardAguardando from "./CardDashboardAguardando";
import CardDashboardAgendado from "./CardDashboardAgendado";
import api from "../../services/api";

export const NavControle = () => { 

  return (
    <>
      <div className="row">
        <div className="grey-bg container-fluid">
          <div className="row">
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardRecusado />
            </div>
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardAberto  />
            </div>
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardRetornoOS />
            </div>
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardDesignados/>
            </div>
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardAguardando />
            </div>
            <div className="col-xl-2 col-sm-6 col-12">
              <CardDashboardAgendado />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
