import { CheckCircle } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import api from "../../../services/api";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const ModalConfirmacao = ({ openModal, handleClose, selectedRows }) => {
  const [mensagemDialog, setMensagemDialog] = useState([]);
  const dadosJson = {
    idChamados: selectedRows.map((row) => row.id),
  };
  const [corDialog, setCorDialog] = useState([]);
  const [tipoDialog, setTipoDialog] = useState([]);
  const [openMsg, setOpenMsg] = useState(false);

  const queryClient = useQueryClient();

  const { data: aprovarPecas, isLoading: isLoadingAprovar } = useQuery(
    ["aprovarPecas"],
    async () => {
      const response = await api.post(`chamado-pecas/detalhes`, {
        ids: dadosJson.idChamados,
      });
      return response.data;
    }
  );

  const { mutate: mutation, isLoading } = useMutation(
    async () => {
      const dadosConfirmar = {
        statusAprov: "A",
        motivo: "",
        idChamados: dadosJson.idChamados,
        pecas: aprovarPecas?.pecas,
      };

      const response = await api.put(
        `chamado-pecas/aprovar-reprovar/`,
        dadosConfirmar
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["chamadoPecas"]);
        setMensagemDialog(`Aprovação realizada com sucesso!`);
        handleClose();
        setOpenMsg(false);
        setTipoDialog("success");
        setCorDialog("success");
      },
      onError: (error) => {
        setMensagemDialog(
          `Houve um erro na operação de aprovação: ${error.message}`
        );
        setTipoDialog("error");
        setCorDialog("success");
        setOpenMsg(false);
      },
    }
  );

  const handleFecharAlert = () => {
    setOpenMsg(false);
  };

  return (
    <>
      <Modal open={openModal} onClose={!isLoading && handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 1000,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            maxHeight: "75vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "2px dashed",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 4,
                right: 8,
                cursor: "pointer",
                fontSize: 15,
                color: "gray",
              }}
              onClick={handleClose}
            >
              X
            </Box>
            <Typography sx={{ fontSize: 18, color: "green" }}>
              <CheckCircle sx={{ color: "green", marginRight: 1 }} />
              Confirmar Aprovação
            </Typography>

            <Box sx={{ fontWeight: "Bold" }}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "inherit",
                  marginLeft: 2,
                  marginRight: 1,
                }}
              >
                Esteja ciente de que, ao liberar o uso dessas peças, será
                emitida uma requisição para saída do almoxarifado. Caso seja
                necessário utilizar o empenho informado, haverá um novo pedido
                de compra, incluindo a quantidade padrão exigida.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ marginTop: 3 }}>
            <Typography>Patrimônio</Typography>
          </Box>

          <Box sx={{ display: "flex"}}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "transparent" }}>
                <TableCell sx={{ width: "90px", fontSize: "12px" }}>
                  Chamado
                </TableCell>
                <TableCell sx={{ width: "100px", fontSize: "12px" }}>
                  Patrimônio
                </TableCell>
                <TableCell sx={{ width: "250px", fontSize: "12px" }}>
                  Descrição
                </TableCell>
                <TableCell sx={{ width: "330px", fontSize: "12px" }}>
                  Setor
                </TableCell>
                <TableCell sx={{ alignItems: "left", fontSize: "12px" }}>
                  Unidade
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedRows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    backgroundColor: "transparent",
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {" "}
                    {row.id}{" "}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.patrimonio?.numPatrimonio}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.patrimonio?.descricao}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.setor?.descricao}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {row.setor?.unidadeProducao?.fantasia}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </Box>

          <hr style={{ border: "1px solid #000", width: "100%" }} />

          <Box>
            <Typography>Necessidades</Typography>
          </Box>

          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "transparent" }}>
                <TableCell sx={{ width: "400px", fontSize: "12px" }}>
                  Cod. Descrição{" "}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "12px" }}>
                  Un.
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "12px" }}>
                  Qtde
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "12px" }}>
                  Custo Médio
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "12px" }}>
                  Estoque
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "12px" }}>
                  Empenhar
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedRows.map((row) =>
                row.chamadoPecas.map((peca) => (
                  <TableRow
                    key={peca.id}
                    sx={{ backgroundColor: "transparent" }}
                  >
                    <TableCell sx={{ fontSize: "12px" }}>
                      {peca.matprima.matprima}
                      {" - "} {peca.matprima.descricao}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      {peca.matprima.unidade}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      {peca.quantidade}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      {peca.custoMedio.toFixed(2)}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      {peca.qtdEstoque}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "12px",
                        color: peca.qtdEmpenhar > 0 ? "red" : "inherit",
                      }}
                    >
                      {peca.qtdEmpenhar}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <hr style={{ border: "1px solid #000", width: "100%" }} />

          <Box
            sx={{ display: "flex", marginTop: 4, justifyContent: "flex-end" }}
          >
            <Button
              onClick={handleClose}
              size="small"
              variant="outlined"
              sx={{ color: "gray" }}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{ minWidth: 100, marginLeft: 1 }}
              onClick={() => mutation()}
              disabled={isLoading}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      {openMsg && (
        <Dialog open={openMsg} onClose={isLoadingAprovar && handleFecharAlert}>
          <Alert
            severity={tipoDialog}
            color={corDialog}
            role="button"
            /* onClose={() => {
              setOpenMsg(false);
            }} */
            sx={{
              "& .MuiAlert-icon": {
                color: "blue",
              },
            }}
          >
            <AlertTitle></AlertTitle>
            {mensagemDialog}
          </Alert>
        </Dialog>
      )}
    </>
  );
};
