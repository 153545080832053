import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GridComponent } from '../../../components/controles/GridComponente';
import { addZeroes } from '../../../utils/utils';
import api from "../../../services/api";



/**
*   Montar a tela de visualizacao de dados
* - preenchimento do objeto columns para seja formatado 
* - as colunas da tabela para o componente grid
* - const columns = []
* @returns 
 */

export const EspecialidadeDataGrid = () => {
  let history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);

  const handleClick = (event, cellValues) => {
    history.push({
      pathname: '/EspecialidadeItem/' + cellValues.row.id
    });
  };

  const handleChange = value => {
    setSelectedRows(value);
  }

  const deleteRow = event => {
    console.log(event.target.id);
  };

  const viewRow = event => {
    console.log(event.target.id);
  };

  /**
   * VARIAVEL COLUMNS
   * - Tipo Objeto 
   * - Elementos principais 
   * - - field (nome do campo), 
   * - - headerName( Label da Coluna )
   * - - headerClassName ( classe estilo definido no componente de retorno )
   * - - outros campos que podera sera usado de acordo com a documentação abaixo 
          field: string;
          renderCell: (cellValues: any) => JSX.Element;
          headerName?: undefined;
          headerClassName?: undefined;
          width?: undefined;
          flex?: undefined;
          minWidth?: undefined;
          editable?: undefined;
          type?: undefined;
          align?: undefined;
   * 
   * - Este deve ser formatado corretamenta para que possa enviar para o 
   * componente GridComponente.js
   */
  const columns = [
    {
      field: " Ações ",
      renderCell: (cellValues) => {
        return (

          <button type="button" className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
            onClick={(event) => {
              handleClick(event, cellValues);
            }}
          >
            <i className="fas fa-pen"></i>
          </button>


        );
      }
    },


    {
      field: 'id', headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      width: 90,
      flex: 50,
      renderCell: params => {
        return <div style={{ color: '#000' }} > { addZeroes(params.row.id, 8 )} </div>;
    }
    },
    {
      field: 'nome',
      headerName: 'Nome',
      headerClassName: 'super-app-theme--header',
      minWidth: 250,
      editable: false,
      flex: 50,
    },
    
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      minWidth: 80,
      type: 'number',
      editable: false,
      align: 'right',
      flex: 50,
      renderCell: params => {
        if (params.row.status === 1) {
          return <div style={{ color: '#29B606' }} > <i className="fas fa-check"></i> </div>;
        } else {
          return <div style={{ color: '#B6060F' }} > <i className="fas fa-times"></i> </div>;
        }


      },

    }


  ];

  useEffect(() => {
   
      api.get(`especialidades`)
        .then((response) => {
          setData(response.data.records);
        });
    
  }, []);

  if (!data) return null;

  return (

    < div >
      {/* GRID COMPONENTE RECEBE OS DADOS E AS COLUNAS QUE SERAO RENDERIZADA */}
      <GridComponent data={data} columns={columns} />

    </div >


  );
}
