import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import api from "../../../../services/api";
import { addZeroes } from "../../../../utils/utils";
import "./grid.css";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const SubTipoGrid = () => {
  let history = useHistory();
  const { id } = useParams();

  const [selectedStatus, setSelectedStatus] = useState(1);

  const [listCat, setListCat] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState([]);
  const [existe, setExiste] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMsg, setOpenMsg] =  useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const [tipoDialog, setTipoDialog] = useState("");
  const [corDialog, setCorDialog] = useState("");
  const [listGravidade, setListGravidade] = useState([]);
  const [selectedGravidade, setSelectedGravidade] = useState([]);

  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);

  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRegistro, setTotalRegistro] = useState(0);

  const [loading, setLoading] = useState(false);
  const [mensagemDialog, setMensagemDialog] = useState("Carregando...");

  let limit = 50;

  const handleClick = (event, codigo) => {
    history.push({
      pathname: "/subTiposChamadosItem/" + codigo,
    });
  };

  const handleChangeCategoria = (event) => {
    let valor = event.target.value;
    setSelectedCategoria(valor);
  };

  const handleChangeGravidade = (event) => {
    let valor = event.target.value;
    setSelectedGravidade(valor);
  };

  const handleChangeStatus = (event) => {
    let valor = event.target.value;
    setSelectedStatus(valor);
  };

  const handlePageClick = async (data) => {
    let _currentPage = data.selected + 1;

    setCurrentPage(_currentPage);
  };

  useEffect(() => {
    const getCategoria = async () => {
      await api.get(`/tipoChamado`).then((response) => {
        setListCat(response.data.records);
      });
    };

    const getGravidade = async () => {
      await api.get(`tipoChamadoDescricao`).then((response) => {
        setListGravidade(response.data.records);
      });
    };
    getCategoria();
    getGravidade();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
    const getDados = () => {
      let pagina = currentPage == 0 ? 1 : currentPage;

      api.get(`/tipoChamadoDescricao`).then((response) => {
        if (selectedCategoria.length != 0) {
          //
          if (selectedGravidade.length != 0) {
            setDataDef(
              response.data.records.filter(
                (base) =>
                  base.tipoChamado.id == selectedCategoria &&
                  base.nivelGravidade == selectedGravidade.toString() &&
                  base.status == selectedStatus
              )
            );
          } else {
            setDataDef(
              response.data.records.filter(
                (base) =>
                  base.tipoChamado.id == selectedCategoria &&
                  base.status == selectedStatus
              )
            );
          }
        } else if (selectedGravidade.length != 0) {
          setDataDef(
            response.data.records.filter(
              (base) =>
                base.nivelGravidade == selectedGravidade.toString() &&
                base.status == selectedStatus
            )
          );
        } else {
          setDataDef(
            response.data.records.filter(
              (base) => base.status == selectedStatus
            )
          );
        }
      });

      const total = dataDef.length;

      setpageCount(Math.ceil(total / limit));
      setItems(dataDef);
      setTotalRegistro(total);
    };
    getDados();
      }, 500);
     return () => clearInterval(interval);
  }, [selectedCategoria, selectedGravidade, selectedStatus]);

  const deleteItemSubChamado = async (event, cod) => {
    
   
    try {
      await api.get(`/tipoChamadoDescricao/existe/` + cod).then((response) => {
        setExiste(response.data.records.existe);

     

        if (response.data.records.existe === 1) {
          console.log("Verdadeiro ");
          
          setMensagemDialog("O item não pode ser excluído, pois está em uso.");
          setTipoDialog("error");
          setCorDialog("error");
          setOpenMsg(!openMsg);
        } else {
          console.log("Falso ");  
          api.delete(`tipoChamadoDescricao/` + cod);
          setMensagemDialog(`Operação realizada com sucesso!`);
          setTipoDialog("success");
          setCorDialog("success");
          setOpenMsg(!openMsg);
          setOpen(false);
  


        }
       
        
      });
    
    } catch (error) {
      setMensagemDialog(`Houve um erro na operação de exclusão.` + error);
      setTipoDialog("error");
      setCorDialog("error");
      //setOpenMsg(!openMsg);
    }


    
  };

  const handleClickOpen = (event, it) => {
    setDeleteData(it);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!data) return null;

  return (
    <div>
      <div>
        <div className="row">
          <div className="d-flex flex-wrap ">
            <div className="col-md-3 col-sm-3">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  Categoria
                </InputLabel>
                <Select
                  sx={{ m: 1, minWidth: 200 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select-label"
                  label="Categoria"
                  value={selectedCategoria}
                  onChange={handleChangeCategoria}
                  spacing={3}
                >
                  <MenuItem value="">Selecione</MenuItem>

                  {listCat
                    .sort((a, b) => {
                      if (a.tipo > b.tipo) return 1;
                      if (a.tipo < b.tipo) return -1;
                      return 0;
                    })
                    .map((item) => (
                      <MenuItem value={item.id}>{item.tipo}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex flex-wrap ">
            <div className="col-md-3 col-sm-3">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  Gravidade
                </InputLabel>
                <Select
                  sx={{ m: 1, minWidth: 200 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select-label"
                  label="Gravidade"
                  value={selectedGravidade}
                  onChange={handleChangeGravidade}
                  spacing={3}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem id="text-warning" value={"MEDIA"}>
                    MÉDIA
                  </MenuItem>
                  <MenuItem id="text-primary" value={"BAIXA"}>
                    BAIXA
                  </MenuItem>
                  <MenuItem id="text-danger" value={"ALTA"}>
                    ALTA
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex flex-wrap ">
            <div className="col-md-3 col-sm-3">
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  Status
                </InputLabel>
                <Select
                  sx={{ m: 1, minWidth: 200 }}
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select-label"
                  label="Status"
                  value={selectedStatus}
                  onChange={handleChangeStatus}
                  spacing={3}
                >
                  <MenuItem value="1">Ativo</MenuItem>
                  <MenuItem value="0">Inativo</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table>
              <caption></caption>
              <thead>
                <tr>
                  <th style={{ width: "10%", textAlign: "center" }} scope="col">
                    Editar
                  </th>
                  <th style={{ width: "8%", textAlign: "center" }} scope="col">
                    id
                  </th>
                  <th style={{ width: "30%", textAlign: "center" }} scope="col">
                    Categoria{" "}
                  </th>
                  <th style={{ width: "30%", textAlign: "center" }} scope="col">
                    SubCategoria{" "}
                  </th>
                  <th style={{ width: "10%", textAlign: "center" }} scope="col">
                    Gravidade{" "}
                  </th>

                  <th style={{ width: "8%", textAlign: "center" }} scope="col">
                    Status
                  </th>
                  <th style={{ width: "8%", textAlign: "center" }} scope="col">
                    Excluir
                  </th>
                </tr>
              </thead>

              <tbody>
                {dataDef.map((item) => {
                  const BadgeClass = (nivelGravidade) => {
                    if (nivelGravidade === "ALTA") {
                      return "badge bg-danger";
                    } else if (nivelGravidade === "MEDIA") {
                      return "badge bg-warning";
                    } else {
                      return "badge bg-primary";
                    }
                  };

                  return (
                    <tr key={item.id}>
                      <td>
                        <button
                          type="button"
                          className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                          onClick={(event) => {
                            handleClick(event, item.id);
                          }}
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                      </td>

                      <td
                        style={{ textAlign: "center" }}
                        data-label="Categoria "
                      >
                        {" "}
                        {addZeroes(item.id, 5)}
                      </td>

                      <td
                        style={{ textAlign: "center" }}
                        data-label="Categoria "
                      >
                        {" "}
                        {item.tipoChamado.tipo}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        data-label="SubCategoria "
                      >
                        {" "}
                        {item.descricao}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        data-label="Gravidade"
                      >
                        <span
                          className={BadgeClass(item.nivelGravidade)}
                          style={{ fontSize: 13 }}
                        >
                          {item.nivelGravidade}
                        </span>
                      </td>

                      <td data-label="Status" style={{ textAlign: "center" }}>
                        {item.status == 1 ? " Ativo" : " Inativo "}{" "}
                      </td>

                      <td data-label="btn btn">
                        <button
                          className="mb-xs mt-xs mr-xs btn btn-sm btn-light"
                          variant="outlined"
                          type="button"
                          onClick={(event) => {
                            handleClickOpen(event, item.id);
                          }}
                        >
                          <i
                            className="fa fa-solid fa-trash"
                            style={{
                              alignItems: "center",
                              fontSize: 18,
                              color: "#DC3545",
                            }}
                          ></i>
                        </button>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Esta ação é permanente !"}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              Deseja realmente excluir ?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Não</Button>
                            <Button
                            onClick={(event) => {
                              deleteItemSubChamado(event, deleteData);
                            }}
                              
                              autoFocus
                            >
                              Sim
                            </Button>
                            
                          </DialogActions>
                        </Dialog>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>

      <Dialog open={openMsg} >
                      <Alert
                        severity={tipoDialog}
                        color={corDialog}
                        role="button"
                        onClose={() => {
                          setOpenMsg(false);
                        }}
                        sx={{
                          //width: '80%',
                          //margin: 'auto',
                          "& .MuiAlert-icon": {
                            color: "blue",
                          },
                          //backgroundColor: "green"
                        }}
                      >
                        <AlertTitle></AlertTitle>
                        {mensagemDialog}
                      </Alert>
                    </Dialog>

      

      {/*  <GridComponent data={data} columns={columns} /> */}
    </div>
  );
};
