import { Radio, Box, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import api from "../../../services/api";
import { QRCodeCanvas } from "qrcode.react";

import { AlertSuccess, AlertDanger } from "./styles";

export const MaquinarioSetorEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [setores, setSetores] = useState();
  const [maquinario, setMaquinario] = useState([]);
  const [maquinaPesquisa, setMaquinaPesquisa] = useState([]);
  const [listSetores, setListSetores] = useState([]);
  const [observacao, setObservacao] = useState("");
  const [status, setStatus] = useState("1");
  const [qrCode, setQrCode] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });

  const [selectedSetores, setSelectedSetores] = useState(0);
  const [setorUnidade, setSetorUnidade] = useState("");
  const [selectedMaquinario, setSelectedMaquinario] = useState(0);

  const [numSerie, setNumSerie] = useState("");
  const [numPatrimonio, setNumPatrimonio] = useState("");

  const [nomeFabricante, setNomeFabricante] = useState("");
  const [site, setSite] = useState("");
  const [vinculado, setVinculado] = useState("");
  const [dadosTecnicos, setDadosTecnicos] = useState("");
  const [selectedOptionSetor, setSelectedOptionSetor] = useState(null);
  const [selectedOptionMaquinario, setSelectedOptionMaquinario] =
    useState(null);

  const idParam = id === 0 ? null : id;

  const [open, setOpen] = React.useState(false);
  const [descMaqui, setDescMaqui] = useState([]);
  const [descInfoSetor, setDescInfoSetor] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [unidProducao, setUnidProducao] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    p: 4,
    m: "auto",
  };
  const modal = {
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    m: "auto",
  };

  const handleChangeMaquinario = (event) => {
    let { value } = event.target;
    setMaquinario(value);
    setMaquinaPesquisa(value);
  };

  const PesquisaMaquHandle = () => {
    if (vinculado == "S") {
      return handleOpen();
    } else {
      return handleClose();
    }
  };

  const PesquisaMaquinario = ({ maquinario }) => {
    var string = `${maquinario}`;

    if (string.length >= 4) {
      try {
        api.get(`/maquinario/${maquinario}`).then((response) => {
          setDescMaqui(response.data.records);
          setVinculado(response.data.records[0].vinculoSetor);
          setDescInfoSetor(
            response.data.records[0].maquinarioSetores[0].setores.descricao
          );
          setUnidProducao(
            response.data.records[0].maquinarioSetores[0].setores
              .unidadeProducao.fantasia
          );

          setResponsavel(
            response.data.records[0].maquinarioSetores[0].setores.responsavel
              .nome
          );
        });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    }

    return (
      <>
        {descMaqui.map((maq) => (
          <input
            type="text"
            className="form-control"
            id="descPatrimonio"
            name="descPatrimonio"
            value={maq.descricao}
            placeholder="Desc. Patrimônio..."
            readOnly="True"
          />
        ))}
      </>
    );
  };

  /*Editar Registros*/
  const editMaquinarioSetores = async (e) => {
    /**
     * CRIADO UMA LISTA DE JSON SEPARADO
     * DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
     */

    e.preventDefault();
    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          observacao: observacao,
          status: status,
        };

        await api.put(`maquinario_setores/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          setores: selectedSetores != null ? selectedSetores : setores,
          maquinario: parseInt(maquinario),
          observacao: observacao,
          status: status,
        };

        await api.post(`maquinario_setores/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/maquinarioSetores",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    const getMaquinarioSetores = async () => {
      await api.get(`maquinario_setores/` + id).then((response) => {
        setSetores(response.data.records[0].setores);
        setSelectedSetores(response.data.records[0].setores.id);
        setSetorUnidade(response.data.records[0].setores.descricao);
        setMaquinario(response.data.records[0].maquinario.id);
        setSelectedMaquinario(response.data.records[0].maquinario.id);

        if (id !== "0") {
          PesquisaMaquinario(maquinario);
        }

        api
          .get(`maquinario/${response.data.records[0].maquinario.id}`)
          .then((res) => {
            setNumSerie(res.data.records[0].numSerie);
            setNumPatrimonio(res.data.records[0].numPatrimonio);
            setDadosTecnicos(res.data.records[0].dadosTecnicos);

            setQrCode(res.data.records[0].numPatrimonio);

            api
              .get(`fabricante/` + res.data.records[0].fabricante.id)
              .then((res1) => {
                setSite(res1.data.records[0].site);
                setNomeFabricante(res1.data.records[0].nome);
              });
          });

        setObservacao(response.data.records[0].observacao.toUpperCase());
        setStatus(
          response.data.records[0].status == null
            ? 0
            : response.data.records[0].status
        );
      });
    };

    const getSetores = async () => {
      await api.get(`setores`).then((response) => {
        setListSetores(response.data.records);
        setSelectedOptionSetor(response.data.records[0].id);
      });
    };

    if (id !== "0") {
      getMaquinarioSetores();
    }

    getSetores();
  }, [idParam]);

  /** OPTION RESPONSAVEL
   * Função para formatar os dados que serao utilizado no ReactSelect
   * - o formato que deve retornar é Value e Label
   */
  const optionsSetores = listSetores.map(function (respons) {
    return {
      value: respons.id,
      label:
        respons.descricao.toUpperCase() +
        (respons.unidadeProducao !== null
          ? " | " + respons.unidadeProducao?.fantasia.toUpperCase()
          : ""),
    };
  });

  /**
   * Responsavel por listar as mudanças feitas no componente
   * onde recebe o const vindo da lista de setor
   * @param {*} selectedOptionSetor
   */
  const handleChangeSetor = (selectedOptionSetor) => {
    setSelectedSetores(selectedOptionSetor.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <form
                  className="form-horizontal"
                  onSubmit={editMaquinarioSetores}
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="card card-comment card-outline">
                        <div
                          className="card-body"
                          style={{ textAlign: "center" }}
                        >
                          <QRCodeCanvas
                            style={{ width: "200px", height: "200px" }}
                            data-qr={qrCode}
                            value={qrCode}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9">
                      {(() => {
                        if (id != 0) {
                          return (
                            <div className="row">
                              <div className="col-sm-12">
                                <label className="col-sm-6 col-form-label">
                                  Setor | Unidade
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="descPatrimonio"
                                  name="descPatrimonio"
                                  value={setorUnidade + " | " + unidProducao}
                                  placeholder="Desc. Patrimônio..."
                                  readOnly="True"
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="row">
                              <div className="col-sm-12">
                                <label className="col-sm-6 col-form-label">
                                  Setor | Unidade
                                </label>
                                <div className="col-sm-10"></div>

                                <Select
                                  value={optionsSetores.filter(
                                    (obj) => obj.value === selectedSetores
                                  )}
                                  onChange={handleChangeSetor}
                                  options={optionsSetores}
                                  clearable={"true"}
                                  searchable={"true"}
                                  labelKey={"descricao"}
                                  valueKey={"id"}
                                  noOptionsMessage={() =>
                                    "Nenhum registro Encontrado"
                                  }
                                  required="required"
                                />
                              </div>
                            </div>
                          );
                        }
                      })()}

                      <div className="row">
                        <div className="col-sm-4">
                          <label className="col-sm-12 col-form-label">
                            Patrimônio
                          </label>
                          <input
                            disabled={id != 0}
                            type="text"
                            className="form-control"
                            id="maquinario"
                            name="maquinario"
                            value={maquinario}
                            placeholder="Núm. Patrimônio..."
                            onBlur={PesquisaMaquHandle}
                            onChange={(e) => setMaquinario(e.target.value)}
                          />
                        </div>

                        <div className="col-sm-8">
                          <label className="col-sm-8 col-form-label">
                            Desc. Patrimônio
                          </label>

                          <PesquisaMaquinario maquinario={maquinario} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label
                              htmlFor="inputExperience"
                              className="col-sm-6 col-form-label"
                            >
                              Observação
                            </label>
                            <textarea
                              className="form-control"
                              id="observacao"
                              placeholder="Observação..."
                              onChange={(e) => setObservacao(e.target.value)}
                              defaultValue={observacao}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <label className="col-sm-12 col-form-label">
                          Status{" "}
                        </label>
                        <div className="col-sm-10">
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Ativo
                          </FormLabel>
                          <Radio
                            checked={status == "1"}
                            onChange={(e) => setStatus(e.target.value)}
                            value="1"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "1" }}
                          />
                          <FormLabel id="demo-controlled-radio-buttons-group">
                            Inativo
                          </FormLabel>
                          <Radio
                            checked={status == "0"}
                            onChange={(e) => setStatus(e.target.value)}
                            value="0"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "0" }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <label className="col-sm-6 col-form-label">
                            Núm. Série
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="numSerie"
                            name="numSerie"
                            value={numSerie}
                            placeholder="Núm. Série..."
                            readOnly="True"
                          />
                        </div>

                        <div className="col-sm-6">
                          <label className="col-sm-6 col-form-label">
                            Núm. Patrimônio
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="numPatrimonio"
                            name="numPatrimonio"
                            value={numPatrimonio}
                            placeholder="Núm. Patrimônio..."
                            readOnly="True"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <label className="col-sm-6 col-form-label">
                            Fabricante
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fabricante"
                            name="fabricante"
                            value={nomeFabricante}
                            placeholder="Nome do fabricante..."
                            readOnly="True"
                          />
                        </div>

                        <div className="col-sm-6">
                          <label className="col-sm-6 col-form-label">
                            Site
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="site"
                            name="site"
                            value={site}
                            placeholder="Site"
                            readOnly="True"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label
                              htmlFor="inputExperience"
                              className="col-sm-6 col-form-label"
                            >
                              Dados Técnicos
                            </label>
                            <textarea
                              className="form-control"
                              id="dados_tecnicos"
                              placeholder="Dados técnicos..."
                              readOnly="True"
                              defaultValue={dadosTecnicos}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success"
                            disabled={vinculado == "S" && id == 0}
                          >
                            {" "}
                            Gravar{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
      <Modal
        sx={modal}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="info-box bg-danger">
            <span className="info-box-icon">
              <i className="fas fa-exclamation" />
            </span>
            <div className="info-box-content">
              <div className="header">
                <div className="row">
                  <div className="col-10 text-left">
                    <span className="info-box-text text-lg mb-3">
                      <b>Maquinário Alocado</b>
                    </span>
                  </div>

                  <div className="col-2 text-right">
                    <button
                      onClick={handleClose}
                      className="btn btn-light btn-xs"
                    >
                      <i className="fas fa-close" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <span className="progress-description">
                  <b>Setor: </b> {descInfoSetor}
                </span>
              </div>
              <div className="d-flex flex-wrap">
                <span className="progress-description">
                  <b>Responsável: </b> {responsavel}
                </span>
              </div>
              <div className="d-flex flex-wrap">
                <span className="progress-description">
                  {" "}
                  <b>Unidade Produção: </b> {unidProducao}
                </span>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
