import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar } from "@mui/material";
import SemImg from "../../../assets/sem-foto.svg";
import { CorGravidadeKanban } from "../../../utils/utils";
import { ReactComponent as Falha } from "../../../assets/falha.svg";
import { ReactComponent as Defeito } from "../../../assets/defeito.svg";
import { ReactComponent as Reparo } from "../../../assets/reparo.svg";
import { ReactComponent as Sdefinicao } from "../../../assets/sem_definicao.svg";
import { addZeroes, formatarDataBr } from "../../../utils/utils";
import { red } from "@mui/material/colors";

export default function CustomizedChamados({
  children,
  title,
  item,
  blobImage,
}) {
  const [open, setOpen] = useState(false);
  const [dadosItem, setDadosItem] = useState(item);

  const [fullWidth, setFullWidth] = useState(true);
  /* valores da caixa de dialog 
    md = medio
    xs = pequeno
    sm = default (entre o pequeno e medio )
    lg = largo
    xl = grande
     */
  const [maxWidth, setMaxWidth] = useState("md");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        className={`btn btn-default ${CorGravidadeKanban(
          item.tipoChamadoDesc[0] &&
            item.tipoChamadoDesc[0].tipoChamadoDescricao.nivelGravidade
        )}`}
        onClick={handleClickOpen}
        style={{
          width: "100%",
          float: "right",
        }}
      >
        <div className="row">
          <div
            className=""
            style={{
              width: "90%",
            }}
          >
            {blobImage ? (
              <img
                className="img-circle img-bordered-sm"
                src={blobImage}
                alt="Imagem"
              />
            ) : (
              <img
                className="img-circle img-bordered-sm"
                src={SemImg}
                alt="Photo"
              />
            )}
            <span
              className="username"
              style={{
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              O.S. {addZeroes(dadosItem.id, 6)} {dadosItem.tipoChamado}
            </span>
            <span
              className="username"
              style={{
                fontSize: "11px",
                textAlign: "left",
              }}
            >
              {dadosItem.mecanico?.usuario.nome}
            </span>
            <span
              className="description"
              style={{
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              Setor : {dadosItem.setor.descricao} -{" "}
              {dadosItem.patrimonio.descricao}
            </span>

            <span
              className="description"
              style={{
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              {" "}
              {item.status == 3 ? "Abert ." : "Abert."}{" "}
              {formatarDataBr(dadosItem.dataAbertura)}{" "}
            </span>

            <span
              className="description"
              style={{
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              {" "}
              {item.status == 2
                ? "Fech ."
                : item.status == 3
                ? "Homol."
                : ""}{" "}
              {item.status == 2
                ? formatarDataBr(
                    dadosItem.movimentoMecanico[0]?.dataFechamentoMecanico
                  )
                : item.status == 3
                ? formatarDataBr(dadosItem.dataFechamento)
                : ""}
            </span>
          </div>
          <div
            className="float-right"
            style={{
              width: "10%",
            }}
          >
            {dadosItem.tipoChamadoDesc[0] && (
              <span
                className="username float-right mr-1"
                style={{
                  fontSize: 12,
                  color:
                    dadosItem.tipoChamadoDesc[0].tipoChamadoDescricao
                      .nivelGravidade == "ALTA"
                      ? "red"
                      : dadosItem.tipoChamadoDesc[0].tipoChamadoDescricao
                          .nivelGravidade == "BAIXA"
                      ? "blue"
                      : "#fdbf06",
                }}
              >
                {dadosItem.tipoChamadoDesc[0] &&
                  dadosItem.tipoChamadoDesc[0].tipoChamadoDescricao
                    .nivelGravidade}
              </span>
            )}
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>{title}</DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {children}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
