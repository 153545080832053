import {Button, CircularProgress, FormControl, Grid, Stack, TextField, InputLabel, Select, MenuItem, styled, useMediaQuery, createTheme, responsiveFontSizes} from "@mui/material";
import React, { useEffect, useState } from "react";
import LogoPatrimonio from "../../assets/logoPatrimonio.svg";
import Logo from "../../assets/democrata-logo.svg";
import { PDFExport } from "@progress/kendo-react-pdf";
import dayjs from "dayjs";
import api from "../../services/api";
import { useParams, Link } from "react-router-dom/cjs/react-router-dom";
import { addZeroes, formatarData, formatarDataBr } from "../../utils/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useQuery } from "react-query";
import { routesContext } from "../../contexts/RoutesContext";

export const FiltroHistMecanicos = () => {
  const { id } = useParams();
  const { filterHistory, handleUpdateFilter } = routesContext();
  const [filteredSetores, setFilteredSetores] = useState([]);
  const [value, setValue] = useState(
    dayjs().subtract(20, "day").format("YYYY-MM-DD")
  );
  const [fantasia, setFantasia] = useState([]);
  const [valueFim, setValueFim] = useState(dayjs().format("YYYY-MM-DD"));
  const [listGrupo, setListGrupo] = useState([]);
  const [listSetor, setListSetor] = useState([]);
  const [unidadeProducao, setUnidadeProducao] = useState("Todos");
  const [setor, setSetor] = useState("Todos");
  const [setorNome, setSetorNome] = useState("");
  const [grupoNome, setGrupoNome] = useState("");
  const [grupo, setGrupo] = useState("Todos");

  const [dataImpressao, setDataImpressao] = useState(
    dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")
  );

  const getUnidade = async () => {
    const response = await api.get(`/unidadeproducao/`);
    return response.data.records;
  };

  const getSetor = async () => {
    if (unidadeProducao != "Todos") {
      const response = await api.get(
        `/setores/unidadeproducao/${unidadeProducao}`
      );
      setListSetor(response.data.records);
    } else if (filterHistory.unidadeFabricacao != "Todos") {
      const response = await api.get(
        `/setores/unidadeproducao/${filterHistory.unidadeFabricacao}`
      );
      setListSetor(response.data.records);
    }
    const response = await api.get(`/setores/`);
    return response.data.records;
  };

  const { data: listSetores, isLoading: isLoadingSetores } = useQuery(
    ["setoresData", unidadeProducao, filterHistory.unidadeFabricacao],
    getSetor
  );

  const getGrupos = async () => {
    await api.get(`grupomaquinario`).then((response) => {
      setListGrupo(response.data.records);
    });
  };

  const getFiltroData = async () => {
    let unidade =
      filterHistory.unidadeFabricacao == "Todos"
        ? unidadeProducao === "Todos"
          ? ""
          : `&unidadeProducao=${unidadeProducao}`
        : `&unidadeProducao=${filterHistory.unidadeFabricacao}`;
    let selectedSetor =
      filterHistory.setor == "Todos"
        ? ""
        : filterHistory.setor == ""
        ? ""
        : filterHistory.setor != "Todos"
        ? `&setor=${filterHistory.setor}`
        : setor === "Todos"
        ? ""
        : setor != "Todos"
        ? `&setor=${setor}`
        : "";
    let selectedGrupo =
      filterHistory.grupo == ""
        ? grupo === "Todos"
          ? ""
          : `&grupo=${grupo}`
        : `&grupo=${filterHistory.grupo}`;
    const response = await api.get(
      `chamado/dataabertura?maiorOuIgual=${
        filterHistory.dataInicial == "" ? value : filterHistory.dataInicial
      }&menorOuIgual=${filterHistory.dataFinal == "" ? valueFim : filterHistory.dataFinal}&mecanico=${id}${unidade}${selectedSetor}${selectedGrupo}`
    );
    return response.data.records;
  };

  const { data: chamado, isLoading: isLoadingChamado } = useQuery(
    [
      "chamados",
      value,
      valueFim,
      unidadeProducao,
      setor,
      listSetor,
      grupo,
      filteredSetores,
      filterHistory.unidadeFabricacao,
      filterHistory.setor,
      filterHistory.grupo,
      filterHistory.dataInicial,
      filterHistory.dataFinal,
    ],
    getFiltroData
    /* {
      enabled: !!id || (value !== undefined && valueFim !== undefined),
    } */
  );

  const { data: listUnidades, isLoading: isLoadingUnidades } = useQuery(
    "unidadesData",
    getUnidade
  );

  const { data: listGrupos, isLoading: isLoadingGrupos } = useQuery(
    "gruposData",
    getGrupos
  );

  const pdf = React.createRef(null);
  const handleExport = () => {
    pdf.current.save();
  };

  let somaHoras = 0;
  let somaMinutos = 0;

  chamado &&
    chamado.forEach((item) => {
      if (item.relacaoTempoAtendimento && item.relacaoTempoAtendimento[3]) {
        somaHoras += item.relacaoTempoAtendimento[3].horas;
        somaMinutos += item.relacaoTempoAtendimento[3].minutos;
      }
    });

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [isMobilee, setIsMobilee] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
    setIsMobilee(window.innerWidth <= 768);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
  }, []);



  if (isLoadingChamado) return <CircularProgress />;

  const PDFExportPageTemplate = (props) => (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  <div className="col-12">
                    <span>
                      Pg. {props.pageNum} / {props.totalPages}
                      <small className="float-right">
                        Data da Impressão {dataImpressao}
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  const ResponsiveImg = styled('img')(({ theme }) => ({
    width: '190px',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  }));

  const spanStyle = {
    display: 'inline-block',
    marginBottom: '5px',
    marginLeft: "5px",
    
  };
  
  const mobileStyle = {
    display: 'block',
    marginLeft: "0px",
  };

  console.log("chamado === ", chamado);

  const htmlFormat = () => {
    return (
      <>
        <Grid container columnSpacing={2} padding={1.5}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="col-sm-12">
              <div className="row">
                <span className="col-6 p-0">
                  <ResponsiveImg src={Logo} alt="Logo" />
                </span>
                <span className="col-6 p-0 text-right">
                <ResponsiveImg className="float-right" src={LogoPatrimonio} alt="LogoPatrimonio" />
                </span>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <h5>Análise de atendimento</h5>
                  </div>

                  <span className="col-6 text-right" style={{ fontSize: 13 }}>
                    Total Chamados: {!isLoadingChamado && chamado?.length}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <Grid container>
                  <Grid item>
                    {`Mecânico: ${chamado[0]?.mecanico?.usuario?.nome}`}
                  </Grid>
                  {isMobile && <div style={{width: '100%'}}></div>}
                  <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                    {`Período: ${formatarData(
                      filterHistory.dataInicial == '' ? value : filterHistory.dataInicial
                    ).slice(0, 10)} a ${formatarData(
                      filterHistory.dataFinal == '' ? valueFim : filterHistory.dataFinal
                    ).slice(0, 10)}`}
                  </Grid>
                  {isMobile && <div style={{width: '100%'}}></div>}
                  {unidadeProducao === 'Todos' && filterHistory.unidadeFabricacao === '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Unidade Produção: Todas`}
                    </Grid>
                  ) : unidadeProducao !== 'Todos' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Unidade Produção: ${fantasia}`}
                    </Grid>
                  ) : unidadeProducao === 'Todos' && filterHistory.unidadeFabricacao !== '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Unidade Produção: ${
                        listUnidades.find((option) => option.cgcCpf === filterHistory.unidadeFabricacao)?.fantasia ||
                        filterHistory.unidadeFabricacao
                      }`}
                    </Grid>
                  ) : null}
                  {isMobile && <div style={{width: '100%'}}></div>}
                  {setor === 'Todos' && filterHistory.setor === '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Setor: Todos`}
                    </Grid>
                  ) : setor !== 'Todos' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Setor: ${setorNome}`}
                    </Grid>
                  ) : setor === 'Todos' && filterHistory.setor !== '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Setor: ${
                        listSetor.find((option) => option.id === filterHistory.setor)?.descricao || filterHistory.setor
                      }`}
                    </Grid>
                  ) : null}
                  {isMobile && <div style={{width: '100%'}}></div>}
                  {grupo === 'Todos' && filterHistory.grupo === '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Grupo: Todos`}
                    </Grid>
                  ) : grupo !== 'Todos' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Grupo: ${grupoNome}`}
                    </Grid>
                  ) : grupo === 'Todos' && filterHistory.grupo !== '' ? (
                    <Grid item style={{ ...window.innerWidth <= 768 ? mobileStyle : spanStyle}}>
                      {`Grupo: ${
                        listGrupo.find((option) => option.id === filterHistory.grupo)?.descricao || filterHistory.grupo
                      }`}
                    </Grid>
                  ) : null}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} padding={1}>
          <div className="col-md-12">
            <div className="row">
              <div className="card">
                <table>
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "6%",
                          textAlign: "center",
                          backgroundColor: "#fbf2b7",
                        }}
                      >
                        Nº PAT.
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#fbf2b7",
                        }}
                      >
                        SETOR
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#fbf2b7",
                        }}
                      >
                        UNIDADE DE PRODUÇÃO
                      </th>

                      <th
                        style={{
                          width: "8%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        Nº CHAMADO
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        STATUS CHAMADO
                      </th>
                      <th
                        style={{
                          width: "10",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        SOLICITANTE
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        DATA HORA ABERTURA
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        DATA HORA DESIGNADO
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        DATA HORA ACEITE
                      </th>
                      <th
                        style={{
                          width: "10%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        DATA HORA FINALIZADO
                      </th>
                      <th
                        className="total"
                        style={{
                          width: "6%",
                          textAlign: "center",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        Tempo Gasto
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {!isLoadingChamado &&
                      chamado
                        .sort((a, b) => {
                          if (a.id < b.id) return 1;
                          if (a.id > b.id) return -1;
                          return 0;
                        })
                        .map((item) => {
                          return (
                            <tr key={item.id}>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#FFF5E6",
                                }}
                              >
                                <Link
                                  to={`/maquinarioHistorico/${item.patrimonio.id}`}
                                >
                                  {addZeroes(item.patrimonio.id, 6)}
                                </Link>
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#FFF5E6",
                                }}
                              >
                                {item.setor.descricao}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#FFF5E6",
                                }}
                              >
                                {item.setor.unidadeProducao.fantasia}
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                <Link to={`/resultadoChamadosItem/${item.id}`}>
                                  {addZeroes(item.id, 6)}
                                </Link>
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {item.status == 0
                                  ? "Aguardando"
                                  : item.status == 1
                                  ? "Andamento"
                                  : item.status == 2
                                  ? "Finalizado"
                                  : item.status == 3
                                  ? "Homologado"
                                  : item.status == 4
                                  ? "Retornado"
                                  : item.status == 5
                                  ? "Recusado"
                                  : item.status == 6
                                  ? "Encerrado pelo solicitante"
                                  : ""}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {item.solicitante.nome}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {formatarDataBr(item.dataAbertura)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {formatarDataBr(item.dataDesignacao)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {item.dataAceite == null
                                  ? ""
                                  : formatarDataBr(item.dataAceite)}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {item.movimentoMecanico == ""
                                  ? ""
                                  : formatarDataBr(
                                      item.movimentoMecanico[0]
                                        ?.dataFechamentoMecanico
                                    )}
                              </td>
                              <td
                                className="campo1"
                                id="campo1"
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#D6F0F5",
                                }}
                              >
                                {item?.relacaoTempoAtendimento[3]?.horas == null
                                  ? ""
                                  : item?.relacaoTempoAtendimento[3]?.horas >= 0
                                  ? `${addZeroes(
                                      item?.relacaoTempoAtendimento[3]?.horas,
                                      2
                                    )}:${addZeroes(
                                      item?.relacaoTempoAtendimento[3]?.minutos,
                                      2
                                    )}`
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          width: "100%",
                          textAlign: "right",
                          backgroundColor: "#B0E0E6",
                          borderBottom: "1px solid #BBBBBB",
                        }}
                      >
                        Tempo Total Gasto:{" "}
                      </th>

                      <th
                        scope="col"
                        style={{
                          width: "6%",
                          backgroundColor: "#B0E0E6",
                        }}
                      >
                        {(somaHoras + Math.floor(somaMinutos / 60))
                          .toString()
                          .padStart(2, "0")}
                        :{(somaMinutos % 60).toString().padStart(2, "0")}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </Grid>
      </>
    );
  };

  const htmlNull = () => {
    return (
      <>
        <div className="col-12">
          <div className="invoice p-3 mb-3">
            <div className="col-md-12">
              <div className="info-box bg-light">
                <div className="info-box-content">
                  <span className="info-box-text text-center text-muted">
                    Não Há Informações{" "}
                  </span>
                  <span className="info-box-number text-center text-muted mb-0">
                    Defina os Critérios de Pesquisa acima!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  

  return (
    <>
      <Grid container spacing={1} padding={1} alignItems="center">
        <Grid item container xs={12} spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={1.7} sx={{ mt: 1, ml: isMobile ? 0 : isTablet ? 0 : 1}}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="pt-br"
            >
              <Stack>
                <DatePicker
                  label="Data Inicial"
                  value={
                    filterHistory.dataInicial == ""
                      ? value
                      : filterHistory.dataInicial
                  }
                  onChange={(newValue) =>
                    handleUpdateFilter(
                      dayjs(newValue).format("YYYY-MM-DD"),
                      "dataInicial"
                    )
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  dayOfWeekFormatter={(day) => {
                    const daysOfWeek = [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab",
                    ];
                    return daysOfWeek[day];
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={1.7} sx={{ mt: 1 }}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="pt-br"
            >
              <Stack>
                <DatePicker
                  label="Data Final"
                  value={
                    filterHistory.dataFinal == ""
                      ? valueFim
                      : filterHistory.dataFinal
                  }
                  onChange={(newValue) =>
                    handleUpdateFilter(
                      dayjs(newValue).format("YYYY-MM-DD"),
                      "dataFinal"
                    )
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                  dayOfWeekFormatter={(day) => {
                    const daysOfWeek = [
                      "Dom",
                      "Seg",
                      "Ter",
                      "Qua",
                      "Qui",
                      "Sex",
                      "Sab",
                    ];
                    return daysOfWeek[day];
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} sm={6} md={2.5} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-controlled-open-select-label"
                style={{textAlign: "start", top: (filterHistory.unidadeFabricacao == "" ? "-17%" : "0%")
                }}
              >
                Unidade Produção
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                label="Unidade Produção"
                value={filterHistory.unidadeFabricacao}
                onChange={(e) =>
                  handleUpdateFilter(e.target.value, "unidadeFabricacao")
                }
                size="small"
              >
                <MenuItem value="">Selecione</MenuItem>
                {listUnidades &&
                  listUnidades
                    .sort((a, b) => {
                      if (a.fantasia > b.fantasia) return 1;
                      if (a.fantasia < b.fantasia) return -1;
                      return 0;
                    })
                    .map((item) => (
                      <MenuItem key={item.cgcCpf} value={item.cgcCpf}>
                        {item.fantasia}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={2.5} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-controlled-open-select-label"
                style={{ top: (filterHistory.setor == "" ? "-17%" : "0%") }}>
                Setor
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                label="Setor"
                value={filterHistory.setor}
                onChange={(e) => handleUpdateFilter(e.target.value, "setor")}
                size="small"
              >
                <MenuItem value="">Selecione</MenuItem>
                {listSetor &&
                  listSetor
                    .sort((a, b) => {
                      if (a.descricao > b.descricao) return 1;
                      if (a.descricao < b.descricao) return -1;
                      return 0;
                    })
                    .map((item) => (
                      <MenuItem value={item.id}>{item.descricao}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={2.2} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-controlled-open-select-label"
                
                style={{ top: (filterHistory.grupo == "" ? "-17%" : "0%") }}>
                Grupo
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select-label"
                label="Grupo"
                value={filterHistory.grupo}
                onChange={(e) => handleUpdateFilter(e.target.value, "grupo")}
                size="small"
              >
                <MenuItem value="">Selecione</MenuItem>
                {listGrupo &&
                  listGrupo
                    .sort((a, b) => {
                      if (a.descricao > b.descricao) return 1;
                      if (a.descricao < b.descricao) return -1;
                      return 0;
                    })
                    .map((item) => (
                      <MenuItem value={item.id}>{item.descricao}</MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={1.2} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <Button
                className="btn"
                size="small"
                style={{ height: "40px" }}
                onClick={handleExport}
                variant="outlined"
              >
                Gerar PDF
              </Button>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {(() => {
            if (!isLoadingChamado && chamado?.length == 0) {
              return htmlNull();
            } else {
              return (
                <PDFExport
                  scale={0.5}
                  landscape="true"
                  paperSize={"A4"}
                  margin="0.8cm"
                  fileName="historicoMec_.pdf"
                  forcePageBreak=".page-break"
                  pageTemplate={PDFExportPageTemplate}
                  ref={pdf}
                >
                  {htmlFormat()}
                </PDFExport>
              );
            }
          })()}
        </Grid>
      </Grid>
    </>
  );
};
