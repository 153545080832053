import { Component } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import KanbanGrid from "./Components/KanbanGrid";
class Kanban extends Component {
  handlePrint = (e) => {
    this.props.history.push("/kanbanPrint");
  };
  constructor(props) {
    super(props);
    this.state = {
      nomePagina: "Designados / Kanban",
      nomeNivelCrumb: "Kanban",
      nomeNivelCrumb2: "Página Inicial",
      nomeNivelCrumb3: "Ajuda",
    };
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <Menu />
        <div>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0">{this.state.nomePagina} </h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <p>
                              <i className="fa fa-map-marker mr-1" />
                              {this.state.nomeNivelCrumb}
                            </p>
                          </li>

                          <li className="breadcrumb-item active">
                            <a className="text-primary" href="/home/">
                              <i className="fa fa-home mr-1" />
                              {this.state.nomeNivelCrumb2}
                            </a>
                          </li>

                          <li className="breadcrumb-item active">
                            <a
                              className="text-danger text-bold"
                              href="https://doc-patrimonio.appdemocrata.com/docs/Movimentos/kanban/kanban"
                              target="_blank"
                            >
                              <i className="fa fa-info mr-1" />
                              {this.state.nomeNivelCrumb3}
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body" style={{ textAlign: "right" }}>
                        <a
                          className="btn btn-app bg-primary"
                          style={{ borderRadius: "5px" }}
                          onClick={this.handlePrint}
                        >
                          <i className="fas fa-print" /> Relatório
                        </a>
                      </div>
                      <KanbanGrid />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Kanban;
