import React, { Component } from "react";
import ChamadosAndamentoGrid from "./controles/listModal/andamentos/ChamadosAndamentoGrid";
import { MsgLiberacaoModulo } from "./controles/msgLiberacaoModulo";
import { LIBERAR_MODULO_CHAMADO } from "../utils/constants";
import { NavControle } from "./controles/NavControle";
import { FilterProvider } from "./controles/listModal/andamentos/FilterContext";

export default class Dashboard extends Component {
  render() {
    return (
      <FilterProvider>
        <div className="content-wrapper">
          <div className="content">
            {(() => {
              if (LIBERAR_MODULO_CHAMADO == "TRUE") {
                return (
                  <div className="col-md-12">
                    <div className="grey-bg container-fluid">
                      <section id="minimal-statistics">
                        <div className="row">
                          <div className="col-6 mt-3 mb-1">
                            <h4 className="text-uppercase">Movimentações</h4>
                            <p>Análise de Movimentos Chamados</p>
                          </div>
                          <div className="col-6 text-right mt-3 mb-1">
                            <a
                              className="text-danger text-bold"
                              href="https://doc-patrimonio.appdemocrata.com/docs/Movimentos/kanban/kanban"
                              target="_blank"
                              style={{ fontSize: "14px" }}
                            >
                              <i className="fas fa-info" /> Ajuda
                            </a>
                          </div>
                        </div>
                        <NavControle />
                        <div className="card">
                          <div className="col-lg-12">
                            <ChamadosAndamentoGrid />
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                );
              } else {
                return <MsgLiberacaoModulo />;
              }
            })()}
          </div>
        </div>
      </FilterProvider>
    );
  }
}
