import React, { useState, useEffect } from "react";

import api from "../../services/api";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useHistory,
} from "react-router-dom";
import { logout, getControle } from "../../services/auth";
import { Hidden } from "@mui/material";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
import { CallEndTwoTone } from "@mui/icons-material";

/**
 * Componente criado para renderizar os detalhes dos usuario logado
 * Este compoe junto com o componente Menu exibindo a foto e outros itens
 * de informacoes do usuario
 * @returns
 */
export const UserIdent = () => {
  const usuarioControle = getControle();
  const [data, setData] = useState([]);

  const [dataMec, setDataMec] = useState([]);

  const [uDescricao, setUDescricao] = useState([]);
  const [uEmail, setUEmail] = useState([]);
  const [uCCusto, setUCCusto] = useState([]);
  const [uImagem, setImagem] = useState([]);

  const [blobImage, setBlobImage] = useState([]);

  let history = useHistory();

  useEffect(() => {
    api.get(`/usuarioDetalhe/` + usuarioControle).then((response) => {
      setUDescricao(response.data.records[0].descricao);
      setUEmail(response.data.records[0].email);
      setUCCusto(response.data.records[0].ccusto);
    }, (error) => {
      logOff();
     
    });
  }, []);

  useEffect(() => {
    api.get(`/mecanico`).then((response) => {
      setDataMec(response.data.records);
    });
  }, []);

    useEffect(() => {
    if (usuarioControle === null) {
      logOff();
    }
  });

  const logOff = () => {
    //FUNCAO DO AUTH PADRAO
    logout();
    history.push({
      pathname: "/",
    });

    window.location.reload();
  };

  if (!data) return null;

  return (
    <div>
    

      <div className="mt-1 pb-1 mb-1 d-flex">   
        <div className="col-12 info">
          <div>
            <div>
              {(() => {
                if (uImagem != "") {
                  console.log("IMagem " + JSON.stringify(uImagem));
                  return (
                    <img
                      className="profile-user-img img-fluid img-circle elevation-5"
                      src={`data:image/jpeg;base64, ${mecanicoFilter}`}
                      alt="Perfil"
                      
                    />
                  );
                }
              })()}
            </div>
            <h3
              className="profile-username"
              style={{ color: "#FFF", fontSize: "16px", textAlign: "center", lineHeight: '1.5' }}
            >
              {" "}
              {uDescricao}
            </h3>
            
            <h3
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "12px" }}
            >
              {uEmail}
            </h3>
            <br/>
            <h3
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "12px" }}
            ></h3>
            <h2
              className="profile-username text-center"
              style={{ color: "#FFF", fontSize: "15px" }}
            >
              <a href="#" className="d-block" onClick={logOff}>
                Sair
              </a>
              <br /> <br/>
              <div style={{ borderBottom: '1px solid black' }}></div>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
